import { Component, Injectable, OnInit } from "@angular/core";
import { ActivatedRoute, ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from "@angular/router";
import { TitleService as LayoutTitleService } from "layout/title.service";
import { BreadcrumbService } from "layout/breadcrumb.service";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { JsonProperty, ObjectMapper } from "json-object-mapper";
import { Company } from "company/company";
import { AuthService } from "app/auth/auth.service";
import { CompanyInfo as VacancyCompanyInfo } from "./vacancy";
import { CompanyInfoForm } from "./vacancy.form";

export class CompanyInfoData {
    @JsonProperty({type: Company})
    public company: Company;

    @JsonProperty({type: VacancyCompanyInfo})
    public info: VacancyCompanyInfo;
}

@Injectable()
export class CompanyInfoResolver implements Resolve<CompanyInfoData> {

    public constructor(
        protected httpClient: HttpClient,
        protected authService: AuthService
    ) {
    }

    public resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<CompanyInfoData> {
        let companyId = route.params.companyId || this.authService.getUser().company.companyId;
        return this.httpClient.get<CompanyInfoData>('/v2/vacancies/company/' + companyId)
            .pipe(map(data => ObjectMapper.deserialize(CompanyInfoData, data)));
    }

}

@Component({
    selector: 'vacancy-company-info',
    templateUrl: './company-info.component.html'
})
export class CompanyInfoComponent implements OnInit {
    protected company: Company;
    protected companyInfo: VacancyCompanyInfo;
    public edit: boolean = false;
    public companyInfoForm: CompanyInfoForm;

    public constructor(
        protected route: ActivatedRoute,
        protected layoutTitleService: LayoutTitleService,
        protected breadcrumbService: BreadcrumbService,
        protected httpClient: HttpClient
    ) {
    }

    public ngOnInit(): void {
        this.route.data.subscribe(data => {
            this.company = data.component.company;
            this.companyInfo = data.component.info;
            this.layoutTitleService.title = 'Информация о компании';
            this.breadcrumbService.breadcrumbs = [
                {name: 'Вакансии', link: '/vacancies'},
                {name: this.company.name},
                {name: 'Информация о компании'}
            ];
            this.companyInfoForm = new CompanyInfoForm(this.companyInfo);
        });
    }

    public onSubmit() {
        this.companyInfoForm.disable();
        this.httpClient.put<{ info: any }>('/v2/vacancies/company/' + this.company.companyId, ObjectMapper.serialize({
            info: this.companyInfoForm.getInfo()
        })).toPromise().then((data) => {
            this.companyInfo = ObjectMapper.deserialize(CompanyInfoData, data).info;
            this.companyInfoForm = new CompanyInfoForm(this.companyInfo);
            this.edit = false;
        }).catch((error: any) => {
            console.error("Save vacancy company info error:", error);
            this.companyInfoForm.enable();
            throw error;
        });
    }

}
