<a *ngIf="!employee.isDeleted()" [routerLink]="editRouterLink" class="btn btn-white btn-xs float-right">
    Редактировать
</a>
<ng-template [ngIf]="canChangeStatus">
    <button *ngIf="employee.isDisabled()" (click)="onDelete($event.target)"
            type="button" [disabled]="disableButtons"
            class="btn btn-danger btn-xs float-right m-r-sm ladda-button" data-style="slide-right">
        Удалить
    </button>
    <button *ngIf="employee.isEnabled()" (click)="onDisable($event.target)"
            type="button" [disabled]="disableButtons"
            class="btn btn-warning btn-xs float-right m-r-sm ladda-button" data-style="slide-right">
        Заблокировать
    </button>
    <button *ngIf="employee.isDisabled()" (click)="onEnable($event.target)"
            type="button" [disabled]="disableButtons"
            class="btn btn-success btn-xs float-right m-r-sm ladda-button" data-style="slide-right">
        Разблокировать
    </button>
</ng-template>
