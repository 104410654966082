import { AbstractControl, FormControl, FormGroup, ValidationErrors, Validators } from "@angular/forms";
import { Employee } from "../employee";
import { HttpClient, HttpResponse } from "@angular/common/http";
import { CommonValidators } from "common/validators";

export class EmployeeDetailsForm extends FormGroup {

    public constructor(employee?: Employee, nameRequired: boolean = false) {
        super({
            firstName: new FormControl(employee ? employee.name.first : null),
            lastName: new FormControl(employee ? employee.name.last : null),
            middleName: new FormControl(employee ? employee.name.middle : null, [
                Validators.pattern(/^[a-zA-Zа-яёА-ЯЁ0-9 \-+_."/\\&():;]+$/),
                Validators.minLength(1),
                Validators.maxLength(50)
            ]),
            position: new FormControl(employee ? employee.position : null, [
                Validators.pattern(/^[a-zA-Zа-яёА-ЯЁ0-9 \-+_."/\\&():;]+$/),
                Validators.minLength(1),
                Validators.maxLength(50)
            ]),
            phone: new FormControl(employee ? employee.phone : null, [
                Validators.pattern(/^[0-9 \-+()]+$/),
                Validators.minLength(10),
                Validators.maxLength(50)
            ])
        });
        let firstNameValidators = [
            Validators.pattern(/^[a-zA-Zа-яёА-ЯЁ0-9 \-+_."/\\&():;]+$/),
            Validators.minLength(1),
            Validators.maxLength(50)
        ];
        let lastNameValidators = [
            Validators.pattern(/^[a-zA-Zа-яёА-ЯЁ0-9 \-+_."/\\&():;]+$/),
            Validators.minLength(1),
            Validators.maxLength(50)
        ];
        if (!nameRequired && employee) {
            nameRequired = !!employee.name.first || !!employee.name.last;
        }
        if (nameRequired) {
            firstNameValidators.push(Validators.required);
            lastNameValidators.push(Validators.required);
        }
        this.get('firstName').setValidators(firstNameValidators);
        this.get('lastName').setValidators(lastNameValidators);
    }

    public getEmployee(employee?: Employee): Employee {
        employee = employee ? employee : new Employee();
        employee.name.first = this.get('firstName').value;
        employee.name.last = this.get('lastName').value;
        let middleName: string | null = this.get('middleName').value;
        employee.name.middle = middleName && middleName.trim().length > 0 ? middleName.trim() : null;
        let position: string | null = this.get('position').value;
        employee.position = position && position.trim().length > 0 ? position.trim() : null;
        employee.phone = this.formatPhone(this.get('phone').value);
        return employee;
    }

    protected formatPhone(value: string): string {
        if (!value || value.trim().length == 0) {
            return null;
        }
        value = value.replace(/\D/g, '');
        if (value.charAt(0) == "8") {
            value = "7" + value.substr(1);
        }
        return "+" + value;
    }

}

export class EmployeeForm extends FormGroup {

    public constructor(httpClient: HttpClient, employee?: Employee, nameRequired: boolean = false) {
        super({
            email: new FormControl(employee ? employee.email : null, [
                Validators.required,
                Validators.minLength(1),
                Validators.maxLength(50),
                CommonValidators.email
            ], [
                EmployeeForm.emailValidator(httpClient, employee ? employee.email : null)
            ]),
            timezone: new FormControl(employee ? employee.timezone : 'Europe/Moscow', [
                Validators.required
            ]),
            city: new FormControl(employee ? employee.cityId : null, [
                Validators.required
            ]),
            details: new EmployeeDetailsForm(employee, nameRequired)
        });
    }

    public static emailValidator(httpClient: HttpClient, initial?: string): (control: AbstractControl) => Promise<ValidationErrors | null> {
        return (control) => {
            return new Promise((resolve, reject) => {
                if (control.value && initial && control.value.trim().toLowerCase() == initial.trim().toLowerCase()) {
                    resolve();
                    return;
                }
                httpClient.get<HttpResponse<any>>('/v2/employees/' + control.value, {
                    observe: 'response'
                }).toPromise().then(() => {
                    resolve({exists: true});
                }).catch((response: HttpResponse<any>) => {
                    if (response.status == 404) {
                        resolve();
                    } else {
                        reject(response);
                    }
                });
            });
        };
    }

    public getEmployee(): Employee {
        let employee = new Employee();
        employee.email = this.get('email').value;
        employee.timezone = this.get('timezone').value;
        employee.cityId = this.get('city').value;
        employee = (this.get('details') as EmployeeDetailsForm).getEmployee(employee);
        return employee;
    }

}
