import { CacheKey, Deserializer, Serializer } from "json-object-mapper";

@CacheKey("DateSerializerDeserializer")
export class DateSerializerDeserializer implements Deserializer, Serializer {

    public deserialize(value: number): Date {
        return new Date(value * 1000);
    }

    public serialize(value: Date): number {
        return value.getTime() / 1000;
    }

}

export function formatDate(date: Date): string {
    return date.getFullYear() + "-" + ("0" + (date.getMonth() + 1)).slice(-2) + "-" + ("0" + date.getDate()).slice(-2);
}
