import { EventEmitter, Injectable } from "@angular/core";
import { User } from "app/user";
import * as Cookies from 'js-cookie';
import { Employee } from "company/employee";

@Injectable()
export class AuthService {

    protected static readonly AUTH_TOKEN_COOKIE = 'erp-auth-token';
    protected static readonly REMEMBERME_TOKEN_COOKIE = 'erp-rememberme-token';

    protected user: User | null = null;

    public onAuthenticate: EventEmitter<User> = new EventEmitter<User>();
    public onLogout: EventEmitter<void> = new EventEmitter<void>();

    public getUser(): User | null {
        return this.user;
    }

    public setUser(user: User): void {
        this.user = user;
        this.onAuthenticate.emit(user);
    }

    public setEmployee(employee: Employee): void {
        if (this.isAuthenticated()) {
            this.user = new User(employee, this.getUser().hotels);
        }
    }

    public isAuthenticated(): boolean {
        return this.getUser() != null && (this.hasToken() || this.hasRemembermeToken());
    }

    public authenticateUser(token: string, user: User, remember: boolean = false) {
        this.setUser(user);
        if (remember) {
            this.setRemembermeToken(token);
        } else {
            this.setToken(token);
        }
    }

    public logout(): void {
        this.user = null;
        this.clearToken();
        this.clearRemembermeToken();
        this.onLogout.emit();
    }

    public hasToken(): boolean {
        return Cookies.get(AuthService.AUTH_TOKEN_COOKIE) ? true : false;
    }

    public getToken(): string | null {
        if (Cookies.get(AuthService.AUTH_TOKEN_COOKIE)) {
            return Cookies.get(AuthService.AUTH_TOKEN_COOKIE);
        }
        return null;
    }

    public setToken(token: string): void {
        Cookies.set(AuthService.AUTH_TOKEN_COOKIE, token);
    }

    public clearToken(): void {
        Cookies.remove(AuthService.AUTH_TOKEN_COOKIE);
    }

    public setRemembermeToken(token: string): void {
        Cookies.set(AuthService.REMEMBERME_TOKEN_COOKIE, token, {expires: 30});
    }

    public getRemembermeToken(): string | null {
        if (Cookies.get(AuthService.REMEMBERME_TOKEN_COOKIE)) {
            return Cookies.get(AuthService.REMEMBERME_TOKEN_COOKIE);
        }
        return null;
    }

    public clearRemembermeToken(): void {
        Cookies.remove(AuthService.REMEMBERME_TOKEN_COOKIE);
    }

    public hasRemembermeToken(): boolean {
        return Cookies.get(AuthService.REMEMBERME_TOKEN_COOKIE) ? true : false;
    }

}
