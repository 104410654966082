import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from "@angular/router";
import { AuthService } from "app/auth/auth.service";
import { FranchiseeEmployeePermissions } from "company/employee";

@Injectable()
export class VacanciesGuard implements CanActivate {

    public constructor(
        protected authService: AuthService
    ) {
    }

    public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        let user = this.authService.getUser();
        if (user.company.isManagementCompany()) {
            return true;
        }
        if (user.company.isFranchiseeCompany()) {
            let permissions = this.authService.getUser().permissions as FranchiseeEmployeePermissions;
            if (permissions.canEditVacancies) {
                return true;
            }
            for (let hotelPermissions of permissions.hotels) {
                if (hotelPermissions.permissions.canEditVacancies) {
                    return true;
                }
            }
            return false;
        }
        return false;
    }

}

@Injectable()
export class CanEditCompanyVacanciesGuard implements CanActivate {
    public constructor(
        protected authService: AuthService
    ) {
    }

    public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        return this.authService.getUser().permissions.canEditVacancies;
    }

}
