import { NgModule } from "@angular/core";
import { HotelRoutingModule } from "./hotel-routing.module";
import { HotelsActionComponent, HotelsComponent } from "./hotel/hotels.component";
import { CommonModule } from "@angular/common";
import { HotelComponent } from "./hotel/hotel.component";
import { EditHotelComponent } from "./hotel/edit-hotel.component";
import { ReactiveFormsModule } from "@angular/forms";
import { HotelFormComponent } from "./hotel/hotel-form.component";
import { NewHotelComponent } from "./hotel/new-hotel.component";
import { RoomsActionComponent, RoomsComponent } from "./room/rooms.component";
import { RoomComponent } from "./room/room.component";
import { NewRoomComponent } from "./room/new-room.component";
import { EditRoomComponent } from "./room/edit-room.component";
import { RoomFormComponent } from "./room/room-form.component";
import { RoomCategoryFormComponent } from "./room-category/room-category-form.component";
import { RoomCategoriesActionComponent, RoomCategoriesComponent } from "./room-category/room-categories.component";
import { RoomCategoryComponent } from "./room-category/room-category.component";
import { NewRoomCategoryComponent } from "./room-category/new-room-category.component";
import { EditRoomCategoryComponent } from "./room-category/edit-room-category.component";
import { CommonModule as AppCommonModule } from "common/common.module";
import { EmployeesActionComponent, EmployeesComponent } from "./employee/employees.component";
import { CompanyCommonModule } from "company/company-common.module";
import { EmployeeComponent } from "./employee/employee.component";
import { NewEmployeeComponent } from "./employee/new-employee.component";
import { EditEmployeeComponent } from "./employee/edit-employee.component";
import { PricesActionComponent, PricesComponent } from "./price/prices.component";
import { PriceActionComponent, PriceComponent } from "./price/price.component";
import { NewPriceComponent } from "./price/new-price.component";
import { PriceFormComponent } from "./price/price-form.component";
import { EditPriceComponent } from "./price/edit-price.component";

@NgModule({
    imports: [
        HotelRoutingModule,
        ReactiveFormsModule,
        CompanyCommonModule,
        CommonModule, AppCommonModule
    ],
    declarations: [
        HotelsComponent, HotelsActionComponent,
        HotelComponent, EditHotelComponent,
        HotelFormComponent, NewHotelComponent,
        RoomsComponent, RoomsActionComponent,
        RoomComponent, NewRoomComponent, EditRoomComponent,
        RoomFormComponent, RoomCategoryFormComponent,
        RoomCategoriesComponent, RoomCategoryComponent,
        NewRoomCategoryComponent, EditRoomCategoryComponent,
        RoomCategoriesActionComponent, EmployeesComponent,
        EmployeeComponent, EmployeesActionComponent,
        NewEmployeeComponent, EditEmployeeComponent,
        PricesComponent, PricesActionComponent, PriceComponent,
        NewPriceComponent, PriceFormComponent, EditPriceComponent,
        PriceActionComponent
    ]
})
export class HotelModule {
}
