import { Component, Injectable, OnInit } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { ActivatedRoute, ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot } from "@angular/router";
import { CityForm } from "./city.form";
import { JsonProperty, ObjectMapper } from "json-object-mapper";
import { City } from "../city";
import { Observable } from "rxjs";
import { Country } from "../country";
import { map } from "rxjs/operators";
import { TitleService as LayoutTitleService } from "layout/title.service";
import { BreadcrumbService } from "layout/breadcrumb.service";

export class NewCityData {
    @JsonProperty({type: Country})
    public countries: Country[];
}

@Injectable()
export class NewCityResolver implements Resolve<NewCityData> {

    public constructor(
        protected httpClient: HttpClient
    ) {
    }

    public resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<NewCityData> {
        return this.httpClient.get<NewCityData>("/v2/locations/countries")
            .pipe(map(data => ObjectMapper.deserialize(NewCityData, data)));
    }

}

@Component({
    selector: 'location-city-new',
    templateUrl: './new-city.component.html'
})
export class NewCityComponent implements OnInit {

    public cityForm: CityForm = new CityForm(this.httpClient);

    public countries: Country[] = [];

    public constructor(
        protected httpClient: HttpClient,
        protected router: Router,
        protected route: ActivatedRoute,
        protected layoutTitleService: LayoutTitleService,
        protected breadcrumbService: BreadcrumbService
    ) {
    }

    public ngOnInit(): void {
        this.route.data.subscribe((data) => {
            this.countries = data.component.countries;
        });
        this.layoutTitleService.title = 'Новый город';
        this.breadcrumbService.breadcrumbs = [
            {name: 'Города', link: '/locations/cities'},
            {name: 'Новый город'}
        ];
    }

    public onSubmit(): void {
        this.cityForm.disable();
        this.httpClient.post<{ city: any }>('/v2/locations/cities', ObjectMapper.serialize({
            city: this.cityForm.getCity()
        })).toPromise().then((data) => {
            let city = ObjectMapper.deserialize(City, data.city);
            this.router.navigate(['/locations/countries', city.country.alias, 'cities', city.alias]);
        }).catch((error: any) => {
            console.error("Create city error:", error);
            this.cityForm.enable();
            throw error;
        });
    }

}
