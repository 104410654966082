import { Component, OnDestroy, OnInit } from "@angular/core";
import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { Router } from "@angular/router";
import { GlobalConfig } from "app/global-config";
import { retry } from "rxjs/operators";

@Component({
    selector: 'phperp-redirect',
    templateUrl: './redirect.component.html'
})
export class RedirectComponent implements OnInit, OnDestroy {
    public appVersion = APP_VERSION;
    public showUserNotFoundError: boolean = false;

    public constructor(
        protected globalConfig: GlobalConfig,
        protected httpClient: HttpClient,
        protected router: Router
    ) {
    }

    public ngOnInit(): void {
        jQuery('body').addClass('gray-bg');
        this.httpClient.post<{ token: string }>('/v2/phperp/token', {})
            .pipe(retry(2))
            .subscribe(data => {
                let onetimeToken = data.token;
                let redirectUrl = 'https://erp.likehostels.ru/auth/onetime?token=' + onetimeToken;
                if (this.globalConfig.isProductionMode()) {
                    window.location.href = redirectUrl;
                } else {
                    console.log('Redirect to', redirectUrl);
                    redirectUrl = 'http://erp.likehostels/auth/onetime?token=' + onetimeToken;
                    window.location.href = redirectUrl;
                }
            }, error => {
                if (error instanceof HttpErrorResponse && error.status == 403) {
                    this.showUserNotFoundError = true;
                } else {
                    console.error("Redirect to PHP ERP error:", error);
                    this.router.navigate(['/']);
                    throw error;
                }
            });
    }

    public ngOnDestroy(): void {
        jQuery('body').removeClass('gray-bg');
    }

}
