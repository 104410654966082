import { Component, Injectable, OnInit } from "@angular/core";
import { Vacancy } from "./vacancy";
import { ActivatedRoute, ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from "@angular/router";
import { TitleService as LayoutTitleService } from "layout/title.service";
import { BreadcrumbService } from "layout/breadcrumb.service";
import { JsonProperty, ObjectMapper } from "json-object-mapper";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

export class VacanciesArchiveVacancy extends Vacancy {
    @JsonProperty({name: 'responses_count'})
    public responsesCount: number;
}

export class VacanciesArchiveData {
    @JsonProperty({type: VacanciesArchiveVacancy})
    public vacancies: VacanciesArchiveVacancy[];
}

@Injectable()
export class VacanciesArchiveResolver implements Resolve<VacanciesArchiveData> {

    public constructor(
        protected httpClient: HttpClient
    ) {
    }

    public resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<VacanciesArchiveData> {
        return this.httpClient.get<VacanciesArchiveData>('/v2/vacancies?status=archived')
            .pipe(map(data => ObjectMapper.deserialize(VacanciesArchiveData, data)));
    }

}

@Component({
    selector: 'vacancy-vacancies-archive',
    templateUrl: './vacancies-archive.component.html'
})
export class VacanciesArchiveComponent implements OnInit {
    public vacancies: VacanciesArchiveVacancy[] = [];

    public constructor(
        protected route: ActivatedRoute,
        protected layoutTitleService: LayoutTitleService,
        protected breadcrumbService: BreadcrumbService
    ) {
    }

    public ngOnInit(): void {
        this.layoutTitleService.title = 'Архив вакансии';
        this.breadcrumbService.breadcrumbs = [
            {name: 'Вакансии', link: '/vacancies'},
            {name: 'Архив'}
        ];
        this.route.data.subscribe(data => {
            this.vacancies = data.component.vacancies;
        });
    }

    public trackByVacancy(index: number, vacancy: Vacancy): number {
        return vacancy.vacancyId;
    }

}
