<ul class="nav metismenu" #metisMenu>
    <li routerLinkActive="active">
        <a>
            <i class="fa fa-building"></i>
            <span class="nav-label">Компании</span>
            <span class="fa arrow"></span>
        </a>
        <ul class="nav nav-second-level" routerLinkActive="in">
            <li routerLinkActive="active"><a routerLink="/companies">Компании</a></li>
            <li routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}">
                <a routerLink="/hotels">Отели</a>
            </li>
            <li routerLinkActive="active"><a routerLink="/employees">Сотрудники</a></li>
        </ul>
    </li>
    <li routerLinkActive="active">
        <a>
            <i class="fa fa-compass"></i>
            <span class="nav-label">Города</span>
            <span class="fa arrow"></span>
        </a>
        <ul class="nav nav-second-level" routerLinkActive="in">
            <li routerLinkActive="active"><a routerLink="/locations/countries">Страны</a></li>
            <li routerLinkActive="active"><a routerLink="/locations/cities">Города</a></li>
        </ul>
    </li>
    <li routerLinkActive="active">
        <a routerLink="/vacancies">
            <i class="far fa-handshake"></i>
            <span class="nav-label">Вакансии</span>
        </a>
    </li>
</ul>
