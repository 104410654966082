<h3>Управление компанией</h3>
<div [formGroup]="permissionsForm">
    <div class="row">
        <div class="col-4">
            <div>
                <label>
                    <input type="checkbox" formControlName="canEditEmployees">
                    Управление сотрудниками
                </label>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-4">
            <div>
                <label>
                    <input type="checkbox" formControlName="canEditVacancies">
                    Управление вакансиями
                </label>
            </div>
        </div>
    </div>
</div>
