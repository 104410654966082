import { Component, OnDestroy, OnInit } from "@angular/core";
import { Title } from "@angular/platform-browser";
import './error404.component.scss';

@Component({
    selector: 'error-404',
    templateUrl: './error404.component.html'
})
export class Error404Component implements OnInit, OnDestroy {

    public appVersion = APP_VERSION;

    public constructor(
        protected title: Title
    ) {}

    public ngOnInit(): void {
        jQuery('body').addClass('gray-bg');
        this.title.setTitle('Страница не найдена | Лайк Хостел ERP');
    }

    public ngOnDestroy(): void {
        jQuery('body').removeClass('gray-bg');
    }

}
