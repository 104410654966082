<div class="row border-bottom">
    <nav class="navbar navbar-static-top" role="navigation" style="margin-bottom: 0">
        <div class="navbar-header">
            <a class="navbar-minimalize minimalize-styl-2 btn btn-primary" href="#">
                <i class="fa fa-bars"></i>
            </a>
        </div>
        <ul class="nav navbar-top-links navbar-right">
            <li>
                <a routerLink="/phperp/redirect" target="_blank">
                    <i class="fa fa-tachometer-alt"></i>
                    Старая ERP
                </a>
            </li>
            <li>
                <a routerLink="/logout">
                    <i class="fa fa-sign-out-alt"></i>
                    Выход
                </a>
            </li>
        </ul>
    </nav>
</div>
