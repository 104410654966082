import { Injectable } from "@angular/core";
import { CanActivate, Router } from "@angular/router";
import { AuthService } from "./auth.service";

@Injectable()
export class AuthGuard implements CanActivate {

    public constructor(
        protected authService: AuthService,
        protected router: Router
    ) {
    }

    public canActivate(): boolean {
        if (!this.authService.isAuthenticated()) {
            this.router.navigate(['/login']);
        }
        return true;
    }

}
