import { Component, Injectable, OnInit } from "@angular/core";
import { LayoutService } from "layout/layout.service";
import { Title } from "@angular/platform-browser";
import { AuthService } from "app/auth/auth.service";
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from "@angular/router";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { ObjectMapper } from "json-object-mapper";
import { ManagementDashboardData } from "./management.component";
import { FranchiseeDashboardData } from "./franchisee.component";

@Injectable()
export class DashboardResolver implements Resolve<ManagementDashboardData | FranchiseeDashboardData> {

    public constructor(
        protected httpClient: HttpClient,
        protected authService: AuthService
    ) {
    }

    public resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<ManagementDashboardData | FranchiseeDashboardData> {
        return this.httpClient.get<ManagementDashboardData>('/v2/dashboard')
            .pipe(map(data => {
                if (this.authService.getUser().company.isManagementCompany()) {
                    return ObjectMapper.deserialize(ManagementDashboardData, data);
                }
                if (this.authService.getUser().company.isFranchiseeCompany()) {
                    return ObjectMapper.deserialize(FranchiseeDashboardData, data);
                }
                return null;
            }));
    }

}

@Component({
    selector: 'dashboard',
    templateUrl: './dashboard.component.html'
})
export class DashboardComponent implements OnInit {

    public constructor(
        protected title: Title,
        protected layoutService: LayoutService,
        protected authService: AuthService
    ) {
    }

    public ngOnInit(): void {
        this.title.setTitle('Добро пожаловать в Лайк Хостел ERP!');
        this.layoutService.showHeading = false;
    }

    public isManagementDashboard(): boolean {
        return this.authService.getUser().company.isManagementCompany();
    }

    public isFranchiseeDashboard(): boolean {
        return this.authService.getUser().company.isFranchiseeCompany();
    }

}
