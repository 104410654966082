<router-outlet></router-outlet>

<div class="container mt-3 mb-3">
    <div class="clearfix">
        <a routerLink="/logout" class="float-right btn btn-outline btn-default">Выход из системы</a>
    </div>
    <hr/>
    <div class="row">
        <div class="col-8">
            Лайк Хостел &copy; 2014-2018
        </div>
        <div class="col-4 text-right">
            <small>ERP v{{ appVersion }}</small>
        </div>
    </div>
</div>
