import { Component, Injectable, OnDestroy, OnInit } from "@angular/core";
import { mergeRouteData } from "app/merge-route-data";
import { ActivatedRoute, ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot } from "@angular/router";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs/index";
import { Hotel } from "../hotel";
import { map } from "rxjs/operators";
import { JsonProperty, ObjectMapper } from "json-object-mapper";
import { TitleService as LayoutTitleService } from "layout/title.service";
import { BreadcrumbService } from "layout/breadcrumb.service";
import { RoomCategory } from "../room-category";
import { RoomCategoryForm } from "./room-category.form";
import { HotelSidebarService } from "layout/sidebar/hotel.service";

export class EditRoomCategoryData {
    @JsonProperty({type: Hotel})
    public hotel: Hotel;

    @JsonProperty({type: RoomCategory})
    public category: RoomCategory;
}

@Injectable()
export class EditRoomCategoryResolver implements Resolve<EditRoomCategoryData> {

    public constructor(
        protected httpClient: HttpClient
    ) {
    }

    public resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<EditRoomCategoryData> {
        let hotel: Hotel = mergeRouteData(route).component.hotel;
        return this.httpClient.get<EditRoomCategoryData>('/v2/hotels/' + hotel.hotelId + '/rooms/categories/' + route.params.roomCategoryId)
            .pipe(map(data => ObjectMapper.deserialize(EditRoomCategoryData, data)));
    }

}

@Component({
    selector: 'hotel-room-category-edit',
    templateUrl: './edit-room-category.component.html'
})
export class EditRoomCategoryComponent implements OnInit, OnDestroy {
    public hotel: Hotel;
    public category: RoomCategory;
    public categoryForm: RoomCategoryForm = new RoomCategoryForm();

    public constructor(
        protected route: ActivatedRoute,
        protected httpClient: HttpClient,
        protected router: Router,
        protected layoutTitleService: LayoutTitleService,
        protected breadcrumbService: BreadcrumbService,
        protected hotelSidebarService: HotelSidebarService
    ) {
    }

    public ngOnInit(): void {
        this.route.data.subscribe((data) => {
            this.hotel = data.component.hotel;
            this.category = data.component.category;
            this.categoryForm = new RoomCategoryForm(this.category);
            this.layoutTitleService.title = this.category.name;
            this.layoutTitleService.small = 'Редактирование категории номера';
            this.breadcrumbService.breadcrumbs = [
                {name: 'Отели', link: '/hotels'},
                {name: this.hotel.name, link: ['/hotels', this.hotel.alias]},
                {name: 'Категории номеров', link: ['/hotels', this.hotel.alias, 'rooms', 'categories']},
                {
                    name: this.category.name,
                    link: ['/hotels', this.hotel.alias, 'rooms', 'categories', this.category.roomCategoryId]
                },
                {name: 'Редактирование'}
            ];
            this.categoryForm.get('name').valueChanges.subscribe((value) => {
                this.layoutTitleService.title = value || 'Категория';
                this.breadcrumbService.breadcrumbs = [
                    {name: 'Отели', link: '/hotels'},
                    {name: this.hotel.name, link: ['/hotels', this.hotel.alias]},
                    {name: 'Категории номеров', link: ['/hotels', this.hotel.alias, 'rooms', 'categories']},
                    {
                        name: value || 'Категория',
                        link: ['/hotels', this.hotel.alias, 'rooms', 'categories', this.category.roomCategoryId]
                    },
                    {name: 'Редактирование'}
                ];
            });
            this.hotelSidebarService.setHotel(this.hotel);
        });
    }

    public ngOnDestroy(): void {
        this.hotelSidebarService.clearHotel();
    }

    public onSubmit(): void {
        this.categoryForm.disable();
        let category = this.categoryForm.getRoomCategory();
        category.type = this.category.type;
        this.httpClient.put<{ category: any }>('/v2/hotels/' + this.hotel.hotelId + '/rooms/categories/' + this.category.roomCategoryId, ObjectMapper.serialize({
            category: category
        })).toPromise().then((data) => {
            let category = ObjectMapper.deserialize(RoomCategory, data.category);
            this.router.navigate(['/hotels', this.hotel.alias, 'rooms', 'categories', category.roomCategoryId]);
        }).catch((error: any) => {
            console.error("Save room category error:", error);
            this.categoryForm.enable();
            throw error;
        });
    }

}
