import { Component, Input } from "@angular/core";
import { RoomForm } from "./room.form";
import { RoomCategory, RoomCategoryType } from "../room-category";

@Component({
    selector: 'hotel-room-form',
    templateUrl: 'room-form.component.html'
})
export class RoomFormComponent {

    @Input('roomForm')
    public roomForm: RoomForm;

    @Input('categories')
    public categories: RoomCategory[] = [];

    public trackByCategory(index: number, category: RoomCategory): number {
        return category.roomCategoryId;
    }

    public getCategoryName(category: RoomCategory): string {
        let capacity: string = category.capacity.guests.toString();
        if (category.capacity.additional) {
            capacity += '+' + category.capacity.additional;
        }
        switch (category.type) {
            case RoomCategoryType.Standart:
                return category.name + ' (стандартный номер, ' + capacity + ' гостей)';
            case RoomCategoryType.Shared:
                return category.name + ' (общий номер, ' + capacity + ' гостей)';
            case RoomCategoryType.SharedMale:
                return category.name + ' (общий мужской номер, ' + capacity + ' гостей)';
            case RoomCategoryType.SharedFemale:
                return category.name + ' (общий женский номер, ' + capacity + ' гостей)';
            default:
                return category.name;
        }
    }

}
