<fieldset [formGroup]="hotelForm">
    <div class="form-group row"
         [class.has-error]="!hotelForm.controls.name.disabled && hotelForm.controls.name.value && !hotelForm.controls.name.valid">
        <label class="col-2 col-form-label" for="form-hotel-name">Название:</label>
        <div class="col-10">
            <input type="text" class="form-control"
                   id="form-hotel-name"
                   formControlName="name">
        </div>
    </div>
    <div class="form-group row"
         [class.has-error]="!hotelForm.controls.alias.disabled && hotelForm.controls.alias.value && !hotelForm.controls.alias.valid">
        <label class="col-2 col-form-label" for="form-hotel-alias">Алиас:</label>
        <div class="col-10">
            <input type="text" class="form-control"
                   id="form-hotel-alias" formControlName="alias">
        </div>
    </div>
</fieldset>
