import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from "@angular/router";
import { ActionService } from "./action.service";

@Injectable()
export class AllActionsResolved implements CanActivate {

    public constructor(
        protected actionService: ActionService,
        protected router: Router
    ) {
    }

    public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        if (this.actionService.hasUnresolvedActions()) {
            this.router.navigate(['/actions']);
            return false;
        }
        return true;
    }

}
