<div class="ibox">
    <div class="ibox-content">
        <div class="m-b-md">
            <employee-buttons *canEditHotelEmployees="hotel" [(employee)]="employee"
                              [editRouterLink]="['/hotels', hotel.alias, 'employees', employee.employeeId, 'edit']"
            ></employee-buttons>
            <h2>{{ employeeName }}</h2>
        </div>
        <employee-view [employee]="employee"></employee-view>
        <hr class="hr-line-dashed">
        <h2>Права доступа</h2>
        <employee-permissions-view [employee]="employee"
                                   [hotels]="[hotel]"
                                   [viewAllHotelsPermissions]="false"></employee-permissions-view>
        <h2 class="text-muted text-right">#{{ employee.employeeId }}</h2>
    </div>
</div>
