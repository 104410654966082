import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { JsonProperty } from "json-object-mapper";

export class ManagementDashboardData {
    @JsonProperty()
    public type: 'management';
}

@Component({
    selector: 'dashboard-management',
    templateUrl: './management.component.html'
})
export class ManagementDashboardComponent implements OnInit {

    public constructor(
        protected route: ActivatedRoute
    ) {
    }

    public ngOnInit(): void {
        this.route.data.subscribe((data) => {
        });
    }

}
