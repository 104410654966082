import { AbstractControl, FormControl, FormGroup, ValidationErrors, Validators } from "@angular/forms";
import { HttpClient, HttpResponse } from "@angular/common/http";
import { City } from "../city";

export class CityForm extends FormGroup {

    public constructor(httpClient: HttpClient, city?: City) {
        super({
            alias: new FormControl(city ? city.alias : null, [
                Validators.required,
                Validators.pattern(/^[a-zA-Z][a-zA-Z0-9\-_]+$/),
                Validators.minLength(2),
                Validators.maxLength(20)
            ], [
                CityForm.aliasValidator(httpClient, city ? city.alias : null)
            ]),
            name: new FormControl(city ? city.name : null, [
                Validators.required,
                Validators.pattern(/^[a-zA-Zа-яёА-ЯЁ0-9 \-+_."/\\&():;]+$/),
                Validators.minLength(1),
                Validators.maxLength(50)
            ]),
            country: new FormControl(city ? city.country.countryId : null, [
                Validators.required
            ]),
            timezone: new FormControl(city ? city.timezone : 'Europe/Moscow', [
                Validators.required
            ])
        });
    }

    public static aliasValidator(httpClient: HttpClient, initial?: string): (control: AbstractControl) => Promise<ValidationErrors | null> {
        return (control) => {
            return new Promise((resolve, reject) => {
                if (control.value && initial && control.value.trim().toLowerCase() == initial.trim().toLowerCase()) {
                    resolve();
                    return;
                }
                httpClient.get<HttpResponse<any>>('/v2/alias/' + control.value, {
                    observe: 'response'
                }).toPromise().then(() => {
                    resolve({exists: true});
                }).catch((response: HttpResponse<any>) => {
                    if (response.status == 404) {
                        resolve();
                    } else {
                        reject(response);
                    }
                });
            });
        };
    }

    public getCity(): City {
        let city = new City();
        city.alias = this.get('alias').value;
        city.name = this.get('name').value;
        city.countryId = this.get('country').value;
        city.timezone = this.get('timezone').value;
        return city;
    }

}
