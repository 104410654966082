<div class="ibox-content">
    <form [formGroup]="categoryForm" (ngSubmit)="onSubmit()">
        <hotel-room-category-form [categoryForm]="categoryForm" [category]="category"></hotel-room-category-form>
        <div class="form-group row">
            <div class="col-10 offset-2">
                <a class="btn btn-white"
                   [routerLink]="['/hotels', hotel.alias, 'rooms', 'categories', category.roomCategoryId]">Отмена</a>
                &nbsp;
                <button class="btn btn-primary" type="submit" [disabled]="!categoryForm.valid">Сохранить</button>
            </div>
        </div>
    </form>
</div>
