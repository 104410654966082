import { Injectable } from "@angular/core";

@Injectable()
export class ActionService {
    protected actions: Map<string, boolean> = new Map<string, boolean>(); // true = unresolved

    public hasUnresolvedActions(): boolean {
        return this.countUnresolvedActions() > 0;
    }

    public countUnresolvedActions(): number {
        return this.getUnresolvedActions().length;
    }

    public getUnresolvedActions(): string[] {
        let actions: string[] = [];
        this.actions.forEach((value, key) => {
            if (value) actions.push(key);
        });
        return actions;
    }

    public resolveAction(action: string): void {
        this.actions.set(action, false);
    }

    public addAction(action: string): void {
        this.actions.set(action, true);
    }

    public clearActions(): void {
        this.actions.clear();
    }

    public nextUnresolvedAction(): string | null {
        return this.getUnresolvedActions().pop() || null;
    }

    public hasUnresolvedAction(action: string): boolean {
        return this.getUnresolvedActions().indexOf(action) > -1;
    }

}
