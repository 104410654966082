import { Pipe, PipeTransform } from "@angular/core";
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";

@Pipe({
    name: 'nl2br'
})
export class Nl2brPipe implements PipeTransform {

    public constructor(
        protected domSanitizer: DomSanitizer
    ) {
    }

    public transform(value: string): SafeHtml {
        return this.domSanitizer.bypassSecurityTrustHtml(
            value.replace(/(?:\r\n|\r|\n)/g, '<br/>')
        );
    }

}
