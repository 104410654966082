import { AccessType, CacheKey, Deserializer, JsonIgnore, JsonProperty, Serializer } from "json-object-mapper";
import { Company } from "company/company";
import { City } from "location/city";

export enum HotelType {
    Hotel = 'hotel',
    Hostel = 'hostel'
}

export enum HotelStatus {
    Disabled = 'disabled',
    Enabled = 'enabled',
    Deleted = 'deleted'
}

@CacheKey("HotelTypeSerializerDeserializer")
class HotelTypeSerializerDeserializer implements Deserializer, Serializer {

    public deserialize(value: string): HotelType {
        return HotelType[value.charAt(0).toUpperCase() + value.slice(1).toLowerCase()];
    }

    public serialize(value: HotelType): string {
        return '"' + HotelType[value.charAt(0).toUpperCase() + value.slice(1).toLowerCase()].toLowerCase() + '"';
    }

}

@CacheKey("HotelStatusSerializerDeserializer")
class HotelStatusSerializerDeserializer implements Deserializer, Serializer {

    public deserialize(value: string): HotelStatus {
        return HotelStatus[value.charAt(0).toUpperCase() + value.slice(1).toLowerCase()];
    }

    public serialize(value: HotelStatus): string {
        return '"' + HotelStatus[value.charAt(0).toUpperCase() + value.slice(1).toLowerCase()].toLowerCase() + '"';
    }

}

export class Hotel {
    @JsonProperty({name: 'hotel_id', access: AccessType.READ_ONLY})
    public readonly hotelId: number;

    @JsonProperty({
        type: HotelStatus,
        serializer: HotelStatusSerializerDeserializer,
        deserializer: HotelStatusSerializerDeserializer
    })
    public status: HotelStatus;

    @JsonIgnore()
    public isEnabled(): boolean {
        return this.status == HotelStatus.Enabled;
    }

    @JsonIgnore()
    public isDisabled(): boolean {
        return this.status == HotelStatus.Disabled;
    }

    @JsonIgnore()
    public isDeleted(): boolean {
        return this.status == HotelStatus.Deleted;
    }

    @JsonProperty({
        type: HotelType,
        serializer: HotelTypeSerializerDeserializer,
        deserializer: HotelTypeSerializerDeserializer
    })
    public type: HotelType;

    @JsonProperty({name: 'company', required: false, type: Company, access: AccessType.READ_ONLY})
    public company?: Company;

    @JsonIgnore()
    private _companyId: number;

    public get companyId(): number {
        if (this.company) {
            return this.company.companyId;
        }
        return this._companyId;
    }

    @JsonProperty({name: "company_id", required: false, access: AccessType.WRITE_ONLY})
    public set companyId(companyId: number) {
        if (this.companyId != companyId) {
            this.company = null;
        }
        this._companyId = companyId;
    }

    @JsonProperty()
    public name: string;

    @JsonProperty()
    public alias: string;

    @JsonProperty({name: 'city', required: false, type: City, access: AccessType.READ_ONLY})
    public city?: City;

    @JsonIgnore()
    private _cityId: number;

    public get cityId(): number {
        if (this.city) {
            return this.city.cityId;
        }
        return this._cityId;
    }

    @JsonProperty({name: "city_id", required: false, access: AccessType.WRITE_ONLY})
    public set cityId(cityId: number) {
        if (this.cityId != cityId) {
            this.city = null;
        }
        this._cityId = cityId;
    }

    @JsonProperty({name: 'arrival_time', access: AccessType.READ_ONLY})
    public readonly arrivalTime: number;

    @JsonProperty({name: 'departure_time', access: AccessType.READ_ONLY})
    public readonly departureTime: number;

}
