<div class="ibox-content">
    <form [formGroup]="roomForm" (ngSubmit)="onSubmit()">
        <hotel-room-form [roomForm]="roomForm" [categories]="categories"></hotel-room-form>
        <div class="form-group row">
            <div class="col-10 offset-2">
                <a class="btn btn-white"
                   [routerLink]="['/hotels', hotel.alias, 'rooms']">Отмена</a>
                &nbsp;
                <button class="btn btn-primary" type="submit" [disabled]="!roomForm.valid">Сохранить</button>
            </div>
        </div>
    </form>
</div>
