import { Component, Injectable, OnDestroy, OnInit } from "@angular/core";
import { JsonProperty, ObjectMapper } from "json-object-mapper";
import { Hotel } from "../hotel";
import { HttpClient } from "@angular/common/http";
import { ActivatedRoute, ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from "@angular/router";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { mergeRouteData } from "app/merge-route-data";
import { TitleService as LayoutTitleService } from "layout/title.service";
import { BreadcrumbService } from "layout/breadcrumb.service";
import { RoomCategory, RoomCategoryType } from "../room-category";
import { HotelSidebarService } from "layout/sidebar/hotel.service";
import { SweetalertService } from "app/sweetalert.service";
import { LaddaService } from "app/ladda.service";

export class RoomCategoryData {
    @JsonProperty({type: Hotel})
    public hotel: Hotel;

    @JsonProperty({type: RoomCategory})
    public category: RoomCategory;
}

@Injectable()
export class RoomCategoryResolver implements Resolve<RoomCategoryData> {

    public constructor(
        protected httpClient: HttpClient
    ) {
    }

    public resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<RoomCategoryData> {
        let hotel: Hotel = mergeRouteData(route).component.hotel;
        return this.httpClient.get<RoomCategoryData>('/v2/hotels/' + hotel.hotelId + '/rooms/categories/' + route.params.roomCategoryId)
            .pipe(map(data => ObjectMapper.deserialize(RoomCategoryData, data)));
    }

}

@Component({
    selector: 'hotel-room-category',
    templateUrl: './room-category.component.html'
})
export class RoomCategoryComponent implements OnInit, OnDestroy {
    public readonly RoomCategoryType = RoomCategoryType;
    public hotel: Hotel;
    public category: RoomCategory;
    public disableButtons: boolean = false;

    public constructor(
        protected route: ActivatedRoute,
        protected layoutTitleService: LayoutTitleService,
        protected breadcrumbService: BreadcrumbService,
        protected hotelSidebarService: HotelSidebarService,
        protected sweetalertService: SweetalertService,
        protected laddaService: LaddaService,
        protected httpClient: HttpClient
    ) {
    }

    public ngOnInit(): void {
        this.route.data.subscribe((data) => {
            this.hotel = data.component.hotel;
            this.category = data.component.category;
            this.layoutTitleService.title = this.category.name;
            this.breadcrumbService.breadcrumbs = [
                {name: 'Отели', link: '/hotels'},
                {name: this.hotel.name, link: ['/hotels', this.hotel.alias]},
                {name: 'Категории номеров', link: ['/hotels', this.hotel.alias, 'rooms', 'categories']},
                {name: this.category.name}
            ];
            this.hotelSidebarService.setHotel(this.hotel);
        });
    }

    public ngOnDestroy(): void {
        this.hotelSidebarService.clearHotel();
    }

    public onEnable(target: HTMLButtonElement): void {
        let ladda = this.laddaService.create(target);
        this.sweetalertService.alert({
            icon: "warning",
            title: 'Включить категорию?',
            buttons: ['Отмена', 'Включить'],
            dangerMode: true
        }).then((data) => {
            if (data) {
                ladda.start();
                this.disableButtons = true;
                this.httpClient.post<{ category: any }>('/v2/hotels/' + this.hotel.hotelId + '/rooms/categories/' + this.category.roomCategoryId + '/enable', {}).subscribe((data) => {
                    ladda.stop();
                    this.disableButtons = false;
                    this.category = ObjectMapper.deserialize(RoomCategory, data.category);
                    this.sweetalertService.alert({
                        icon: "success",
                        title: 'Категория включена!'
                    });
                }, error => {
                    ladda.stop();
                    this.disableButtons = false;
                    console.error("Enable hotel room category error:", error);
                    throw error;
                });
            }
        });
    }

    public onDisable(target: HTMLButtonElement): void {
        let ladda = this.laddaService.create(target);
        this.sweetalertService.alert({
            icon: "warning",
            title: 'Отключить категорию?',
            buttons: ['Отмена', 'Отключить'],
            dangerMode: true
        }).then((data) => {
            if (data) {
                ladda.start();
                this.disableButtons = true;
                this.httpClient.post<{ category: any }>('/v2/hotels/' + this.hotel.hotelId + '/rooms/categories/' + this.category.roomCategoryId + '/disable', {}).subscribe((data) => {
                    ladda.stop();
                    this.disableButtons = false;
                    this.category = ObjectMapper.deserialize(RoomCategory, data.category);
                    this.sweetalertService.alert({
                        icon: "success",
                        title: 'Категория отключена!'
                    });
                }, error => {
                    ladda.stop();
                    this.disableButtons = false;
                    console.error("Disable hotel room category error:", error);
                    throw error;
                });
            }
        });
    }

    public onDelete(target: HTMLButtonElement): void {
        let ladda = this.laddaService.create(target);
        this.sweetalertService.alert({
            icon: "warning",
            title: 'Удалить категорию?',
            buttons: ['Отмена', 'Удалить'],
            dangerMode: true
        }).then((data) => {
            if (data) {
                ladda.start();
                this.disableButtons = true;
                this.httpClient.delete<{ category: any }>('/v2/hotels/' + this.hotel.hotelId + '/rooms/categories/' + this.category.roomCategoryId, {}).subscribe((data) => {
                    ladda.stop();
                    this.disableButtons = false;
                    this.category = ObjectMapper.deserialize(RoomCategory, data.category);
                    this.sweetalertService.alert({
                        icon: "success",
                        title: 'Категория удалена!'
                    });
                }, error => {
                    ladda.stop();
                    this.disableButtons = false;
                    console.error("Delete hotel room category error:", error);
                    throw error;
                });
            }
        });
    }

}
