import { AfterViewInit, Component, ElementRef, Input, NgZone, ViewChild } from "@angular/core";

require('tui-editor/dist/tui-editor-contents.css'); // editor content
const TuiEditorViewer = require('tui-editor/dist/tui-editor-Viewer');

@Component({
    selector: 'tui-editor-viewer',
    template: '<div #viewer></div>'
})
export class TuiEditorViewerComponent implements AfterViewInit {

    @ViewChild("viewer")
    public viewerElementRef: ElementRef<HTMLElement>;

    public viewer: any;

    private _content: string = '';

    @Input('content')
    public set content(content: string) {
        this._content = content;
        if (this.viewer) {
            this.viewer.setMarkdown(content);
        }
    }

    public get content(): string {
        return this._content;
    }

    public constructor(protected ngZone: NgZone) {
    }

    public ngAfterViewInit(): void {
        this.ngZone.runOutsideAngular(() => {
            this.viewer = new TuiEditorViewer({
                usageStatistics: false,
                el: this.viewerElementRef.nativeElement,
                initialValue: this.content
            });
        });
    }

}
