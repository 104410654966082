import { Component, Input } from "@angular/core";
import { VacancyForm } from "./vacancy.form";

@Component({
    selector: 'vacancy-form',
    templateUrl: './vacancy-form.component.html'
})
export class VacancyFormComponent {
    @Input("vacancyForm")
    public vacancyForm: VacancyForm;
}
