<div class="row border-bottom white-bg dashboard-header">
    <div class="col-lg-12">
        <h2>Добро пожаловать в Лайк Хостел ERP!</h2>
    </div>
</div>
<div class="row">
    <div class="col-lg-12">
        <div class="wrapper wrapper-content">
            <a routerLink="/companies" class="btn btn-default btn-lg m-r" type="button">
                Компании
            </a>
            <a routerLink="/hotels" class="btn btn-default btn-lg m-r" type="button">
                Отели
            </a>
            <a routerLink="/employees" class="btn btn-default btn-lg m-r" type="button">
                Сотрудники
            </a>
            <a routerLink="/locations/countries" class="btn btn-default btn-lg m-r" type="button">
                Страны
            </a>
            <a routerLink="/locations/cities" class="btn btn-default btn-lg" type="button">
                Города
            </a>
        </div>
    </div>
</div>
