<div class="ibox-content">
    <div class="table-responsive">
        <table class="table table-hover">
            <thead>
            <tr>
                <th>ID</th>
                <th>Алиас</th>
                <th>Город</th>
                <th>Страна</th>
                <th>Часовой пояс</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let city of cities; trackBy: trackByCity">
                <th [class.del]="city.isDisabled()">
                    <a [routerLink]="['/locations/countries', city.country.alias, 'cities', city.alias]">
                        {{ city.cityId }}
                    </a>
                </th>
                <td [class.del]="city.isDisabled()">
                    <i *ngIf="city.isEnabled()" class="far fa-check-circle text-navy m-r" title="Город включён"></i>
                    <i *ngIf="city.isDisabled()" class="fas fa-ban text-danger m-r" title="Город заблокирован"></i>
                    <a [routerLink]="['/locations/countries', city.country.alias, 'cities', city.alias]">
                        {{ city.alias }}
                    </a>
                </td>
                <td [class.del]="city.isDisabled()">
                    <a [routerLink]="['/locations/countries', city.country.alias, 'cities', city.alias]">
                        {{ city.name }}
                    </a>
                </td>
                <td>{{ city.country.name }}</td>
                <td>{{ city.timezone }}</td>
            </tr>
            </tbody>
        </table>
    </div>
</div>
