import { Component, Input } from "@angular/core";
import { Employee, FranchiseeEmployeePermissions, ManagementEmployeePermissions } from "../employee";
import './franchisee-employee-permissions-view.component.scss';
import { Hotel, HotelType } from "hotel/hotel";

@Component({
    selector: 'management-employee-permissions-view',
    templateUrl: './management-employee-permissions-view.component.html'
})
export class ManagementEmployeePermissionsViewComponent {

    @Input('employee')
    public employee: Employee;

    public get permissions(): ManagementEmployeePermissions {
        return this.employee.permissions as ManagementEmployeePermissions;
    }

}

@Component({
    selector: 'franchisee-employee-permissions-view',
    templateUrl: './franchisee-employee-permissions-view.component.html'
})
export class FranchiseeEmployeePermissionsViewComponent {

    @Input('employee')
    public employee: Employee;

    @Input('hotels')
    public hotels: Hotel[] = [];

    @Input('viewAllHotelsPermissions')
    public viewAllHotelsPermissions = true;

    public getHotels(): Hotel[] {
        if (!this.employee || !this.hotels) {
            return [];
        }
        let permissions = this.employee.permissions as FranchiseeEmployeePermissions;
        if (permissions.allHotels.hasAccess()) {
            return this.hotels;
        }
        return this.hotels.filter(hotel => {
            for (let hotelPermissions of permissions.hotels) {
                if (hotelPermissions.hotelId == hotel.hotelId) {
                    return hotelPermissions.permissions.hasAccess();
                }
            }
            return false;
        });
    }

    public trackByHotel(index: number, hotel: Hotel): number {
        return hotel.hotelId;
    }

    public hotelsOrHostels(hotels: string = 'отели', hostels: string = 'хостелы'): string {
        if (this.hotels.length == 0) {
            return hotels;
        }
        for (let hotel of this.hotels) {
            if (hotel.type == HotelType.Hotel) {
                return hotels;
            }
        }
        return hostels;
    }

}

@Component({
    selector: 'employee-permissions-view',
    templateUrl: './employee-permissions-view.component.html'
})
export class EmployeePermissionsViewComponent {

    @Input('employee')
    public employee: Employee;

    @Input('hotels')
    public hotels: Hotel[] = [];

    @Input('viewAllHotelsPermissions')
    public viewAllHotelsPermissions = true;

}
