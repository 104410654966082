import { Component, Injectable, OnDestroy, OnInit } from "@angular/core";
import { JsonProperty, ObjectMapper } from "json-object-mapper";
import { Room } from "../room";
import { HttpClient } from "@angular/common/http";
import { ActivatedRoute, ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from "@angular/router";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { TitleService as LayoutTitleService } from "layout/title.service";
import { BreadcrumbService } from "layout/breadcrumb.service";
import { Hotel } from "../hotel";
import { mergeRouteData } from "app/merge-route-data";
import { RoomCategory, RoomCategoryType } from "../room-category";
import { HotelSidebarService } from "layout/sidebar/hotel.service";

export class RoomsData {
    @JsonProperty({type: Room})
    public rooms: Room[];

    @JsonProperty({type: Hotel})
    public hotel: Hotel;
}

@Injectable()
export class RoomsResolver implements Resolve<RoomsData> {

    public constructor(
        protected httpClient: HttpClient
    ) {
    }

    public resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<RoomsData> {
        let hotel: Hotel = mergeRouteData(route).component.hotel;
        return this.httpClient.get<RoomsData>('/v2/hotels/' + hotel.hotelId + '/rooms')
            .pipe(map(data => ObjectMapper.deserialize(RoomsData, data)));
    }

}


@Component({
    selector: 'hotel-rooms-action',
    templateUrl: './rooms-action.component.html'
})
export class RoomsActionComponent implements OnInit {
    public hotel: Hotel;

    public constructor(
        protected route: ActivatedRoute
    ) {
    }

    public ngOnInit(): void {
        this.route.data.subscribe((data) => {
            this.hotel = data.component.hotel;
        });
    }

}

@Component({
    selector: 'hotel-rooms',
    templateUrl: './rooms.component.html'
})
export class RoomsComponent implements OnInit, OnDestroy {
    public hotel: Hotel;
    public rooms: Room[] = [];

    public constructor(
        protected route: ActivatedRoute,
        protected layoutTitleService: LayoutTitleService,
        protected breadcrumbService: BreadcrumbService,
        protected hotelSidebarService: HotelSidebarService
    ) {
    }

    public ngOnInit(): void {
        this.route.data.subscribe((data) => {
            this.rooms = data.component.rooms;
            this.hotel = data.component.hotel;
            this.layoutTitleService.title = 'Номера';
            this.breadcrumbService.breadcrumbs = [
                {name: 'Отели', link: '/hotels'},
                {name: this.hotel.name, link: ['/hotels', this.hotel.alias]},
                {name: 'Номера'}
            ];
            this.hotelSidebarService.setHotel(this.hotel);
        });
    }

    public ngOnDestroy(): void {
        this.hotelSidebarService.clearHotel();
    }

    public trackByRoom(index: number, room: Room): number {
        return room.roomId;
    }

    public getCategoryTypeName(category: RoomCategory): string {
        let capacity: string = category.capacity.guests.toString();
        if (category.capacity.additional) {
            capacity += '+' + category.capacity.additional;
        }
        switch (category.type) {
            case RoomCategoryType.Standart:
                return '(стандартный номер, ' + capacity + ' гостей)';
            case RoomCategoryType.Shared:
                return '(общий номер, ' + capacity + ' гостей)';
            case RoomCategoryType.SharedMale:
                return '(общий мужской номер, ' + capacity + ' гостей)';
            case RoomCategoryType.SharedFemale:
                return '(общий женский номер, ' + capacity + ' гостей)';
            default:
                return '';
        }
    }

}
