<ng-template [ngIf]="layoutService.showHeading">
    <div class="row wrapper border-bottom white-bg page-heading">
        <div class="col-sm-8">
            <layout-title></layout-title>
            <layout-breadcrumb></layout-breadcrumb>
        </div>
        <div class="col-sm-4">
            <div class="title-action">
                <router-outlet name="action"></router-outlet>
            </div>
        </div>
    </div>
</ng-template>
