import { RouterModule, Routes } from "@angular/router";
import { NgModule } from "@angular/core";
import { AuthGuard } from "app/auth/auth.guard";
import { LayoutComponent } from "layout/layout.component";
import { AllActionsResolved } from "action/all-actions-resolved.guard";
import { ProfileComponent, ProfileResolver } from "./profile/profile.component";

const routes: Routes = [
    {
        path: '',
        component: LayoutComponent,
        canActivate: [AuthGuard, AllActionsResolved],
        children: [
            {
                path: 'profile',
                component: ProfileComponent,
                resolve: {
                    component: ProfileResolver
                }
            }
        ]
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
    providers: [
        ProfileResolver
    ]
})
export class ProfileRoutingModule {
}
