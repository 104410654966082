import { Component, Injectable } from "@angular/core";
import { ActivatedRoute, ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot } from "@angular/router";
import { HttpClient } from "@angular/common/http";
import { City } from "../city";
import { forkJoin, Observable } from 'rxjs';
import { map } from "rxjs/operators";
import { JsonProperty, ObjectMapper } from "json-object-mapper";
import { CityForm } from "./city.form";
import { Country } from "../country";
import { TitleService as LayoutTitleService } from "layout/title.service";
import { BreadcrumbService } from "layout/breadcrumb.service";

export class EditCityData {
    @JsonProperty({type: City})
    public city: City;

    @JsonProperty({type: Country})
    public countries: Country[];
}

@Injectable()
export class EditCityResolver implements Resolve<EditCityData> {

    public constructor(
        protected httpClient: HttpClient
    ) {
    }

    public resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<EditCityData> {
        return forkJoin(
            this.httpClient.get<{ city: any }>("/v2/locations/cities/" + route.params.alias),
            this.httpClient.get<{ countries: any }>("/v2/locations/countries")
        ).pipe(map(data => Object.assign({}, data[0], data[1])))
            .pipe(map(data => ObjectMapper.deserialize(EditCityData, data)));
    }

}

@Component({
    selector: 'location-city-edit',
    templateUrl: './edit-city.component.html'
})
export class EditCityComponent {
    public city: City;
    public countries: Country[] = [];
    public cityForm: CityForm = new CityForm(this.httpClient);

    public constructor(
        protected route: ActivatedRoute,
        protected httpClient: HttpClient,
        protected router: Router,
        protected layoutTitleService: LayoutTitleService,
        protected breadcrumbService: BreadcrumbService
    ) {
    }

    public ngOnInit(): void {
        this.route.data.subscribe((data) => {
            this.city = data.component.city;
            this.countries = data.component.countries;
            this.cityForm = new CityForm(this.httpClient, this.city);
            this.layoutTitleService.title = this.city.name;
            this.layoutTitleService.small = 'Редактирование города';
            this.breadcrumbService.breadcrumbs = [
                {name: 'Страны', link: '/locations/countries'},
                {name: this.city.country.name, link: ['/locations/countries', this.city.country.alias]},
                {name: 'Города', link: '/locations/cities'},
                {
                    name: this.city.name,
                    link: ['/locations/countries', this.city.country.alias, 'cities', this.city.cityId]
                },
                {name: 'Редактирование'}
            ];
            this.cityForm.get('name').valueChanges.subscribe((value) => {
                this.layoutTitleService.title = value || 'Город';
                this.breadcrumbService.breadcrumbs = [
                    {name: 'Страны', link: '/locations/countries'},
                    {name: this.city.country.name, link: ['/locations/countries', this.city.country.alias]},
                    {name: 'Города', link: '/locations/cities'},
                    {
                        name: value || 'Город',
                        link: ['/locations/countries', this.city.country.alias, 'cities', this.city.cityId]
                    },
                    {name: 'Редактирование'}
                ];
            });
        });
    }

    public onSubmit(): void {
        this.cityForm.disable();
        this.httpClient.put<{ city: any }>('/v2/locations/cities/' + this.city.cityId, ObjectMapper.serialize({
            city: this.cityForm.getCity()
        })).toPromise().then((data) => {
            let city = ObjectMapper.deserialize(City, data.city);
            this.router.navigate(['/locations/countries', city.country.alias, 'cities', city.alias]);
        }).catch((error: any) => {
            console.error("Save city error:", error);
            this.cityForm.enable();
            throw error;
        });
    }

}
