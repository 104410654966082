import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";

@Component({
    selector: 'app-index',
    template: ''
})
export class IndexComponent implements OnInit {

    public constructor(
        protected router: Router
    ) {
    }

    public ngOnInit(): void {
        this.router.navigate(['/dashboard']);
    }

}
