import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { LayoutComponent } from "layout/layout.component";
import { AuthGuard } from "app/auth/auth.guard";
import { CountriesActionComponent, CountriesComponent, CountriesResolver } from "./country/countries.component";
import { CitiesActionComponent, CitiesComponent, CitiesResolver } from "./city/cities.component";
import { CountryComponent, CountryResolver } from "./country/country.component";
import { NewCountryComponent } from "./country/new-country.component";
import { CityComponent, CityResolver } from "./city/city.component";
import { NewCityComponent, NewCityResolver } from "./city/new-city.component";
import { EditCountryComponent, EditCountryResolver } from "./country/edit-country.component";
import { EditCityComponent, EditCityResolver } from "./city/edit-city.component";
import { AllActionsResolved } from "action/all-actions-resolved.guard";
import { ManagementCompanyGuard } from "app/auth/management-company.guard";

const routes: Routes = [
    {
        path: '',
        component: LayoutComponent,
        canActivate: [AuthGuard, AllActionsResolved, ManagementCompanyGuard],
        children: [
            {
                path: 'locations',
                children: [
                    {
                        path: '',
                        redirectTo: 'countries',
                        pathMatch: 'full'
                    },
                    {
                        path: 'countries',
                        children: [
                            {
                                path: '',
                                children: [
                                    {
                                        path: '',
                                        component: CountriesComponent,
                                        resolve: {
                                            component: CountriesResolver
                                        }
                                    },
                                    {
                                        path: '',
                                        component: CountriesActionComponent,
                                        outlet: 'action'
                                    }
                                ]
                            },
                            {
                                path: 'new',
                                component: NewCountryComponent
                            },
                            {
                                path: ':alias',
                                children: [
                                    {
                                        path: '',
                                        component: CountryComponent,
                                        resolve: {
                                            component: CountryResolver
                                        }
                                    },
                                    {
                                        path: 'edit',
                                        component: EditCountryComponent,
                                        resolve: {
                                            component: EditCountryResolver
                                        }
                                    }
                                ]
                            },
                            {
                                path: ':countryAlias/cities/:alias',
                                children: [
                                    {
                                        path: '',
                                        component: CityComponent,
                                        resolve: {
                                            component: CityResolver
                                        }
                                    },
                                    {
                                        path: 'edit',
                                        component: EditCityComponent,
                                        resolve: {
                                            component: EditCityResolver
                                        }
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        path: 'cities',
                        children: [
                            {
                                path: '',
                                children: [
                                    {
                                        path: '',
                                        component: CitiesComponent,
                                        resolve: {
                                            component: CitiesResolver
                                        }
                                    },
                                    {
                                        path: '',
                                        component: CitiesActionComponent,
                                        outlet: 'action'
                                    }
                                ]
                            },
                            {
                                path: 'new',
                                component: NewCityComponent,
                                resolve: {
                                    component: NewCityResolver
                                }
                            }
                        ]
                    }
                ]
            }
        ]
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
    providers: [
        CountriesResolver, CitiesResolver,
        CountryResolver, CityResolver, NewCityResolver,
        EditCityResolver, EditCountryResolver
    ]
})
export class LocationRoutingModule {
}
