import { NgModule } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { LoginRoutingModule } from "./login-routing.module";
import { LoginComponent } from "./login.component";
import { LogoutComponent } from "./logout.component";

@NgModule({
    imports: [
        LoginRoutingModule,
        ReactiveFormsModule,
        BrowserAnimationsModule
    ],
    declarations: [
        LoginComponent,
        LogoutComponent
    ]
})
export class LoginModule {
}
