import { Component, Injectable, OnDestroy, OnInit } from "@angular/core";
import { JsonProperty, ObjectMapper } from "json-object-mapper";
import { Hotel } from "../hotel";
import { HttpClient } from "@angular/common/http";
import { Observable, forkJoin } from "rxjs";
import { map } from "rxjs/operators";
import { ActivatedRoute, ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot } from "@angular/router";
import { mergeRouteData } from "app/merge-route-data";
import { RoomCategory } from "../room-category";
import { Price } from "../price";
import { TitleService as LayoutTitleService } from "layout/title.service";
import { HotelSidebarService } from "layout/sidebar/hotel.service";
import { BreadcrumbService } from "layout/breadcrumb.service";
import { PriceForm } from "./price.form";

export class EditPriceData {
    @JsonProperty({type: Hotel})
    public hotel: Hotel;

    @JsonProperty({type: RoomCategory})
    public categories: RoomCategory[];

    @JsonProperty({type: Price})
    public price: Price;
}

@Injectable()
export class EditPriceResolver implements Resolve<EditPriceData> {
    public constructor(
        protected httpClient: HttpClient
    ) {
    }

    public resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<EditPriceData> {
        let hotel: Hotel = mergeRouteData(route).component.hotel;
        return forkJoin(
            this.httpClient.get('/v2/hotels/' + hotel.hotelId + '/prices/' + route.params.priceId),
            this.httpClient.get<{ categories: any }>('/v2/hotels/' + hotel.hotelId + '/rooms/categories')
                .pipe(map(data => {
                    return {categories: data.categories};
                }))
        ).pipe(map(data => Object.assign({}, data[0], data[1])))
            .pipe(map(data => ObjectMapper.deserialize(EditPriceData, data)));
    }
}

@Component({
    selector: 'hotel-edit-price',
    templateUrl: './edit-price.component.html'
})
export class EditPriceComponent implements OnInit, OnDestroy {
    public hotel: Hotel;
    public price: Price;
    public categories: RoomCategory[] = [];
    public priceForm: PriceForm;

    public constructor(
        protected route: ActivatedRoute,
        protected layoutTitleService: LayoutTitleService,
        protected breadcrumbService: BreadcrumbService,
        protected hotelSidebarService: HotelSidebarService,
        protected httpClient: HttpClient,
        protected router: Router
    ) {
    }

    public ngOnInit(): void {
        this.route.data.subscribe((data) => {
            this.hotel = data.component.hotel;
            this.price = data.component.price;
            this.categories = data.component.categories;
            this.priceForm = new PriceForm(this.categories, this.price);
            this.layoutTitleService.title = this.price.name;
            this.layoutTitleService.small = 'Редактирование цен';
            this.breadcrumbService.breadcrumbs = [
                {name: 'Отели', link: '/hotels'},
                {name: this.hotel.name, link: ['/hotels', this.hotel.alias]},
                {name: 'Цены', link: ['/hotels', this.hotel.alias, 'prices']},
                {name: this.price.name, link: ['/hotels', this.hotel.alias, 'prices', this.price.priceId]},
                {name: 'Редактирование'}
            ];
            this.hotelSidebarService.setHotel(this.hotel);
            this.priceForm.get('name').valueChanges.subscribe(value => {
                this.layoutTitleService.title = value || 'Прайс';
                this.breadcrumbService.breadcrumbs = [
                    {name: 'Отели', link: '/hotels'},
                    {name: this.hotel.name, link: ['/hotels', this.hotel.alias]},
                    {name: 'Цены', link: ['/hotels', this.hotel.alias, 'prices']},
                    {name: value || 'Прайс', link: ['/hotels', this.hotel.alias, 'prices', this.price.priceId]},
                    {name: 'Редактирование'}
                ];
            });
        });
    }

    public ngOnDestroy(): void {
        this.hotelSidebarService.clearHotel();
    }

    public onSubmit(): void {
        this.priceForm.disable();
        this.httpClient.put<{ price: any }>('/v2/hotels/' + this.hotel.hotelId + '/prices/' + this.price.priceId, ObjectMapper.serialize({
            price: this.priceForm.getPrice()
        })).toPromise().then((data) => {
            let price = ObjectMapper.deserialize(Price, data.price);
            this.router.navigate(['/hotels', this.hotel.alias, 'prices', price.priceId]);
        }).catch((error: any) => {
            console.error("Save price error:", error);
            this.priceForm.enable();
            throw error;
        });
    }

}
