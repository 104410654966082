import { Component, Injectable, OnDestroy, OnInit } from "@angular/core";
import { JsonProperty, ObjectMapper } from "json-object-mapper";
import { Hotel } from "../hotel";
import { Room } from "../room";
import { HttpClient } from "@angular/common/http";
import { ActivatedRoute, ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from "@angular/router";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { mergeRouteData } from "app/merge-route-data";
import { TitleService as LayoutTitleService } from "layout/title.service";
import { BreadcrumbService } from "layout/breadcrumb.service";
import { HotelSidebarService } from "layout/sidebar/hotel.service";
import { RoomCategory, RoomCategoryType } from "../room-category";
import { SweetalertService } from "app/sweetalert.service";
import { LaddaService } from "app/ladda.service";

export class RoomData {
    @JsonProperty({type: Hotel})
    public hotel: Hotel;

    @JsonProperty({type: Room})
    public room: Room;
}

@Injectable()
export class RoomResolver implements Resolve<RoomData> {

    public constructor(
        protected httpClient: HttpClient
    ) {
    }

    public resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<RoomData> {
        let hotel: Hotel = mergeRouteData(route).component.hotel;
        return this.httpClient.get<RoomData>('/v2/hotels/' + hotel.hotelId + '/rooms/' + route.params.roomNumber)
            .pipe(map(data => ObjectMapper.deserialize(RoomData, data)));
    }

}

@Component({
    selector: 'hotel-room',
    templateUrl: './room.component.html'
})
export class RoomComponent implements OnInit, OnDestroy {
    public hotel: Hotel;
    public room: Room;
    public disableButtons: boolean = false;

    public constructor(
        protected route: ActivatedRoute,
        protected layoutTitleService: LayoutTitleService,
        protected breadcrumbService: BreadcrumbService,
        protected hotelSidebarService: HotelSidebarService,
        protected sweetalertService: SweetalertService,
        protected laddaService: LaddaService,
        protected httpClient: HttpClient
    ) {
    }

    public ngOnInit(): void {
        this.route.data.subscribe((data) => {
            this.hotel = data.component.hotel;
            this.room = data.component.room;
            this.layoutTitleService.title = this.room.number;
            this.layoutTitleService.small = this.room.category.name;
            this.breadcrumbService.breadcrumbs = [
                {name: 'Отели', link: '/hotels'},
                {name: this.hotel.name, link: ['/hotels', this.hotel.alias]},
                {name: 'Номера', link: ['/hotels', this.hotel.alias, 'rooms']},
                {name: this.room.number}
            ];
            this.hotelSidebarService.setHotel(this.hotel);
        });
    }

    public ngOnDestroy(): void {
        this.hotelSidebarService.clearHotel();
    }

    public getCategoryTypeName(category: RoomCategory): string {
        let capacity: string = category.capacity.guests.toString();
        if (category.capacity.additional) {
            capacity += '+' + category.capacity.additional;
        }
        switch (category.type) {
            case RoomCategoryType.Standart:
                return '(стандартный номер, ' + capacity + ' гостей)';
            case RoomCategoryType.Shared:
                return '(общий номер, ' + capacity + ' гостей)';
            case RoomCategoryType.SharedMale:
                return '(общий мужской номер, ' + capacity + ' гостей)';
            case RoomCategoryType.SharedFemale:
                return '(общий женский номер, ' + capacity + ' гостей)';
            default:
                return '';
        }
    }

    public onEnable(target: HTMLButtonElement): void {
        let ladda = this.laddaService.create(target);
        this.sweetalertService.alert({
            icon: "warning",
            title: 'Включить номер?',
            buttons: ['Отмена', 'Включить'],
            dangerMode: true
        }).then((data) => {
            if (data) {
                ladda.start();
                this.disableButtons = true;
                this.httpClient.post<{ room: any }>('/v2/hotels/' + this.hotel.hotelId + '/rooms/' + this.room.roomId + '/enable', {}).subscribe((data) => {
                    ladda.stop();
                    this.disableButtons = false;
                    this.room = ObjectMapper.deserialize(Room, data.room);
                    this.sweetalertService.alert({
                        icon: "success",
                        title: 'Номер включён!'
                    });
                }, error => {
                    ladda.stop();
                    this.disableButtons = false;
                    console.error("Enable hotel room error:", error);
                    throw error;
                });
            }
        });
    }

    public onDisable(target: HTMLButtonElement): void {
        let ladda = this.laddaService.create(target);
        this.sweetalertService.alert({
            icon: "warning",
            title: 'Отключить номер?',
            buttons: ['Отмена', 'Отключить'],
            dangerMode: true
        }).then((data) => {
            if (data) {
                ladda.start();
                this.disableButtons = true;
                this.httpClient.post<{ room: any }>('/v2/hotels/' + this.hotel.hotelId + '/rooms/' + this.room.roomId + '/disable', {}).subscribe((data) => {
                    ladda.stop();
                    this.disableButtons = false;
                    this.room = ObjectMapper.deserialize(Room, data.room);
                    this.sweetalertService.alert({
                        icon: "success",
                        title: 'Номер отключён!'
                    });
                }, error => {
                    ladda.stop();
                    this.disableButtons = false;
                    console.error("Disable hotel room error:", error);
                    throw error;
                });
            }
        });
    }

    public onDelete(target: HTMLButtonElement): void {
        let ladda = this.laddaService.create(target);
        this.sweetalertService.alert({
            icon: "warning",
            title: 'Удалить номер?',
            buttons: ['Отмена', 'Удалить'],
            dangerMode: true
        }).then((data) => {
            if (data) {
                ladda.start();
                this.disableButtons = true;
                this.httpClient.delete<{ room: any }>('/v2/hotels/' + this.hotel.hotelId + '/rooms/' + this.room.roomId, {}).subscribe((data) => {
                    ladda.stop();
                    this.disableButtons = false;
                    this.room = ObjectMapper.deserialize(Room, data.room);
                    this.sweetalertService.alert({
                        icon: "success",
                        title: 'Номер удалён!'
                    });
                }, error => {
                    ladda.stop();
                    this.disableButtons = false;
                    console.error("Delete hotel room error:", error);
                    throw error;
                });
            }
        });
    }

}
