import { Component, EventEmitter, Input, Output } from "@angular/core";
import { Vacancy } from "./vacancy";
import { ObjectMapper } from "json-object-mapper";
import { SweetalertService } from "app/sweetalert.service";
import { LaddaService } from "app/ladda.service";
import { HttpClient } from "@angular/common/http";
import { AuthService } from "app/auth/auth.service";
import { ManagementEmployeePermissions } from "company/employee";

@Component({
    selector: 'vacancy-buttons',
    templateUrl: './vacancy-buttons.component.html'
})
export class VacancyButtonsComponent {
    public disableButtons: boolean = false;

    @Input('vacancy')
    public vacancy: Vacancy;

    @Output('vacancyChange')
    public vacancyChange = new EventEmitter<Vacancy>();

    public constructor(
        protected sweetalertService: SweetalertService,
        protected laddaService: LaddaService,
        protected httpClient: HttpClient,
        protected authService: AuthService
    ) {
    }

    public onEnable(target: HTMLButtonElement): void {
        let ladda = this.laddaService.create(target);
        this.sweetalertService.alert({
            icon: "warning",
            title: 'Опубликовать вакансию?',
            buttons: ['Отмена', 'Опубликовать'],
            dangerMode: true
        }).then((data) => {
            if (data) {
                ladda.start();
                this.disableButtons = true;
                this.httpClient.post<{ vacancy: any }>('/v2/vacancies/' + this.vacancy.vacancyId + '/enable', {}).subscribe(data => {
                    ladda.stop();
                    this.disableButtons = false;
                    this.vacancy = ObjectMapper.deserialize(Vacancy, data.vacancy);
                    this.vacancyChange.emit(this.vacancy);
                    this.sweetalertService.alert({
                        icon: "success",
                        title: 'Вакансия опубликована!'
                    });
                }, error => {
                    ladda.stop();
                    this.disableButtons = false;
                    console.error("Enable vacancy error:", error);
                    throw error;
                });
            }
        });
    }

    public onDisable(target: HTMLButtonElement): void {
        let ladda = this.laddaService.create(target);
        this.sweetalertService.alert({
            icon: "warning",
            title: 'Снять вакансию с публикации?',
            buttons: ['Отмена', 'Снять с публикации'],
            dangerMode: true
        }).then((data) => {
            if (data) {
                ladda.start();
                this.disableButtons = true;
                this.httpClient.post<{ vacancy: any }>('/v2/vacancies/' + this.vacancy.vacancyId + '/disable', {}).subscribe(data => {
                    ladda.stop();
                    this.disableButtons = false;
                    this.vacancy = ObjectMapper.deserialize(Vacancy, data.vacancy);
                    this.vacancyChange.emit(this.vacancy);
                    this.sweetalertService.alert({
                        icon: "success",
                        title: 'Вакансия снята с публикации!'
                    });
                }, error => {
                    ladda.stop();
                    this.disableButtons = false;
                    console.error("Disable vacancy error:", error);
                    throw error;
                });
            }
        });
    }

    public onArchive(target: HTMLButtonElement): void {
        let ladda = this.laddaService.create(target);
        this.sweetalertService.alert({
            icon: "warning",
            title: 'Переместить вакансию в архив?',
            text: 'Это действие нельзя отменить.',
            buttons: ['Отмена', 'Переместить в архив'],
            dangerMode: true
        }).then((data) => {
            if (data) {
                ladda.start();
                this.disableButtons = true;
                this.httpClient.post<{ vacancy: any }>('/v2/vacancies/' + this.vacancy.vacancyId + '/archive', {}).subscribe(data => {
                    ladda.stop();
                    this.disableButtons = false;
                    this.vacancy = ObjectMapper.deserialize(Vacancy, data.vacancy);
                    this.vacancyChange.emit(this.vacancy);
                    this.sweetalertService.alert({
                        icon: "success",
                        title: 'Вакансия перемещена в архив!'
                    });
                }, error => {
                    ladda.stop();
                    this.disableButtons = false;
                    console.error("Archive vacancy error:", error);
                    throw error;
                });
            }
        });
    }

    public onDelete(target: HTMLButtonElement): void {
        let ladda = this.laddaService.create(target);
        this.sweetalertService.alert({
            icon: "warning",
            title: 'Удалить вакансию?',
            text: 'Это действие нельзя отменить.',
            buttons: ['Отмена', 'Удалить'],
            dangerMode: true
        }).then((data) => {
            if (data) {
                ladda.start();
                this.disableButtons = true;
                this.httpClient.delete<{ vacancy: any }>('/v2/vacancies/' + this.vacancy.vacancyId, {}).subscribe(data => {
                    ladda.stop();
                    this.disableButtons = false;
                    this.vacancy = ObjectMapper.deserialize(Vacancy, data.vacancy);
                    this.vacancyChange.emit(this.vacancy);
                    this.sweetalertService.alert({
                        icon: "success",
                        title: 'Вакансия удалена!'
                    });
                }, error => {
                    ladda.stop();
                    this.disableButtons = false;
                    console.error("Delete vacancy error:", error);
                    throw error;
                });
            }
        });
    }

    public showDeleteButton(): boolean {
        let user = this.authService.getUser();
        if (!user.company.isManagementCompany()) {
            return false;
        }
        let permissions: ManagementEmployeePermissions = user.permissions as ManagementEmployeePermissions;
        return permissions.canDeleteAllObjects;
    }

}
