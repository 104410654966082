<fieldset [formGroup]="employeeForm">
    <div class="form-group row"
         [class.has-error]="employeeForm.controls.email.value && !employeeForm.controls.email.disabled && !employeeForm.controls.email.valid">
        <label class="col-2 col-form-label" for="form-company-employee-email">Email:</label>
        <div class="col-10">
            <input type="email" class="form-control"
                   id="form-company-employee-email"
                   formControlName="email">
        </div>
    </div>
    <div class="form-group row"
         [class.has-error]="employeeForm.controls.city.value && !employeeForm.controls.city.disabled && !employeeForm.controls.city.valid">
        <label class="col-2 col-form-label" for="form-company-employee-city">Город:</label>
        <div class="col-10">
            <select class="form-control" id="form-company-employee-city" formControlName="city">
                <option *ngFor="let city of cities; trackBy: trackByCity" [value]="city.cityId">
                    {{ city.name }}
                </option>
            </select>
        </div>
    </div>
    <div class="form-group row"
         [class.has-error]="!employeeForm.controls.timezone.disabled && !employeeForm.controls.timezone.valid">
        <label class="col-2 col-form-label" for="form-company-employee-timezone">Часовой пояс:</label>
        <div class="col-10">
            <select class="form-control"
                    id="form-company-employee-timezone"
                    formControlName="timezone">
                <option *ngFor="let timezone of timezones" [value]="timezone">
                    {{ timezone }}
                </option>
            </select>
        </div>
    </div>
    <employee-details-form [employeeForm]="employeeForm"></employee-details-form>
</fieldset>
