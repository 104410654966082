import { Component, Injectable } from "@angular/core";
import { ObjectMapper } from "json-object-mapper";
import { Employee } from "../employee";
import { map } from "rxjs/operators";
import { ActivatedRoute, ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from "@angular/router";
import { HttpClient } from "@angular/common/http";
import { TitleService as LayoutTitleService } from "layout/title.service";
import { BreadcrumbService } from "layout/breadcrumb.service";
import { Company } from "../company";
import { AuthService } from "app/auth/auth.service";
import { Hotel } from "hotel/hotel";
import { PermissionsService } from "app/permissions.service";

export class EmployeeData {
    public employee: Employee;
    public hotels?: Hotel[];
}

@Injectable()
export class EmployeeResolver implements Resolve<EmployeeData> {

    public constructor(
        protected httpClient: HttpClient
    ) {
    }

    public resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<EmployeeData> {
        return new Promise<EmployeeData>((resolve, reject) => {
            this.httpClient.get<{ employee: any }>('/v2/employees/' + route.params.employeeId)
                .pipe(map(data => ObjectMapper.deserialize(Employee, data.employee)))
                .subscribe((employee) => {
                    if (employee.company.isFranchiseeCompany()) {
                        this.httpClient.get<{ hotels: any[] }>('/v2/companies/' + employee.company.companyId + '/hotels')
                            .pipe(map(data => ObjectMapper.deserializeArray(Hotel, data.hotels)))
                            .subscribe(hotels => {
                                resolve({employee: employee, hotels: hotels});
                            }, error => {
                                reject(error);
                            })
                    } else {
                        resolve({employee: employee});
                    }
                }, error => {
                    reject(error);
                });
        });
    }

}

@Component({
    selector: 'company-employee',
    templateUrl: './employee.component.html'
})
export class EmployeeComponent {
    public employee: Employee;
    public employeeName: string;
    public company: Company;
    public hotels: Hotel[] = [];

    public constructor(
        protected route: ActivatedRoute,
        protected layoutTitleService: LayoutTitleService,
        protected breadcrumbService: BreadcrumbService,
        protected authService: AuthService,
        protected permissionsService: PermissionsService
    ) {
    }

    public ngOnInit(): void {
        this.route.data.subscribe((data) => {
            this.employee = data.component.employee;
            this.hotels = data.component.hotels || [];
            this.company = this.employee.company;
            this.employeeName = (this.employee.name.isPresent()) ? this.employee.name.toString() : this.employee.email;
            this.layoutTitleService.title = this.employeeName;
            this.layoutTitleService.small = this.employee.position;
            if (this.authService.getUser().company.isManagementCompany()) {
                this.breadcrumbService.breadcrumbs = [
                    {name: 'Компании', link: '/companies'},
                    {name: this.employee.company.name, link: ['/companies', this.employee.company.companyId]},
                    {name: 'Сотрудники', link: '/employees'},
                    {name: this.employeeName}
                ];
            }
            if (this.authService.getUser().company.isFranchiseeCompany()) {
                this.breadcrumbService.breadcrumbs = [
                    {name: 'Сотрудники', link: '/employees'},
                    {name: this.employeeName}
                ];
            }
        });
    }

    public getEditLink(): any[] {
        if (this.authService.getUser().company.isManagementCompany()) {
            return ['/companies', this.company.companyId, 'employees', this.employee.employeeId, 'edit'];
        }
        if (this.authService.getUser().company.isFranchiseeCompany()) {
            return ['/employees', this.employee.employeeId, 'edit'];
        }
        return null;
    }

    /**
     * @todo: use canEditEmployee directive
     * @hotfix: fix duplicate employee buttons
     */
    public canEditEmployee(): boolean {
        return this.permissionsService.canEditEmployee(this.employee);
    }

}
