import { Injectable } from "@angular/core";

@Injectable()
export class GlobalConfig {

    public readonly deploy: {
        readonly scheme: string;
        readonly domain: string;
    } = {
        scheme: APP_CONFIG.deploy.scheme,
        domain: APP_CONFIG.deploy.domain
    };

    public readonly erpapi: {
        readonly url: string;
    } = {
        url: APP_CONFIG.erpapi.url
    };

    public readonly content: {
        readonly url: string;
    } = {
        url: APP_CONFIG.content.url
    };

    public readonly static: {
        readonly url: string;
    } = {
        url: APP_CONFIG.static.url
    };

    public readonly mode: string = APP_MODE;

    public isProductionMode(): boolean {
        return this.mode == 'production' || this.mode == 'prod';
    }

    public isDevelopmentMode(): boolean {
        return this.mode == 'development' || this.mode == 'dev';
    }

}
