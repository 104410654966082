import { Component, Injectable, OnInit } from "@angular/core";
import { ActivatedRoute, ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from "@angular/router";
import { Country } from "../country";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { JsonProperty, ObjectMapper } from "json-object-mapper";
import { TitleService as LayoutTitleService } from "layout/title.service";
import { BreadcrumbService } from "layout/breadcrumb.service";

export class CountryData {
    @JsonProperty({type: Country})
    public country: Country;
}

@Injectable()
export class CountryResolver implements Resolve<CountryData> {

    public constructor(
        protected httpClient: HttpClient
    ) {
    }

    public resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<CountryData> {
        return this.httpClient.get<CountryData>("/v2/locations/countries/" + route.params.alias)
            .pipe(map(data => ObjectMapper.deserialize(CountryData, data)));
    }

}

@Component({
    selector: 'location-country',
    templateUrl: 'country.component.html'
})
export class CountryComponent implements OnInit {

    public country: Country;

    public constructor(
        protected route: ActivatedRoute,
        protected layoutTitleService: LayoutTitleService,
        protected breadcrumbService: BreadcrumbService
    ) {
    }

    public ngOnInit(): void {
        this.route.data.subscribe((data) => {
            this.country = data.component.country;
            this.layoutTitleService.title = this.country.name;
            this.breadcrumbService.breadcrumbs = [
                {name: 'Страны', link: '/locations/countries'},
                {name: this.country.name}
            ];
        });
    }

}
