<a *ngIf="vacancy.isEnabled() || vacancy.isDisabled()" [routerLink]="['/vacancies', vacancy.vacancyId, 'edit']"
   class="btn btn-white btn-xs float-right">
    Редактировать
</a>
<button *ngIf="showDeleteButton() && (vacancy.isDisabled() || vacancy.isArchived())" (click)="onDelete($event.target)"
        type="button" [disabled]="disableButtons"
        class="btn btn-danger btn-xs float-right m-r-sm ladda-button" data-style="slide-right">
    Удалить
</button>
<button *ngIf="vacancy.isEnabled() || vacancy.isDisabled()" (click)="onArchive($event.target)"
        type="button" [disabled]="disableButtons"
        class="btn btn-warning btn-xs float-right m-r-sm ladda-button" data-style="slide-right">
    Переместить в архив
</button>
<button *ngIf="vacancy.isEnabled()" (click)="onDisable($event.target)"
        type="button" [disabled]="disableButtons"
        class="btn btn-warning btn-xs float-right m-r-sm ladda-button" data-style="slide-right">
    Снять с публикации
</button>
<button *ngIf="vacancy.isDisabled()" (click)="onEnable($event.target)"
        type="button" [disabled]="disableButtons"
        class="btn btn-success btn-xs float-right m-r-sm ladda-button" data-style="slide-right">
    Опубликовать
</button>
