import { AccessType, CacheKey, Deserializer, JsonIgnore, JsonProperty, Serializer } from "json-object-mapper";

export enum CountryStatus {
    Disabled = 'disabled',
    Enabled = 'enabled',
    Deleted = 'deleted'
}

@CacheKey("CountryStatusSerializerDeserializer")
class CountryStatusSerializerDeserializer implements Deserializer, Serializer {

    public deserialize(value: string): CountryStatus {
        return CountryStatus[value.charAt(0).toUpperCase() + value.slice(1).toLowerCase()];
    }

    public serialize(value: CountryStatus): string {
        return '"' + CountryStatus[value.charAt(0).toUpperCase() + value.slice(1).toLowerCase()].toLowerCase() + '"';
    }

}

export class Country {

    @JsonProperty({name: 'country_id', access: AccessType.READ_ONLY})
    public countryId: number;

    @JsonProperty({
        type: CountryStatus,
        serializer: CountryStatusSerializerDeserializer,
        deserializer: CountryStatusSerializerDeserializer
    })
    public status: CountryStatus;

    @JsonIgnore()
    public isEnabled(): boolean {
        return this.status == CountryStatus.Enabled;
    }

    @JsonIgnore()
    public isDisabled(): boolean {
        return this.status == CountryStatus.Disabled;
    }

    @JsonIgnore()
    public isDeleted(): boolean {
        return this.status == CountryStatus.Deleted;
    }

    @JsonProperty()
    public alias: string;

    @JsonProperty()
    public name: string;

}
