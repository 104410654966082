import { Hotel } from "hotel/hotel";
import { Company } from "company/company";
import { Component } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { JsonProperty } from "json-object-mapper";
import { Price } from "hotel/price";

export class DashboardHotel extends Hotel {

    @JsonProperty({type: Price, required: false})
    public price?: Price | null = null;

    @JsonProperty({name: 'rooms_count'})
    public roomsCount = 0;

}

export class FranchiseeDashboardData {
    @JsonProperty()
    public type: 'franchisee';

    @JsonProperty({type: Company})
    public readonly company: Company;

    @JsonProperty({type: DashboardHotel})
    public readonly hotels: DashboardHotel[];
}

@Component({
    selector: 'dashboard-franchisee',
    templateUrl: './franchisee.component.html'
})
export class FranchiseeDashboardComponent {
    public company: Company;
    public hotels: Hotel[] = [];

    public constructor(
        protected route: ActivatedRoute
    ) {
    }

    public ngOnInit(): void {
        this.route.data.subscribe((data) => {
            let dashboardData: FranchiseeDashboardData = data.component;
            this.company = dashboardData.company;
            this.hotels = dashboardData.hotels;
        });
    }

    public trackByHotel(hotel: Hotel): number {
        return hotel.hotelId;
    }

}
