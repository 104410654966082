import { NgModule } from "@angular/core";
import { CommonModule as NgCommonModule } from "@angular/common";
import {
    CanDeleteVacancyDirective,
    CanEditEmployeeDirective,
    CanEditHotelDirective,
    CanEditHotelEmployeesDirective,
    CanEditHotelPricesDirective,
    CanEditHotelRoomsDirective
} from "./permissions.directive";
import { UtcTimePipe } from "./utc-time.pipe";
import { UtcTimeComponent } from "./utc-time.component";
import { TuiEditorComponent } from "./tui-editor.component";
import { TuiEditorViewerComponent } from "./tui-editor-viewer.component";
import { Nl2brPipe } from "./nl2br.pipe";

@NgModule({
    imports: [NgCommonModule],
    providers: [],
    declarations: [
        CanEditHotelRoomsDirective, CanEditHotelEmployeesDirective, CanEditHotelPricesDirective,
        CanEditHotelDirective, CanEditEmployeeDirective, CanDeleteVacancyDirective,
        UtcTimePipe, UtcTimeComponent,
        TuiEditorComponent, TuiEditorViewerComponent,
        Nl2brPipe
    ],
    exports: [
        CanEditHotelRoomsDirective, CanEditHotelEmployeesDirective, CanEditHotelPricesDirective,
        CanEditHotelDirective, CanEditEmployeeDirective, CanDeleteVacancyDirective,
        UtcTimePipe, UtcTimeComponent,
        TuiEditorComponent, TuiEditorViewerComponent,
        Nl2brPipe
    ]
})
export class CommonModule {
}
