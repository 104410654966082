import { Component, Input } from "@angular/core";
import { PriceForm, PriceItemForm } from "./price.form";
import { FormArray } from "@angular/forms";
import { RoomCategoryType } from "../room-category";

@Component({
    selector: 'hotel-price-form',
    templateUrl: './price-form.component.html'
})
export class PriceFormComponent {
    public readonly RoomCategoryType = RoomCategoryType;

    @Input('priceForm')
    public priceForm: PriceForm;

    public getItemsFormArray(): FormArray {
        return this.priceForm.get('items') as FormArray;
    }

    public trackByItemForm(index: number, priceItemForm: PriceItemForm): number {
        return priceItemForm.roomCategory.roomCategoryId;
    }

}
