import { Component } from "@angular/core";
import { TitleService } from "./title.service";

@Component({
    selector: 'layout-title',
    templateUrl: './title.component.html'
})
export class TitleComponent {

    public constructor(
        public titleService: TitleService
    ) {
    }

}
