import { EventEmitter, Injectable } from "@angular/core";
import { Event, NavigationEnd, Router } from "@angular/router";
import { Title } from "@angular/platform-browser";

export interface Breadcrumb {
    name: string;
    link?: any[] | string;
}

@Injectable()
export class BreadcrumbService {

    private _breadcrumbs: Breadcrumb[] = [];

    public readonly changes: EventEmitter<Breadcrumb[]> = new EventEmitter<Breadcrumb[]>();

    public constructor(
        protected router: Router,
        protected title: Title
    ) {
        router.events.subscribe(event => this.onRounterEvent(event));
    }

    protected onRounterEvent(event: Event): void {
        if (event instanceof NavigationEnd) {
            this.breadcrumbs = [];
        }
    }

    public get breadcrumbs(): Breadcrumb[] {
        return this._breadcrumbs;
    }

    public set breadcrumbs(breadcrumbs: Breadcrumb[]) {
        this._breadcrumbs = breadcrumbs;
        this.changes.emit(breadcrumbs);
        let pageTitle = [].concat(breadcrumbs).reverse().map(b => b.name).join(' | ');
        pageTitle += pageTitle ? ' | ' : '';
        pageTitle += 'Лайк Хостел ERP';
        this.title.setTitle(pageTitle);
    }

}
