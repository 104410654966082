<ng-template [ngIf]="!isDifferentTZ()">
    {{ time | utcTime: timezone }}
</ng-template>
<ng-template [ngIf]="isDifferentTZ()">
    <ng-template [ngIf]="showByUserTZ">
        {{ time | utcTime: getUserTZ() : false }}
    </ng-template>
    <ng-template [ngIf]="!showByUserTZ">
        {{ time | utcTime: timezone : false }}
    </ng-template>
    <div class="btn-group btn-group-sm ml-2" role="group" aria-label="Timezone">
        <button type="button"
                [class]="'btn ' + (showByUserTZ ? 'btn-primary' : 'btn-white')"
                (click)="showByUserTZ = true">
            {{ getTZAbbr(getUserTZ()) }}
        </button>
        <button type="button"
                [class]="'btn ' + (!showByUserTZ ? 'btn-primary' : 'btn-white')"
                (click)="showByUserTZ = false">
            {{ getTZAbbr(timezone) }}
        </button>
    </div>
</ng-template>
