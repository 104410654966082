import { Component, Injectable, OnDestroy, OnInit } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { map } from "rxjs/operators";
import { JsonIgnore, JsonProperty, ObjectMapper } from "json-object-mapper";
import { Employee } from "company/employee";
import { ActivatedRoute, ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from "@angular/router";
import { Observable } from "rxjs";
import { TitleService as LayoutTitleService } from "layout/title.service";
import { BreadcrumbService } from "layout/breadcrumb.service";
import { Hotel } from "../hotel";
import { mergeRouteData } from "app/merge-route-data";
import { HotelSidebarService } from "layout/sidebar/hotel.service";

export class EmployeeData {
    @JsonProperty({type: Employee})
    public employee: Employee;

    @JsonIgnore()
    public hotel: Hotel;
}

@Injectable()
export class EmployeeResolver implements Resolve<EmployeeData> {

    public constructor(
        protected httpClient: HttpClient
    ) {
    }

    public resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<EmployeeData> {
        let hotel: Hotel = mergeRouteData(route).component.hotel;
        return this.httpClient.get<EmployeeData>('/v2/companies/' + hotel.company.companyId + '/employees/' + route.params.employeeId)
            .pipe(map(data => ObjectMapper.deserialize(EmployeeData, data)))
            .pipe(map(data => {
                data.hotel = hotel;
                return data;
            }));
    }

}

@Component({
    selector: 'hotel-employee',
    templateUrl: './employee.component.html'
})
export class EmployeeComponent implements OnInit, OnDestroy {
    public employee: Employee;
    public employeeName: string;
    public hotel: Hotel;

    public constructor(
        protected route: ActivatedRoute,
        protected layoutTitleService: LayoutTitleService,
        protected breadcrumbService: BreadcrumbService,
        protected hotelSidebarService: HotelSidebarService
    ) {
    }

    public ngOnInit(): void {
        this.route.data.subscribe((data) => {
            this.employee = data.component.employee;
            this.hotel = data.component.hotel;
            this.employee.company = this.hotel.company;
            this.employeeName = (this.employee.name.isPresent()) ? this.employee.name.toString() : this.employee.email;
            this.layoutTitleService.title = this.employeeName;
            this.layoutTitleService.small = this.employee.position;
            this.breadcrumbService.breadcrumbs = [
                {name: 'Отели', link: '/hotels'},
                {name: this.hotel.name, link: ['/hotels', this.hotel.alias]},
                {name: 'Сотрудники', link: ['/hotels', this.hotel.alias, 'employees']},
                {name: this.employeeName}
            ];
            this.hotelSidebarService.setHotel(this.hotel);
        });
    }

    public ngOnDestroy(): void {
        this.hotelSidebarService.clearHotel();
    }

}
