import { Component, Injectable, OnInit } from "@angular/core";
import { ActivatedRoute, ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot } from "@angular/router";
import { HttpClient } from "@angular/common/http";
import { JsonProperty, ObjectMapper } from "json-object-mapper";
import { map } from "rxjs/operators";
import { Observable } from "rxjs";
import { Company } from "../company";
import { TitleService as LayoutTitleService } from "layout/title.service";
import { BreadcrumbService } from "layout/breadcrumb.service";
import { SweetalertService } from "app/sweetalert.service";
import { LaddaService } from "app/ladda.service";

export class CompanyData {
    @JsonProperty({type: Company})
    public company: Company;
}

@Injectable()
export class CompanyResolver implements Resolve<CompanyData> {

    public constructor(
        protected httpClient: HttpClient
    ) {
    }

    public resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<CompanyData> {
        return this.httpClient.get<CompanyData>('/v2/companies/' + route.params.companyId)
            .pipe(map(data => ObjectMapper.deserialize(CompanyData, data)));
    }

}

@Component({
    selector: 'company-company',
    templateUrl: './company.component.html'
})
export class CompanyComponent implements OnInit {
    public company: Company;
    public disableButtons: boolean = false;

    public constructor(
        protected route: ActivatedRoute,
        protected layoutTitleService: LayoutTitleService,
        protected breadcrumbService: BreadcrumbService,
        protected httpClient: HttpClient,
        protected sweetalertService: SweetalertService,
        protected laddaService: LaddaService,
        protected router: Router
    ) {
    }

    public ngOnInit(): void {
        this.route.data.subscribe((data) => {
            this.company = data.component.company;
            this.layoutTitleService.title = this.company.name;
            this.breadcrumbService.breadcrumbs = [
                {name: 'Компании', link: '/companies'},
                {name: this.company.name}
            ];
        });
    }

    public onEnable(target: HTMLButtonElement): void {
        let ladda = this.laddaService.create(target);
        this.sweetalertService.alert({
            icon: "warning",
            title: 'Включить компанию?',
            buttons: ['Отмена', 'Включить'],
            dangerMode: true
        }).then((data) => {
            if (data) {
                ladda.start();
                this.disableButtons = true;
                this.httpClient.post<{ company: any }>('/v2/companies/' + this.company.companyId + '/enable', {}).subscribe((data) => {
                    ladda.stop();
                    this.disableButtons = false;
                    this.company = ObjectMapper.deserialize(Company, data.company);
                    this.sweetalertService.alert({
                        icon: "success",
                        title: 'Компания включена!'
                    });
                }, error => {
                    ladda.stop();
                    this.disableButtons = false;
                    console.error("Enable company error:", error);
                    throw error;
                });
            }
        });
    }

    public onDisable(target: HTMLButtonElement): void {
        let ladda = this.laddaService.create(target);
        this.sweetalertService.alert({
            icon: "warning",
            title: 'Отключить компанию?',
            buttons: ['Отмена', 'Отключить'],
            dangerMode: true
        }).then((data) => {
            if (data) {
                ladda.start();
                this.disableButtons = true;
                this.httpClient.post<{ company: any }>('/v2/companies/' + this.company.companyId + '/disable', {}).subscribe((data) => {
                    ladda.stop();
                    this.disableButtons = false;
                    this.company = ObjectMapper.deserialize(Company, data.company);
                    this.sweetalertService.alert({
                        icon: "success",
                        title: 'Компания отключена!'
                    });
                }, error => {
                    ladda.stop();
                    this.disableButtons = false;
                    console.error("Disable company error:", error);
                    throw error;
                });
            }
        });
    }

    public onDelete(target: HTMLButtonElement): void {
        let ladda = this.laddaService.create(target);
        this.sweetalertService.alert({
            icon: "warning",
            title: 'Удалить компанию?',
            buttons: ['Отмена', 'Удалить'],
            dangerMode: true
        }).then((data) => {
            if (data) {
                ladda.start();
                this.disableButtons = true;
                this.httpClient.delete<{ company: any }>('/v2/companies/' + this.company.companyId, {}).subscribe((data) => {
                    ladda.stop();
                    this.sweetalertService.alert({
                        icon: "success",
                        title: 'Компания удалена!'
                    }).then(() => {
                        this.router.navigate(['/companies']);
                    });
                }, error => {
                    ladda.stop();
                    this.disableButtons = false;
                    console.error("Delete company error:", error);
                    throw error;
                });
            }
        });
    }

}
