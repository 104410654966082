import { ApplicationRef, ComponentFactoryResolver, ErrorHandler, Injector } from "@angular/core";
import { HttpErrorResponse } from "@angular/common/http";
import { NavigationError, Router, RouterEvent } from "@angular/router";
import { AppComponent } from "app/app.component";
import { Error404Component } from "./error404.component";

export class RouterErrorHandler implements ErrorHandler {

    protected lastError: NavigationError;

    public constructor(
        protected injector: Injector,
        protected resolver: ComponentFactoryResolver
    ) {
        setTimeout(() => {
            this.injector.get(Router).events.subscribe((event: RouterEvent) => {
                if (event instanceof NavigationError) {
                    this.lastError = event;
                }
            });
        }, 0);
    }

    public handleError(error: any): void {
        if (error instanceof HttpErrorResponse && error.status == 404) {
            console.warn("Navigation error: " + error.message, error);
            let app = this.injector.get(ApplicationRef);
            if (app.components.length != 1) {
                return;
            }
            let appComponent = app.components[0].instance as AppComponent;
            let factory = this.resolver.resolveComponentFactory(Error404Component);
            appComponent.routerOutlet.clear();
            appComponent.routerOutlet.createComponent(factory);
            window.history.pushState({}, '', this.lastError.url);
            window.addEventListener('popstate', (event: PopStateEvent) => {
                location.reload();
            });
        } else {
            throw error;
        }
    }

}
