<div class="ibox-content">
    <div class="table-responsive">
        <table class="table table-hover">
            <thead>
            <tr>
                <th>ID</th>
                <th>E-mail</th>
                <th>ФИО</th>
                <th>Телефон</th>
                <th *ngIf="authUserCompany.isManagementCompany()">Компания</th>
                <th>Должность</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let employee of employees; trackBy: trackByEmployee">
                <th [class.del]="employee.isDisabled()">
                    <a *ngIf="authUserCompany.isManagementCompany()"
                       [routerLink]="['/companies', employee.company.companyId, 'employees', employee.employeeId]">
                        {{ employee.employeeId }}
                    </a>
                    <a *ngIf="authUserCompany.isFranchiseeCompany()"
                       [routerLink]="['/employees', employee.employeeId]">
                        {{ employee.employeeId }}
                    </a>
                </th>
                <td [class.del]="employee.isDisabled()">
                    <i *ngIf="employee.isEnabled()" class="far fa-check-circle text-navy m-r"
                       title="Сотрудник включен"></i>
                    <i *ngIf="employee.isDisabled()" class="fas fa-ban text-danger m-r"
                       title="Сотрудник заблокирован"></i>
                    <a *ngIf="authUserCompany.isManagementCompany()"
                       [routerLink]="['/companies', employee.company.companyId, 'employees', employee.employeeId]">
                        {{ employee.email }}
                    </a>
                    <a *ngIf="authUserCompany.isFranchiseeCompany()"
                       [routerLink]="['/employees', employee.employeeId]">
                        {{ employee.email }}
                    </a>
                    <span *ngIf="employee.employeeId == currentEmployeeId" class="badge badge-warning m-l">Это Вы</span>
                </td>
                <td>
                    {{ employee.name.first }}
                    {{ employee.name.middle }}
                    {{ employee.name.last }}
                </td>
                <td>{{ employee.phone }}</td>
                <td *ngIf="authUserCompany.isManagementCompany()">{{ employee.company.name }}</td>
                <td>{{ employee.position }}</td>
            </tr>
            <tr *ngIf="employees.length == 0">
                <td [attr.colspan]="authUserCompany.isManagementCompany() ? 6 : 5" class="text-center">
                    Сотрудники не найдены
                    <a class="m-l" [routerLink]="['/employees', 'new']">(добавить нового)</a>
                </td>
            </tr>
            </tbody>
        </table>
    </div>
</div>
