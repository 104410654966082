<div class="row mb-3">
    <div class="col-12 text-right">
        <a *ngIf="showCompanyInfoLink()" [routerLink]="['/vacancies/company']">Информация о компании</a>
        <a [routerLink]="['/vacancies/archive']" class="ml-3">Архив вакансий</a>
    </div>
</div>

<div class="ibox-content">
    <div class="table-responsive">
        <table class="table table-hover">
            <thead>
            <tr>
                <th>ID</th>
                <th>Заголовок</th>
                <th>Компания / Отель</th>
                <th>Город</th>
                <th>Отклики</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let vacancy of vacancies; trackBy: trackByVacancy">
                <th>
                    <a [routerLink]="['/vacancies', vacancy.vacancyId]">
                        {{ vacancy.vacancyId }}
                    </a>
                </th>
                <td>
                    <i *ngIf="vacancy.isEnabled()" class="far fa-check-circle text-navy m-r"
                       title="Вакансия опубликована"></i>
                    <i *ngIf="vacancy.isDisabled()" class="fas fa-ban text-danger m-r"
                       title="Вакансия снаята с публикации"></i>
                    <a [routerLink]="['/vacancies', vacancy.vacancyId]">
                        {{ vacancy.title }}
                    </a>
                </td>
                <td>
                    {{ vacancy.company.name }}
                    <ng-template [ngIf]="vacancy.hotel">
                        / {{ vacancy.hotel.name }}
                    </ng-template>
                </td>
                <td>{{ vacancy.city.name }}</td>
                <td *ngIf="vacancy.responsesCount == 0">Нет откликов</td>
                <td *ngIf="vacancy.responsesCount > 0">
                    <a [routerLink]="['/vacancies', vacancy.vacancyId]">
                        {{ vacancy.responsesCount }} откликов
                    </a>
                </td>
            </tr>
            <tr *ngIf="vacancies.length == 0">
                <td class="text-center" colspan="5">
                    Вакансии не найдены
                    <a class="m-l" [routerLink]="['/vacancies', 'new']">(добавить новую)</a>
                </td>
            </tr>
            </tbody>
        </table>
    </div>
</div>
