import { Component, Injectable, OnDestroy, OnInit } from "@angular/core";
import { Hotel } from "../hotel";
import { TitleService as LayoutTitleService } from "layout/title.service";
import { HotelSidebarService } from "layout/sidebar/hotel.service";
import { ActivatedRoute, ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot } from "@angular/router";
import { BreadcrumbService } from "layout/breadcrumb.service";
import { PriceForm } from "./price.form";
import { JsonProperty, ObjectMapper } from "json-object-mapper";
import { HttpClient } from "@angular/common/http";
import { Observable, forkJoin } from "rxjs";
import { mergeRouteData } from "app/merge-route-data";
import { map } from "rxjs/operators";
import { RoomCategory } from "../room-category";
import { Price } from "../price";

export class NewPriceData {
    @JsonProperty({type: Hotel})
    public hotel: Hotel;

    @JsonProperty({type: RoomCategory})
    public categories: RoomCategory[];

    @JsonProperty({type: Price, required: false, name: 'price'})
    public clone?: Price;
}

@Injectable()
export class NewPriceResolver implements Resolve<NewPriceData> {
    public constructor(
        protected httpClient: HttpClient
    ) {
    }

    public resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<NewPriceData> {
        let hotel: Hotel = mergeRouteData(route).component.hotel;
        let resolvers = [
            this.httpClient.get<any>('/v2/hotels/' + hotel.hotelId + '/rooms/categories')
                .pipe(map(data => {
                    return {
                        hotel: data.hotel,
                        categories: data.categories
                    };
                }))
        ];
        if (route.params.clone) {
            resolvers.push(this.httpClient.get<any>('/v2/hotels/' + hotel.hotelId + '/prices/' + route.params.clone));
        }
        return forkJoin(resolvers)
            .pipe(map(data => {
                let result = {};
                for (let d of data) Object.assign(result, d);
                return result;
            }))
            .pipe(map(data => ObjectMapper.deserialize(NewPriceData, data)));
    }
}

@Component({
    selector: 'hotel-new-price',
    templateUrl: './new-price.component.html'
})
export class NewPriceComponent implements OnInit, OnDestroy {
    public hotel: Hotel;
    public categories: RoomCategory[] = [];
    public priceForm: PriceForm;

    public constructor(
        protected route: ActivatedRoute,
        protected layoutTitleService: LayoutTitleService,
        protected breadcrumbService: BreadcrumbService,
        protected hotelSidebarService: HotelSidebarService,
        protected httpClient: HttpClient,
        protected router: Router
    ) {
    }

    public ngOnInit(): void {
        this.route.data.subscribe((data) => {
            this.hotel = data.component.hotel;
            this.categories = data.component.categories;
            if (data.component.clone) {
                let clone: Price = data.component.clone;
                this.priceForm = new PriceForm(this.categories, clone, true);
                this.priceForm.get('name').setValue(this.priceForm.get('name').value + ' (копия)');
            } else {
                this.priceForm = new PriceForm(this.categories);
            }
            this.layoutTitleService.title = 'Новый прайс';
            this.breadcrumbService.breadcrumbs = [
                {name: 'Отели', link: '/hotels'},
                {name: this.hotel.name, link: ['/hotels', this.hotel.alias]},
                {name: 'Цены', link: ['/hotels', this.hotel.alias, 'prices']},
                {name: 'Новый прайс'}
            ];
            this.hotelSidebarService.setHotel(this.hotel);
        });
    }

    public ngOnDestroy(): void {
        this.hotelSidebarService.clearHotel();
    }

    public onSubmit(): void {
        this.priceForm.disable();
        this.httpClient.post<{ price: any }>('/v2/hotels/' + this.hotel.hotelId + '/prices', ObjectMapper.serialize({
            price: this.priceForm.getPrice()
        })).toPromise().then((data) => {
            let price = ObjectMapper.deserialize(Price, data.price);
            this.router.navigate(['/hotels', this.hotel.alias, 'prices', price.priceId]);
        }).catch((error: any) => {
            console.error("Create price error:", error);
            this.priceForm.enable();
            throw error;
        });
    }

}
