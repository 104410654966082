import { Component, OnDestroy, OnInit } from "@angular/core";
import './layout.component.scss';

@Component({
    selector: 'action-layout',
    templateUrl: './layout.component.html'
})
export class LayoutComponent implements OnInit, OnDestroy {
    public appVersion = APP_VERSION;

    public ngOnInit(): void {
        jQuery('body').addClass('gray-bg');
    }

    public ngOnDestroy(): void {
        jQuery('body').removeClass('gray-bg');
    }

}
