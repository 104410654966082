import { AbstractControl, FormControl, FormGroup, ValidationErrors, Validators } from "@angular/forms";
import { Room } from "../room";
import { HttpClient, HttpResponse } from "@angular/common/http";
import { Hotel } from "../hotel";

export class RoomForm extends FormGroup {

    public constructor(httpClient: HttpClient, hotel: Hotel, room?: Room) {
        super({
            category: new FormControl(room ? room.category.roomCategoryId : null, [
                Validators.required
            ]),
            number: new FormControl(room ? room.number : null, [
                Validators.required,
                Validators.pattern(/^[a-zA-Zа-яёА-ЯЁ][a-zA-Zа-яёА-ЯЁ0-9\-_]+$/),
                Validators.minLength(1),
                Validators.maxLength(5)
            ], [
                RoomForm.numberValidator(httpClient, hotel, room ? room.number : null)
            ])
        });
    }

    public static numberValidator(httpClient: HttpClient, hotel: Hotel, initial?: string): (control: AbstractControl) => Promise<ValidationErrors | null> {
        return (control) => {
            return new Promise((resolve, reject) => {
                if (control.value && initial && control.value.trim().toUpperCase() == initial.trim().toUpperCase()) {
                    resolve();
                    return;
                }
                httpClient.get<HttpResponse<any>>('/v2/hotels/' + hotel.hotelId + '/rooms/' + control.value, {
                    observe: 'response'
                }).toPromise().then(() => {
                    resolve({exists: true});
                }).catch((response: HttpResponse<any>) => {
                    if (response.status == 404) {
                        resolve();
                    } else {
                        reject(response);
                    }
                });
            });
        };
    }

    public getRoom(): Room {
        let room = new Room();
        room.categoryId = this.get('category').value;
        room.number = this.get('number').value;
        return room;
    }

}
