import { AccessType, CacheKey, Deserializer, JsonIgnore, JsonProperty, Serializer } from "json-object-mapper";
import { DateSerializerDeserializer } from "app/date";

export enum VacancyResponseStatus {
    Disabled = 'disabled',
    Enabled = 'enabled',
    Deleted = 'deleted'
}

@CacheKey("VacancyResponseStatusSerializerDeserializer")
class VacancyResponseStatusSerializerDeserializer implements Deserializer, Serializer {

    public deserialize(value: string): VacancyResponseStatus {
        return VacancyResponseStatus[value.charAt(0).toUpperCase() + value.slice(1).toLowerCase()];
    }

    public serialize(value: VacancyResponseStatus): string {
        return '"' + VacancyResponseStatus[value.charAt(0).toUpperCase() + value.slice(1).toLowerCase()].toLowerCase() + '"';
    }

}

export class VacancyResponse {
    @JsonProperty({name: 'vacancy_response_id', access: AccessType.READ_ONLY})
    public vacancyResponseId: number;

    @JsonProperty({
        type: VacancyResponseStatus,
        serializer: VacancyResponseStatusSerializerDeserializer,
        deserializer: VacancyResponseStatusSerializerDeserializer
    })
    public status: VacancyResponseStatus;

    @JsonIgnore()
    public isEnabled(): boolean {
        return this.status == VacancyResponseStatus.Enabled;
    }

    @JsonIgnore()
    public isDisabled(): boolean {
        return this.status == VacancyResponseStatus.Disabled;
    }

    @JsonIgnore()
    public isDeleted(): boolean {
        return this.status == VacancyResponseStatus.Deleted;
    }

    @JsonProperty({
        name: 'creation_date',
        access: AccessType.READ_ONLY,
        type: Date,
        serializer: DateSerializerDeserializer,
        deserializer: DateSerializerDeserializer
    })
    public creationDate: Date;

    @JsonProperty({
        name: 'update_date',
        access: AccessType.READ_ONLY,
        type: Date,
        serializer: DateSerializerDeserializer,
        deserializer: DateSerializerDeserializer
    })
    public updateDate: Date;

    @JsonProperty()
    public name: string;

    @JsonProperty()
    public phone: string;

    @JsonProperty({required: false})
    public email: string | null;

    @JsonProperty({name: 'covering_letter'})
    public coveringLetter: string;

}
