import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from "@angular/router";
import { ActionService } from "./action.service";

@Injectable()
export class ActionGuard implements CanActivate {

    public constructor(
        protected actionService: ActionService,
        protected router: Router
    ) {
    }

    public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        if (!this.actionService.hasUnresolvedActions()) {
            this.router.navigate(['/']);
            return false;
        }
        if (!state.url.startsWith('/actions')) {
            this.router.navigate(['/']);
            return false;
        }
        if (state.url == '/actions') {
            return true;
        }
        let action = state.url.substr('/actions/'.length);
        if (this.actionService.hasUnresolvedAction(action)) {
            return true;
        } else {
            this.router.navigate(['/actions']);
            return false;
        }
    }

}
