import { Injectable } from "@angular/core";
import { Event, NavigationEnd, Router } from "@angular/router";

@Injectable()
export class LayoutService {
    public showHeading: boolean = true;

    public constructor(
        protected router: Router
    ) {
        router.events.subscribe(event => this.onRounterEvent(event));
    }

    protected onRounterEvent(event: Event): void {
        if (event instanceof NavigationEnd) {
            this.showHeading = true;
        }
    }

}
