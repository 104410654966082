<div class="ibox">
    <div class="ibox-content">
        <div class="m-b-md">
            <a *ngIf="!company.isDeleted()" [routerLink]="['/companies', company.companyId, 'edit']"
               class="btn btn-white btn-xs float-right">
                Редактировать
            </a>
            <button *ngIf="company.isDisabled()" (click)="onDelete($event.target)"
                    type="button" [disabled]="disableButtons"
                    class="btn btn-danger btn-xs float-right m-r-sm ladda-button" data-style="slide-right">
                Удалить
            </button>
            <button *ngIf="company.isEnabled()" (click)="onDisable($event.target)"
                    type="button" [disabled]="disableButtons"
                    class="btn btn-warning btn-xs float-right m-r-sm ladda-button" data-style="slide-right">
                Отключить
            </button>
            <button *ngIf="company.isDisabled()" (click)="onEnable($event.target)"
                    type="button" [disabled]="disableButtons"
                    class="btn btn-success btn-xs float-right m-r-sm ladda-button" data-style="slide-right">
                Включить
            </button>
            <h2>{{ company.name }}</h2>
        </div>
        <dl class="row mb-0">
            <dt class="col-2">Статус:</dt>
            <dd class="col-10">
                <span *ngIf="company.isEnabled()" class="label label-primary">Активный</span>
                <span *ngIf="company.isDisabled()" class="label label-warning">Заблокирован</span>
                <span *ngIf="company.isDeleted()" class="label label-danger">Удалён</span>
            </dd>
            <dt class="col-2">Город:</dt>
            <dd class="col-10">{{ company.city.name }}</dd>
        </dl>
        <h2 class="text-muted text-right">#{{ company.companyId }}</h2>
    </div>
</div>
