import { Component, OnInit } from "@angular/core";
import { AuthService } from "app/auth/auth.service";
import { EmployeeDetailsForm } from "company/employee/employee.form";
import { JsonProperty, ObjectMapper } from "json-object-mapper";
import { AbstractEmployee, Employee } from "company/employee";
import { HttpClient } from "@angular/common/http";
import { Router } from "@angular/router";
import { ActionService } from "../action.service";

export class ProfileModel extends AbstractEmployee {
}

export class ProfileRequest {

    public constructor(employee: Employee) {
        this.user = new ProfileModel(employee);
    }

    @JsonProperty({type: ProfileModel})
    public user: ProfileModel;
}

@Component({
    selector: 'action-profile',
    templateUrl: './profile.component.html'
})
export class ProfileComponent implements OnInit {
    public employeeDetailsForm: EmployeeDetailsForm;

    public static isIncompleteProfile(authService: AuthService): boolean {
        return !authService.getUser().name.isPresent();
    }

    public constructor(
        protected authService: AuthService,
        protected httpClient: HttpClient,
        protected router: Router,
        protected actionService: ActionService
    ) {
    }

    public ngOnInit(): void {
        this.employeeDetailsForm = new EmployeeDetailsForm(this.authService.getUser(), true);
    }

    public onSubmit(): void {
        this.employeeDetailsForm.disable();
        this.httpClient.post<{ user: any }>('/v2/user', ObjectMapper.serialize(
            new ProfileRequest(this.employeeDetailsForm.getEmployee(this.authService.getUser()))
        )).subscribe((data) => {
            let user = ObjectMapper.deserialize(ProfileModel, data.user);
            this.authService.setEmployee(new Employee(
                user, this.authService.getUser().company, this.authService.getUser().permissions
            ));
            this.actionService.resolveAction('profile');
            this.router.navigate(['/actions']);
        }, (error) => {
            console.error("Update user error:", error);
            this.employeeDetailsForm.enable();
            throw error;
        });

    }

}
