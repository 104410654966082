import { Component, OnInit } from "@angular/core";
import { CountryForm } from "./country.form";
import { HttpClient } from "@angular/common/http";
import { ObjectMapper } from "json-object-mapper";
import { Router } from "@angular/router";
import { Country } from "../country";
import { TitleService as LayoutTitleService } from "layout/title.service";
import { BreadcrumbService } from "layout/breadcrumb.service";

@Component({
    selector: 'location-country-new',
    templateUrl: 'new-country.component.html'
})
export class NewCountryComponent implements OnInit {

    public countryForm: CountryForm = new CountryForm(this.httpClient);

    public constructor(
        protected httpClient: HttpClient,
        protected router: Router,
        protected layoutTitleService: LayoutTitleService,
        protected breadcrumbService: BreadcrumbService
    ) {
    }

    public ngOnInit(): void {
        this.layoutTitleService.title = 'Новая страна';
        this.breadcrumbService.breadcrumbs = [
            {name: 'Страны', link: '/locations/countries'},
            {name: 'Новая страна'}
        ];
    }

    public onSubmit(): void {
        this.countryForm.disable();
        this.httpClient.post<{ country: any }>('/v2/locations/countries', ObjectMapper.serialize({
            country: this.countryForm.getCountry()
        })).toPromise().then((data) => {
            let country = ObjectMapper.deserialize(Country, data.country);
            this.router.navigate(['/locations/countries', country.alias]);
        }).catch((error: any) => {
            console.error("Create country error:", error);
            this.countryForm.enable();
            throw error;
        });
    }

}
