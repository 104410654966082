<div class="ibox-content">
    <form [formGroup]="categoryForm" (ngSubmit)="onSubmit()">
        <div class="form-group row"
             [class.has-error]="categoryForm.controls.type.value && !categoryForm.controls.type.disabled && !categoryForm.controls.type.valid">
            <label class="col-2 col-form-label" for="form-hotel-room-category-type">Тип:</label>
            <div class="col-10">
                <select class="form-control" id="form-hotel-room-category-type" formControlName="type">
                    <option [value]="RoomCategoryType.Standart">Стандартный номер</option>
                    <option [value]="RoomCategoryType.Shared">Общий номер</option>
                    <option [value]="RoomCategoryType.SharedMale">Общий мужской номер</option>
                    <option [value]="RoomCategoryType.SharedFemale">Общий женский номер</option>
                </select>
            </div>
        </div>
        <hotel-room-category-form [categoryForm]="categoryForm"></hotel-room-category-form>
        <div class="form-group row">
            <div class="col-10 offset-2">
                <a class="btn btn-white"
                   [routerLink]="['/hotels', hotel.alias, 'rooms', 'categories']">Отмена</a>
                &nbsp;
                <button class="btn btn-primary" type="submit" [disabled]="!categoryForm.valid">Сохранить</button>
            </div>
        </div>
    </form>
</div>
