<div class="ibox-content">
    <form [formGroup]="companyForm" (ngSubmit)="onSubmit()">
        <company-form [companyForm]="companyForm" [cities]="cities"></company-form>
        <div class="form-group row">
            <div class="col-10 offset-2">
                <a class="btn btn-white" [routerLink]="['/companies', company.companyId]">Отмена</a>
                &nbsp;
                <button class="btn btn-primary" type="submit" [disabled]="!companyForm.valid">Сохранить</button>
            </div>
        </div>
    </form>
</div>
