<div class="container mt-3">
    <div class="row">
        <div class="col-12 col-sm-10 offset-sm-1 col-md-8 offset-md-2 col-lg-6 offset-lg-3">
            <div class="panel panel-danger">
                <div class="panel-heading">
                    <i class="fas fa-exclamation-triangle"></i>
                    Отель не найден
                </div>
                <div class="panel-body text-justify">
                    <p>Для Вашего аккаунта не найден ни один отель.</p>
                    <p>
                        Возможно, у Вас не соответствующих прав доступа.
                        Пожалуйста, обратитесь к руководителю Вашей компании или менеджеру Лайк Хостел.
                    </p>
                </div>
            </div>
        </div>
    </div>
</div>
