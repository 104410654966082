<ul class="nav metismenu" #metisMenu>
    <li *ngIf="hasHotelsItem()" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}">
        <a routerLink="/hotels">
            <i class="fas fa-bed"></i>
            <span class="nav-label">{{ hotelsLinkTitle }}</span>
        </a>
    </li>
    <li *ngIf="hasEmployeesItem()" routerLinkActive="active">
        <a routerLink="/employees">
            <i class="fas fa-users"></i>
            <span class="nav-label">Сотрудники</span>
        </a>
    </li>
    <li *ngIf="hasVacanciesItem()" routerLinkActive="active">
        <a routerLink="/vacancies">
            <i class="far fa-handshake"></i>
            <span class="nav-label">Вакансии</span>
        </a>
    </li>
</ul>
