<div class="container mt-3">
    <div class="row">
        <div class="col-12 col-sm-10 offset-sm-1 col-md-8 offset-md-2 col-lg-6 offset-lg-3">
            <div class="panel panel-default">
                <div class="panel-heading">
                    <i class="fas fa-tachometer-alt"></i>
                    Переход к старой ERP
                </div>
                <div *ngIf="!showUserNotFoundError" class="panel-body text-center">
                    Идёт авторизация в старой ERP...
                </div>
                <div *ngIf="showUserNotFoundError" class="panel-body text-justify">
                    Похоже, что у Вас нет аккаунта в старой ERP. Вы можете обратиться к Вашему
                    менеджеру для создания аккаунта или <a href="/">вернуться к работе с новой ERP</a>.
                </div>
            </div>
        </div>
    </div>
</div>

<div class="container mt-3 mb-3">
    <div class="clearfix">
        <a routerLink="/logout" class="float-right btn btn-outline btn-default">Выход из системы</a>
    </div>
    <hr/>
    <div class="row">
        <div class="col-8">
            Лайк Хостел &copy; 2014-2018
        </div>
        <div class="col-4 text-right">
            <small>ERP v{{ appVersion }}</small>
        </div>
    </div>
</div>
