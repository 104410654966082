<div class="ibox-content">
    <div class="table-responsive">
        <table class="table table-hover">
            <thead>
            <tr>
                <th>ID</th>
                <th>Название</th>
                <th *ngIf="showCompany">Компания</th>
                <th>Алиас</th>
                <th>Город</th>
                <th>Тип</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let hotel of hotels; trackBy: trackByHotel">
                <th [class.del]="hotel.isDisabled()">
                    <a [routerLink]="['/hotels', hotel.alias]">
                        {{ hotel.hotelId }}
                    </a>
                </th>
                <td [class.del]="hotel.isDisabled()">
                    <i *ngIf="hotel.isEnabled()" class="far fa-check-circle text-navy m-r" title="Отель включен"></i>
                    <i *ngIf="hotel.isDisabled()" class="fas fa-ban text-danger m-r" title="Отель заблокирован"></i>
                    <a [routerLink]="['/hotels', hotel.alias]">
                        {{ hotel.name }}
                    </a>
                </td>
                <td *ngIf="showCompany">
                    <span *ngIf="hotel.company">{{ hotel.company.name }}</span>
                </td>
                <td [class.del]="hotel.isDisabled()">
                    <a [routerLink]="['/hotels', hotel.alias]">
                        {{ hotel.alias }}
                    </a>
                </td>
                <td>{{ hotel.city.name }}</td>
                <td>
                    <span *ngIf="hotel.type == HotelType.Hotel">Отель</span>
                    <span *ngIf="hotel.type == HotelType.Hostel">Хостел</span>
                </td>
            </tr>
            <tr *ngIf="hotels.length == 0">
                <td [attr.colspan]="showCompany ? 6 : 5" class="text-center">
                    Отели не найдены
                    <a *ngIf="showNewHotelButton" class="m-l" [routerLink]="['/hotels', 'new']">(добавить новый)</a>
                </td>
            </tr>
            </tbody>
        </table>
    </div>
</div>
