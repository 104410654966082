<div class="ibox">
    <div class="ibox-content">
        <div class="m-b-md">
            <ng-template [canEditHotelRooms]="hotel">
                <a *ngIf="!category.isDeleted()" [routerLink]="['/hotels', hotel.alias, 'rooms', 'categories', category.roomCategoryId, 'edit']"
                   class="btn btn-white btn-xs float-right">
                    Редактировать
                </a>
                <button *ngIf="category.isDisabled()" (click)="onDelete($event.target)"
                        type="button" [disabled]="disableButtons"
                        class="btn btn-danger btn-xs float-right m-r-sm ladda-button" data-style="slide-right">
                    Удалить
                </button>
                <button *ngIf="category.isEnabled()" (click)="onDisable($event.target)"
                        type="button" [disabled]="disableButtons"
                        class="btn btn-warning btn-xs float-right m-r-sm ladda-button" data-style="slide-right">
                    Отключить
                </button>
                <button *ngIf="category.isDisabled()" (click)="onEnable($event.target)"
                        type="button" [disabled]="disableButtons"
                        class="btn btn-success btn-xs float-right m-r-sm ladda-button" data-style="slide-right">
                    Включить
                </button>
            </ng-template>
            <h2>{{ category.name }}</h2>
        </div>
        <dl class="row mb-0">
            <dt class="col-2">Тип:</dt>
            <dd class="col-10">
                <span *ngIf="category.type == RoomCategoryType.Standart">Стандартный номер</span>
                <span *ngIf="category.type == RoomCategoryType.Shared">Общий номер</span>
                <span *ngIf="category.type == RoomCategoryType.SharedMale">Общий мужской номер</span>
                <span *ngIf="category.type == RoomCategoryType.SharedFemale">Общий женский номер</span>
            </dd>
            <dt class="col-2">Статус:</dt>
            <dd class="col-10">
                <span *ngIf="category.isEnabled()" class="label label-primary">Активная</span>
                <span *ngIf="category.isDisabled()" class="label label-warning">Заблокировна</span>
                <span *ngIf="category.isDeleted()" class="label label-danger">Удалена</span>
            </dd>
            <dt class="col-2">Кол-во гостей:</dt>
            <dd class="col-10">
                {{ category.capacity.guests }}
                <small *ngIf="category.capacity.additional">(+{{ category.capacity.additional }})</small>
            </dd>
        </dl>
        <h2 class="text-muted text-right">#{{ category.roomCategoryId }}</h2>
    </div>
</div>
