import { RouterModule, Routes } from "@angular/router";
import { NgModule } from "@angular/core";
import { LayoutComponent } from "layout/layout.component";
import { AuthGuard } from "app/auth/auth.guard";
import { AllActionsResolved } from "action/all-actions-resolved.guard";
import { VacanciesActionComponent, VacanciesComponent, VacanciesResolver } from "./vacancies.component";
import { CanEditCompanyVacanciesGuard, VacanciesGuard } from "./vacancy.guard";
import { VacancyComponent, VacancyResolver } from "./vacancy.component";
import { NewVacancyComponent, NewVacancyResolver } from "./new-vacancy.component";
import { EditVacancyComponent, EditVacancyResolver } from "./edit-vacancy.component";
import { CompanyInfoComponent, CompanyInfoResolver } from "./company-info.component";
import { ManagementCompanyGuard } from "app/auth/management-company.guard";
import { VacanciesArchiveComponent, VacanciesArchiveResolver } from "./vacancies-archive.component";

const routes: Routes = [
    {
        path: '',
        component: LayoutComponent,
        canActivate: [AuthGuard, AllActionsResolved, VacanciesGuard],
        children: [
            {
                path: 'vacancies',
                children: [
                    {
                        path: '',
                        children: [
                            {
                                path: '',
                                component: VacanciesComponent,
                                resolve: {
                                    component: VacanciesResolver
                                }
                            },
                            {
                                path: '',
                                component: VacanciesActionComponent,
                                outlet: 'action'
                            }
                        ]
                    },
                    {
                        path: 'archive',
                        component: VacanciesArchiveComponent,
                        resolve: {
                            component: VacanciesArchiveResolver
                        }
                    },
                    {
                        path: 'new',
                        component: NewVacancyComponent,
                        resolve: {
                            component: NewVacancyResolver
                        }
                    },
                    {
                        path: 'company',
                        component: CompanyInfoComponent,
                        canActivate: [CanEditCompanyVacanciesGuard],
                        resolve: {
                            component: CompanyInfoResolver
                        }
                    },
                    {
                        path: 'company/:companyId',
                        component: CompanyInfoComponent,
                        canActivate: [ManagementCompanyGuard],
                        resolve: {
                            component: CompanyInfoResolver
                        }
                    },
                    {
                        path: ':vacancyId',
                        component: VacancyComponent,
                        resolve: {
                            component: VacancyResolver
                        }
                    },
                    {
                        path: ':vacancyId/edit',
                        component: EditVacancyComponent,
                        resolve: {
                            component: EditVacancyResolver
                        }
                    }
                ]
            }
        ]
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
    providers: [
        VacanciesResolver, VacanciesGuard,
        VacancyResolver, EditVacancyResolver,
        NewVacancyResolver, CompanyInfoResolver,
        CanEditCompanyVacanciesGuard,
        VacanciesArchiveResolver
    ]
})
export class VacancyRoutingModule {
}
