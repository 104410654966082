import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from "@angular/common/http";
import { Observable } from "rxjs";
import { AuthService } from "./auth.service";
import { GlobalConfig } from "../global-config";

export class AuthHttpInterceptor implements HttpInterceptor {

    protected static readonly AUTH_HEADER = 'X-Auth-Token';

    public constructor(
        protected globalConfig: GlobalConfig,
        protected authService: AuthService
    ) {
    }

    public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (req.url.startsWith(this.globalConfig.erpapi.url) && (this.authService.hasToken() || this.authService.hasRemembermeToken())) {
            req = req.clone({
                headers: req.headers.set(AuthHttpInterceptor.AUTH_HEADER, this.authService.hasToken() ? this.authService.getToken() : this.authService.getRemembermeToken())
            });
        }
        // @todo: rememberme and other
        /*return next.handle(req).pipe(tap((event) => {
            if (event instanceof HttpResponse) {
                console.log(event.headers.has(AuthHttpInterceptor.AUTH_HEADER));
            }
        }));*/
        return next.handle(req);
    }

}
