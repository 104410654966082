<div *ngIf="priceForm" class="ibox-content">
    <form [formGroup]="priceForm" (ngSubmit)="onSubmit()">
        <hotel-price-form [priceForm]="priceForm"></hotel-price-form>
        <hr class="hr-line-dashed">
        <div class="form-group row">
            <div class="col-10 offset-2">
                <a class="btn btn-white"
                   [routerLink]="['/hotels', hotel.alias, 'prices', price.priceId]">Отмена</a>
                &nbsp;
                <button class="btn btn-primary" type="submit" [disabled]="!priceForm.valid">Сохранить</button>
            </div>
        </div>
    </form>
</div>
