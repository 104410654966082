import { Component, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute, ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from "@angular/router";
import { Hotel } from "../hotel";
import { BreadcrumbService } from "layout/breadcrumb.service";
import { HotelSidebarService } from "layout/sidebar/hotel.service";
import { TitleService as LayoutTitleService } from "layout/title.service";
import { Price } from "../price";
import { JsonProperty, ObjectMapper } from "json-object-mapper";
import { Observable } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { mergeRouteData } from "app/merge-route-data";
import { map } from "rxjs/operators";

export class PricesData {

    @JsonProperty({type: Hotel})
    public hotel: Hotel;

    @JsonProperty({type: Price})
    public prices: Price[];
}

export class PricesResolver implements Resolve<PricesData> {

    public constructor(
        protected httpClient: HttpClient
    ) {
    }

    public resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<PricesData> {
        let hotel: Hotel = mergeRouteData(route).component.hotel;
        return this.httpClient.get<PricesData>('/v2/hotels/' + hotel.hotelId + '/prices')
            .pipe(map(data => ObjectMapper.deserialize(PricesData, data)));
    }

}

@Component({
    selector: 'hotel-prices-action',
    templateUrl: './prices-action.component.html'
})
export class PricesActionComponent {
    public hotel: Hotel;

    public constructor(
        protected route: ActivatedRoute
    ) {
    }

    public ngOnInit(): void {
        this.route.data.subscribe((data) => {
            this.hotel = data.component.hotel;
        });
    }
}

@Component({
    selector: 'hotel-prices',
    templateUrl: './prices.component.html'
})
export class PricesComponent implements OnInit, OnDestroy {
    public hotel: Hotel;
    public prices: Price[] = [];

    public constructor(
        protected route: ActivatedRoute,
        protected layoutTitleService: LayoutTitleService,
        protected breadcrumbService: BreadcrumbService,
        protected hotelSidebarService: HotelSidebarService
    ) {
    }

    public ngOnInit(): void {
        this.route.data.subscribe((data) => {
            this.hotel = data.component.hotel;
            this.prices = data.component.prices;
            this.layoutTitleService.title = 'Цены';
            this.breadcrumbService.breadcrumbs = [
                {name: 'Отели', link: '/hotels'},
                {name: this.hotel.name, link: ['/hotels', this.hotel.alias]},
                {name: 'Цены'}
            ];
            this.hotelSidebarService.setHotel(this.hotel);
        });
    }

    public ngOnDestroy(): void {
        this.hotelSidebarService.clearHotel();
    }

    public trackByPrice(index: number, price: Price): number {
        return price.priceId;
    }

    public localDate(date: Date): string {
        return date.toLocaleDateString();
    }

}
