<fieldset [formGroup]="countryForm">
    <div class="form-group row"
         [class.has-error]="countryForm.controls.name.value && !countryForm.controls.name.disabled && !countryForm.controls.name.valid">
        <label class="col-2 col-form-label" for="form-country-name">Страна:</label>
        <div class="col-10">
            <input type="text" class="form-control"
                   id="form-country-name"
                   formControlName="name">
        </div>
    </div>
    <div class="form-group row"
         [class.has-error]="!countryForm.controls.alias.disabled && countryForm.controls.alias.value && !countryForm.controls.alias.valid">
        <label class="col-2 col-form-label" for="form-country-alias">Алиас:</label>
        <div class="col-10">
            <input type="text" class="form-control"
                   id="form-country-alias" formControlName="alias">
        </div>
    </div>
</fieldset>
