import { Injectable } from "@angular/core";
import { Hotel } from "hotel/hotel";

@Injectable()
export class HotelSidebarService {
    protected hotel: Hotel | null = null;

    public setHotel(hotel: Hotel): void {
        this.hotel = hotel;
    }

    public getHotel(): Hotel | null {
        return this.hotel;
    }

    public hasHotel(): boolean {
        return !!this.hotel;
    }

    public clearHotel(): void {
        this.hotel = null;
    }

}
