import { Component, Injectable, OnDestroy, OnInit } from "@angular/core";
import { Employee } from "company/employee";
import { ActivatedRoute, ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot } from "@angular/router";
import { Hotel } from "../hotel";
import { HttpClient } from "@angular/common/http";
import { map } from "rxjs/operators";
import { JsonIgnore, JsonProperty, ObjectMapper } from "json-object-mapper";
import { mergeRouteData } from "app/merge-route-data";
import { Observable, forkJoin } from "rxjs";
import { TitleService as LayoutTitleService } from "layout/title.service";
import { BreadcrumbService } from "layout/breadcrumb.service";
import { HotelSidebarService } from "layout/sidebar/hotel.service";
import { EmployeeForm } from "company/employee/employee.form";
import { City } from "location/city";
import { Company } from "company/company";
import { FranchiseeEmployeePermissionsForm } from "company/employee/employee-permissions.form";
import { FranchiseeEmployeePermissions } from "company/employee";
import { AuthService } from "app/auth/auth.service";

export class EditEmployeeData {
    @JsonProperty({type: Employee})
    public employee: Employee;

    @JsonIgnore()
    public hotel: Hotel;

    @JsonProperty({type: City})
    public cities: City[];
}

@Injectable()
export class EditEmployeeResolver implements Resolve<EditEmployeeData> {

    public constructor(
        protected httpClient: HttpClient
    ) {
    }

    public resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<EditEmployeeData> {
        let hotel: Hotel = mergeRouteData(route).component.hotel;
        let requests = [
            this.httpClient.get('/v2/companies/' + hotel.company.companyId + '/employees/' + route.params.employeeId),
            this.httpClient.get("/v2/locations/cities")
        ];
        return forkJoin(requests)
            .pipe(map(data => {
                let result = {};
                for (let d of data) Object.assign(result, d);
                return result;
            }))
            .pipe(map(data => ObjectMapper.deserialize(EditEmployeeData, data)))
            .pipe(map(data => {
                data.hotel = hotel;
                return data;
            }));
    }

}

@Component({
    selector: 'hotel-employee-edit',
    templateUrl: './edit-employee.component.html'
})
export class EditEmployeeComponent implements OnInit, OnDestroy {
    public employee: Employee;
    public hotel: Hotel;
    public employeeForm: EmployeeForm;
    public franchiseePermissionsForm: FranchiseeEmployeePermissionsForm;
    public cities: City[] = [];

    public constructor(
        protected route: ActivatedRoute,
        protected layoutTitleService: LayoutTitleService,
        protected breadcrumbService: BreadcrumbService,
        protected hotelSidebarService: HotelSidebarService,
        protected httpClient: HttpClient,
        protected router: Router,
        protected authService: AuthService
    ) {
    }

    public ngOnInit(): void {
        this.route.data.subscribe((data) => {
            this.employee = data.component.employee;
            this.hotel = data.component.hotel;
            this.cities = data.component.cities;
            let employeeName = (this.employee.name.isPresent()) ? this.employee.name.toString() : this.employee.email;
            this.layoutTitleService.title = employeeName;
            this.layoutTitleService.small = 'Редактирование сотрудника';
            this.breadcrumbService.breadcrumbs = [
                {name: 'Отели', link: '/hotels'},
                {name: this.hotel.name, link: ['/hotels', this.hotel.alias]},
                {name: 'Сотрудники', link: ['/hotels', this.hotel.alias, 'employees']},
                {name: employeeName, link: ['/hotels', this.hotel.alias, 'employees', this.employee.employeeId]},
                {name: 'Редактирование'}
            ];
            this.hotelSidebarService.setHotel(this.hotel);
            this.employeeForm = new EmployeeForm(this.httpClient, this.employee, !this.authService.getUser().company.isManagementCompany());
            if (this.authService.getUser().employeeId != this.employee.employeeId) {
                this.franchiseePermissionsForm = new FranchiseeEmployeePermissionsForm(
                    [this.hotel], this.employee.permissions as FranchiseeEmployeePermissions, false
                );
            }
            this.employeeForm.valueChanges.subscribe(data => {
                employeeName = this.getEmployeeName(data);
                this.layoutTitleService.title = employeeName;
                this.breadcrumbService.breadcrumbs = [
                    {name: 'Отели', link: '/hotels'},
                    {name: this.hotel.name, link: ['/hotels', this.hotel.alias]},
                    {name: 'Сотрудники', link: ['/hotels', this.hotel.alias, 'employees']},
                    {name: employeeName, link: ['/hotels', this.hotel.alias, 'employees', this.employee.employeeId]},
                    {name: 'Редактирование'}
                ];
            });
        });
    }

    public ngOnDestroy(): void {
        this.hotelSidebarService.clearHotel();
    }

    public onSubmit(): void {
        this.employeeForm.disable();
        let employee = this.employeeForm.getEmployee();
        employee.company = this.hotel.company;
        if (this.franchiseePermissionsForm) {
            employee.permissions = this.franchiseePermissionsForm.getPermissions();
        } else {
            employee.permissions = this.employee.permissions;
        }
        this.httpClient.put<{
            employee: any, company: any
        }>('/v2/companies/' + employee.company.companyId + '/employees/' + this.employee.employeeId, ObjectMapper.serialize({
            employee: employee
        })).toPromise().then((data) => {
            let employee = ObjectMapper.deserialize(Employee, data.employee);
            employee.company = ObjectMapper.deserialize(Company, data.company);
            this.router.navigate(['/hotels', this.hotel.alias, 'employees', employee.employeeId]);
        }).catch((error: any) => {
            console.error("Save employee error:", error);
            this.employeeForm.enable();
            throw error;
        });
    }

    private getEmployeeName(data: any): string {
        let employeeName = (
            (data.details.firstName ? data.details.firstName : '') + ' '
            + (data.details.middleName ? data.details.middleName : '') + ' '
            + (data.details.lastName ? data.details.lastName : '')
        ).trim() || data.email || 'Сотрудник';
        return employeeName;
    }

}
