<div class="ibox">
    <div class="ibox-content">
        <div class="m-b-md">
            <vacancy-buttons [(vacancy)]="vacancy"></vacancy-buttons>
            <h2>{{ vacancy.title }}</h2>
        </div>
        <dl class="row mb-0">
            <dt class="col-2">Компания:</dt>
            <dd class="col-10">
                {{ vacancy.company.name }}
                <a *ngIf="showCompanyInfoLink()" class="small" [routerLink]="['/vacancies/company', vacancy.company.companyId]">
                    (информация о компании)
                </a>
            </dd>
            <dt *ngIf="vacancy.hotel" class="col-2">Отель:</dt>
            <dd *ngIf="vacancy.hotel" class="col-10">{{ vacancy.hotel.name }}</dd>
            <dt class="col-2">Город:</dt>
            <dd class="col-10">{{ vacancy.city.name }}</dd>
            <dt class="col-2">Статус:</dt>
            <dd class="col-10">
                <span *ngIf="vacancy.isEnabled()" class="label label-primary">Опубликована</span>
                <span *ngIf="vacancy.isDisabled()" class="label label-warning">Снята с публикации</span>
                <span *ngIf="vacancy.isArchived()" class="label label-warning">В архиве</span>
                <span *ngIf="vacancy.isDeleted()" class="label label-danger">Удалена</span>
            </dd>
            <dt class="col-2">Опубликована:</dt>
            <dd class="col-10">{{ vacancy.creationDate | date }}</dd>
            <dt *ngIf="showUpdateDate()" class="col-2">Обновлена:</dt>
            <dd *ngIf="showUpdateDate()" class="col-10">{{ vacancy.updateDate | date }}</dd>
        </dl>
        <hr class="hr-line-dashed">
        <tui-editor-viewer [content]="vacancy.description"></tui-editor-viewer>
        <h2 class="text-muted text-right">#{{ vacancy.vacancyId }}</h2>
    </div>
</div>

<h2 *ngIf="responses.length == 0" class="mb-3">На эту вакансию откликов нет</h2>
<h2 *ngIf="responses.length > 0">Отклики</h2>
<div *ngFor="let response of responses; trackBy: trackByVacancyResponse" class="ibox">
    <div class="ibox-content">
        <dl class="row mb-3">
            <dt class="col-2">Дата:</dt>
            <dd class="col-10">{{ response.creationDate | date }}</dd>
            <dt class="col-2">ФИО:</dt>
            <dd class="col-10">{{ response.name }}</dd>
            <dt class="col-2">Телефон:</dt>
            <dd class="col-10">{{ response.phone }}</dd>
            <ng-template [ngIf]="response.email">
                <dt class="col-2">E-mail:</dt>
                <dd class="col-10"><a [href]="'mailto:' + response.email">{{ response.email }}</a></dd>
            </ng-template>
        </dl>
        <div [innerHTML]="response.coveringLetter | nl2br: true"></div>
        <h2 class="text-muted text-right">#{{ response.vacancyResponseId }}</h2>
    </div>
</div>
