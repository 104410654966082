import { NgModule } from "@angular/core";
import {
    EmployeePermissionsFormComponent,
    FranchiseeEmployeePermissionsFormComponent,
    ManagementEmployeePermissionsFormComponent
} from "./employee/employee-permissions-form.component";
import { EmployeeDetailsFormComponent, EmployeeFormComponent } from "./employee/employee-form.component";
import { CommonModule } from "@angular/common";
import { ReactiveFormsModule } from "@angular/forms";
import { EmployeeViewComponent } from "./employee/employee-view.component";
import {
    EmployeePermissionsViewComponent, FranchiseeEmployeePermissionsViewComponent,
    ManagementEmployeePermissionsViewComponent
} from "./employee/employee-permissions-view.component";
import { EmployeeButtonsComponent } from "./employee/employee-buttons.component";
import { RouterModule } from "@angular/router";

@NgModule({
    imports: [
        CommonModule, ReactiveFormsModule, RouterModule
    ],
    declarations: [
        EmployeeFormComponent,
        EmployeeDetailsFormComponent,
        EmployeePermissionsFormComponent,
        ManagementEmployeePermissionsFormComponent,
        FranchiseeEmployeePermissionsFormComponent,
        EmployeeViewComponent, EmployeePermissionsViewComponent,
        ManagementEmployeePermissionsViewComponent, FranchiseeEmployeePermissionsViewComponent,
        EmployeeButtonsComponent
    ],
    exports: [
        EmployeeFormComponent,
        EmployeeDetailsFormComponent,
        EmployeePermissionsFormComponent,
        ManagementEmployeePermissionsFormComponent,
        FranchiseeEmployeePermissionsFormComponent,
        EmployeeViewComponent, EmployeePermissionsViewComponent,
        EmployeeButtonsComponent
    ]
})
export class CompanyCommonModule {
}
