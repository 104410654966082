import { Employee } from "company/employee";
import { Hotel } from "hotel/hotel";

export class User extends Employee {
    public readonly hotels: Hotel[] = [];

    public constructor(employee: Employee, hotels?: Hotel[]) {
        super(employee);
        if (hotels) {
            this.hotels = hotels;
        }
    }

    public getHotel(alias: string): Hotel | null {
        for (let hotel of this.hotels) {
            if (hotel.alias == alias) {
                return hotel;
            }
        }
        return null;
    }

}
