import { Component, Injectable, OnInit } from "@angular/core";
import { CompanyForm } from "./company.form";
import { HttpClient } from "@angular/common/http";
import { JsonProperty, ObjectMapper } from "json-object-mapper";
import { Company } from "../company";
import { ActivatedRoute, ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot } from "@angular/router";
import { TitleService as LayoutTitleService } from "layout/title.service";
import { BreadcrumbService } from "layout/breadcrumb.service";
import { City } from "location/city";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

export class NewCompanyData {
    @JsonProperty({type: City})
    public cities: City[];
}

@Injectable()
export class NewCompanyResolver implements Resolve<NewCompanyData> {

    public constructor(
        protected httpClient: HttpClient
    ) {
    }

    public resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<NewCompanyData> {
        return this.httpClient.get<NewCompanyData>("/v2/locations/cities")
            .pipe(map(data => ObjectMapper.deserialize(NewCompanyData, data)));
    }

}

@Component({
    selector: 'company-company-new',
    templateUrl: './new-company.component.html'
})
export class NewCompanyComponent implements OnInit {

    public companyForm: CompanyForm = new CompanyForm(this.httpClient);
    public cities: City[];

    public constructor(
        protected httpClient: HttpClient,
        protected router: Router,
        protected layoutTitleService: LayoutTitleService,
        protected breadcrumbService: BreadcrumbService,
        protected route: ActivatedRoute
    ) {
    }

    public ngOnInit(): void {
        this.layoutTitleService.title = 'Новая компания';
        this.breadcrumbService.breadcrumbs = [
            {name: 'Компании', link: '/companies'},
            {name: 'Новая компания'}
        ];
        this.route.data.subscribe((data) => {
            this.cities = data.component.cities;
        });
    }

    public onSubmit(): void {
        this.companyForm.disable();
        this.httpClient.post<{ company: any }>('/v2/companies', ObjectMapper.serialize({
            company: this.companyForm.getCompany()
        })).toPromise().then((data) => {
            let company = ObjectMapper.deserialize(Company, data.company);
            this.router.navigate(['/companies', company.companyId]);
        }).catch((error: any) => {
            console.error("Create company error:", error);
            this.companyForm.enable();
            throw error;
        });
    }

}
