import { NgModule } from "@angular/core";
import { VacancyRoutingModule } from "./vacancy-routing.module";
import { VacanciesActionComponent, VacanciesComponent } from "./vacancies.component";
import { CommonModule } from "@angular/common";
import { VacancyComponent } from "./vacancy.component";
import { NewVacancyComponent } from "./new-vacancy.component";
import { ReactiveFormsModule } from "@angular/forms";
import { VacancyFormComponent } from "./vacancy-form.component";
import { EditVacancyComponent } from "./edit-vacancy.component";
import { CommonModule as AppCommonModule } from "common/common.module";
import { CompanyInfoComponent } from "./company-info.component";
import { VacancyButtonsComponent } from "./vacancy-buttons.component";
import { VacanciesArchiveComponent } from "./vacancies-archive.component";

@NgModule({
    imports: [
        CommonModule, AppCommonModule,
        ReactiveFormsModule,
        VacancyRoutingModule
    ],
    declarations: [
        VacanciesComponent, VacanciesActionComponent,
        VacancyComponent, NewVacancyComponent, VacancyFormComponent,
        EditVacancyComponent, CompanyInfoComponent, VacancyButtonsComponent,
        VacanciesArchiveComponent
    ]
})
export class VacancyModule {
}
