import { AfterViewInit, Component, ElementRef, NgZone, OnDestroy, ViewChild } from "@angular/core";
import { AuthService } from "app/auth/auth.service";
import { HotelSidebarService } from "./hotel.service";
import { Hotel } from "hotel/hotel";

@Component({
    selector: 'layout-sidebar',
    templateUrl: './sidebar.component.html'
})
export class SidebarComponent implements AfterViewInit, OnDestroy {

    @ViewChild('metisMenu')
    public metisMenu: ElementRef<HTMLElement>;

    public constructor(
        protected ngZone: NgZone,
        protected authService: AuthService,
        public hotelSidebarService: HotelSidebarService
    ) {
    }

    public ngAfterViewInit(): void {
        this.ngZone.runOutsideAngular(() => {
            jQuery(this.metisMenu.nativeElement).metisMenu();
        });
    }

    public ngOnDestroy(): void {
        jQuery(this.metisMenu.nativeElement).metisMenu('dispose');
    }

    public isManagementSidebar(): boolean {
        return this.authService.getUser().company.isManagementCompany();
    }

    public isFranchiseeSidebar(): boolean {
        return this.authService.getUser().company.isFranchiseeCompany();
    }

    public hasHotelSidebar(): boolean {
        if (this.authService.getUser().company.isFranchiseeCompany()
            && this.authService.getUser().hotels.length == 1) {
            return true;
        }
        return this.hotelSidebarService.hasHotel();
    }

    public getHotelSidebarHotel(): Hotel {
        if (this.authService.getUser().company.isFranchiseeCompany()
            && this.authService.getUser().hotels.length == 1) {
            return this.authService.getUser().hotels[0];
        }
        return this.hotelSidebarService.getHotel();
    }

    public getName(): string {
        let user = this.authService.getUser();
        if (user.name.first && user.name.last) {
            return user.name.first + ' ' + (user.name.middle ? user.name.middle : '') + ' ' + user.name.last;
        }
        return user.email;
    }

    public getPosition(): string {
        return this.authService.getUser().position;
    }

}
