<div class="ibox-content">
    <form [formGroup]="employeeForm" (ngSubmit)="onSubmit()">
        <employee-form [employeeForm]="employeeForm" [cities]="cities"></employee-form>
        <ng-template [ngIf]="managementPermissionsForm || franchiseePermissionsForm">
            <hr class="hr-line-dashed">
            <h2>Права доступа</h2>
        </ng-template>
        <employee-permissions-form *ngIf="employeePermissionsForm" [permissionsForm]="employeePermissionsForm"></employee-permissions-form>
        <management-employee-permissions-form
                *ngIf="managementPermissionsForm && employee.company.isManagementCompany()"
                [permissionsForm]="managementPermissionsForm"
        ></management-employee-permissions-form>
        <franchisee-employee-permissions-form
                *ngIf="franchiseePermissionsForm && employee.company.isFranchiseeCompany()"
                [permissionsForm]="franchiseePermissionsForm"
        ></franchisee-employee-permissions-form>
        <hr *ngIf="managementPermissionsForm && employee.company.isManagementCompany() || franchiseePermissionsForm && employee.company.isFranchiseeCompany()"
            class="hr-line-dashed">
        <div class="form-group row">
            <div class="col-10 offset-2">
                <a class="btn btn-white"
                   [routerLink]="getCancelLink()">Отмена</a>
                &nbsp;
                <button class="btn btn-primary" type="submit" [disabled]="!employeeForm.valid">Сохранить</button>
            </div>
        </div>
    </form>
</div>
