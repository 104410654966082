<div class="ibox-content">
    <form [formGroup]="employeeForm" (ngSubmit)="onSubmit()">
        <employee-form [employeeForm]="employeeForm" [cities]="cities"></employee-form>
        <hr class="hr-line-dashed">
        <h2>Права доступа</h2>
        <franchisee-employee-permissions-form
                [permissionsForm]="franchiseePermissionsForm"></franchisee-employee-permissions-form>
        <hr class="hr-line-dashed">
        <div class="form-group row">
            <div class="col-10 offset-2">
                <a class="btn btn-white"
                   [routerLink]="['/hotels', hotel.alias, 'employees']">Отмена</a>
                &nbsp;
                <button class="btn btn-primary" type="submit" [disabled]="!employeeForm.valid">Сохранить</button>
            </div>
        </div>
    </form>
</div>
