import { Component, Injectable, OnInit } from "@angular/core";
import { Employee, FranchiseeEmployeePermissions } from "../employee";
import { JsonProperty, ObjectMapper } from "json-object-mapper";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { ActivatedRoute, ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from "@angular/router";
import { HttpClient } from "@angular/common/http";
import { TitleService as LayoutTitleService } from "layout/title.service";
import { BreadcrumbService } from "layout/breadcrumb.service";
import { Company } from "../company";
import { AuthService } from "app/auth/auth.service";

export class EmployeesData {
    @JsonProperty({type: Employee})
    public employees: Employee[];
}

@Injectable()
export class EmployeesResolver implements Resolve<EmployeesData> {

    public constructor(
        protected httpClient: HttpClient
    ) {
    }

    public resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<EmployeesData> {
        return this.httpClient.get<EmployeesData>('/v2/employees')
            .pipe(map(data => ObjectMapper.deserialize(EmployeesData, data)));
    }

}

@Component({
    selector: 'company-employees-action',
    templateUrl: './employees-action.component.html'
})
export class EmployeesActionComponent implements OnInit {
    public canCreateEmployee: boolean = false;

    public constructor(
        protected authService: AuthService
    ) {
    }

    public ngOnInit(): void {
        let user = this.authService.getUser();
        if (user.company.isManagementCompany()) {
            this.canCreateEmployee = true;
        }
        if (user.company.isFranchiseeCompany()) {
            let permissions = user.permissions as FranchiseeEmployeePermissions;
            this.canCreateEmployee = permissions.canEditEmployees || permissions.allHotels.canEditEmployees;
        }
    }

}

@Component({
    selector: 'company-employees',
    templateUrl: './employees.component.html'
})
export class EmployeesComponent implements OnInit {
    public authUserCompany: Company;
    public employees: Employee[] = [];
    public currentEmployeeId: number;

    public constructor(
        protected route: ActivatedRoute,
        protected layoutTitleService: LayoutTitleService,
        protected breadcrumbService: BreadcrumbService,
        protected authService: AuthService
    ) {
    }

    public ngOnInit(): void {
        this.currentEmployeeId = this.authService.getUser().employeeId;
        this.layoutTitleService.title = 'Сотрудники';
        this.authUserCompany = this.authService.getUser().company;
        this.breadcrumbService.breadcrumbs = [
            {name: 'Сотрудники'}
        ];
        this.route.data.subscribe((data) => {
            this.employees = data.component.employees;
        });
    }

    public trackByEmployee(index: number, employee: Employee): number {
        return employee.employeeId;
    }

}
