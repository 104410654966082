import { Component, Injectable, OnDestroy, OnInit } from "@angular/core";
import { JsonProperty, ObjectMapper } from "json-object-mapper";
import { Hotel } from "../hotel";
import { HttpClient } from "@angular/common/http";
import { map } from "rxjs/operators";
import { ActivatedRoute, ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot } from "@angular/router";
import { Observable } from "rxjs";
import { BreadcrumbService } from "layout/breadcrumb.service";
import { TitleService as LayoutTitleService } from "layout/title.service";
import { HotelForm } from "./hotel.form";
import { HotelSidebarService } from "layout/sidebar/hotel.service";

export class EditHotelData {
    @JsonProperty({type: Hotel})
    public hotel: Hotel;
}

@Injectable()
export class EditHotelResolver implements Resolve<EditHotelData> {

    public constructor(
        protected httpClient: HttpClient
    ) {
    }

    public resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<EditHotelData> {
        return this.httpClient.get<EditHotelData>('/v2/hotels/' + route.params.alias)
            .pipe(map(data => ObjectMapper.deserialize(EditHotelData, data)));
    }

}

@Component({
    selector: 'hotel-hotel-edit',
    templateUrl: './edit-hotel.component.html'
})
export class EditHotelComponent implements OnInit, OnDestroy {
    public hotel: Hotel;
    public hotelForm: HotelForm = new HotelForm(this.httpClient);

    public constructor(
        protected route: ActivatedRoute,
        protected layoutTitleService: LayoutTitleService,
        protected breadcrumbService: BreadcrumbService,
        protected httpClient: HttpClient,
        protected router: Router,
        protected hotelSidebarService: HotelSidebarService
    ) {
    }

    public ngOnInit(): void {
        this.route.data.subscribe((data) => {
            this.hotel = data.component.hotel;
            this.layoutTitleService.title = this.hotel.name;
            this.layoutTitleService.small = 'Редактирование отеля';
            this.breadcrumbService.breadcrumbs = [
                {name: 'Отели', link: '/hotels'},
                {name: this.hotel.name, link: ['/hotels', this.hotel.alias]},
                {name: 'Редактирование'}
            ];
            this.hotelForm = new HotelForm(this.httpClient, this.hotel);
            this.hotelForm.get('name').valueChanges.subscribe((value) => {
                this.layoutTitleService.title = value || 'Отель';
                this.breadcrumbService.breadcrumbs = [
                    {name: 'Отели', link: '/hotels'},
                    {name: value || 'Отель', link: ['/hotels', this.hotel.alias]},
                    {name: 'Редактирование'}
                ];
            });
            this.hotelSidebarService.setHotel(this.hotel);
        });
    }

    public ngOnDestroy(): void {
        this.hotelSidebarService.clearHotel();
    }

    public onSubmit(): void {
        this.hotelForm.disable();
        let hotel = this.hotelForm.getHotel();
        hotel.company = this.hotel.company;
        hotel.city = this.hotel.city;
        hotel.type = this.hotel.type;
        this.httpClient.put<{ hotel: any }>('/v2/hotels/' + this.hotel.hotelId, ObjectMapper.serialize({
            hotel: hotel
        })).toPromise().then((data) => {
            let hotel = ObjectMapper.deserialize(Hotel, data.hotel);
            this.router.navigate(['/hotels', hotel.alias]);
        }).catch((error: any) => {
            console.error("Save hotel error:", error);
            this.hotelForm.enable();
            throw error;
        });
    }

}
