import { AfterViewInit, ChangeDetectorRef, Component, NgZone, OnDestroy, OnInit } from "@angular/core";
import { LayoutService } from "./layout.service";
import { Subscription } from "rxjs";
import { NavigationEnd, Router } from "@angular/router";

@Component({
    selector: 'layout',
    templateUrl: './layout.component.html'
})
export class LayoutComponent implements OnInit, OnDestroy, AfterViewInit {
    private _contentWrapper = true;
    protected onStableSubscription: Subscription;
    public appVersion = APP_VERSION;

    public constructor(
        protected ngZone: NgZone,
        protected changeDetectorRef: ChangeDetectorRef,
        protected layoutService: LayoutService,
        protected router: Router
    ) {
    }

    public ngOnInit(): void {
        this.onStableSubscription = this.ngZone.onStable.subscribe(() => {
            if (this._contentWrapper != this.layoutService.showHeading) {
                this._contentWrapper = this.layoutService.showHeading;
                this.changeDetectorRef.detectChanges();
            }
        });
        this.router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                this.ngZone.runOutsideAngular(() => {
                    window.scroll(0, 0);
                });
            }
        });
    }

    public ngOnDestroy(): void {
        this.onStableSubscription.unsubscribe();
    }

    public isContentWrapper(): boolean {
        return this._contentWrapper;
    }

    public ngAfterViewInit(): void {
        this.ngZone.runOutsideAngular(() => {
            let body = jQuery('body');
            if (jQuery(window).width() < 769) {
                body.addClass('body-small')
            } else {
                body.removeClass('body-small')
            }
            jQuery('.navbar-minimalize').on('click', (event) => {
                event.preventDefault();
                this.toggleSidebar();
            });
        });
    }

    protected toggleSidebar(): void {
        jQuery("body").toggleClass("mini-navbar");
        this.smoothlyMenu();
    }

    protected smoothlyMenu(): void {
        let body = jQuery('body');
        let sideMenu = jQuery('#side-menu');
        if (!body.hasClass('mini-navbar') || body.hasClass('body-small')) {
            // Hide menu in order to smoothly turn on when maximize menu
            sideMenu.hide();
            // For smoothly turn on menu
            setTimeout(
                function () {
                    sideMenu.fadeIn(400);
                }, 200);
        } else if (body.hasClass('fixed-sidebar')) {
            sideMenu.hide();
            setTimeout(
                function () {
                    sideMenu.fadeIn(400);
                }, 100);
        } else {
            // Remove all inline style from jquery fadeIn function to reset menu state
            sideMenu.removeAttr('style');
        }
    }

}
