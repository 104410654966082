import { NgModule } from "@angular/core";
import { PhperpRoutingModule } from "./phperp-routing.module";
import { RedirectComponent } from "./redirect.component";
import { CommonModule } from "@angular/common";

@NgModule({
    imports: [
        PhperpRoutingModule,
        CommonModule
    ],
    declarations: [
        RedirectComponent
    ]
})
export class PhperpModule {
}
