import { AbstractControl, FormControl, FormGroup, ValidationErrors, Validators } from "@angular/forms";
import { Hotel } from "../hotel";
import { HttpClient, HttpResponse } from "@angular/common/http";

export class HotelForm extends FormGroup {

    public constructor(httpClient: HttpClient, hotel?: Hotel) {
        super({
            alias: new FormControl(hotel ? hotel.alias : null, [
                Validators.required,
                Validators.pattern(/^[a-zA-Z][a-zA-Z0-9\-_]+$/),
                Validators.minLength(2),
                Validators.maxLength(20)
            ], [
                HotelForm.aliasValidator(httpClient, hotel ? hotel.alias : null)
            ]),
            name: new FormControl(hotel ? hotel.name : null, [
                Validators.required,
                Validators.pattern(/^[a-zA-Zа-яёА-ЯЁ0-9 \-+_."/\\&():;]+$/),
                Validators.minLength(1),
                Validators.maxLength(50)
            ])
        });
    }

    public static aliasValidator(httpClient: HttpClient, initial?: string): (control: AbstractControl) => Promise<ValidationErrors | null> {
        return (control) => {
            return new Promise((resolve, reject) => {
                if (control.value && initial && control.value.trim().toLowerCase() == initial.trim().toLowerCase()) {
                    resolve();
                    return;
                }
                httpClient.get<HttpResponse<any>>('/v2/alias/' + control.value, {
                    observe: 'response'
                }).toPromise().then(() => {
                    resolve({exists: true});
                }).catch((response: HttpResponse<any>) => {
                    if (response.status == 404) {
                        resolve();
                    } else {
                        reject(response);
                    }
                });
            });
        };
    }

    public getHotel(): Hotel {
        let hotel = new Hotel();
        hotel.alias = this.get('alias').value;
        hotel.name = this.get('name').value;
        return hotel;
    }

}
