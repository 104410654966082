import { NgModule } from "@angular/core";
import { DashboardRoutingModule } from "./dashboard-routing.module";
import { DashboardComponent } from "./dashboard/dashboard.component";
import { CommonModule } from "@angular/common";
import { ManagementDashboardComponent } from "./dashboard/management.component";
import { FranchiseeDashboardComponent } from "./dashboard/franchisee.component";
import { FranchiseeHotelCardComponent } from "./dashboard/franchisee-hotel-card.component";
import { CommonModule as AppCommonModule } from "common/common.module";

@NgModule({
    imports: [
        CommonModule,
        DashboardRoutingModule,
        AppCommonModule
    ],
    declarations: [
        DashboardComponent, ManagementDashboardComponent, FranchiseeDashboardComponent,
        FranchiseeHotelCardComponent
    ]
})
export class DashboardModule {
}
