import { Directive, Input, TemplateRef, ViewContainerRef } from "@angular/core";
import { Hotel } from "hotel/hotel";
import { PermissionsService } from "app/permissions.service";
import { Employee } from "company/employee";
import { Vacancy } from "vacancy/vacancy";

export abstract class AbstractPermissionDirective {

    public constructor(
        protected templateRef: TemplateRef<any>,
        protected viewContainer: ViewContainerRef,
        protected permissionsService: PermissionsService
    ) {
    }

    protected render(condition: boolean): void {
        if (condition) {
            this.viewContainer.createEmbeddedView(this.templateRef);
        } else {
            this.viewContainer.clear();
        }
    }

}

@Directive({
    selector: '[canEditHotel]'
})
export class CanEditHotelDirective extends AbstractPermissionDirective {
    @Input()
    public set canEditHotel(hotel: Hotel) {
        this.render(this.permissionsService.canEditHotel(hotel));
    }
}

@Directive({
    selector: '[canEditHotelRooms]'
})
export class CanEditHotelRoomsDirective extends AbstractPermissionDirective {
    @Input()
    public set canEditHotelRooms(hotel: Hotel) {
        this.render(this.permissionsService.canEditRooms(hotel));
    }
}

@Directive({
    selector: '[canEditHotelEmployees]'
})
export class CanEditHotelEmployeesDirective extends AbstractPermissionDirective {
    @Input()
    public set canEditHotelEmployees(hotel: Hotel) {
        this.render(this.permissionsService.canEditEmployees(hotel));
    }
}

@Directive({
    selector: '[canEditEmployee]'
})
export class CanEditEmployeeDirective extends AbstractPermissionDirective {
    @Input()
    public set canEditEmployee(employee: Employee) {
        this.render(this.permissionsService.canEditEmployee(employee));
    }
}

@Directive({
    selector: '[canEditHotelPrices]'
})
export class CanEditHotelPricesDirective extends AbstractPermissionDirective {
    @Input()
    public set canEditHotelPrices(hotel: Hotel) {
        this.render(this.permissionsService.canEditPrices(hotel));
    }
}

@Directive({
    selector: '[canDeleteVacancy]'
})
export class CanDeleteVacancyDirective extends AbstractPermissionDirective {
    @Input()
    public set canDeleteVacancy(vacancy: Vacancy) {
        this.render(this.permissionsService.canDeleteVacancy(vacancy));
    }
}
