<div class="ibox-content">
    <form [formGroup]="vacancyForm" (ngSubmit)="onSubmit()">
        <vacancy-form [vacancyForm]="vacancyForm"></vacancy-form>
        <div class="form-group row">
            <div class="col-10 offset-2">
                <a class="btn btn-white"
                   [routerLink]="['/vacancies', vacancy.vacancyId]">Отмена</a>
                &nbsp;
                <button class="btn btn-primary" type="submit" [disabled]="!vacancyForm.valid">Сохранить</button>
            </div>
        </div>
    </form>
</div>
