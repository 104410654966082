import { AfterViewInit, Component, ElementRef, NgZone, OnDestroy, ViewChild } from "@angular/core";

@Component({
    selector: 'layout-sidebar-management',
    templateUrl: './management.component.html'
})
export class ManagementSidebarComponent implements AfterViewInit, OnDestroy {

    @ViewChild('metisMenu')
    public metisMenu: ElementRef<HTMLElement>;

    public constructor(
        protected ngZone: NgZone
    ) {
    }

    public ngAfterViewInit(): void {
        this.ngZone.runOutsideAngular(() => {
            jQuery(this.metisMenu.nativeElement).metisMenu();
        });
    }

    public ngOnDestroy(): void {
        jQuery(this.metisMenu.nativeElement).metisMenu('dispose');
    }

}
