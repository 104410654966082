<div class="ibox-content">
    <form [formGroup]="cityForm" (ngSubmit)="onSubmit()">
        <location-city-form [cityForm]="cityForm" [countries]="countries"></location-city-form>
        <div class="form-group row">
            <div class="col-10 offset-2">
                <a class="btn btn-white" routerLink="/locations/cities">Отмена</a>
                &nbsp;
                <button class="btn btn-primary" type="submit" [disabled]="!cityForm.valid">Сохранить</button>
            </div>
        </div>
    </form>
</div>
