import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Observable } from 'rxjs';
import { GlobalConfig } from "./global-config";

export class ErpAPIHttpInterceptor implements HttpInterceptor {

    public constructor(
        protected globalConfig: GlobalConfig
    ) {
    }

    public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (!req.url.startsWith('http')) {
            req = req.clone({
                url: this.globalConfig.erpapi.url + req.url
            });
        }
        return next.handle(req);
    }

}
