import { Component, Injectable, OnInit } from "@angular/core";
import { BreadcrumbService } from "layout/breadcrumb.service";
import { TitleService as LayoutTitleService } from "layout/title.service";
import { HotelForm } from "./hotel.form";
import { HttpClient } from "@angular/common/http";
import { JsonProperty, ObjectMapper } from "json-object-mapper";
import { Hotel, HotelType } from "../hotel";
import { ActivatedRoute, ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot } from "@angular/router";
import { FormControl, Validators } from "@angular/forms";
import { Company } from "company/company";
import { map } from "rxjs/operators";
import { Observable, forkJoin } from "rxjs";
import { City } from "location/city";

export class NewHotelData {
    @JsonProperty({type: Company})
    public companies: Company[];

    @JsonProperty({type: City})
    public cities: City[];
}

@Injectable()
export class NewHotelResolver implements Resolve<NewHotelData> {

    public constructor(
        protected httpClient: HttpClient
    ) {
    }

    public resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<NewHotelData> {
        return forkJoin(
            this.httpClient.get('/v2/companies'),
            this.httpClient.get("/v2/locations/cities")
        ).pipe(map(data => ObjectMapper.deserialize(NewHotelData, Object.assign({}, data[0], data[1]))));
    }

}

export class NewHotelForm extends HotelForm {

    public constructor(httpClient: HttpClient) {
        super(httpClient);
        this.addControl('company', new FormControl());
        this.addControl('city', new FormControl(null, [Validators.required]));
        this.addControl('type', new FormControl(HotelType.Hotel, [Validators.required]));
    }

    public getHotel(): Hotel {
        let hotel = super.getHotel();
        hotel.companyId = this.get('company').value;
        hotel.cityId = this.get('city').value;
        hotel.type = this.get('type').value;
        return hotel;
    }

}

@Component({
    selector: 'hotel-hotel-new',
    templateUrl: './new-hotel.component.html'
})
export class NewHotelComponent implements OnInit {
    public HotelType = HotelType;
    public hotelForm: NewHotelForm = new NewHotelForm(this.httpClient);
    public companies: Company[] = [];
    public cities: City[] = [];

    public constructor(
        protected layoutTitleService: LayoutTitleService,
        protected breadcrumbService: BreadcrumbService,
        protected httpClient: HttpClient,
        protected router: Router,
        protected route: ActivatedRoute
    ) {
    }

    public ngOnInit(): void {
        this.layoutTitleService.title = 'Новый отель';
        this.breadcrumbService.breadcrumbs = [
            {name: 'Отели', link: '/hotels'},
            {name: 'Новый отель'}
        ];
        this.route.data.subscribe((data) => {
            this.companies = data.component.companies.filter((company: Company) => {
                return company.isFranchiseeCompany();
            });
            this.cities = data.component.cities;
        });
        let lastCompanyId: number = null;
        this.hotelForm.get('company').valueChanges.subscribe((value) => {
            this.onCompanyChanged(lastCompanyId, value);
            lastCompanyId = value;
        });
    }

    public onSubmit(): void {
        this.hotelForm.disable();
        this.httpClient.post<{ hotel: any }>('/v2/hotels', ObjectMapper.serialize({
            hotel: this.hotelForm.getHotel()
        })).toPromise().then((data) => {
            let hotel = ObjectMapper.deserialize(Hotel, data.hotel);
            this.router.navigate(['/hotels', hotel.alias]);
        }).catch((error: any) => {
            console.error("Create hotel error:", error);
            this.hotelForm.enable();
            throw error;
        });
    }

    public trackByCompany(index: number, company: Company): number {
        return company.companyId;
    }

    public trackByCity(index: number, city: City): number {
        return city.cityId;
    }

    protected getCompany(companyId: number): Company | null {
        for (let company of this.companies) {
            if (company.companyId == companyId) {
                return company;
            }
        }
        return null;
    }

    protected onCompanyChanged(oldId: number, newId: number): void {
        if (this.hotelForm.enabled && (!oldId
            || this.getCompany(oldId).city.cityId == this.hotelForm.get('city').value)) {
            this.hotelForm.get('city').setValue(this.getCompany(newId).city.cityId);
        }
    }

}
