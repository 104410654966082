import { ActivatedRouteSnapshot, Data } from "@angular/router";

function mergeDataRecursive(route: ActivatedRouteSnapshot): Data {
    let data = {};
    for (let children of route.children) {
        Object.assign(data, children.data, mergeDataRecursive(children));
    }
    return data;
}

export function mergeRouteData(route: ActivatedRouteSnapshot): Data {
    return mergeDataRecursive(route.root);
}
