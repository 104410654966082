import { AccessType, CacheKey, Deserializer, JsonIgnore, JsonProperty, Serializer } from "json-object-mapper";

export enum RoomCategoryStatus {
    Disabled = 'disabled',
    Enabled = 'enabled',
    Deleted = 'deleted'
}

@CacheKey("RoomCategoryStatusSerializerDeserializer")
class RoomCategoryStatusSerializerDeserializer implements Deserializer, Serializer {

    public deserialize(value: string): RoomCategoryStatus {
        return RoomCategoryStatus[value.charAt(0).toUpperCase() + value.slice(1).toLowerCase()];
    }

    public serialize(value: RoomCategoryStatus): string {
        return '"' + RoomCategoryStatus[value.charAt(0).toUpperCase() + value.slice(1).toLowerCase()].toLowerCase() + '"';
    }

}

export enum RoomCategoryType {
    Standart = 'standart',
    Shared = 'shared',
    SharedFemale = 'shared_female',
    SharedMale = 'shared_male'
}

@CacheKey("RoomCategoryTypeSerializerDeserializer")
class RoomCategoryTypeSerializerDeserializer implements Deserializer, Serializer {

    public deserialize(value: string): RoomCategoryType {
        let enumValue = '';
        for (let part of value.split('_')) {
            enumValue += part.charAt(0).toUpperCase() + part.slice(1).toLowerCase();
        }
        return RoomCategoryType[enumValue];
    }

    public serialize(value: RoomCategoryType): string {
        let enumValue = '';
        for (let part of value.split('_')) {
            enumValue += part.charAt(0).toUpperCase() + part.slice(1).toLowerCase();
        }
        return '"' + RoomCategoryType[enumValue].toLowerCase() + '"';
    }

}

export class RoomCategoryCapacity {
    @JsonProperty()
    public guests: number;

    @JsonProperty({required: false})
    public additional: number;
}

export class RoomCategoryPrice {

    @JsonProperty()
    public value: number;

    @JsonProperty({required: false})
    public additional?: number;

    @JsonProperty({name: 'price_id'})
    public priceId: number;

}

export class RoomCategory {

    @JsonProperty({name: 'room_category_id', access: AccessType.READ_ONLY})
    public roomCategoryId: number;

    @JsonProperty({
        type: RoomCategoryStatus,
        serializer: RoomCategoryStatusSerializerDeserializer,
        deserializer: RoomCategoryStatusSerializerDeserializer
    })
    public status: RoomCategoryStatus;

    @JsonIgnore()
    public isEnabled(): boolean {
        return this.status == RoomCategoryStatus.Enabled;
    }

    @JsonIgnore()
    public isDisabled(): boolean {
        return this.status == RoomCategoryStatus.Disabled;
    }

    @JsonIgnore()
    public isDeleted(): boolean {
        return this.status == RoomCategoryStatus.Deleted;
    }

    @JsonProperty({
        type: RoomCategoryType,
        serializer: RoomCategoryTypeSerializerDeserializer,
        deserializer: RoomCategoryTypeSerializerDeserializer
    })
    public type: RoomCategoryType;

    @JsonProperty()
    public name: string;

    @JsonProperty({type: RoomCategoryCapacity})
    public capacity: RoomCategoryCapacity = new RoomCategoryCapacity();

    @JsonProperty({type: RoomCategoryPrice, required: false, access: AccessType.READ_ONLY})
    public price?: RoomCategoryPrice;

}
