import { APP_INITIALIZER, ErrorHandler, NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { AppComponent } from "./app.component";
import { AppRoutingModule } from "./app-routing.module";
import { LoginModule } from "login/login.module";
import { Error404Module } from "error404/error404.module";
import { BootstrapService } from "./bootstrap.service";
import { AuthService } from "./auth/auth.service";
import { HTTP_INTERCEPTORS, HttpClientModule } from "@angular/common/http";
import { ErpAPIHttpInterceptor } from "./erpapi.http-interceptor";
import { IndexComponent } from "./index.component";
import { AuthHttpInterceptor } from "./auth/auth.http-interceptor";
import { GlobalConfig } from "./global-config";
import { errorHandler } from 'app/error/error';
import { CompanyModule } from "company/company.module";
import { LayoutModule } from "layout/layout.module";
import { LocationModule } from "location/location.module";
import { JsonHttpInterceptor } from "./json.http-interceptor";
import { HotelModule } from "hotel/hotel.module";
import { SweetalertService } from "./sweetalert.service";
import { ActionModule } from "action/action.module";
import { PhperpModule } from "phperp/phperp.module";
import { TimeZoneService } from "./timezone.service";
import './app.scss';
import { LaddaService } from "./ladda.service";
import { DashboardModule } from "dashboard/dashboard.module";
import { ProfileModule } from "profile/profile.module";
import { PermissionsService } from "./permissions.service";
import { VacancyModule } from "vacancy/vacancy.module";

export function bootstrap(bootstrapService: BootstrapService) {
    return () => {
        return new Promise<void>((resolve, reject) => {
            bootstrapService.init()
                .then(() => {
                    resolve();
                })
                .catch((error: Error) => {
                    console.error('Bootstrap error:', error);
                    reject(error);
                });
        });
    };
}

export class AppErrorHandler implements ErrorHandler {

    public handleError(error: any): void {
        errorHandler(error);
    }

}

@NgModule({
    id: 'app-browser',
    imports: [
        BrowserModule,
        HttpClientModule,
        AppRoutingModule,
        PhperpModule,
        ActionModule,
        LoginModule,
        LayoutModule,
        CompanyModule,
        LocationModule,
        HotelModule,
        DashboardModule,
        ProfileModule,
        VacancyModule,
        Error404Module
    ],
    providers: [
        {provide: ErrorHandler, useClass: AppErrorHandler},
        GlobalConfig,
        {provide: HTTP_INTERCEPTORS, useClass: ErpAPIHttpInterceptor, multi: true},
        {provide: HTTP_INTERCEPTORS, useClass: AuthHttpInterceptor, multi: true},
        {provide: HTTP_INTERCEPTORS, useClass: JsonHttpInterceptor, multi: true},
        AuthService,
        BootstrapService,
        {
            provide: APP_INITIALIZER,
            useFactory: bootstrap,
            deps: [BootstrapService],
            multi: true
        },
        SweetalertService,
        TimeZoneService,
        LaddaService,
        PermissionsService
    ],
    declarations: [
        AppComponent, IndexComponent
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
