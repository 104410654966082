import { Component, OnDestroy, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { AuthService } from "app/auth/auth.service";
import { HttpClient } from "@angular/common/http";

@Component({
    selector: 'app-logout',
    template: 'Logout...'
})
export class LogoutComponent implements OnInit, OnDestroy {

    public constructor(
        protected httpClient: HttpClient,
        protected router: Router,
        protected authService: AuthService
    ) {
    }

    public ngOnInit(): void {
        jQuery('body').addClass('gray-bg');
        this.httpClient.delete('/v2/auth')
            .toPromise()
            .then(() => {
                this.authService.logout();
                this.router.navigate(['/']);
            });
    }

    public ngOnDestroy(): void {
        jQuery('body').removeClass('gray-bg');
    }

}
