import { NgModule } from "@angular/core";
import { LocationRoutingModule } from "./location-routing.module";
import { CountriesActionComponent, CountriesComponent } from "./country/countries.component";
import { CitiesActionComponent, CitiesComponent } from "./city/cities.component";
import { CommonModule } from "@angular/common";
import { NewCountryComponent } from "./country/new-country.component";
import { CountryComponent } from "./country/country.component";
import { CityComponent } from "./city/city.component";
import { ReactiveFormsModule } from "@angular/forms";
import { NewCityComponent } from "./city/new-city.component";
import { EditCountryComponent } from "./country/edit-country.component";
import { CountryFormComponent } from "./country/country-form.component";
import { EditCityComponent } from "./city/edit-city.component";
import { CityFormComponent } from "./city/city-form.component";

@NgModule({
    imports: [
        LocationRoutingModule,
        ReactiveFormsModule,
        CommonModule
    ],
    declarations: [
        CountriesComponent, CountriesActionComponent,
        CitiesComponent, CitiesActionComponent,
        CountryComponent, NewCountryComponent,
        CityComponent, NewCityComponent,
        EditCountryComponent, CountryFormComponent,
        EditCityComponent, CityFormComponent
    ]
})
export class LocationModule {
}
