import { Component, Input, OnInit } from "@angular/core";
import { CityForm } from "./city.form";
import { Country } from "../country";
import { TimeZoneService } from "app/timezone.service";

@Component({
    selector: 'location-city-form',
    templateUrl: './city-form.component.html'
})
export class CityFormComponent implements OnInit {
    @Input('cityForm')
    public cityForm: CityForm;

    @Input('countries')
    public countries: Country[] = [];

    public timezones: string[] = [];

    public constructor(
        protected timeZoneService: TimeZoneService
    ) {
    }

    public ngOnInit(): void {
        this.timeZoneService.getTimeZones().then(tz => this.timezones = tz);
    }

    public trackByCountry(index: number, country: Country): number {
        return country.countryId;
    }

}
