<div class="container mt-3">
    <div class="row">
        <div class="col-12 col-sm-10 offset-sm-1 col-md-8 offset-md-2 col-lg-6 offset-lg-3">
            <div class="panel panel-default">
                <div class="panel-heading">
                    <i class="fas fa-user"></i>
                    Пожалуйста, обновите данные о себе
                </div>
                <div class="panel-body text-justify">
                    <form [formGroup]="employeeDetailsForm" (ngSubmit)="onSubmit()">
                        <employee-details-form [employeeDetailsForm]="employeeDetailsForm"></employee-details-form>
                        <div class="form-group row">
                            <div class="col-10 offset-2">
                                <button class="btn btn-primary" type="submit" [disabled]="!employeeDetailsForm.valid">
                                    Сохранить
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>
