import { AbstractControl, FormControl, FormGroup, ValidationErrors, Validators } from "@angular/forms";
import { Country } from "../country";
import { HttpClient, HttpResponse } from "@angular/common/http";

export class CountryForm extends FormGroup {

    public constructor(httpClient: HttpClient, country?: Country) {
        super({
            alias: new FormControl(country ? country.alias : null, [
                Validators.required,
                Validators.pattern(/^[a-zA-Z][a-zA-Z0-9\-_]+$/),
                Validators.minLength(2),
                Validators.maxLength(20),
                (control) => ['new'].indexOf(control.value && control.value.trim()) > -1 ? {reserved: true} : null
            ], [
                CountryForm.aliasValidator(httpClient, country ? country.alias : null)
            ]),
            name: new FormControl(country ? country.name : null, [
                Validators.required,
                Validators.pattern(/^[a-zA-Zа-яёА-ЯЁ0-9 \-+_."/\\&():;]+$/),
                Validators.minLength(1),
                Validators.maxLength(50)
            ])
        });
    }

    public static aliasValidator(httpClient: HttpClient, initial?: string): (control: AbstractControl) => Promise<ValidationErrors | null> {
        return (control) => {
            return new Promise((resolve, reject) => {
                if (control.value && initial && control.value.trim().toLowerCase() == initial.trim().toLowerCase()) {
                    resolve();
                    return;
                }
                httpClient.get<HttpResponse<any>>('/v2/locations/countries/' + control.value, {
                    observe: 'response'
                }).toPromise().then(() => {
                    resolve({exists: true});
                }).catch((response: HttpResponse<any>) => {
                    if (response.status == 404) {
                        resolve();
                    } else {
                        reject(response);
                    }
                });
            });
        };
    }

    public getCountry(): Country {
        let country = new Country();
        country.alias = this.get('alias').value;
        country.name = this.get('name').value;
        return country;
    }

}
