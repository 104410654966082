import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { AllActionsResolved } from "action/all-actions-resolved.guard";
import { LayoutComponent } from "layout/layout.component";
import { AuthGuard } from "app/auth/auth.guard";
import { DashboardComponent, DashboardResolver } from "./dashboard/dashboard.component";

const routes: Routes = [
    {
        path: '',
        component: LayoutComponent,
        canActivate: [AuthGuard, AllActionsResolved],
        children: [
            {
                path: 'dashboard',
                component: DashboardComponent,
                resolve: {
                    component: DashboardResolver
                }
            }
        ]
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
    providers: [
        DashboardResolver
    ]
})
export class DashboardRoutingModule {
}
