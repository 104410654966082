<fieldset [formGroup]="employeeDetailsForm">
    <div class="form-group row"
         [class.has-error]="employeeDetailsForm.controls.firstName.value && !employeeDetailsForm.controls.firstName.disabled && !employeeDetailsForm.controls.firstName.valid">
        <label class="col-2 col-form-label" for="form-company-employee-name-first">Имя:</label>
        <div class="col-10">
            <input type="text" class="form-control"
                   id="form-company-employee-name-first"
                   formControlName="firstName">
        </div>
    </div>
    <div class="form-group row"
         [class.has-error]="employeeDetailsForm.controls.middleName.value && !employeeDetailsForm.controls.middleName.disabled && !employeeDetailsForm.controls.middleName.valid">
        <label class="col-2 col-form-label" for="form-company-employee-name-middle">Отчество:</label>
        <div class="col-10">
            <input type="text" class="form-control"
                   id="form-company-employee-name-middle"
                   formControlName="middleName">
        </div>
    </div>
    <div class="form-group row"
         [class.has-error]="employeeDetailsForm.controls.lastName.value && !employeeDetailsForm.controls.lastName.disabled && !employeeDetailsForm.controls.lastName.valid">
        <label class="col-2 col-form-label" for="form-company-employee-name-last">Фамилия:</label>
        <div class="col-10">
            <input type="text" class="form-control"
                   id="form-company-employee-name-last"
                   formControlName="lastName">
        </div>
    </div>

    <div class="form-group row"
         [class.has-error]="employeeDetailsForm.controls.position.value && !employeeDetailsForm.controls.position.disabled && !employeeDetailsForm.controls.position.valid">
        <label class="col-2 col-form-label" for="form-company-employee-position">Должность:</label>
        <div class="col-10">
            <input type="text" class="form-control"
                   id="form-company-employee-position"
                   formControlName="position">
        </div>
    </div>
    <div class="form-group row"
         [class.has-error]="employeeDetailsForm.controls.phone.value && !employeeDetailsForm.controls.phone.disabled && !employeeDetailsForm.controls.phone.valid">
        <label class="col-2 col-form-label" for="form-company-employee-phone">Телефон:</label>
        <div class="col-10">
            <input type="tel" class="form-control"
                   id="form-company-employee-phone"
                   placeholder="+7 (xxx) xxx-xx-xx"
                   formControlName="phone">
        </div>
    </div>
</fieldset>
