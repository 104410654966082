<div class="ibox float-e-margins">
    <div class="ibox-title">
        <h5 *ngIf="hotel.type == HotelType.Hotel">Отель {{ hotel.name }}</h5>
        <h5 *ngIf="hotel.type == HotelType.Hostel">Хостел {{ hotel.name }}</h5>
    </div>
    <div class="ibox-content">
        <div class="row">
            <div class="col-md-3"><b>Город:</b></div>
            <div class="col-md-9">{{ hotel.city.name }}</div>
        </div>
        <div class="row">
            <div class="col-md-3"><b>Номеров:</b></div>
            <div *ngIf="hotel.roomsCount <= 0" class="col-md-9 text-danger">нет номеров</div>
            <div *ngIf="hotel.roomsCount > 0" class="col-md-9">
                {{ hotel.roomsCount }}
                <ng-template [ngIf]="hotel.roomsCount == 1">номер</ng-template>
                <ng-template [ngIf]="hotel.roomsCount > 1 && hotel.roomsCount <= 4">номера</ng-template>
                <ng-template [ngIf]="hotel.roomsCount > 4">номеров</ng-template>
            </div>
        </div>
        <div class="row">
            <div class="col-md-3"><b>Цены:</b></div>
            <div class="col-md-9">
                <ng-template [ngIf]="hotel.price">
                    <a [routerLink]="['/hotels', hotel.alias, 'prices', hotel.price.priceId]">
                        {{ hotel.price.name }}
                    </a>
                </ng-template>
                <ng-template [ngIf]="!hotel.price">
                    <span class="text-danger m-r">нет прайса</span>
                    <a *canEditHotelPrices="hotel" [routerLink]="['/hotels', hotel.alias, 'prices', 'new']">
                        добавить новый
                    </a>
                </ng-template>
            </div>
        </div>
        <div class="m-t">
            <a [routerLink]="['/hotels', hotel.alias]">
                перейти к
                <ng-template [ngIf]="hotel.type == HotelType.Hotel">отелю</ng-template>
                <ng-template [ngIf]="hotel.type == HotelType.Hostel">хостелу</ng-template>
            </a>
        </div>
    </div>
    <div class="ibox-footer">
        <a href="http://{{ hotel.alias }}.likehostels.ru" target="_blank" class="btn btn-xs btn-outline btn-success">
            перейти на сайт
            <ng-template [ngIf]="hotel.type == HotelType.Hotel">отеля</ng-template>
            <ng-template [ngIf]="hotel.type == HotelType.Hostel">хостела</ng-template>
        </a>
    </div>
</div>
