import { AbstractControl, ValidationErrors } from "@angular/forms";

export class CommonValidators {

    protected static isEmptyInputValue(value: any): boolean {
        // we don't check for string here so it also works with arrays
        return value == null || value.length === 0;
    }

    public static email(control: AbstractControl): ValidationErrors | null {
        if (CommonValidators.isEmptyInputValue(control.value)) {
            return null;  // don't validate empty values to allow optional controls
        }
        return /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/.test(control.value) ? null : {'email': true};
    };

    public static date(control: AbstractControl): ValidationErrors | null {
        if (CommonValidators.isEmptyInputValue(control.value)) {
            return null;  // don't validate empty values to allow optional controls
        }
        return /^[0-9]{4}-[0-9]{2}-[0-9]{2}$/.test(control.value.trim()) ? null : {'date': true};
    }

}
