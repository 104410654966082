import { Component, Injectable, OnInit } from "@angular/core";
import { JsonProperty, ObjectMapper } from "json-object-mapper";
import { Hotel, HotelType } from "../hotel";
import { HttpClient } from "@angular/common/http";
import { map } from "rxjs/operators";
import { ActivatedRoute, ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from "@angular/router";
import { Observable } from "rxjs";
import { BreadcrumbService } from "layout/breadcrumb.service";
import { TitleService as LayoutTitleService } from "layout/title.service";
import { AuthService } from "app/auth/auth.service";

export class HotelsData {
    @JsonProperty({type: Hotel})
    public hotels: Hotel[];
}

@Injectable()
export class HotelsResolver implements Resolve<HotelsData> {

    public constructor(
        protected httpClient: HttpClient
    ) {
    }

    public resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<HotelsData> {
        return this.httpClient.get<HotelsData>('/v2/hotels')
            .pipe(map(data => ObjectMapper.deserialize(HotelsData, data)));
    }

}

@Component({
    selector: 'hotel-hotels-action',
    templateUrl: './hotels-action.component.html'
})
export class HotelsActionComponent implements OnInit {
    public showNewHotelButton = false;

    public constructor(
        protected authService: AuthService
    ) {
    }

    public ngOnInit(): void {
        if (this.authService.getUser().company.isManagementCompany()) {
            this.showNewHotelButton = true;
        }
    }

}

@Component({
    selector: 'hotel-hotels',
    templateUrl: './hotels.component.html'
})
export class HotelsComponent implements OnInit {
    public hotels: Hotel[] = [];
    public HotelType = HotelType;
    public showCompany = false;
    public showNewHotelButton = false;

    public constructor(
        protected route: ActivatedRoute,
        protected layoutTitleService: LayoutTitleService,
        protected breadcrumbService: BreadcrumbService,
        protected authService: AuthService
    ) {
    }

    public ngOnInit(): void {
        this.layoutTitleService.title = 'Отели';
        this.breadcrumbService.breadcrumbs = [{name: 'Отели'}];
        if (this.authService.getUser().company.isManagementCompany()) {
            this.showCompany = true;
            this.showNewHotelButton = true;
        }
        if (this.authService.getUser().company.isFranchiseeCompany()) {
            this.showCompany = false;
            this.showNewHotelButton = false;
        }
        this.route.data.subscribe((data) => {
            this.hotels = data.component.hotels;
        });
    }

    public trackByHotel(index: number, hotel: Hotel): number {
        return hotel.hotelId;
    }

}
