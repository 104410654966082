<div class="ibox">
    <div class="ibox-content">
        <div class="m-b-md">
            <a [routerLink]="['/locations/countries', city.country.alias, 'cities', city.alias, 'edit']"
               class="btn btn-white btn-xs float-right">
                Редактировать
            </a>
            <h2>{{ city.name }}</h2>
        </div>
        <dl class="row mb-0">
            <dt class="col-2">Страна:</dt>
            <dd class="col-10">{{ city.country.name }}</dd>
            <dt class="col-2">Алиас:</dt>
            <dd class="col-10">{{ city.alias }}</dd>
            <dt class="col-2">Статус:</dt>
            <dd class="col-10">
                <span *ngIf="city.isEnabled()" class="label label-primary">Активный</span>
                <span *ngIf="city.isDisabled()" class="label label-warning">Заблокирован</span>
                <span *ngIf="city.isDeleted()" class="label label-danger">Удалён</span>
            </dd>
            <dt class="col-2">Часовой пояс:</dt>
            <dd class="col-10">{{ city.timezone }}</dd>
            <dt class="col-2">Сайт:</dt>
            <dd class="col-10">
                <a href="{{ citySite }}" target="_blank" class="text-navy">
                    {{ city.alias }}.likehostels.ru
                </a>
                <a href="{{ citySite }}" target="_blank" class="text-navy m-l-sm">
                    <i class="fas fa-external-link-alt"></i>
                </a>
            </dd>
        </dl>
        <h2 class="text-muted text-right">#{{ city.cityId }}</h2>
    </div>
</div>
