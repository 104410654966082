<div class="ibox">
    <div class="ibox-content">
        <div class="m-b-md">
            <a [routerLink]="['/locations/countries', country.alias, 'edit']"
               class="btn btn-white btn-xs float-right">
                Редактировать
            </a>
            <h2>{{ country.name }}</h2>
        </div>
        <dl class="row mb-0">
            <dt class="col-2">Алиас:</dt>
            <dd class="col-10">{{ country.alias }}</dd>
            <dt class="col-2">Статус:</dt>
            <dd class="col-10">
                <span *ngIf="country.isEnabled()" class="label label-primary">Активный</span>
                <span *ngIf="country.isDisabled()" class="label label-warning">Заблокирован</span>
                <span *ngIf="country.isDeleted()" class="label label-danger">Удалён</span>
            </dd>
        </dl>
        <h2 class="text-muted text-right">#{{ country.countryId }}</h2>
    </div>
</div>
