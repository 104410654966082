import { Component, Injectable, OnDestroy, OnInit } from "@angular/core";
import { TitleService as LayoutTitleService } from "layout/title.service";
import { HotelSidebarService } from "layout/sidebar/hotel.service";
import { ActivatedRoute, ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from "@angular/router";
import { BreadcrumbService } from "layout/breadcrumb.service";
import { Hotel } from "../hotel";
import { Price } from "../price";
import { JsonProperty, ObjectMapper } from "json-object-mapper";
import { HttpClient } from "@angular/common/http";
import { mergeRouteData } from "app/merge-route-data";
import { Observable, forkJoin } from "rxjs";
import { map } from "rxjs/operators";
import { RoomCategory, RoomCategoryType } from "../room-category";
import { SweetalertService } from "app/sweetalert.service";
import { LaddaService } from "app/ladda.service";

export class PriceData {

    @JsonProperty({type: Hotel})
    public hotel: Hotel;

    @JsonProperty({type: Price})
    public price: Price;

    @JsonProperty({type: RoomCategory})
    public categories: RoomCategory[];
}

@Injectable()
export class PriceResolver implements Resolve<PriceData> {

    public constructor(
        protected httpClient: HttpClient
    ) {
    }

    public resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<PriceData> {
        let hotel: Hotel = mergeRouteData(route).component.hotel;
        return forkJoin([
            this.httpClient.get<any>('/v2/hotels/' + hotel.hotelId + '/rooms/categories')
                .pipe(map(data => {
                    return {
                        hotel: data.hotel,
                        categories: data.categories
                    };
                })),
            this.httpClient.get<PriceData>('/v2/hotels/' + hotel.hotelId + '/prices/' + route.params.priceId)
        ])
            .pipe(map(data => {
                let result = {};
                for (let d of data) Object.assign(result, d);
                return result;
            }))
            .pipe(map(data => ObjectMapper.deserialize(PriceData, data)));
    }

}

@Component({
    selector: 'hotel-price-action',
    templateUrl: './price-action.component.html'
})
export class PriceActionComponent {
    public hotel: Hotel;
    public priceId: number;

    public constructor(
        protected route: ActivatedRoute
    ) {
    }

    public ngOnInit(): void {
        this.route.data.subscribe((data) => {
            this.hotel = data.component.hotel;
        });
        this.route.params.subscribe((params) => {
            this.priceId = params.priceId;
        });
    }
}

@Component({
    selector: 'hotel-price',
    templateUrl: './price.component.html'
})
export class PriceComponent implements OnInit, OnDestroy {
    public readonly RoomCategoryType = RoomCategoryType;
    public hotel: Hotel;
    public price: Price;
    public categories: RoomCategory[] = [];
    public disableButtons: boolean = false;

    public constructor(
        protected route: ActivatedRoute,
        protected layoutTitleService: LayoutTitleService,
        protected breadcrumbService: BreadcrumbService,
        protected hotelSidebarService: HotelSidebarService,
        protected sweetalertService: SweetalertService,
        protected laddaService: LaddaService,
        protected httpClient: HttpClient
    ) {
    }

    public ngOnInit(): void {
        this.route.data.subscribe((data) => {
            this.hotel = data.component.hotel;
            this.price = data.component.price;
            this.categories = data.component.categories;
            this.layoutTitleService.title = this.price.name;
            this.breadcrumbService.breadcrumbs = [
                {name: 'Отели', link: '/hotels'},
                {name: this.hotel.name, link: ['/hotels', this.hotel.alias]},
                {name: 'Цены', link: ['/hotels', this.hotel.alias, 'prices']},
                {name: this.price.name}
            ];
            this.hotelSidebarService.setHotel(this.hotel);
        });
    }

    public ngOnDestroy(): void {
        this.hotelSidebarService.clearHotel();
    }

    public localDate(date: Date): string {
        return date.toLocaleDateString();
    }

    public trackByCategory(index: number, category: RoomCategory): number {
        return category.roomCategoryId;
    }

    public getPriceValue(category: RoomCategory): number | null {
        for (let item of this.price.items) {
            if (item.itemType == 'hotel_room_category' && item.itemId == category.roomCategoryId) {
                return item.value;
            }
        }
        return null;
    }

    public getPriceAdditional(category: RoomCategory): number | null {
        if (category.type != RoomCategoryType.Standart) {
            return null;
        }
        for (let item of this.price.items) {
            if (item.itemType == 'hotel_room_category_additional' && item.itemId == category.roomCategoryId) {
                return item.value;
            }
        }
        return null;
    }

    public onEnable(target: HTMLButtonElement): void {
        let ladda = this.laddaService.create(target);
        this.sweetalertService.alert({
            icon: "warning",
            title: 'Включить прайс?',
            buttons: ['Отмена', 'Включить'],
            dangerMode: true
        }).then((data) => {
            if (data) {
                ladda.start();
                this.disableButtons = true;
                this.httpClient.post<{ price: any }>('/v2/hotels/' + this.hotel.hotelId + '/prices/' + this.price.priceId + '/enable', {}).subscribe((data) => {
                    ladda.stop();
                    this.disableButtons = false;
                    this.price = ObjectMapper.deserialize(Price, data.price);
                    this.sweetalertService.alert({
                        icon: "success",
                        title: 'Прайс включён!'
                    });
                }, error => {
                    ladda.stop();
                    this.disableButtons = false;
                    console.error("Enable hotel price error:", error);
                    throw error;
                });
            }
        });
    }

    public onDisable(target: HTMLButtonElement): void {
        let ladda = this.laddaService.create(target);
        this.sweetalertService.alert({
            icon: "warning",
            title: 'Отключить прайс?',
            buttons: ['Отмена', 'Отключить'],
            dangerMode: true
        }).then((data) => {
            if (data) {
                ladda.start();
                this.disableButtons = true;
                this.httpClient.post<{ price: any }>('/v2/hotels/' + this.hotel.hotelId + '/prices/' + this.price.priceId + '/disable', {}).subscribe((data) => {
                    ladda.stop();
                    this.disableButtons = false;
                    this.price = ObjectMapper.deserialize(Price, data.price);
                    this.sweetalertService.alert({
                        icon: "success",
                        title: 'Прайс отключён!'
                    });
                }, error => {
                    ladda.stop();
                    this.disableButtons = false;
                    console.error("Disable hotel price error:", error);
                    throw error;
                });
            }
        });
    }

    public onDelete(target: HTMLButtonElement): void {
        let ladda = this.laddaService.create(target);
        this.sweetalertService.alert({
            icon: "warning",
            title: 'Удалить прайс?',
            buttons: ['Отмена', 'Удалить'],
            dangerMode: true
        }).then((data) => {
            if (data) {
                ladda.start();
                this.disableButtons = true;
                this.httpClient.delete<{ price: any }>('/v2/hotels/' + this.hotel.hotelId + '/prices/' + this.price.priceId, {}).subscribe((data) => {
                    ladda.stop();
                    this.disableButtons = false;
                    this.price = ObjectMapper.deserialize(Price, data.price);
                    this.sweetalertService.alert({
                        icon: "success",
                        title: 'Прайс удалён!'
                    });
                }, error => {
                    ladda.stop();
                    this.disableButtons = false;
                    console.error("Delete hotel price error:", error);
                    throw error;
                });
            }
        });
    }

}
