import { Component, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { HttpClient } from "@angular/common/http";
import { TitleService as LayoutTitleService } from "layout/title.service";
import { BreadcrumbService } from "layout/breadcrumb.service";
import { Hotel } from "../hotel";
import { AbstractControl, FormControl, ValidationErrors, Validators } from "@angular/forms";
import { ObjectMapper } from "json-object-mapper";
import { RoomCategoryForm } from "./room-category.form";
import { RoomCategory, RoomCategoryType } from "../room-category";
import { HotelSidebarService } from "layout/sidebar/hotel.service";

export class NewRoomCategoryForm extends RoomCategoryForm {

    public constructor() {
        super();
        this.addControl('type', new FormControl(null, [
            Validators.required
        ]));
        this.get('type').valueChanges.subscribe(() => {
            this.get('capacityAdditional').updateValueAndValidity();
        });
    }

    public capacityAdditionalValidator(roomCategory?: RoomCategory): (control: AbstractControl) => ValidationErrors | null {
        return (control) => {
            let type: RoomCategoryType = this.get('type').value;
            if (!type || type == RoomCategoryType.Standart && !control.value && control.value !== 0) {
                return {required: true};
            }
            return null;
        };
    }

    public getRoomCategory(): RoomCategory {
        let category = super.getRoomCategory();
        category.type = this.get('type').value;
        return category;
    }

}

@Component({
    selector: 'hotel-room-category-new',
    templateUrl: './new-room-category.component.html'
})
export class NewRoomCategoryComponent implements OnInit, OnDestroy {
    public RoomCategoryType = RoomCategoryType;
    public hotel: Hotel;
    public categoryForm: NewRoomCategoryForm = new NewRoomCategoryForm();

    public constructor(
        protected layoutTitleService: LayoutTitleService,
        protected breadcrumbService: BreadcrumbService,
        protected httpClient: HttpClient,
        protected router: Router,
        protected route: ActivatedRoute,
        protected hotelSidebarService: HotelSidebarService
    ) {
    }

    public ngOnInit(): void {
        this.route.data.subscribe((data) => {
            this.hotel = data.component.hotel;
            this.layoutTitleService.title = 'Новая категория';
            this.breadcrumbService.breadcrumbs = [
                {name: 'Отели', link: '/hotels'},
                {name: this.hotel.name, link: ['/hotels', this.hotel.alias]},
                {name: 'Категории номеров', link: ['/hotels', this.hotel.alias, 'rooms', 'categories']},
                {name: 'Новая категория'}
            ];
            this.hotelSidebarService.setHotel(this.hotel);
        });
    }

    public ngOnDestroy(): void {
        this.hotelSidebarService.clearHotel();
    }

    public onSubmit(): void {
        this.categoryForm.disable();
        this.httpClient.post<{ category: any }>('/v2/hotels/' + this.hotel.hotelId + '/rooms/categories', ObjectMapper.serialize({
            category: this.categoryForm.getRoomCategory()
        })).toPromise().then((data) => {
            let category = ObjectMapper.deserialize(RoomCategory, data.category);
            this.router.navigate(['/hotels', this.hotel.alias, 'rooms', 'categories', category.roomCategoryId]);
        }).catch((error: any) => {
            console.error("Create room category error:", error);
            this.categoryForm.enable();
            throw error;
        });
    }

}
