import { Component, Input } from "@angular/core";
import { HotelType } from "hotel/hotel";
import {
    EmployeePermissionsForm,
    FranchiseeEmployeeHotelPermissionsForm,
    FranchiseeEmployeePermissionsForm,
    ManagementEmployeePermissionsForm
} from "./employee-permissions.form";
import { AbstractControl, FormArray } from "@angular/forms";

@Component({
    selector: 'employee-permissions-form',
    templateUrl: './employee-permissions-form.component.html'
})
export class EmployeePermissionsFormComponent {
    @Input('permissionsForm')
    public permissionsForm: EmployeePermissionsForm = new EmployeePermissionsForm();
}

@Component({
    selector: 'management-employee-permissions-form',
    templateUrl: './management-employee-permissions-form.component.html'
})
export class ManagementEmployeePermissionsFormComponent {
    @Input('permissionsForm')
    public permissionsForm: ManagementEmployeePermissionsForm = new ManagementEmployeePermissionsForm();
}

@Component({
    selector: 'franchisee-employee-permissions-form',
    templateUrl: './franchisee-employee-permissions-form.component.html'
})
export class FranchiseeEmployeePermissionsFormComponent {

    @Input('permissionsForm')
    public permissionsForm: FranchiseeEmployeePermissionsForm = new FranchiseeEmployeePermissionsForm([]);

    /**
     * @hotfix
     * ERROR in src/company/employee/franchisee-employee-permissions-form.component.html(35,14):
     * Property 'controls' does not exist on type 'AbstractControl'.
     */
    public getHotelsPermissionsFormArray(): FormArray {
        return this.permissionsForm.get('hotels') as FormArray;
    }

    /**
     * @hotfix
     * ERROR in src/company/employee/franchisee-employee-permissions-form.component.html(29,40)
     * Property 'selectAll' does not exist on type 'AbstractControl'.
     */
    public getHotelPermissionsForm(hotelPermissionsForm: AbstractControl): FranchiseeEmployeeHotelPermissionsForm {
        return hotelPermissionsForm as FranchiseeEmployeeHotelPermissionsForm;
    }

    public trackByHotelPermissionsForm(index: number, hotelPermissionsForm: FranchiseeEmployeeHotelPermissionsForm): number {
        return hotelPermissionsForm.hotel.hotelId;
    }

    public hotelsOrHostels(hotels: string = 'отели', hostels: string = 'хостелы'): string {
        if (this.permissionsForm.hotels.length == 0) {
            return hotels;
        }
        for (let hotel of this.permissionsForm.hotels) {
            if (hotel.type == HotelType.Hotel) {
                return hotels;
            }
        }
        return hostels;
    }

}
