import { AccessType, CacheKey, Deserializer, JsonIgnore, JsonProperty, Serializer } from "json-object-mapper";
import { Country } from "./country";

export enum CityStatus {
    Disabled = 'disabled',
    Enabled = 'enabled',
    Deleted = 'deleted'
}

@CacheKey("CityStatusSerializerDeserializer")
class CityStatusSerializerDeserializer implements Deserializer, Serializer {

    public deserialize(value: string): CityStatus {
        return CityStatus[value.charAt(0).toUpperCase() + value.slice(1).toLowerCase()];
    }

    public serialize(value: CityStatus): string {
        return '"' + CityStatus[value.charAt(0).toUpperCase() + value.slice(1).toLowerCase()].toLowerCase() + '"';
    }

}

export class City {

    @JsonProperty({name: 'city_id', access: AccessType.READ_ONLY})
    public cityId: number;

    @JsonProperty({
        type: CityStatus,
        serializer: CityStatusSerializerDeserializer,
        deserializer: CityStatusSerializerDeserializer
    })
    public status: CityStatus;

    @JsonIgnore()
    public isEnabled(): boolean {
        return this.status == CityStatus.Enabled;
    }

    @JsonIgnore()
    public isDisabled(): boolean {
        return this.status == CityStatus.Disabled;
    }

    @JsonIgnore()
    public isDeleted(): boolean {
        return this.status == CityStatus.Deleted;
    }

    @JsonProperty()
    public alias: string;

    @JsonProperty()
    public name: string;

    @JsonProperty({type: Country, required: false, access: AccessType.READ_ONLY})
    public country: Country;

    @JsonIgnore()
    private _countryId: number;

    public get countryId(): number {
        if (this.country) {
            return this.country.countryId;
        }
        return this._countryId;
    }

    @JsonProperty({name: "country_id", required: false, access: AccessType.WRITE_ONLY})
    public set countryId(countryId: number) {
        if (this.countryId != countryId) {
            this.country = null;
        }
        this._countryId = countryId;
    }

    @JsonProperty()
    public timezone: string;

}
