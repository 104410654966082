import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from "@angular/router";
import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { ObjectMapper } from "json-object-mapper";
import { map } from "rxjs/operators";
import { PermissionsService } from "app/permissions.service";
import { AuthService } from "app/auth/auth.service";
import { Employee, FranchiseeEmployeePermissions } from "./employee";

@Injectable()
export class NewEmployeeGuard implements CanActivate {

    public constructor(
        protected authService: AuthService
    ) {
    }

    public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        let user = this.authService.getUser();
        if (user.company.isManagementCompany()) {
            return true;
        }
        if (user.company.isFranchiseeCompany()) {
            let permissions = user.permissions as FranchiseeEmployeePermissions;
            return permissions.canEditEmployees || permissions.allHotels.canEditEmployees;
        }
        return false;
    }
}

@Injectable()
export class EditEmployeeGuard implements CanActivate {

    public constructor(
        protected authService: AuthService,
        protected httpClient: HttpClient,
        protected permissionsService: PermissionsService
    ) {
    }

    public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> | boolean {
        if (!route.params.employeeId) {
            return false;
        }
        let user = this.authService.getUser();
        if (user.company.isManagementCompany()) {
            if (!route.params.companyId) {
                return false;
            }
            if (user.company.companyId == route.params.companyId) {
                return user.permissions.canEditEmployees;
            }
            return true;
        }
        if (user.company.isFranchiseeCompany()) {
            let permissions = user.permissions as FranchiseeEmployeePermissions;
            if (permissions.allHotels.canEditEmployees) {
                return true;
            }
            return new Promise((resolve, reject) => {
                this.httpClient.get<{ employee: any }>('/v2/employees/' + route.params.employeeId)
                    .pipe(map(data => ObjectMapper.deserialize(Employee, data.employee)))
                    .subscribe(employee => {
                        resolve(this.permissionsService.canEditEmployee(employee));
                    }, error => {
                        reject(error);
                    });
            });
        }
        return false;
    }

}


@Injectable()
export class AllEmployeesGuard implements CanActivate {

    public constructor(
        protected authService: AuthService
    ) {
    }

    public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        let user = this.authService.getUser();
        if (user.company.isManagementCompany()) {
            return true;
        }
        if (user.company.isFranchiseeCompany()) {
            return user.permissions.canEditEmployees || user.hotels.length > 1;
        }
        return false;
    }

}
