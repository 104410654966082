import {
    AccessType,
    CacheKey,
    Deserializer,
    JsonIgnore,
    JsonProperty,
    ObjectMapper,
    Serializer
} from "json-object-mapper";
import { Company } from "./company";
import { City } from "location/city";
import { Hotel } from "hotel/hotel";

export enum EmployeeStatus {
    Disabled = 'disabled',
    Enabled = 'enabled',
    Deleted = 'deleted'
}

@CacheKey("EmployeeStatusSerializerDeserializer")
class EmployeeStatusSerializerDeserializer implements Deserializer, Serializer {

    public deserialize(value: string): EmployeeStatus {
        return EmployeeStatus[value.charAt(0).toUpperCase() + value.slice(1).toLowerCase()];
    }

    public serialize(value: EmployeeStatus): string {
        return '"' + EmployeeStatus[value.charAt(0).toUpperCase() + value.slice(1).toLowerCase()].toLowerCase() + '"';
    }

}

export class EmployeePermissions {
    @JsonProperty()
    public readonly type: 'management' | 'franchisee';

    @JsonProperty({name: 'can_edit_employees'})
    public canEditEmployees: boolean = false;

    @JsonProperty({name: 'can_edit_vacancies'})
    public canEditVacancies: boolean = false;
}

export class ManagementEmployeePermissions extends EmployeePermissions {

    @JsonProperty()
    public readonly type: 'management' = 'management';

    @JsonProperty({name: 'can_delete_all_objects'})
    public canDeleteAllObjects: boolean = false;

}

export class FranchiseeEmployeeHotelPermissions {

    @JsonProperty({name: 'can_view_bookings'})
    public canViewBookings: boolean = false;

    @JsonProperty({name: 'can_edit_bookings'})
    public canEditBookings: boolean = false;

    @JsonProperty({name: 'can_edit_rooms'})
    public canEditRooms: boolean = false;

    @JsonProperty({name: 'can_edit_prices'})
    public canEditPrices: boolean = false;

    @JsonProperty({name: 'can_edit_employees'})
    public canEditEmployees: boolean = false;

    @JsonProperty({name: 'can_edit_vacancies'})
    public canEditVacancies: boolean = false;

    public constructor(permissions?: FranchiseeEmployeeHotelPermissions) {
        if (permissions) {
            this.canViewBookings = permissions.canViewBookings;
            this.canEditBookings = permissions.canEditBookings;
            this.canEditRooms = permissions.canEditRooms;
            this.canEditPrices = permissions.canEditPrices;
            this.canEditEmployees = permissions.canEditEmployees;
            this.canEditVacancies = permissions.canEditVacancies;
        }
    }

    @JsonIgnore()
    public hasAccess?(): boolean {
        return this.canViewBookings
            || this.canEditBookings
            || this.canEditRooms
            || this.canEditPrices
            || this.canEditEmployees
            || this.canEditVacancies;
    }

}

export class FranchiseeEmployeeHotelPermissionsContainer {

    @JsonProperty({name: 'hotel_id'})
    public hotelId: number;

    @JsonProperty({type: FranchiseeEmployeeHotelPermissions})
    public permissions: FranchiseeEmployeeHotelPermissions;

    public constructor(permissions?: FranchiseeEmployeeHotelPermissionsContainer) {
        if (permissions) {
            this.hotelId = permissions.hotelId;
            if (permissions.permissions instanceof FranchiseeEmployeeHotelPermissions) {
                this.permissions = permissions.permissions;
            } else {
                this.permissions = new FranchiseeEmployeeHotelPermissions(permissions.permissions);
            }
        }
    }

}

export class FranchiseeEmployeePermissions extends EmployeePermissions {

    @JsonProperty()
    public readonly type: 'franchisee' = 'franchisee';

    public constructor() {
        super();
        this.allHotels = new FranchiseeEmployeeHotelPermissions();
    }

    @JsonProperty({name: 'all_hotels', type: FranchiseeEmployeeHotelPermissions})
    public allHotels: FranchiseeEmployeeHotelPermissions;

    @JsonProperty({type: FranchiseeEmployeeHotelPermissionsContainer})
    public hotels: FranchiseeEmployeeHotelPermissionsContainer[] = [];

    public hotel(hotel: Hotel): FranchiseeEmployeeHotelPermissions;
    public hotel(hotelId: number): FranchiseeEmployeeHotelPermissions;
    public hotel(hotel: Hotel | number): FranchiseeEmployeeHotelPermissions {
        if (hotel instanceof Hotel) {
            hotel = hotel.hotelId;
        }
        if (!hotel) {
            return this.allHotels;
        }
        for (let hotelPermissions of this.hotels) {
            if (hotelPermissions.hotelId == hotel) {
                return hotelPermissions.permissions;
            }
        }
        return this.allHotels;
    }

}

@CacheKey("EmployeePermissionsDeserializer")
class EmployeePermissionsDeserializer implements Deserializer {

    public deserialize(value: any): ManagementEmployeePermissions | FranchiseeEmployeePermissions {
        if (value.type == 'management') {
            return ObjectMapper.deserialize(ManagementEmployeePermissions, value);
        }
        if (value.type == 'franchisee') {
            return ObjectMapper.deserialize(FranchiseeEmployeePermissions, value);
        }
        return null;
    }

}

export class EmployeeName {

    @JsonProperty({required: false})
    public first?: string;

    @JsonProperty({required: false})
    public last?: string;

    @JsonProperty({required: false})
    public middle?: string;

    @JsonIgnore()
    public isPresent(): boolean {
        return !!this.first && !!this.last;
    }

    @JsonIgnore()
    public toString(): string {
        if (!this.isPresent()) {
            return '';
        }
        return this.first + ' ' + (this.middle ? this.middle : '') + ' ' + this.last;
    }

}

export abstract class AbstractEmployee {

    public constructor(employee?: AbstractEmployee) {
        if (employee) {
            this.employeeId = employee.employeeId;
            this.status = employee.status;
            this.email = employee.email;
            this.timezone = employee.timezone;
            this.cityId = employee.cityId;
            this.city = employee.city;
            this.name.first = employee.name.first;
            this.name.last = employee.name.last;
            this.name.middle = employee.name.middle;
            this.phone = employee.phone;
            this.position = employee.position;
        }
    }

    @JsonProperty({name: 'employee_id', access: AccessType.READ_ONLY})
    public employeeId: number;

    @JsonProperty({
        type: EmployeeStatus,
        serializer: EmployeeStatusSerializerDeserializer,
        deserializer: EmployeeStatusSerializerDeserializer
    })
    public status: EmployeeStatus;

    @JsonIgnore()
    public isEnabled(): boolean {
        return this.status == EmployeeStatus.Enabled;
    }

    @JsonIgnore()
    public isDisabled(): boolean {
        return this.status == EmployeeStatus.Disabled;
    }

    @JsonIgnore()
    public isDeleted(): boolean {
        return this.status == EmployeeStatus.Deleted;
    }

    @JsonProperty()
    public email: string;

    @JsonProperty()
    public timezone: string;

    @JsonProperty({name: 'city', required: false, type: City, access: AccessType.READ_ONLY})
    public city?: City;

    @JsonIgnore()
    private _cityId: number;

    public get cityId(): number {
        if (this.city) {
            return this.city.cityId;
        }
        return this._cityId;
    }

    @JsonProperty({name: "city_id", required: false, access: AccessType.WRITE_ONLY})
    public set cityId(cityId: number) {
        if (this.cityId != cityId) {
            this.city = null;
        }
        this._cityId = cityId;
    }

    @JsonProperty({type: EmployeeName})
    public name: EmployeeName = new EmployeeName();

    @JsonProperty({required: false})
    public phone?: string;

    @JsonProperty({required: false})
    public position?: string;

}

export class Employee extends AbstractEmployee {

    public constructor();
    public constructor(employee: Employee);
    public constructor(employee: AbstractEmployee, company: Company, permissions: EmployeePermissions);
    public constructor(employee?: AbstractEmployee | Employee, company?: Company, permissions?: EmployeePermissions) {
        super(employee);
        if (employee) {
            if (employee instanceof Employee) {
                this.companyId = employee.companyId;
                this.company = employee.company;
                this.permissions = employee.permissions;
            } else if (permissions && company) {
                this.company = company;
                this.permissions = permissions;
            }
        }
    }

    @JsonProperty({name: 'company', required: false, type: Company, access: AccessType.READ_ONLY})
    public company?: Company;

    @JsonIgnore()
    private _companyId: number;

    public get companyId(): number {
        if (this.company) {
            return this.company.companyId;
        }
        return this._companyId;
    }

    @JsonProperty({name: "company_id", required: false, access: AccessType.WRITE_ONLY})
    public set companyId(companyId: number) {
        if (this.companyId != companyId) {
            this.company = null;
        }
        this._companyId = companyId;
    }

    @JsonProperty({type: EmployeePermissions, deserializer: EmployeePermissionsDeserializer})
    public permissions: EmployeePermissions;

}
