<ol class="breadcrumb">
    <li class="breadcrumb-item">
        <a routerLink="/">ERP</a>
    </li>
    <li *ngFor="let breadcrumb of breadcrumbService.breadcrumbs; trackBy: trackByBreadcrumb; last as isLast"
        [class.active]="isLast" class="breadcrumb-item">
        <span *ngIf="!breadcrumb.link">{{ breadcrumb.name }}</span>
        <a *ngIf="breadcrumb.link" [routerLink]="breadcrumb.link">{{ breadcrumb.name }}</a>
    </li>
</ol>
