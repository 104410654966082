import { Pipe, PipeTransform } from "@angular/core";
import { AuthService } from "app/auth/auth.service";
import * as moment from "moment";
import { TimeZoneService } from "app/timezone.service";

@Pipe({
    name: 'utcTime'
})
export class UtcTimePipe implements PipeTransform {

    public constructor(
        protected authService: AuthService,
        protected timeZoneService: TimeZoneService
    ) {
    }

    public transform(value: number, timezone?: string, withTimezone: boolean = true): string {
        if (!timezone && this.authService.isAuthenticated()) {
            timezone = this.authService.getUser().timezone;
        }
        if (!timezone) {
            timezone = this.timeZoneService.getDefaultTimeZone();
        }
        let result = moment(value, "X").tz(timezone).format('HH:mm');
        if (!withTimezone) {
            return result;
        }
        return result + ' ' + this.timeZoneService.getTZAbbr(timezone);
    }

}
