import { FormControl, FormGroup, Validators } from "@angular/forms";
import { CompanyInfo, Vacancy } from "./vacancy";

export class VacancyForm extends FormGroup {

    public constructor(vacancy?: Vacancy) {
        super({
            title: new FormControl(vacancy ? vacancy.title : null, [
                Validators.required,
                Validators.pattern(/^[a-zA-Zа-яёА-ЯЁ0-9 \-+_."/\\&():;]+$/),
                Validators.minLength(1),
                Validators.maxLength(50)
            ]),
            description: new FormControl(vacancy ? vacancy.description : null, [
                Validators.required,
                Validators.minLength(1),
                Validators.maxLength(3000)
            ])
        });
    }

    public getVacancy(): Vacancy {
        let vacancy = new Vacancy();
        vacancy.title = this.get('title').value;
        vacancy.description = this.get('description').value;
        return vacancy;
    }

}

export class CompanyInfoForm extends FormGroup {

    public constructor(info?: CompanyInfo) {
        super({
            description: new FormControl(info ? info.description : null, [
                Validators.required,
                Validators.minLength(1),
                Validators.maxLength(3000)
            ])
        });
    }

    public getInfo(): CompanyInfo {
        let info = new CompanyInfo();
        info.description = this.get('description').value;
        return info;
    }

}
