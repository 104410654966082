import { Component, Input } from "@angular/core";
import { CountryForm } from "./country.form";

@Component({
    selector: 'location-country-form',
    templateUrl: './country-form.component.html'
})
export class CountryFormComponent {
    @Input('countryForm')
    public countryForm: CountryForm;
}
