import { AccessType, CacheKey, Deserializer, JsonIgnore, JsonProperty, Serializer } from "json-object-mapper";
import { City } from "location/city";

export enum CompanyStatus {
    Disabled = 'disabled',
    Enabled = 'enabled',
    Deleted = 'deleted'
}

@CacheKey("CompanyStatusSerializerDeserializer")
class CompanyStatusSerializerDeserializer implements Deserializer, Serializer {

    public deserialize(value: string): CompanyStatus {
        return CompanyStatus[value.charAt(0).toUpperCase() + value.slice(1).toLowerCase()];
    }

    public serialize(value: CompanyStatus): string {
        return '"' + CompanyStatus[value.charAt(0).toUpperCase() + value.slice(1).toLowerCase()].toLowerCase() + '"';
    }

}

export enum CompanyType {
    Franchisee = 'franchisee',
    Management = 'management'
}

@CacheKey("CompanyTypeSerializerDeserializer")
class CompanyTypeSerializerDeserializer implements Deserializer, Serializer {

    public deserialize(value: string): CompanyType {
        return CompanyType[value.charAt(0).toUpperCase() + value.slice(1).toLowerCase()];
    }

    public serialize(value: CompanyType): string {
        return '"' + CompanyType[value.charAt(0).toUpperCase() + value.slice(1).toLowerCase()].toLowerCase() + '"';
    }

}

export class Company {

    @JsonProperty({name: 'company_id', access: AccessType.READ_ONLY})
    public companyId: number;

    @JsonProperty({
        type: CompanyStatus,
        serializer: CompanyStatusSerializerDeserializer,
        deserializer: CompanyStatusSerializerDeserializer
    })
    public status: CompanyStatus;

    @JsonIgnore()
    public isEnabled(): boolean {
        return this.status == CompanyStatus.Enabled;
    }

    @JsonIgnore()
    public isDisabled(): boolean {
        return this.status == CompanyStatus.Disabled;
    }

    @JsonIgnore()
    public isDeleted(): boolean {
        return this.status == CompanyStatus.Deleted;
    }

    @JsonProperty({
        type: CompanyType,
        serializer: CompanyTypeSerializerDeserializer,
        deserializer: CompanyTypeSerializerDeserializer,
        access: AccessType.READ_ONLY
    })
    public type: CompanyType;

    @JsonIgnore()
    public isFranchiseeCompany(): boolean {
        return this.type == CompanyType.Franchisee;
    }

    @JsonIgnore()
    public isManagementCompany(): boolean {
        return this.type == CompanyType.Management;
    }

    @JsonProperty()
    public name: string;

    @JsonProperty({name: 'city', required: false, type: City, access: AccessType.READ_ONLY})
    public city?: City;

    @JsonIgnore()
    private _cityId: number;

    public get cityId(): number {
        if (this.city) {
            return this.city.cityId;
        }
        return this._cityId;
    }

    @JsonProperty({name: "city_id", required: false, access: AccessType.WRITE_ONLY})
    public set cityId(cityId: number) {
        if (this.cityId != cityId) {
            this.city = null;
        }
        this._cityId = cityId;
    }

}
