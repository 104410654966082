import { Component, ElementRef, Injectable, NgZone, OnInit, ViewChild } from "@angular/core";
import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { TitleService as LayoutTitleService } from "layout/title.service";
import { BreadcrumbService } from "layout/breadcrumb.service";
import { AuthService } from "app/auth/auth.service";
import { PasswordForm } from "./password.form";
import { City } from "location/city";
import { TimeZoneService } from "app/timezone.service";
import { ActivatedRoute, ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from "@angular/router";
import { Observable, forkJoin, from } from "rxjs";
import { JsonProperty, ObjectMapper } from "json-object-mapper";
import { AbstractEmployee, Employee } from "company/employee";
import { map } from "rxjs/operators";
import { animate, state, style, transition, trigger } from "@angular/animations";
import { EmployeeForm } from "company/employee/employee.form";

export class ProfileData {

    @JsonProperty({type: Employee})
    public employee: Employee;

    @JsonProperty({type: City})
    public cities: City[];

    @JsonProperty()
    public timezones: string[];

}

@Injectable()
export class ProfileResolver implements Resolve<ProfileData> {

    public constructor(
        protected authService: AuthService,
        protected httpClient: HttpClient,
        protected timeZoneService: TimeZoneService
    ) {
    }

    public resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<ProfileData> {
        let requests = [
            this.httpClient.get('/v2/employees/' + this.authService.getUser().employeeId),
            this.httpClient.get("/v2/locations/cities"),
            from(this.timeZoneService.getTimeZones()).pipe(map((data) => {
                return {timezones: data};
            }))
        ];
        return forkJoin(requests).pipe(map(data => {
            let result = {};
            for (let d of data) Object.assign(result, d);
            return result;
        })).pipe(map(data => ObjectMapper.deserialize(ProfileData, data)));
    }

}

export class ProfileModel extends AbstractEmployee {
}

export class ProfileRequest {

    public constructor(employee: Employee) {
        this.user = new ProfileModel(employee);
    }

    @JsonProperty({type: ProfileModel})
    public user: ProfileModel;
}

export class PasswordRequest {
    @JsonProperty({name: 'old_password'})
    public oldPassword: string;

    @JsonProperty({name: 'new_password'})
    public newPassword: string;

    public constructor(password: {
        oldPassword: string,
        newPassword: string
    }) {
        this.oldPassword = password.oldPassword;
        this.newPassword = password.newPassword;
    }
}

@Component({
    selector: 'user-profile',
    templateUrl: './profile.component.html',
    animations: [
        trigger('showProfileSuccessUpdateTrigger', [
            state('true', style({opacity: 1})),
            state('false', style({opacity: 0})),
            transition('true => false', animate('600ms')),
            transition('false => true', animate('300ms')),
        ]),
        trigger('showPasswordUpdateTrigger', [
            state('true', style({opacity: 1})),
            state('false', style({opacity: 0})),
            transition('true => false', animate('600ms')),
            transition('false => true', animate('300ms')),
        ])
    ]
})
export class ProfileComponent implements OnInit {
    public employeeForm: EmployeeForm;
    public passwordForm: PasswordForm = new PasswordForm();
    public cities: City[] = [];
    public showProfileSuccessUpdate: boolean = false;
    public showProfileSuccessTimer: number;
    public showPasswordUpdate: boolean = false;
    public showPasswordTimer: number;
    public showPasswordSuccessUpdate: boolean = false;
    public showPasswordErrorUpdate: boolean = false;

    @ViewChild("newPasswordControl")
    public newPasswordControl: ElementRef<HTMLInputElement>;

    public constructor(
        protected httpClient: HttpClient,
        protected layoutTitleService: LayoutTitleService,
        protected breadcrumbService: BreadcrumbService,
        protected authService: AuthService,
        protected route: ActivatedRoute,
        protected ngZone: NgZone
    ) {
    }

    public ngOnInit(): void {
        this.layoutTitleService.title = 'Ваш профиль';
        this.breadcrumbService.breadcrumbs = [
            {name: 'Ваш профиль'}
        ];
        this.route.data.subscribe((data) => {
            this.cities = data.component.cities;
            this.employeeForm = new EmployeeForm(this.httpClient, data.component.employee, true);
        });
        this.ngZone.runOutsideAngular(() => {
            jQuery(this.newPasswordControl.nativeElement).on('blur', () => {
                let value = this.passwordForm.get('newPassword').value;
                if (value && value.trim().length != value.length) {
                    this.ngZone.run(() => {
                        this.passwordForm.get('newPassword').setValue(value.trim());
                    });
                }
            });
        });
    }

    public onProfileSubmit(): void {
        this.employeeForm.disable();
        window.clearTimeout(this.showProfileSuccessTimer);
        this.showProfileSuccessUpdate = false;
        this.httpClient.post<{ user: any }>('/v2/user', ObjectMapper.serialize(
            new ProfileRequest(this.employeeForm.getEmployee())
        )).subscribe((data) => {
            let user = ObjectMapper.deserialize(ProfileModel, data.user);
            this.authService.setEmployee(new Employee(
                user, this.authService.getUser().company, this.authService.getUser().permissions
            ));
            this.showProfileSuccessUpdate = true;
            this.employeeForm.enable();
            this.employeeForm = new EmployeeForm(this.httpClient, this.authService.getUser(), true);
            this.showProfileSuccessTimer = window.setTimeout(() => {
                this.showProfileSuccessUpdate = false;
            }, 5000);
        }, (error) => {
            console.error("Update user error:", error);
            this.employeeForm.enable();
            throw error;
        });
    }

    public onPasswordSubmit(): void {
        this.passwordForm.disable();
        window.clearTimeout(this.showPasswordTimer);
        this.showPasswordUpdate = false;
        this.showPasswordSuccessUpdate = false;
        this.showPasswordErrorUpdate = false;
        this.httpClient.post('/v2/user/password', ObjectMapper.serialize(
            new PasswordRequest(this.passwordForm.getPassword())
        ), {observe: 'response'}).subscribe(() => {
            this.showPasswordUpdate = true;
            this.showPasswordSuccessUpdate = true;
            this.passwordForm.reset();
            this.passwordForm.enable();
            this.showPasswordTimer = window.setTimeout(() => {
                this.showPasswordUpdate = false;
            }, 5000);
        }, (error: HttpErrorResponse) => {
            if (error.status == 403) {
                this.showPasswordUpdate = true;
                this.showPasswordErrorUpdate = true;
                this.showPasswordTimer = window.setTimeout(() => {
                    this.showPasswordUpdate = false;
                }, 5000);
            } else {
                console.error("Update password error:", error);
                throw error;
            }
            this.passwordForm.reset();
            this.passwordForm.enable();
        });
    }

    public confirmPasswordError(): boolean {
        let control = this.passwordForm.get('confirmPassword');
        if (!control.errors || !control.value) {
            return false;
        }
        return control.errors.confirmError ? true : false;
    }

    public newPasswordError(): boolean {
        let control = this.passwordForm.get('newPassword');
        if (!control.errors || !control.value) {
            return false;
        }
        if (control.errors.equalsError) {
            return true;
        }
        let isActive = this.newPasswordControl.nativeElement == document.activeElement;
        let minLengthError = control.errors.minlength ? true : false;
        return !isActive && minLengthError;
    }

}
