import { Component, Injectable, OnDestroy, OnInit } from "@angular/core";
import { Hotel, HotelType } from "../hotel";
import { JsonProperty, ObjectMapper } from "json-object-mapper";
import { HttpClient } from "@angular/common/http";
import { map } from "rxjs/operators";
import { ActivatedRoute, ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from "@angular/router";
import { Observable } from "rxjs";
import { BreadcrumbService } from "layout/breadcrumb.service";
import { TitleService as LayoutTitleService } from "layout/title.service";
import { HotelSidebarService } from "layout/sidebar/hotel.service";

export class HotelData {
    @JsonProperty({type: Hotel})
    public hotel: Hotel;
}

@Injectable()
export class HotelResolver implements Resolve<HotelData> {

    public constructor(
        protected httpClient: HttpClient
    ) {
    }

    public resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<HotelData> {
        return this.httpClient.get<HotelData>('/v2/hotels/' + route.params.alias)
            .pipe(map(data => ObjectMapper.deserialize(HotelData, data)));
    }

}

@Component({
    selector: 'hotel-hotel',
    templateUrl: './hotel.component.html'
})
export class HotelComponent implements OnInit, OnDestroy {
    public readonly HotelType = HotelType;
    public hotel: Hotel;

    public get hotelSite(): string {
        return "http://" + this.hotel.alias + ".likehostels.ru";
    }

    public constructor(
        protected route: ActivatedRoute,
        protected layoutTitleService: LayoutTitleService,
        protected breadcrumbService: BreadcrumbService,
        protected hotelSidebarService: HotelSidebarService
    ) {
    }

    public ngOnInit(): void {
        this.route.data.subscribe((data) => {
            this.hotel = data.component.hotel;
            this.layoutTitleService.title = this.hotel.name;
            this.breadcrumbService.breadcrumbs = [
                {name: 'Отели', link: '/hotels'},
                {name: this.hotel.name}
            ];
            this.hotelSidebarService.setHotel(this.hotel);
        });
    }

    public ngOnDestroy(): void {
        this.hotelSidebarService.clearHotel();
    }

}
