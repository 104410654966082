import { AbstractControl, FormArray, FormControl, FormGroup, ValidationErrors, Validators } from "@angular/forms";
import { HotelRoomCategoryAdditionalPriceItem, HotelRoomCategoryPriceItem, Price } from "../price";
import { RoomCategory, RoomCategoryType } from "../room-category";
import { formatDate } from "app/date";
import { CommonValidators } from "common/validators";

export class PriceItemForm extends FormGroup {

    public constructor(
        public readonly roomCategory: RoomCategory,
        price?: Price
    ) {
        super({
            value: new FormControl(0, [
                Validators.required,
                Validators.min(50)
            ])
        });
        if (roomCategory.type == RoomCategoryType.Standart && roomCategory.capacity.additional > 0) {
            this.setControl('additional', new FormControl(0, [
                Validators.required,
                Validators.min(50)
            ]));
        }
        if (price) {
            this.setPrice(price);
        }
    }

    protected setPrice(price: Price): void {
        for (let item of price.items) {
            if (item.itemId == this.roomCategory.roomCategoryId && item.itemType == 'hotel_room_category') {
                this.get('value').setValue(item.value / 100);
            }
            if (item.itemId == this.roomCategory.roomCategoryId
                && item.itemType == 'hotel_room_category_additional'
                && item.item.capacity.additional > 0) {
                this.get('additional').setValue(item.value / 100);
            }
        }
    }

}

export class PriceForm extends FormGroup {
    protected availableFromReadonly = false;

    public constructor(
        public readonly roomCategories: RoomCategory[],
        price?: Price,
        clone: boolean = false
    ) {
        super({
            name: new FormControl(price ? price.name : null, [
                Validators.required,
                Validators.pattern(/^[a-zA-Zа-яёА-ЯЁ0-9 \-+_."/\\&():;]+$/),
                Validators.minLength(1),
                Validators.maxLength(50)
            ]),
            availableFrom: new FormControl(),
            availableTo: new FormControl(),
            items: new FormArray([])
        });
        this.setValidators(() => {
            return (this.get('items') as FormArray).length == 0 ? {nullItems: true} : null;
        });
        let availableFromValidators = [
            Validators.required,
            CommonValidators.date
        ];
        if (!price || clone || price.availableFrom.getTime() > new Date().getTime()) {
            availableFromValidators.push(this.availableFromValidator());
        }
        this.get('availableFrom').setValidators(availableFromValidators);
        this.get('availableTo').setValidators([
            CommonValidators.date,
            this.availableToValidator()
        ]);
        this.get('availableFrom').valueChanges.subscribe(() => {
            this.get('availableTo').updateValueAndValidity();
        });
        let items: FormArray = this.get('items') as FormArray;
        for (let category of roomCategories) {
            if (price) {
                items.push(new PriceItemForm(category, price));
            } else {
                items.push(new PriceItemForm(category));
            }
        }
        if (price) {
            this.get('availableFrom').setValue(formatDate(price.availableFrom));
            if (price.availableTo) {
                this.get('availableTo').setValue(formatDate(price.availableTo));
            }
        } else {
            let priceNameChanged = false;
            this.get('name').valueChanges.subscribe((value) => {
                priceNameChanged = true;
            });
            this.get('availableFrom').valueChanges.subscribe((value) => {
                if (!priceNameChanged && value) {
                    this.get('name').setValue('Прайс от ' + new Date(value).toLocaleDateString());
                    priceNameChanged = false;
                }
            });
            this.get('availableFrom').setValue(formatDate(this.getNextDay()));
        }
        if (price && !clone) {
            if (price.availableFrom.getTime() < new Date().getTime()) {
                this.get('availableFrom').disable();
                this.availableFromReadonly = true;
            }
        }
    }

    protected availableFromValidator(): (control: AbstractControl) => ValidationErrors | null {
        return control => {
            let availableFrom: string = (control.value) ? control.value.trim() : '';
            if (availableFrom.length == 0) {
                return null;
            }
            if (new Date(availableFrom).getTime() < this.getNextDay().getTime()) {
                return {availableFrom: true};
            }
            return null;
        };
    }

    protected getNextDay(): Date {
        let nextDay = new Date();
        nextDay.setDate(nextDay.getDate() + 1);
        nextDay.setHours(0, 0, 0, 0);
        return nextDay;
    }

    protected availableToValidator(): (control: AbstractControl) => ValidationErrors | null {
        return control => {
            let availableTo: string = (control.value) ? control.value.trim() : '';
            if (availableTo.length == 0) {
                return null;
            }
            let availableFrom: string = (this.get('availableFrom').value) ? this.get('availableFrom').value.trim() : '';
            if (availableFrom.length == 0) {
                return null;
            }
            if (new Date(availableTo).getTime() < new Date(availableFrom).getTime()) {
                return {availableTo: true};
            }
            return null;
        };
    }

    public getPrice(): Price {
        let price = new Price();
        price.name = this.get('name').value;
        price.availableFrom = new Date(this.get('availableFrom').value);
        price.availableFrom.setHours(0, 0, 0, 0);
        if (this.get('availableTo').value) {
            price.availableTo = new Date(this.get('availableTo').value);
            price.availableTo.setHours(23, 59, 59, 999);
        } else {
            price.availableTo = null;
        }
        price.items = [];
        for (let pif of (this.get('items') as FormArray).controls) {
            let itemForm = pif as PriceItemForm;
            let categoryItem = new HotelRoomCategoryPriceItem();
            categoryItem.itemType = 'hotel_room_category';
            categoryItem.itemId = itemForm.roomCategory.roomCategoryId;
            categoryItem.value = itemForm.get('value').value * 100;
            price.items.push(categoryItem);

            if (itemForm.get('additional')) {
                let categoryAdditionalItem = new HotelRoomCategoryAdditionalPriceItem();
                categoryAdditionalItem.itemType = 'hotel_room_category_additional';
                categoryAdditionalItem.itemId = itemForm.roomCategory.roomCategoryId;
                categoryAdditionalItem.value = itemForm.get('additional').value * 100;
                price.items.push(categoryAdditionalItem);
            }
        }
        return price;
    }

    public isAvailableFromReadonly(): boolean {
        return this.availableFromReadonly;
    }

}
