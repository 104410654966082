import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { AuthService } from "./auth/auth.service";
import * as moment from "moment";

@Injectable()
export class TimeZoneService {

    private _showByUserTZ: boolean = true;

    private timezones: string[];

    public constructor(
        protected httpClient: HttpClient,
        protected authService: AuthService
    ) {
    }

    public getTimeZones(): Promise<string[]> {
        return new Promise<string[]>((resolve, reject) => {
            if (this.timezones) {
                resolve(this.timezones);
                return;
            }
            this.httpClient.get<{ timezones: string[] }>('/v2/timezones').toPromise()
                .then((data) => {
                    this.timezones = data.timezones;
                    resolve(this.timezones);
                })
                .catch(error => reject(error));
        });
    }

    public getDefaultTimeZone(): string {
        return this.authService.isAuthenticated() ? this.authService.getUser().timezone : moment.tz.guess();
    }

    public getTZAbbr(timezone: string): string {
        let abbr = moment().tz(timezone).zoneAbbr();
        return abbr.startsWith('+') ? 'GMT' + abbr : abbr;
    }

    public get showByUserTZ(): boolean {
        return this._showByUserTZ;
    }

    public set showByUserTZ(value: boolean) {
        this._showByUserTZ = value;
    }

    public get showByLMT(): boolean {
        return !this.showByUserTZ;
    }

    public set showByLMT(value: boolean) {
        this.showByUserTZ = !value;
    }

}
