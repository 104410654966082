import { Component, Input, OnInit } from "@angular/core";
import { EmployeeDetailsForm, EmployeeForm } from "./employee.form";
import { TimeZoneService } from "app/timezone.service";
import { City } from "location/city";

@Component({
    selector: 'employee-details-form',
    templateUrl: './employee-details-form.component.html'
})
export class EmployeeDetailsFormComponent {

    @Input('employeeDetailsForm')
    public employeeDetailsForm: EmployeeDetailsForm;

    @Input('employeeForm')
    public set employeeForm(employeeForm: EmployeeForm) {
        this.employeeDetailsForm = employeeForm.get('details') as EmployeeDetailsForm;
    }

}

@Component({
    selector: 'employee-form',
    templateUrl: './employee-form.component.html'
})
export class EmployeeFormComponent implements OnInit {
    private _employeeForm: EmployeeForm;

    @Input('employeeForm')
    public set employeeForm(employeeForm: EmployeeForm) {
        this._employeeForm = employeeForm;
        let lastCityId: number = employeeForm.get('city').value;
        this._employeeForm.get('city').valueChanges.subscribe(value => {
            this.onCityChanged(lastCityId, value);
            lastCityId = value;
        });
    }

    public get employeeForm(): EmployeeForm {
        return this._employeeForm;
    }

    public timezones: string[] = [];

    @Input('cities')
    public cities: City[] = [];

    public constructor(
        protected timeZoneService: TimeZoneService
    ) {
    }

    public ngOnInit(): void {
        this.timeZoneService.getTimeZones().then(tz => this.timezones = tz);
    }

    public trackByCity(index: number, city: City): number {
        return city.cityId;
    }

    protected getCity(cityId: number): City | null {
        for (let city of this.cities) {
            if (city.cityId == cityId) {
                return city;
            }
        }
        return null;
    }

    protected onCityChanged(oldId: number, newId: number): void {
        if (this.employeeForm.enabled && (!oldId
            || this.getCity(oldId).timezone == this.employeeForm.get('timezone').value)) {
            this.employeeForm.get('timezone').setValue(this.getCity(newId).timezone);
        }
    }

}
