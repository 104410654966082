<div class="ibox">
    <div class="ibox-content">
        <div class="m-b-md">
            <a *canEditHotel="hotel" [routerLink]="['/hotels', hotel.alias, 'edit']"
               class="btn btn-white btn-xs float-right">
                Редактировать
            </a>
            <h2>{{ hotel.name }}</h2>
        </div>
        <dl class="row mb-0">
            <dt *ngIf="hotel.company" class="col-2">Компания:</dt>
            <dd *ngIf="hotel.company" class="col-10">{{ hotel.company.name }}</dd>
            <dt class="col-2">Тип:</dt>
            <dd class="col-10">
                <span *ngIf="hotel.type == HotelType.Hotel">Отель</span>
                <span *ngIf="hotel.type == HotelType.Hostel">Хостел</span>
            </dd>
            <dt class="col-2">Статус:</dt>
            <dd class="col-10">
                <span *ngIf="hotel.isEnabled()" class="label label-primary">Активный</span>
                <span *ngIf="hotel.isDisabled()" class="label label-warning">Заблокирован</span>
                <span *ngIf="hotel.isDeleted()" class="label label-danger">Удалён</span>
            </dd>
            <dt class="col-2">Алиас:</dt>
            <dd class="col-10">{{ hotel.alias }}</dd>
            <dt class="col-2">Город:</dt>
            <dd class="col-10">{{ hotel.city.name }}</dd>
            <dt class="col-2">Время заезда:</dt>
            <dd class="col-10">
                <utc-time [time]="hotel.arrivalTime" [timezone]="hotel.city.timezone"></utc-time>
            </dd>
            <dt class="col-2">Время выезда:</dt>
            <dd class="col-10">
                <utc-time [time]="hotel.departureTime" [timezone]="hotel.city.timezone"></utc-time>
            </dd>
            <dt class="col-2">Сайт:</dt>
            <dd class="col-10">
                <a href="{{ hotelSite }}" target="_blank" class="text-navy">
                    {{ hotel.alias }}.likehostels.ru
                </a>
                <a href="{{ hotelSite }}" target="_blank" class="text-navy m-l-sm">
                    <i class="fas fa-external-link-alt"></i>
                </a>
            </dd>
        </dl>
        <h2 class="text-muted text-right">#{{ hotel.hotelId }}</h2>
    </div>
</div>
