import { Component, Injectable } from "@angular/core";
import { Observable, forkJoin } from "rxjs";
import { map } from "rxjs/operators";
import { JsonProperty, ObjectMapper } from "json-object-mapper";
import { ActivatedRoute, ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot } from "@angular/router";
import { HttpClient } from "@angular/common/http";
import { Employee, FranchiseeEmployeePermissions, ManagementEmployeePermissions } from "../employee";
import { EmployeeForm } from "./employee.form";
import { TitleService as LayoutTitleService } from "layout/title.service";
import { BreadcrumbService } from "layout/breadcrumb.service";
import { City } from "location/city";
import { AuthService } from "app/auth/auth.service";
import { Hotel } from "hotel/hotel";
import {
    EmployeePermissionsForm,
    FranchiseeEmployeePermissionsForm,
    ManagementEmployeePermissionsForm
} from "./employee-permissions.form";
import { Company } from "../company";

export class EditEmployeeData {
    @JsonProperty({type: Employee})
    public employee: Employee;

    @JsonProperty({type: City})
    public cities: City[];

    @JsonProperty({type: Hotel, required: false})
    public hotels?: Hotel[];

}

@Injectable()
export class EditEmployeeResolver implements Resolve<EditEmployeeData> {

    public constructor(
        protected httpClient: HttpClient,
        protected authService: AuthService
    ) {
    }

    public resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<EditEmployeeData> {
        let requests = [
            this.httpClient.get('/v2/employees/' + route.params.employeeId),
            this.httpClient.get("/v2/locations/cities")
        ];
        if (this.authService.getUser().company.isManagementCompany()) {
            requests.push(this.httpClient.get("/v2/companies/" + route.params.companyId + "/hotels"));
        }
        return forkJoin(requests).pipe(map(data => {
            let result = {};
            for (let d of data) Object.assign(result, d);
            return result;
        })).pipe(map(data => ObjectMapper.deserialize(EditEmployeeData, data)));
    }

}

@Component({
    selector: 'company-employee-edit',
    templateUrl: './edit-employee.component.html'
})
export class EditEmployeeComponent {
    public employee: Employee;
    public employeeForm: EmployeeForm;
    public managementPermissionsForm: ManagementEmployeePermissionsForm = new ManagementEmployeePermissionsForm();
    public franchiseePermissionsForm: FranchiseeEmployeePermissionsForm = new FranchiseeEmployeePermissionsForm([]);
    public employeePermissionsForm: EmployeePermissionsForm = new EmployeePermissionsForm();
    public cities: City[];

    public constructor(
        protected route: ActivatedRoute,
        protected httpClient: HttpClient,
        protected router: Router,
        protected layoutTitleService: LayoutTitleService,
        protected breadcrumbService: BreadcrumbService,
        protected authService: AuthService
    ) {
    }

    public ngOnInit(): void {
        this.route.data.subscribe((data) => {
            this.employee = data.component.employee;
            this.cities = data.component.cities;
            this.employeeForm = new EmployeeForm(this.httpClient, this.employee, !this.authService.getUser().company.isManagementCompany());
            this.employeePermissionsForm = null;
            if (this.authService.getUser().company.isManagementCompany()) {
                if (this.employee.company.isManagementCompany() && this.authService.getUser().permissions.canEditEmployees) {
                    this.employeePermissionsForm = new EmployeePermissionsForm(this.employee.permissions);
                }
                if (this.employee.company.isFranchiseeCompany()) {
                    this.employeePermissionsForm = new EmployeePermissionsForm(this.employee.permissions);
                }
            }
            if (this.authService.getUser().company.isFranchiseeCompany() && this.authService.getUser().permissions.canEditEmployees) {
                this.employeePermissionsForm = new EmployeePermissionsForm(this.employee.permissions);
            }
            if (this.employee.company.isManagementCompany()) {
                if (this.authService.getUser().employeeId != this.employee.employeeId) {
                    this.managementPermissionsForm = new ManagementEmployeePermissionsForm(
                        this.employee.permissions as ManagementEmployeePermissions
                    );
                } else {
                    this.managementPermissionsForm = null;
                    this.employeePermissionsForm = null;
                }
                this.franchiseePermissionsForm = null;
            }
            if (this.employee.company.isFranchiseeCompany()) {
                if (this.authService.getUser().employeeId != this.employee.employeeId) {
                    if (this.authService.getUser().company.isManagementCompany()) {
                        let hotels = data.component.hotels;
                        this.franchiseePermissionsForm = new FranchiseeEmployeePermissionsForm(
                            hotels, this.employee.permissions as FranchiseeEmployeePermissions
                        );
                    }
                    if (this.authService.getUser().company.isFranchiseeCompany()) {
                        let authUserPermissions = this.authService.getUser().permissions as FranchiseeEmployeePermissions;
                        let hotels = this.authService.getUser().hotels;
                        if (!authUserPermissions.allHotels.canEditEmployees) {
                            hotels = hotels.filter(hotel => {
                                return authUserPermissions.hotel(hotel).canEditEmployees;
                            });
                        }
                        this.franchiseePermissionsForm = new FranchiseeEmployeePermissionsForm(
                            hotels, this.employee.permissions as FranchiseeEmployeePermissions,
                            authUserPermissions.allHotels.canEditEmployees
                        );
                    }
                } else {
                    this.franchiseePermissionsForm = null;
                    this.employeePermissionsForm = null;
                }
                this.managementPermissionsForm = null;
            }
            let employeeName = (this.employee.name.isPresent()) ? this.employee.name.toString() : this.employee.email;
            this.layoutTitleService.title = employeeName;
            this.layoutTitleService.small = 'Редактирование сотрудника';
            if (this.authService.getUser().company.isManagementCompany()) {
                this.breadcrumbService.breadcrumbs = [
                    {name: 'Компании', link: '/companies'},
                    {name: this.employee.company.name, link: ['/companies', this.employee.company.companyId]},
                    {name: 'Сотрудники', link: '/employees'},
                    {
                        name: employeeName,
                        link: ['/companies', this.employee.company.companyId, 'employees', this.employee.employeeId]
                    },
                    {name: 'Редактирование'}
                ];
                this.employeeForm.valueChanges.subscribe(data => {
                    employeeName = this.getEmployeeName(data);
                    this.layoutTitleService.title = employeeName;
                    this.breadcrumbService.breadcrumbs = [
                        {name: 'Компании', link: '/companies'},
                        {name: this.employee.company.name, link: ['/companies', this.employee.company.companyId]},
                        {name: 'Сотрудники', link: '/employees'},
                        {
                            name: employeeName,
                            link: ['/companies', this.employee.company.companyId, 'employees', this.employee.employeeId]
                        },
                        {name: 'Редактирование'}
                    ];
                });
            }
            if (this.authService.getUser().company.isFranchiseeCompany()) {
                this.breadcrumbService.breadcrumbs = [
                    {name: 'Сотрудники', link: '/employees'},
                    {
                        name: employeeName,
                        link: ['/employees', this.employee.employeeId]
                    },
                    {name: 'Редактирование'}
                ];
                this.employeeForm.valueChanges.subscribe(data => {
                    employeeName = this.getEmployeeName(data);
                    this.layoutTitleService.title = employeeName;
                    this.breadcrumbService.breadcrumbs = [
                        {name: 'Сотрудники', link: '/employees'},
                        {
                            name: employeeName,
                            link: ['/employees', this.employee.employeeId]
                        },
                        {name: 'Редактирование'}
                    ];
                });
            }
        });
    }

    public onSubmit(): void {
        this.employeeForm.disable();
        let employee = this.employeeForm.getEmployee();
        employee.company = this.employee.company;
        if (employee.company.isManagementCompany()) {
            if (this.managementPermissionsForm) {
                employee.permissions = this.managementPermissionsForm.getPermissions();
            } else {
                employee.permissions = this.employee.permissions;
            }
        }
        if (employee.company.isFranchiseeCompany()) {
            if (this.franchiseePermissionsForm) {
                employee.permissions = this.franchiseePermissionsForm.getPermissions(this.employee.permissions as FranchiseeEmployeePermissions);
            } else {
                employee.permissions = this.employee.permissions;
            }
        }
        if (this.employeePermissionsForm) {
            let employeePermissions = this.employeePermissionsForm.getPermissions();
            employee.permissions.canEditEmployees = employeePermissions.canEditEmployees;
            employee.permissions.canEditVacancies = employeePermissions.canEditVacancies;
        }
        this.httpClient.put<{
            employee: any, company: any
        }>('/v2/companies/' + employee.company.companyId + '/employees/' + this.employee.employeeId, ObjectMapper.serialize({
            employee: employee
        })).toPromise().then((data) => {
            let employee = ObjectMapper.deserialize(Employee, data.employee);
            employee.company = ObjectMapper.deserialize(Company, data.company);
            if (this.authService.getUser().company.isManagementCompany()) {
                this.router.navigate(['/companies', employee.company.companyId, 'employees', employee.employeeId]);
            }
            if (this.authService.getUser().company.isFranchiseeCompany()) {
                this.router.navigate(['/employees', employee.employeeId]);
            }
        }).catch((error: any) => {
            console.error("Save employee error:", error);
            this.employeeForm.enable();
            throw error;
        });
    }

    public getCancelLink(): any[] {
        if (this.authService.getUser().company.isManagementCompany()) {
            return ['/companies', this.employee.company.companyId, 'employees', this.employee.employeeId];
        }
        if (this.authService.getUser().company.isFranchiseeCompany()) {
            return ['/employees', this.employee.employeeId];
        }
        return ['/'];
    }

    private getEmployeeName(data: any): string {
        let employeeName = (
            (data.details.firstName ? data.details.firstName : '') + ' '
            + (data.details.middleName ? data.details.middleName : '') + ' '
            + (data.details.lastName ? data.details.lastName : '')
        ).trim() || data.email || 'Сотрудник';
        return employeeName;
    }

}
