import { Component, Input } from "@angular/core";
import { RoomCategoryForm } from "./room-category.form";
import { RoomCategory, RoomCategoryType } from "../room-category";

@Component({
    selector: 'hotel-room-category-form',
    templateUrl: 'room-category-form.component.html'
})
export class RoomCategoryFormComponent {

    @Input('categoryForm')
    public categoryForm: RoomCategoryForm;

    @Input('category')
    public category: RoomCategory;

    public isStandartRoom(): boolean {
        let type: RoomCategoryType = this.categoryForm.get('type') ? this.categoryForm.get('type').value : this.category.type;
        return type == RoomCategoryType.Standart;
    }

}
