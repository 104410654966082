import { Component, OnInit } from "@angular/core";
import { VacancyForm } from "./vacancy.form";
import { AuthService } from "app/auth/auth.service";
import { TitleService as LayoutTitleService } from "layout/title.service";
import { BreadcrumbService } from "layout/breadcrumb.service";
import { HttpClient } from "@angular/common/http";
import { JsonProperty, ObjectMapper } from "json-object-mapper";
import { Vacancy } from "./vacancy";
import { ActivatedRoute, ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot } from "@angular/router";
import { AbstractControl, FormControl, ValidationErrors, Validators } from "@angular/forms";
import { Company } from "company/company";
import { City } from "location/city";
import { Hotel } from "hotel/hotel";
import { forkJoin, Observable } from "rxjs";
import { map } from "rxjs/operators";
import { FranchiseeEmployeePermissions } from "company/employee";

export class NewVacancyData {

    @JsonProperty({type: Company})
    public companies?: Company[];

    @JsonProperty({type: City})
    public cities?: City[];

}

export class NewVacancyResolver implements Resolve<NewVacancyData> {

    public constructor(
        protected authService: AuthService,
        protected httpClient: HttpClient
    ) {
    }

    public resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<NewVacancyData> {
        let user = this.authService.getUser();
        if (user.company.isManagementCompany()) {
            return forkJoin(
                this.httpClient.get('/v2/companies'),
                this.httpClient.get("/v2/locations/cities")
            ).pipe(map(data => ObjectMapper.deserialize(NewVacancyData, Object.assign({}, data[0], data[1]))));
        }
        if (user.company.isFranchiseeCompany()) {
            return this.httpClient.get<NewVacancyData>('/v2/locations/cities')
                .pipe(map(data => ObjectMapper.deserialize(NewVacancyData, data)));
        }
        return null;
    }

}

export class NewVacancyForm extends VacancyForm {
    public vacancyWithoutHotel: boolean = true;

    public constructor(
        protected company?: Company
    ) {
        super();
        this.addControl('company', new FormControl(company ? company.companyId : null));
        this.addControl('hotel', new FormControl(0));
        this.addControl('city', new FormControl());
        this.setValidators((control: AbstractControl) => this.formValidator(control));
        if (company) {
            this.get('company').disable();
        }
    }

    protected formValidator(control: AbstractControl): ValidationErrors | null {
        if (!this.company) {
            if (!parseInt(this.get('company').value)) {
                return {
                    companyRequired: 'Company is required field'
                };
            }
        }
        if (this.vacancyWithoutHotel) {
            if (!parseInt(this.get('hotel').value) && !parseInt(this.get('city').value)) {
                return {
                    hotelsOrCity: 'Hotel or city required'
                };
            }
        } else {
            if (!parseInt(this.get('hotel').value)) {
                return {
                    hotelRequired: 'Hotel is required field'
                };
            }
        }
    }

    public getVacancy(): Vacancy {
        let vacancy = super.getVacancy();
        if (this.company) {
            vacancy.company = this.company;
        } else {
            vacancy.companyId = this.get('company').value;
        }
        if (parseInt(this.get('hotel').value)) {
            vacancy.hotelId = this.get('hotel').value;
        } else {
            vacancy.cityId = this.get('city').value;
        }
        return vacancy;
    }

}

export class HotelsRequest {
    @JsonProperty({type: Hotel})
    public hotels: Hotel[];
}

@Component({
    selector: 'vacancy-new',
    templateUrl: './new-vacancy.component.html'
})
export class NewVacancyComponent implements OnInit {
    public vacancyForm: NewVacancyForm;
    public companies: Company[] = [];
    public hotels: Hotel[] = [];
    public cities: City[] = [];
    public showEmptyHotelField: boolean = true;

    public constructor(
        protected authService: AuthService,
        protected layoutTitleService: LayoutTitleService,
        protected breadcrumbService: BreadcrumbService,
        protected httpClient: HttpClient,
        protected router: Router,
        protected route: ActivatedRoute
    ) {
    }

    public ngOnInit(): void {
        this.layoutTitleService.title = 'Новая вакансия';
        this.breadcrumbService.breadcrumbs = [
            {name: 'Вакансии', link: '/vacancies'},
            {name: 'Новая вакансия'}
        ];
        this.route.data.subscribe(data => {
            let user = this.authService.getUser();
            if (user.company.isManagementCompany()) {
                this.vacancyForm = new NewVacancyForm();
                let companies: Company[] = data.component.companies;
                if (!user.permissions.canEditVacancies) {
                    companies = companies.filter(company => company.companyId != user.company.companyId);
                }
                this.companies = companies;
                this.cities = data.component.cities;
                let lastCompanyId: number = 0;
                this.vacancyForm.get('company').valueChanges.subscribe(companyId => {
                    if (companyId != lastCompanyId) {
                        lastCompanyId = companyId;
                        this.onCompanyChanged(this.getCompany(companyId));
                    }
                });
                if (user.permissions.canEditVacancies) {
                    this.vacancyForm.get('company').setValue(user.company.companyId);
                }
            }
            if (user.company.isFranchiseeCompany()) {
                let permissions = user.permissions as FranchiseeEmployeePermissions;
                this.vacancyForm = new NewVacancyForm(user.company);
                this.vacancyForm.vacancyWithoutHotel = permissions.canEditVacancies;
                this.showEmptyHotelField = permissions.canEditVacancies;
                this.hotels = this.authService.getUser().hotels.filter(hotel => permissions.hotel(hotel).canEditVacancies);
                this.cities = data.component.cities;
                this.vacancyForm.get('city').setValue(user.city.cityId);
                if (!permissions.canEditVacancies && this.hotels.length > 0) {
                    this.vacancyForm.get('hotel').setValue(this.hotels[0].hotelId);
                }
            }
        });
    }

    public onSubmit(): void {
        this.vacancyForm.disable();
        this.httpClient.post<{ vacancy: any }>('/v2/vacancies', ObjectMapper.serialize({
            vacancy: this.vacancyForm.getVacancy()
        })).toPromise().then((data) => {
            let vacancy = ObjectMapper.deserialize(Vacancy, data.vacancy);
            this.router.navigate(['/vacancies', vacancy.vacancyId]);
        }).catch((error: any) => {
            console.error("Create vacancy error:", error);
            this.vacancyForm.enable();
            throw error;
        });
    }

    public trackByCompany(index: number, company: Company): number {
        return company.companyId;
    }

    public trackByCity(index: number, city: City): number {
        return city.cityId;
    }

    public trackByHotel(index: number, hotel: Hotel): number {
        return hotel.hotelId;
    }

    protected getCompany(companyId: number): Company | null {
        for (let company of this.companies) {
            if (company.companyId == companyId) return company;
        }
        return null;
    }

    protected onCompanyChanged(company: Company): void {
        this.hotels = [];
        this.vacancyForm.get('hotel').setValue(0);
        this.vacancyForm.get('city').setValue(company.city.cityId);
        if (company.isFranchiseeCompany()) {
            this.httpClient.get<HotelsRequest>('/v2/hotels')
                .pipe(map(data => ObjectMapper.deserialize(HotelsRequest, data)))
                .pipe(map(data => data.hotels.filter(hotel => hotel.company.companyId == company.companyId)))
                .subscribe(hotels => this.hotels = hotels);
        }
    }

    public showCompanies(): boolean {
        return this.companies.length > 0 && this.authService.getUser().company.isManagementCompany();
    }

    public showHotels(): boolean {
        let user = this.authService.getUser();
        if (user.company.isManagementCompany()) {
            let company = this.getCompany(this.vacancyForm.get('company').value);
            if (!company) {
                return false;
            }
            return company.isFranchiseeCompany() && this.hotels.length > 0;
        }
        if (user.company.isFranchiseeCompany()) {
            return this.hotels.length > 0;
        }
        return false;
    }

    public showCities(): boolean {
        let user = this.authService.getUser();
        if (user.company.isFranchiseeCompany() && !user.permissions.canEditVacancies) {
            return false;
        }
        return this.cities.length > 0 && !parseInt(this.vacancyForm.get('hotel').value);
    }

}
