import { Component, Injectable } from "@angular/core";
import { JsonProperty, ObjectMapper } from "json-object-mapper";
import { City } from "../city";
import { ActivatedRoute, ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from "@angular/router";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { TitleService as LayoutTitleService } from "layout/title.service";
import { BreadcrumbService } from "layout/breadcrumb.service";

export class CityData {
    @JsonProperty({type: City})
    public city: City;
}

@Injectable()
export class CityResolver implements Resolve<CityData> {

    public constructor(
        protected httpClient: HttpClient
    ) {
    }

    public resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<CityData> {
        return this.httpClient.get<CityData>("/v2/locations/cities/" + route.params.alias)
            .pipe(map(data => ObjectMapper.deserialize(CityData, data)));
    }

}

@Component({
    selector: 'location-city',
    templateUrl: './city.component.html'
})
export class CityComponent {

    public city: City;

    public get citySite(): string {
        return "http://" + this.city.alias + ".likehostels.ru";
    }

    public constructor(
        protected route: ActivatedRoute,
        protected layoutTitleService: LayoutTitleService,
        protected breadcrumbService: BreadcrumbService
    ) {
    }

    public ngOnInit(): void {
        this.route.data.subscribe((data) => {
            this.city = data.component.city;
            this.layoutTitleService.title = this.city.name;
            this.breadcrumbService.breadcrumbs = [
                {name: 'Страны', link: '/locations/countries'},
                {name: this.city.country.name, link: ['/locations/countries', this.city.country.alias]},
                {name: 'Города', link: '/locations/cities'},
                {name: this.city.name}
            ];
        });
    }

}
