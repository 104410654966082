import { APP_INITIALIZER, ErrorHandler, InjectionToken, NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { IndexComponent } from "./index.component";
import { AuthGuard } from "./auth/auth.guard";
import { NotAuthGuard } from "./auth/not-auth.guard";
import { AllActionsResolved } from "action/all-actions-resolved.guard";
import { ManagementCompanyGuard, NotManagementCompanyGuard } from "./auth/management-company.guard";
import { FranchiseeCompanyGuard, NotFranchiseeCompanyGuard } from "./auth/franchisee-company.guard";

const routes: Routes = [
    {
        path: '',
        component: IndexComponent,
        canActivate: [AuthGuard, AllActionsResolved]
    }
];

let routerErrorHandlers: ErrorHandler[] = [];

export function initRouterErrorHandler(handlers: ErrorHandler[]) {
    return () => {
        return new Promise<void>((resolve, reject) => {
            routerErrorHandlers = handlers;
            resolve();
        });
    };
}

export function routerErrorHandler(err: any) {
    for (let handler of routerErrorHandlers) {
        try {
            handler.handleError(err);
            break;
        } catch (e) {
            if (routerErrorHandlers.indexOf(handler) == routerErrorHandlers.length - 1) {
                throw err;
            }
        }
    }
}

export const ROUTER_ERROR_HANDLERS = new InjectionToken<ErrorHandler[]>('ROUTER_ERROR_HANDLERS');

@NgModule({
    imports: [RouterModule.forRoot(routes, {
        errorHandler: routerErrorHandler
    })],
    providers: [
        {
            provide: APP_INITIALIZER,
            useFactory: initRouterErrorHandler,
            deps: [ROUTER_ERROR_HANDLERS],
            multi: true
        },
        AuthGuard, NotAuthGuard, ManagementCompanyGuard, NotManagementCompanyGuard,
        FranchiseeCompanyGuard, NotFranchiseeCompanyGuard
    ],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
