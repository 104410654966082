import { Component, OnInit } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { Router } from "@angular/router";
import { ActionService } from "./action.service";

@Component({
    selector: 'action-component',
    template: ''
})
export class ActionComponent implements OnInit {

    public constructor(
        protected title: Title,
        protected router: Router,
        protected actionService: ActionService
    ) {
    }

    public ngOnInit(): void {
        this.title.setTitle('Лайк Хостел ERP');
        let next = this.actionService.nextUnresolvedAction();
        this.router.navigate(['/actions', next]);
    }

}
