import { Component, Injectable, OnInit } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { ActivatedRoute, ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from "@angular/router";
import { JsonProperty, ObjectMapper } from "json-object-mapper";
import { Country } from "../country";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { TitleService as LayoutTitleService } from "layout/title.service";
import { BreadcrumbService } from "layout/breadcrumb.service";

export class CountriesData {
    @JsonProperty({type: Country})
    public countries: Country[];
}

@Injectable()
export class CountriesResolver implements Resolve<CountriesData> {

    public constructor(
        protected httpClient: HttpClient
    ) {
    }

    public resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<CountriesData> {
        return this.httpClient.get<CountriesData>("/v2/locations/countries")
            .pipe(map(data => ObjectMapper.deserialize(CountriesData, data)));
    }

}

@Component({
    selector: 'location-countries-action',
    templateUrl: './countries-action.component.html'
})
export class CountriesActionComponent {
}

@Component({
    selector: 'location-countries',
    templateUrl: './countries.component.html'
})
export class CountriesComponent implements OnInit{

    public countries: Country[];

    public constructor(
        protected route: ActivatedRoute,
        protected layoutTitleService: LayoutTitleService,
        protected breadcrumbService: BreadcrumbService
    ) {
    }

    public ngOnInit(): void {
        this.layoutTitleService.title = 'Страны';
        this.breadcrumbService.breadcrumbs = [{name: 'Страны'}];
        this.route.data.subscribe((data) => {
            this.countries = data.component.countries;
        });
    }

    public trackByCountry(index: number, country: Country): number {
        return country.countryId;
    }

}
