import { APP_INITIALIZER, NgModule } from "@angular/core";
import { ActionRoutingModule } from "./action-routing.module";
import { LayoutComponent } from "./layout/layout.component";
import { ActionComponent } from "./action.component";
import { ActionService } from "./action.service";
import { AuthService } from "app/auth/auth.service";
import { User } from "app/user";
import { NoHotelsComponent } from "./nohotels/nohotels.component";
import { ProfileComponent } from "./profile/profile.component";
import { CompanyCommonModule } from "company/company-common.module";
import { ReactiveFormsModule } from "@angular/forms";
import { TimezoneComponent } from "./timezone/timezone.component";
import { CommonModule } from "@angular/common";

export function initActionService(actionService: ActionService, authService: AuthService) {
    return () => {
        return new Promise<void>((resolve, reject) => {
            authService.onAuthenticate.subscribe((user: User) => {
                if (NoHotelsComponent.isNoHotels(authService)) {
                    actionService.addAction('nohotels');
                }
                if (TimezoneComponent.isIllegalTimezone(authService)) {
                    actionService.addAction('timezone');
                }
                if (ProfileComponent.isIncompleteProfile(authService)) {
                    actionService.addAction('profile');
                }
            });
            authService.onLogout.subscribe(() => {
                actionService.clearActions();
            });
            resolve();
        })
    };
}

@NgModule({
    imports: [
        ActionRoutingModule,
        ReactiveFormsModule,
        CommonModule, CompanyCommonModule
    ],
    declarations: [
        LayoutComponent, ActionComponent,
        NoHotelsComponent,
        ProfileComponent, TimezoneComponent
    ],
    providers: [
        ActionService,
        {
            provide: APP_INITIALIZER,
            useFactory: initActionService,
            deps: [ActionService, AuthService],
            multi: true
        }
    ]
})
export class ActionModule {
}
