<fieldset [formGroup]="priceForm">
    <div class="form-group row"
         [class.has-error]="!priceForm.controls.name.disabled && priceForm.controls.name.value && !priceForm.controls.name.valid">
        <label class="col-2 col-form-label" for="form-hotel-price-name">Название:</label>
        <div class="col-10">
            <input type="text" class="form-control" id="form-hotel-price-name" formControlName="name">
        </div>
    </div>
    <div class="form-group row">
        <label class="col-2 col-form-label" for="form-hotel-price-available-from">Срок действия:</label>
        <div class="col-10">
            <div class="input-group">
                <div class="input-group-prepend">
                    <div class="input-group-addon">С</div>
                </div>
                <input type="date" class="form-control" id="form-hotel-price-available-from"
                       [class.text-danger]="!priceForm.controls.availableFrom.disabled && priceForm.controls.availableFrom.value && !priceForm.controls.availableFrom.valid"
                       [readonly]="priceForm.isAvailableFromReadonly()"
                       formControlName="availableFrom">
                <div class="input-group-prepend">
                    <div class="input-group-addon border-left-0">по</div>
                </div>
                <input type="date" class="form-control" id="form-hotel-price-available-to"
                       [class.text-danger]="!priceForm.controls.availableTo.disabled && priceForm.controls.availableTo.value && !priceForm.controls.availableTo.valid"
                       formControlName="availableTo">
            </div>
        </div>
    </div>
    <hr class="hr-line-dashed">
    <div formArrayName="items">
        <div class="form-group row"
             *ngFor="let itemForm of getItemsFormArray().controls; trackBy: trackByItemForm; let i = index;"
             [formGroupName]="i">
            <label class="col-3 col-form-label" for="form-hotel-price-item-value[{{ i }}]">
                {{ itemForm.roomCategory.name }}:<br>
                <small *ngIf="itemForm.roomCategory.type == RoomCategoryType.Standart" class="text-muted">
                    (стандартный номер)
                </small>
                <small *ngIf="itemForm.roomCategory.type == RoomCategoryType.Shared" class="text-muted">
                    (общий номер)
                </small>
                <small *ngIf="itemForm.roomCategory.type == RoomCategoryType.SharedMale" class="text-muted">
                    (общий мужской номер)
                </small>
                <small *ngIf="itemForm.roomCategory.type == RoomCategoryType.SharedFemale" class="text-muted">
                    (общий женский номер)
                </small>
            </label>
            <div class="col-4">
                <div class="input-group">
                    <input type="number" class="form-control" id="form-hotel-price-item-value[{{ i }}]"
                           formControlName="value">
                    <div class="input-group-append">
                        <div class="input-group-addon"><i class="fas fa-ruble-sign"></i></div>
                    </div>
                </div>
                <span *ngIf="itemForm.roomCategory.type == RoomCategoryType.Standart" class="help-block m-b-none">
                    Стоимость всего номера в сутки
                    (1 - {{ itemForm.roomCategory.capacity.guests }} гостя)
                </span>
                <span *ngIf="itemForm.roomCategory.type != RoomCategoryType.Standart" class="help-block m-b-none">
                    Стоимость одного места в сутки
                    (всего мест: {{ itemForm.roomCategory.capacity.guests }})
                </span>
            </div>
            <div *ngIf="itemForm.get('additional')" class="col-4">
                <div class="input-group">
                    <input type="number" class="form-control" id="" formControlName="additional">
                    <div class="input-group-append">
                        <div class="input-group-addon"><i class="fas fa-ruble-sign"></i></div>
                    </div>
                </div>
                <span class="help-block m-b-none">
                    Стоимость одного доп. места
                    (всего мест: {{ itemForm.roomCategory.capacity.additional }})
                </span>
            </div>
        </div>
    </div>
</fieldset>
