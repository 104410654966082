import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { LayoutComponent } from "layout/layout.component";
import { AuthGuard } from "app/auth/auth.guard";
import { CompaniesActionComponent, CompaniesComponent, CompaniesResolver } from "./company/companies.component";
import { EmployeesActionComponent, EmployeesComponent, EmployeesResolver } from "./employee/employees.component";
import { CompanyComponent, CompanyResolver } from "./company/company.component";
import { NewCompanyComponent, NewCompanyResolver } from "./company/new-company.component";
import { EditCompanyComponent, EditCompanyResolver } from "./company/edit-company.component";
import { EmployeeComponent, EmployeeResolver } from "./employee/employee.component";
import { NewEmployeeComponent, NewEmployeeResolver } from "./employee/new-employee.component";
import { EditEmployeeComponent, EditEmployeeResolver } from "./employee/edit-employee.component";
import { AllActionsResolved } from "action/all-actions-resolved.guard";
import { ManagementCompanyGuard } from "app/auth/management-company.guard";
import { FranchiseeCompanyGuard } from "app/auth/franchisee-company.guard";
import { AllEmployeesGuard, EditEmployeeGuard, NewEmployeeGuard } from "./company.guard";

const routes: Routes = [
    {
        path: '',
        component: LayoutComponent,
        canActivate: [AuthGuard, AllActionsResolved],
        children: [
            {
                path: 'companies',
                canActivate: [ManagementCompanyGuard],
                children: [
                    {
                        path: '',
                        children: [
                            {
                                path: '',
                                component: CompaniesComponent,
                                resolve: {
                                    component: CompaniesResolver
                                }
                            },
                            {
                                path: '',
                                component: CompaniesActionComponent,
                                outlet: 'action'
                            }
                        ]
                    },
                    {
                        path: 'new',
                        component: NewCompanyComponent,
                        resolve: {
                            component: NewCompanyResolver
                        }
                    },
                    {
                        path: ':companyId',
                        component: CompanyComponent,
                        resolve: {
                            component: CompanyResolver
                        }
                    },
                    {
                        path: ':companyId/edit',
                        component: EditCompanyComponent,
                        resolve: {
                            component: EditCompanyResolver
                        }
                    },
                    {
                        path: ':companyId/employees/:employeeId',
                        children: [
                            {
                                path: '',
                                component: EmployeeComponent,
                                resolve: {
                                    component: EmployeeResolver
                                }
                            },
                            {
                                path: 'edit',
                                component: EditEmployeeComponent,
                                canActivate: [EditEmployeeGuard],
                                resolve: {
                                    component: EditEmployeeResolver
                                }
                            }
                        ]
                    }
                ]
            },
            {
                path: 'employees',
                canActivate: [AllEmployeesGuard],
                children: [
                    {
                        path: '',
                        children: [
                            {
                                path: '',
                                component: EmployeesComponent,
                                resolve: {
                                    component: EmployeesResolver
                                }
                            },
                            {
                                path: '',
                                component: EmployeesActionComponent,
                                outlet: 'action'
                            }
                        ]
                    },
                    {
                        path: 'new',
                        component: NewEmployeeComponent,
                        canActivate: [NewEmployeeGuard],
                        resolve: {
                            component: NewEmployeeResolver
                        }
                    },
                    {
                        path: ':employeeId',
                        canActivate: [FranchiseeCompanyGuard],
                        children: [
                            {
                                path: '',
                                component: EmployeeComponent,
                                resolve: {
                                    component: EmployeeResolver
                                }
                            },
                            {
                                path: 'edit',
                                component: EditEmployeeComponent,
                                canActivate: [EditEmployeeGuard],
                                resolve: {
                                    component: EditEmployeeResolver
                                }
                            }
                        ]
                    }
                ]
            }
        ]
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
    providers: [
        CompaniesResolver, CompanyResolver,
        EditCompanyResolver, EmployeesResolver,
        EmployeeResolver, EditEmployeeResolver,
        NewEmployeeResolver, NewCompanyResolver,
        NewEmployeeGuard, EditEmployeeGuard,
        AllEmployeesGuard
    ]
})
export class CompanyRoutingModule {
}
