<h3>Системные привилегии</h3>
<div class="row" [formGroup]="permissionsForm">
    <div class="col-md-4">
        <div>
            <label>
                <input type="checkbox" formControlName="canDeleteAllObjects">
                Удаление любых объектов
            </label>
        </div>
    </div>
</div>
