<div class="container mt-3">
    <div class="row">
        <div class="col-12 col-sm-10 offset-sm-1 col-md-8 offset-md-2 col-lg-6 offset-lg-3">
            <div class="panel panel-warning">
                <div class="panel-heading">
                    <i class="fas fa-exclamation-triangle"></i>
                    Обновление часового пояса
                </div>
                <div class="panel-body text-justify">
                    <p>
                        Часовой пояс <strong>{{ getProfileTimezone() }}</strong> Вашего профиля
                        не соответствует часовому поясу <strong>{{ getBrowserTimezone() }}</strong>
                        Вашего браузера.
                    </p>
                    <form [formGroup]="timezoneForm" (ngSubmit)="onSubmit()">
                        <div class="form-group row"
                             [class.has-error]="timezoneForm.controls.city.value && !timezoneForm.controls.city.disabled && !timezoneForm.controls.city.valid">
                            <label class="col-3 col-form-label" for="form-company-employee-city">Город:</label>
                            <div class="col-9">
                                <select class="form-control" id="form-company-employee-city" formControlName="city">
                                    <option *ngFor="let city of cities; trackBy: trackByCity" [value]="city.cityId">
                                        {{ city.name }}
                                    </option>
                                </select>
                            </div>
                        </div>
                        <div class="form-group row"
                             [class.has-error]="timezoneForm.controls.timezone.value && !timezoneForm.controls.timezone.disabled && !timezoneForm.controls.timezone.valid">
                            <label class="col-3 col-form-label" for="form-company-employee-timezone">Часовой
                                пояс:</label>
                            <div class="col-9">
                                <select class="form-control" id="form-company-employee-timezone"
                                        formControlName="timezone">
                                    <option *ngFor="let timezone of timezones" [value]="timezone">
                                        {{ timezone }}
                                    </option>
                                </select>
                            </div>
                        </div>
                        <div class="form-group row">
                            <div class="col-9 offset-3">
                                <button class="btn btn-primary" type="submit" [disabled]="!timezoneForm.valid">
                                    Сохранить
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>
