import { Component, Input } from "@angular/core";
import { CompanyForm } from "./company.form";
import { City } from "location/city";

@Component({
    selector: 'company-form',
    templateUrl: './company-from.component.html'
})
export class CompanyFormComponent {

    @Input('companyForm')
    public companyForm: CompanyForm;

    @Input('cities')
    public cities: City[] = [];

    public trackByCity(index: number, city: City): number {
        return city.cityId;
    }

}
