import { Injectable, NgZone } from "@angular/core";
import swal from 'sweetalert';
import { SwalOptions } from "sweetalert/typings/modules/options";

@Injectable()
export class SweetalertService {

    public constructor(
        protected ngZone: NgZone
    ) {
    }

    public alert(options: Partial<SwalOptions>): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            this.ngZone.runOutsideAngular(() => {
                swal(options).then(data => {
                    resolve(data);
                }).catch(data => {
                    reject(data);
                });
            });
        });
    }

}
