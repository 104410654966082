<div class="ibox-content">
    <form [formGroup]="countryForm" (ngSubmit)="onSubmit()">
        <location-country-form [countryForm]="countryForm"></location-country-form>
        <div class="form-group row">
            <div class="col-10 offset-2">
                <a class="btn btn-white" [routerLink]="['/locations/countries', country.alias]">Отмена</a>
                &nbsp;
                <button class="btn btn-primary" type="submit" [disabled]="!countryForm.valid">Сохранить</button>
            </div>
        </div>
    </form>
</div>
