<div class="ibox-content">
    <div class="table-responsive">
        <table class="table table-hover">
            <thead>
            <tr>
                <th>ID</th>
                <th>Название</th>
                <th>Срок действия</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let price of prices; trackBy: trackByPrice">
                <th [class.del]="price.isDisabled()">
                    <a [routerLink]="['/hotels', hotel.alias, 'prices', price.priceId]">
                        {{ price.priceId }}
                    </a>
                </th>
                <td [class.del]="price.isDisabled()">
                    <i *ngIf="price.isEnabled()" class="far fa-check-circle text-navy m-r" title="Прайс включен"></i>
                    <i *ngIf="price.isDisabled()" class="fas fa-ban text-danger m-r" title="Прайс заблокирован"></i>
                    <a [routerLink]="['/hotels', hotel.alias, 'prices', price.priceId]">
                        {{ price.name }}
                    </a>
                </td>
                <td>
                    С {{ localDate(price.availableFrom) }}
                    <ng-template [ngIf]="price.availableTo">
                        по {{ localDate(price.availableTo) }}
                    </ng-template>
                    <ng-template [ngIf]="!price.availableTo">
                        <i>(бессрочно)</i>
                    </ng-template>
                </td>
            </tr>
            <tr *ngIf="prices.length == 0">
                <td colspan="3" class="text-center">
                    Прайсы не найдены
                    <a *canEditHotelPrices="hotel" class="m-l"
                       [routerLink]="['/hotels', hotel.alias, 'prices', 'new']">(добавить новый)</a>
                </td>
            </tr>
            </tbody>
        </table>
    </div>
</div>
