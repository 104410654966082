import { Component, Injectable, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute, ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from "@angular/router";
import { TitleService as LayoutTitleService } from "layout/title.service";
import { BreadcrumbService } from "layout/breadcrumb.service";
import { HotelSidebarService } from "layout/sidebar/hotel.service";
import { Hotel } from "../hotel";
import { HttpClient } from "@angular/common/http";
import { map } from "rxjs/operators";
import { JsonProperty, ObjectMapper } from "json-object-mapper";
import { Employee } from "company/employee";
import { Observable } from "rxjs";
import { mergeRouteData } from "app/merge-route-data";
import { AuthService } from "app/auth/auth.service";

export class EmployeesData {
    @JsonProperty({type: Employee})
    public employees: Employee[];

    @JsonProperty({type: Hotel})
    public hotel: Hotel;
}

@Injectable()
export class EmployeesResolver implements Resolve<EmployeesData> {

    public constructor(
        protected httpClient: HttpClient
    ) {
    }

    public resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<EmployeesData> {
        let hotel: Hotel = mergeRouteData(route).component.hotel;
        return this.httpClient.get<EmployeesData>('/v2/hotels/' + hotel.hotelId + '/employees')
            .pipe(map(data => ObjectMapper.deserialize(EmployeesData, data)));
    }

}

@Component({
    selector: 'hotel-employees-action',
    templateUrl: './employees-action.component.html'
})
export class EmployeesActionComponent implements OnInit {
    public hotel: Hotel;

    public constructor(
        protected route: ActivatedRoute
    ) {
    }

    public ngOnInit(): void {
        this.route.data.subscribe((data) => {
            this.hotel = data.component.hotel;
        });
    }
}

@Component({
    selector: 'hotel-employees',
    templateUrl: './employees.component.html'
})
export class EmployeesComponent implements OnInit, OnDestroy {
    public hotel: Hotel;
    public employees: Employee[] = [];
    public currentEmployeeId: number;

    public constructor(
        protected route: ActivatedRoute,
        protected layoutTitleService: LayoutTitleService,
        protected breadcrumbService: BreadcrumbService,
        protected hotelSidebarService: HotelSidebarService,
        protected authService: AuthService
    ) {
    }

    public ngOnInit(): void {
        this.currentEmployeeId = this.authService.getUser().employeeId;
        this.route.data.subscribe((data) => {
            this.hotel = data.component.hotel;
            this.employees = data.component.employees;
            this.layoutTitleService.title = 'Сотрудники';
            this.breadcrumbService.breadcrumbs = [
                {name: 'Отели', link: '/hotels'},
                {name: this.hotel.name, link: ['/hotels', this.hotel.alias]},
                {name: 'Сотрудники'}
            ];
            this.hotelSidebarService.setHotel(this.hotel);
        });
    }

    public ngOnDestroy(): void {
        this.hotelSidebarService.clearHotel();
    }

    public trackByEmployee(index: number, employee: Employee): number {
        return employee.employeeId;
    }

}
