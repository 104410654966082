<ul class="nav metismenu" #metisMenu>
    <li class="active">
        <a [routerLink]="['/hotels', hotel.alias]" aria-disabled="true">
            <i class="fas fa-bed"></i>
            <span class="nav-label">
                {{ hotel.name }}
            </span>
        </a>
        <ul class="nav nav-second-level">
            <li routerLinkActive="active">
                <a [routerLink]="['/hotels', hotel.alias, 'rooms', 'categories']">
                    Категории номеров
                </a>
            </li>
            <li [class.active]="isRoomsActive()">
                <a [routerLink]="['/hotels', hotel.alias, 'rooms']">
                    Номера
                </a>
            </li>
            <li routerLinkActive="active">
                <a [routerLink]="['/hotels', hotel.alias, 'prices']">
                    Цены
                </a>
            </li>
            <li routerLinkActive="active">
                <a [routerLink]="['/hotels', hotel.alias, 'employees']">Сотрудники</a>
            </li>
        </ul>
    </li>
</ul>
