<div class="ibox-content">
    <div class="table-responsive">
        <table class="table table-hover">
            <thead>
            <tr>
                <th>ID</th>
                <th>Название</th>
                <th>Тип</th>
                <th>Кол-во гостей</th>
                <th>Цена</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let category of categories; trackBy: trackByCategory">
                <th [class.del]="category.isDisabled()">
                    <a [routerLink]="['/hotels', hotel.alias, 'rooms', 'categories', category.roomCategoryId]">
                        {{ category.roomCategoryId }}
                    </a>
                </th>
                <td [class.del]="category.isDisabled()">
                    <i *ngIf="category.isEnabled()" class="far fa-check-circle text-navy m-r"
                       title="Категория включена"></i>
                    <i *ngIf="category.isDisabled()" class="fas fa-ban text-danger m-r"
                       title="Категория заблокирована"></i>
                    <a [routerLink]="['/hotels', hotel.alias, 'rooms', 'categories', category.roomCategoryId]">
                        {{ category.name }}
                    </a>
                </td>
                <td>
                    <span *ngIf="category.type == RoomCategoryType.Standart">Стандартный номер</span>
                    <span *ngIf="category.type == RoomCategoryType.Shared">Общий номер</span>
                    <span *ngIf="category.type == RoomCategoryType.SharedMale">Общий мужской номер</span>
                    <span *ngIf="category.type == RoomCategoryType.SharedFemale">Общий женский номер</span>
                </td>
                <td>
                    {{ category.capacity.guests }}
                    <small *ngIf="category.capacity.additional">(+{{ category.capacity.additional }})</small>
                </td>
                <td *ngIf="category.price && category.price.value">
                    {{ category.price.value / 100 }}₽
                    <ng-template [ngIf]="category.price.additional">
                        / {{ category.price.additional / 100 }}₽
                    </ng-template>
                </td>
                <td *ngIf="!category.price || !category.price.value">
                    <i>не указана</i>
                </td>
            </tr>
            <tr *ngIf="categories.length == 0">
                <td colspan="5" class="text-center">
                    Категории номеров не найдены
                    <a *canEditHotelRooms="hotel" class="m-l"
                       [routerLink]="['/hotels', hotel.alias, 'rooms', 'categories', 'new']">(добавить новую)</a>
                </td>
            </tr>
            </tbody>
        </table>
    </div>
</div>
