import { Component, Injectable, OnInit } from "@angular/core";
import { ActivatedRoute, ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot } from "@angular/router";
import { Country } from "../country";
import { HttpClient } from "@angular/common/http";
import { JsonProperty, ObjectMapper } from "json-object-mapper";
import { CountryForm } from "./country.form";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { TitleService as LayoutTitleService } from "layout/title.service";
import { BreadcrumbService } from "layout/breadcrumb.service";

export class EditCountryData {
    @JsonProperty({type: Country})
    public country: Country;
}

@Injectable()
export class EditCountryResolver implements Resolve<EditCountryData> {

    public constructor(
        protected httpClient: HttpClient
    ) {
    }

    public resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<EditCountryData> {
        return this.httpClient.get<EditCountryData>("/v2/locations/countries/" + route.params.alias)
            .pipe(map(data => ObjectMapper.deserialize(EditCountryData, data)));
    }

}

@Component({
    selector: 'location-country-edit',
    templateUrl: 'edit-country.component.html'
})
export class EditCountryComponent implements OnInit {

    public country: Country;

    public countryForm: CountryForm = new CountryForm(this.httpClient);

    public constructor(
        protected route: ActivatedRoute,
        protected httpClient: HttpClient,
        protected router: Router,
        protected layoutTitleService: LayoutTitleService,
        protected breadcrumbService: BreadcrumbService
    ) {
    }

    public ngOnInit(): void {
        this.route.data.subscribe((data) => {
            this.country = data.component.country;
            this.countryForm = new CountryForm(this.httpClient, this.country);
            this.layoutTitleService.title = this.country.name;
            this.layoutTitleService.small = 'Редактирование страны';
            this.breadcrumbService.breadcrumbs = [
                {name: 'Страны', link: '/locations/countries'},
                {name: this.country.name, link: ['/locations/countries', this.country.countryId]},
                {name: 'Редактирование'}
            ];
            this.countryForm.get('name').valueChanges.subscribe((value) => {
                this.layoutTitleService.title = value || 'Страна';
                this.breadcrumbService.breadcrumbs = [
                    {name: 'Страны', link: '/locations/countries'},
                    {name: value || 'Страна', link: ['/locations/countries', this.country.countryId]},
                    {name: 'Редактирование'}
                ];
            });
        });
    }

    public onSubmit(): void {
        this.countryForm.disable();
        this.httpClient.put<{ country: any }>('/v2/locations/countries/' + this.country.countryId,
            ObjectMapper.serialize({country: this.countryForm.getCountry()})
        ).toPromise().then((data) => {
            let country = ObjectMapper.deserialize(Country, data.country);
            this.router.navigate(['/locations/countries', country.alias]);
        }).catch((error: any) => {
            console.error("Save country error:", error);
            this.countryForm.enable();
            throw error;
        });
    }

}
