<div class="ibox-content">
    <form [formGroup]="employeeForm" (ngSubmit)="onSubmit()">
        <div *ngIf="employeeForm.controls.company" class="form-group row"
             [class.has-error]="employeeForm.controls.company.value && !employeeForm.controls.company.disabled && !employeeForm.controls.company.valid">
            <label class="col-2 col-form-label" for="form-company-employee-company">Компания:</label>
            <div class="col-10">
                <select class="form-control" id="form-company-employee-company"
                        formControlName="company">
                    <option *ngFor="let company of companies; trackBy: trackByCompany" [value]="company.companyId">
                        {{ company.name }}
                    </option>
                </select>
            </div>
        </div>
        <employee-form [employeeForm]="employeeForm" [cities]="cities"></employee-form>
        <div *ngIf="currentCompany">
            <hr class="hr-line-dashed">
            <h2>Права доступа</h2>
            <employee-permissions-form *ngIf="employeePermissionsForm" [permissionsForm]="employeePermissionsForm"></employee-permissions-form>
            <management-employee-permissions-form
                    *ngIf="currentCompany.isManagementCompany()"
                    [permissionsForm]="managementPermissionsForm"
            ></management-employee-permissions-form>
            <franchisee-employee-permissions-form
                    *ngIf="currentCompany.isFranchiseeCompany()"
                    [permissionsForm]="franchiseePermissionsForm"
            ></franchisee-employee-permissions-form>
            <hr class="hr-line-dashed">
        </div>
        <div class="form-group row">
            <div class="col-10 offset-2">
                <a class="btn btn-white" routerLink="/employees">Отмена</a>
                &nbsp;
                <button class="btn btn-primary" type="submit" [disabled]="!employeeForm.valid">Сохранить</button>
            </div>
        </div>
    </form>
</div>
