import { Injectable } from "@angular/core";
import { Hotel } from "hotel/hotel";
import { Employee, ManagementEmployeePermissions } from "company/employee";
import { AuthService } from "./auth/auth.service";
import { FranchiseeEmployeeHotelPermissions, FranchiseeEmployeePermissions } from "company/employee";
import { User } from "./user";
import { Company } from "company/company";
import { Vacancy } from "vacancy/vacancy";

@Injectable()
export class PermissionsService {

    public constructor(
        protected authService: AuthService
    ) {
    }

    public canEditHotel(hotel: Hotel | string, employee?: Employee): boolean {
        if (!hotel) {
            return false;
        }
        if (!employee && !this.authService.isAuthenticated()) {
            return false;
        }
        let user = this.getUser(employee);
        if (user.company.isManagementCompany()) {
            return true;
        }
        return false;
    }

    public canEditRooms(hotel: Hotel | string, employee?: Employee): boolean {
        if (!hotel) {
            return false;
        }
        if (!employee && !this.authService.isAuthenticated()) {
            return false;
        }
        let user = this.getUser(employee);
        if (user.company.isManagementCompany()) {
            return true;
        }
        if (user.company.isFranchiseeCompany()) {
            if (employee && typeof hotel == 'string') {
                throw new Error("Can not get hotel by alias for non-authenticated employee");
            }
            return this.getFranchiseeEmployeeHotelPermissions(user, hotel).canEditRooms;
        }
        return false;
    }

    public canEditEmployees(hotel: Hotel | string, employee?: Employee): boolean {
        if (!hotel) {
            return false;
        }
        if (!employee && !this.authService.isAuthenticated()) {
            return false;
        }
        let user = this.getUser(employee);
        if (user.company.isManagementCompany()) {
            return true;
        }
        if (user.company.isFranchiseeCompany()) {
            if (employee && typeof hotel == 'string') {
                throw new Error("Can not get hotel by alias for non-authenticated employee");
            }
            return this.getFranchiseeEmployeeHotelPermissions(user, hotel).canEditEmployees;
        }
        return false;
    }

    public canEditEmployee(employee: Employee, checkEmployee?: Employee): boolean {
        if (!employee) {
            return false;
        }
        if (!checkEmployee && !this.authService.isAuthenticated()) {
            return false;
        }
        let user = this.getUser(checkEmployee);
        if (user.company.isManagementCompany()) {
            if (employee.company.companyId == user.company.companyId) {
                return user.permissions.canEditEmployees;
            }
            return true;
        }
        if (user.company.isFranchiseeCompany()) {
            return this.isFranchiseeUserCanEditEmployee(employee, user);
        }
        return false;
    }

    protected isFranchiseeUserCanEditEmployee(employee: Employee, user: User): boolean {
        if (employee.companyId != user.companyId) {
            return false;
        }
        let userPermissions = user.permissions as FranchiseeEmployeePermissions;
        if (userPermissions.canEditEmployees || userPermissions.allHotels.canEditEmployees) {
            return true;
        }
        let employeePermissions = employee.permissions as FranchiseeEmployeePermissions;
        for (let employeeHotelPermissions of employeePermissions.hotels) {
            if (!employeeHotelPermissions.permissions.hasAccess()) {
                continue;
            }
            if (userPermissions.hotel(employeeHotelPermissions.hotelId).canEditEmployees) {
                return true;
            }
        }
        return false;
    }

    public canEditPrices(hotel: Hotel | string, employee?: Employee): boolean {
        if (!hotel) {
            return false;
        }
        if (!employee && !this.authService.isAuthenticated()) {
            return false;
        }
        let user = this.getUser(employee);
        if (user.company.isManagementCompany()) {
            return true;
        }
        if (user.company.isFranchiseeCompany()) {
            if (employee && typeof hotel == 'string') {
                throw new Error("Can not get hotel by alias for non-authenticated employee");
            }
            return this.getFranchiseeEmployeeHotelPermissions(user, hotel).canEditPrices;
        }
        return false;
    }

    protected getFranchiseeEmployeeHotelPermissions(user: User, hotel: Hotel | string): FranchiseeEmployeeHotelPermissions {
        if (typeof hotel == 'string') {
            hotel = user.getHotel(hotel);
            if (!hotel) {
                return null;
            }
        }
        if (user.permissions.type != 'franchisee') {
            return null;
        }
        let permissions = user.permissions as FranchiseeEmployeePermissions;
        return permissions.hotel(hotel);
    }

    protected getUser(employee?: Employee): User {
        if (!employee) {
            return this.authService.getUser();
        } else if (employee instanceof User) {
            return employee;
        } else {
            return new User(employee);
        }
    }

    public canEditVacancies(company: Company, employee?: Employee): boolean;
    public canEditVacancies(hotel: Hotel, employee?: Employee): boolean;
    public canEditVacancies(item: Company | Hotel, employee?: Employee): boolean {
        if (!item) {
            return false;
        }
        if (!employee && !this.authService.isAuthenticated()) {
            return false;
        }
        if (item instanceof Hotel && !item.company) {
            return false;
        }
        let company: Company = item instanceof Company ? item : item.company;
        let user = this.getUser(employee);
        if (user.company.isManagementCompany()) {
            if (item instanceof Hotel) {
                return true;
            }
            if (company.companyId == user.company.companyId) {
                return user.permissions.canEditVacancies;
            } else {
                return true;
            }
        }
        if (user.company.isFranchiseeCompany()) {
            if (company.companyId != user.company.companyId) {
                return false;
            }
            if (item instanceof Company) {
                return user.permissions.canEditVacancies;
            }
            if (item instanceof Hotel) {
                let permissions = user.permissions as FranchiseeEmployeePermissions;
                return permissions.hotel(item).canEditVacancies;
            }
            return false;
        }
        return false;
    }

    public canDeleteVacancy(vacancy: Vacancy, employee?: Employee): boolean {
        if (!employee && !this.authService.isAuthenticated()) {
            return false;
        }
        let user = this.getUser(employee);
        if (user.company.isManagementCompany()) {
            return (user.permissions as ManagementEmployeePermissions).canDeleteAllObjects;
        }
        if (user.company.isFranchiseeCompany()) {
            return false;
        }
        return false;
    }

}
