<div class="ibox">
    <div class="ibox-content">
        <div class="m-b-md">
            <ng-template [canEditHotelPrices]="hotel">
                <a *ngIf="!price.isDeleted()" [routerLink]="['/hotels', hotel.alias, 'prices', price.priceId, 'edit']"
                   class="btn btn-white btn-xs float-right">
                    Редактировать
                </a>
                <button *ngIf="price.isDisabled()" (click)="onDelete($event.target)"
                        type="button" [disabled]="disableButtons"
                        class="btn btn-danger btn-xs float-right m-r-sm ladda-button" data-style="slide-right">
                    Удалить
                </button>
                <button *ngIf="price.isEnabled()" (click)="onDisable($event.target)"
                        type="button" [disabled]="disableButtons"
                        class="btn btn-warning btn-xs float-right m-r-sm ladda-button" data-style="slide-right">
                    Отключить
                </button>
                <button *ngIf="price.isDisabled()" (click)="onEnable($event.target)"
                        type="button" [disabled]="disableButtons"
                        class="btn btn-success btn-xs float-right m-r-sm ladda-button" data-style="slide-right">
                    Включить
                </button>
            </ng-template>
            <h2>{{ price.name }}</h2>
        </div>
        <dl class="row mb-0">
            <dt class="col-2">Срок действия:</dt>
            <dd class="col-10">
                С {{ localDate(price.availableFrom) }}
                <ng-template [ngIf]="price.availableTo">
                    по {{ localDate(price.availableTo) }}
                </ng-template>
                <ng-template [ngIf]="!price.availableTo">
                    <i>(бессрочно)</i>
                </ng-template>
            </dd>
            <dt class="col-2">Статус:</dt>
            <dd class="col-10">
                <span *ngIf="price.isEnabled()" class="label label-primary">Активный</span>
                <span *ngIf="price.isDisabled()" class="label label-warning">Заблокирован</span>
                <span *ngIf="price.isDeleted()" class="label label-danger">Удалён</span>
            </dd>
        </dl>
        <table class="table table-hover">
            <thead>
            <tr>
                <th width="30%">Категория</th>
                <th rowspan="2">Цена</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let category of categories; trackBy: trackByCategory">
                <th>
                    {{ category.name }}<br>
                    <small *ngIf="category.type == RoomCategoryType.Standart" class="text-muted">
                        (стандартный номер)
                    </small>
                    <small *ngIf="category.type == RoomCategoryType.Shared" class="text-muted">
                        (общий номер)
                    </small>
                    <small *ngIf="category.type == RoomCategoryType.SharedMale" class="text-muted">
                        (общий мужской номер)
                    </small>
                    <small *ngIf="category.type == RoomCategoryType.SharedFemale" class="text-muted">
                        (общий женский номер)
                    </small>
                </th>
                <td>
                    <ng-template [ngIf]="getPriceValue(category) > 0">
                        {{ getPriceValue(category) / 100 }}₽
                    </ng-template>
                    <ng-template [ngIf]="getPriceValue(category) <= 0">
                        <span class="text-danger">не указана</span>
                    </ng-template>
                    <br>
                    <small *ngIf="category.type == RoomCategoryType.Standart" class="text-muted">
                        стоимость всего номера в сутки
                        (1 - {{ category.capacity.guests }} гостя)
                    </small>
                    <small *ngIf="category.type != RoomCategoryType.Standart" class="text-muted">
                        стоимость одного места в сутки
                        (всего мест: {{ category.capacity.guests }})
                    </small>
                </td>
                <td>
                    <ng-template
                            [ngIf]="category.type == RoomCategoryType.Standart && category.capacity.additional > 0">
                        <ng-template [ngIf]="getPriceAdditional(category) > 0">
                            {{ getPriceAdditional(category) / 100 }}₽
                        </ng-template>
                        <ng-template [ngIf]="getPriceAdditional(category) <= 0">
                            <span class="text-danger">не указана</span>
                        </ng-template>
                        <br>
                        <small class="text-muted">
                            стоимость одного доп. места (всего мест: {{ category.capacity.additional }})
                        </small>
                    </ng-template>
                </td>
            </tr>
            </tbody>
        </table>

        <h2 class="text-muted text-right">#{{ price.priceId }}</h2>
    </div>
</div>
