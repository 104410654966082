<ng-template [ngIf]="employee.company.isManagementCompany() || employee.company.isFranchiseeCompany() && viewAllHotelsPermissions">
    <h3>Управление компанией</h3>
    <dl class="row">
        <dt class="col-3">Управление сотрудниками</dt>
        <dd class="col-9">
            <span *ngIf="employee.permissions.canEditEmployees" class="label label-primary">разрешено</span>
            <span *ngIf="!employee.permissions.canEditEmployees" class="label label-default">запрещено</span>
        </dd>
        <dt class="col-3">Управление вакансиям</dt>
        <dd class="col-9">
            <span *ngIf="employee.permissions.canEditVacancies" class="label label-primary">разрешено</span>
            <span *ngIf="!employee.permissions.canEditVacancies" class="label label-default">запрещено</span>
        </dd>
    </dl>
</ng-template>

<management-employee-permissions-view
        *ngIf="employee.company.isManagementCompany()"
        [employee]="employee"></management-employee-permissions-view>
<franchisee-employee-permissions-view
        *ngIf="employee.company.isFranchiseeCompany()"
        [employee]="employee" [hotels]="hotels"
        [viewAllHotelsPermissions]="viewAllHotelsPermissions"></franchisee-employee-permissions-view>
