import { Component, OnInit, ViewChild, ViewContainerRef } from "@angular/core";

@Component({
    selector: 'app-root',
    template: '<router-outlet #routerOutlet></router-outlet>'
})
export class AppComponent implements OnInit {

    @ViewChild('routerOutlet', {read: ViewContainerRef})
    public routerOutlet: ViewContainerRef;

    public ngOnInit(): void {
        jQuery('body').removeClass('gray-bg');
    }

}
