<div class="ibox-content">
    <div class="table-responsive">
        <table class="table table-hover">
            <thead>
            <tr>
                <th>ID</th>
                <th>Название</th>
                <th>Город</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let company of companies; trackBy: trackByCompany">
                <th [class.del]="company.isDisabled()">
                    <a [routerLink]="['/companies', company.companyId]">{{ company.companyId }}</a>
                </th>
                <td [class.del]="company.isDisabled()">
                    <i *ngIf="company.isEnabled()" class="far fa-check-circle text-navy m-r"
                       title="Компания включена"></i>
                    <i *ngIf="company.isDisabled()" class="fas fa-ban text-danger m-r" title="Компания отключена"></i>
                    <a [routerLink]="['/companies', company.companyId]" class="m-r">{{ company.name }}</a>
                    <small *ngIf="company.isFranchiseeCompany()">(франчайзи)</small>
                    <small *ngIf="company.isManagementCompany()">(управляющая компания)</small>
                </td>
                <td>{{ company.city.name }}</td>
            </tr>
            </tbody>
        </table>
    </div>
</div>
