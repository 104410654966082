<fieldset [formGroup]="categoryForm">
    <div class="form-group row"
         [class.has-error]="!categoryForm.controls.name.disabled && categoryForm.controls.name.value && !categoryForm.controls.name.valid">
        <label class="col-2 col-form-label" for="form-hotel-room-category-name">Название:</label>
        <div class="col-10">
            <input type="text" class="form-control"
                   id="form-hotel-room-category-name" formControlName="name">
        </div>
    </div>
    <div class="form-group row"
         [class.has-error]="!categoryForm.controls.capacityGuests.disabled && categoryForm.controls.capacityGuests.value && !categoryForm.controls.capacityGuests.valid">
        <label class="col-2 col-form-label" for="form-hotel-room-category-capacity-guests">Кол-во гостей:</label>
        <div class="col-10">
            <input type="number" class="form-control" min="1" max="100" step="1"
                   id="form-hotel-room-category-capacity-guests" formControlName="capacityGuests">
        </div>
    </div>
    <div *ngIf="isStandartRoom()" class="form-group row"
         [class.has-error]="!categoryForm.controls.capacityAdditional.disabled && categoryForm.controls.capacityAdditional.value && !categoryForm.controls.capacityAdditional.valid">
        <label class="col-2 col-form-label" for="form-hotel-room-category-capacity-additional">Доп. места:</label>
        <div class="col-10">
            <input type="number" class="form-control" min="0" max="100" step="1"
                   id="form-hotel-room-category-capacity-additional" formControlName="capacityAdditional">
        </div>
    </div>
</fieldset>
