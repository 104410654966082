import { Component, ElementRef, NgZone, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { HttpClient, HttpResponse } from "@angular/common/http";
import { Router } from "@angular/router";
import { AbstractControl, FormControl, FormGroup, ValidationErrors, Validators } from "@angular/forms";
import { animate, state, style, transition, trigger } from "@angular/animations";
import { User } from "app/user";
import { AuthService } from "app/auth/auth.service";
import './login.component.scss';
import { Employee } from "company/employee";
import { JsonProperty, ObjectMapper } from "json-object-mapper";
import { SweetalertService } from "app/sweetalert.service";
import { Hotel } from "hotel/hotel";

export class AuthResponse {
    public readonly status: 'success' | 'error';

    @JsonProperty({type: Employee})
    public readonly employee: Employee;

    @JsonProperty({type: Hotel, required: false})
    public readonly hotels: Hotel[];

}

export class LoginForm extends FormGroup {
    public constructor() {
        super({
            username: new FormControl(),
            password: new FormControl(),
            rememberme: new FormControl(false)
        });
        this.get('username').setValidators([Validators.required]);
        this.get('password').setValidators([
            Validators.required,
            this.passwordValidator()
        ]);
    }

    public passwordValidator(): (control: AbstractControl) => ValidationErrors | null {
        return (control) => {
            let value: string = control.value;
            if (value && value.trim().length != value.length) {
                return {trimError: true};
            }
            return null;
        };
    }

}

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    animations: [
        trigger('showLoginErrorTrigger', [
            state('true', style({opacity: 1})),
            state('false', style({opacity: 0})),
            transition('true => false', animate('600ms')),
            transition('false => true', animate('300ms')),
        ])
    ]
})
export class LoginComponent implements OnInit, OnDestroy {
    public appVersion = APP_VERSION;
    public showLoginError: boolean = false;
    public loginFormValueChanged: boolean = false; // Fix form validation for password autofill
    public loginForm = new LoginForm();
    public warning: string | null = null;

    @ViewChild("passwordControl")
    public passwordControl: ElementRef<HTMLInputElement>;

    public constructor(
        protected httpClient: HttpClient,
        protected authService: AuthService,
        protected router: Router,
        protected title: Title,
        protected sweetalertService: SweetalertService,
        protected ngZone: NgZone
    ) {
    }

    public ngOnInit(): void {
        jQuery('body').addClass('gray-bg');
        this.title.setTitle('Вход в ERP | Лайк Хостел ERP');
        this.loginForm.valueChanges.subscribe(() => {
            this.showLoginError = false;
            this.loginFormValueChanged = true;
        });
        this.ngZone.runOutsideAngular(() => {
            jQuery(this.passwordControl.nativeElement).on('blur', () => {
                let value = this.loginForm.get('password').value;
                if (value && value.trim().length != value.length) {
                    this.ngZone.run(() => {
                        this.loginForm.get('password').setValue(value.trim());
                    });
                }
            });
        });
    }

    public ngOnDestroy(): void {
        jQuery('body').removeClass('gray-bg');
    }

    public onSubmit(): void {
        if (!this.loginForm.get('username').value || !this.loginForm.get('password').value) {
            this.loginFormValueChanged = true;
            return;
        }
        this.loginForm.disable();
        this.showLoginError = false;
        if (this.loginForm.get('username').value.includes('@')) {
            this.loginByEmail();
        } else {
            this.loginByUsername();
        }
    }

    protected loginByEmail(): void {
        this.httpClient.post<HttpResponse<AuthResponse>>('/v2/auth/email', {
            email: this.loginForm.get('username').value,
            password: this.loginForm.get('password').value,
            rememberme: this.loginForm.get('rememberme').value
        }, {observe: 'response'}).toPromise()
            .then((response) => {
                let body = ObjectMapper.deserialize(AuthResponse, response.body);
                this.authService.authenticateUser(
                    response.headers.get('X-Auth-Token'),
                    new User(body.employee, body.hotels),
                    this.loginForm.get('rememberme').value
                );
                this.router.navigate(['/']);
            })
            .catch(() => {
                this.loginForm.get('password').setValue(null);
                this.loginForm.enable();
                this.showLoginError = true;
            });
    }

    protected loginByUsername(): void {
        this.httpClient.post<HttpResponse<any>>('/v2/auth/username', {
            username: this.loginForm.get('username').value,
            password: this.loginForm.get('password').value
        }, {observe: 'response'}).toPromise()
            .then((response) => {
                let email = (response.body as any).email;
                this.sweetalertService.alert({
                    icon: "warning",
                    text: "В связи с переходом на новую ERP вход по имени пользователя больше не поддерживается.\n\n" +
                    "Теперь Вы можете войти в систему указав Ваш email, пароль остаётся прежний.\n\n" +
                    "Ваш email: " + email
                });
                this.loginForm.get('username').setValue(null);
                this.loginForm.get('password').setValue(null);
                this.loginForm.enable();
            })
            .catch(() => {
                this.loginForm.get('password').setValue(null);
                this.loginForm.enable();
                this.showLoginError = true;
            });
    }

}
