import { Component, Injectable, OnInit } from "@angular/core";
import { EmployeeForm } from "./employee.form";
import { HttpClient } from "@angular/common/http";
import { Employee, FranchiseeEmployeePermissions } from "../employee";
import { ActivatedRoute, ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot } from "@angular/router";
import { FormControl, Validators } from "@angular/forms";
import { Observable, forkJoin } from "rxjs";
import { Company } from "../company";
import { JsonProperty, ObjectMapper } from "json-object-mapper";
import { map } from "rxjs/operators";
import { TitleService as LayoutTitleService } from "layout/title.service";
import { BreadcrumbService } from "layout/breadcrumb.service";
import { City } from "location/city";
import { AuthService } from "app/auth/auth.service";
import {
    EmployeePermissionsForm,
    FranchiseeEmployeePermissionsForm,
    ManagementEmployeePermissionsForm
} from "./employee-permissions.form";
import { Hotel } from "hotel/hotel";

export class NewEmployeeData {
    @JsonProperty({type: Company, required: false})
    public companies?: Company[];

    @JsonProperty({type: City})
    public cities: City[];
}

@Injectable()
export class NewEmployeeResolver implements Resolve<NewEmployeeData> {

    public constructor(
        protected httpClient: HttpClient,
        protected authService: AuthService
    ) {
    }

    public resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<NewEmployeeData> {
        if (this.authService.getUser().company.isManagementCompany()) {
            return forkJoin(
                this.httpClient.get('/v2/companies'),
                this.httpClient.get("/v2/locations/cities")
            ).pipe(map(data => ObjectMapper.deserialize(NewEmployeeData, Object.assign({}, data[0], data[1]))));
        }
        if (this.authService.getUser().company.isFranchiseeCompany()) {
            return this.httpClient.get("/v2/locations/cities")
                .pipe(map(data => ObjectMapper.deserialize(NewEmployeeData, data)));
        }
        return null;
    }

}

export class EmployeeWithCompanyForm extends EmployeeForm {

    public constructor(httpClient: HttpClient) {
        super(httpClient);
        this.addControl('company', new FormControl(null, [Validators.required]));
    }

    public getEmployee(): Employee {
        let employee = super.getEmployee();
        employee.companyId = this.get('company').value;
        return employee;
    }

}

@Component({
    selector: 'company-employee-new',
    templateUrl: './new-employee.component.html'
})
export class NewEmployeeComponent implements OnInit {
    public employeeForm: EmployeeForm;
    public companies: Company[] = [];
    public cities: City[];
    public currentCompany: Company;
    public managementPermissionsForm: ManagementEmployeePermissionsForm = new ManagementEmployeePermissionsForm();
    public franchiseePermissionsForm: FranchiseeEmployeePermissionsForm = new FranchiseeEmployeePermissionsForm([]);
    public employeePermissionsForm: EmployeePermissionsForm;

    public constructor(
        protected route: ActivatedRoute,
        protected httpClient: HttpClient,
        protected router: Router,
        protected layoutTitleService: LayoutTitleService,
        protected breadcrumbService: BreadcrumbService,
        protected authService: AuthService
    ) {
    }

    public ngOnInit(): void {
        this.layoutTitleService.title = 'Новый сотрудник';
        this.breadcrumbService.breadcrumbs = [
            {name: 'Сотрудники', link: '/employees'},
            {name: 'Новый сотрудник'}
        ];
        if (this.authService.getUser().company.isManagementCompany()) {
            this.employeeForm = new EmployeeWithCompanyForm(this.httpClient);
            this.route.data.subscribe((data) => {
                if (this.authService.getUser().permissions.canEditEmployees) {
                    this.companies = data.component.companies;
                } else {
                    this.companies = data.component.companies.filter((company: Company) => company.companyId != this.authService.getUser().company.companyId);
                }
                this.cities = data.component.cities;
            });
            let lastCompanyId: number = null;
            this.employeeForm.get('company').valueChanges.subscribe((value) => {
                this.onCompanyChanged(lastCompanyId, value);
                lastCompanyId = value;
            });
        }
        if (this.authService.getUser().company.isFranchiseeCompany()) {
            let company = this.authService.getUser().company;
            this.employeeForm = new EmployeeForm(this.httpClient, null, true);
            if (this.authService.getUser().permissions.canEditEmployees) {
                this.employeePermissionsForm = new EmployeePermissionsForm();
            }
            this.employeeForm.get('city').setValue(company.city.cityId);
            this.employeeForm.get('timezone').setValue(company.city.timezone);
            this.route.data.subscribe((data) => {
                this.cities = data.component.cities;
            });
            this.currentCompany = company;
            let authUserPermissions = this.authService.getUser().permissions as FranchiseeEmployeePermissions;
            let hotels = this.authService.getUser().hotels;
            if (!authUserPermissions.allHotels.canEditEmployees) {
                hotels = hotels.filter(hotel => {
                    return authUserPermissions.hotel(hotel).canEditEmployees;
                });
            }
            this.franchiseePermissionsForm = new FranchiseeEmployeePermissionsForm(
                hotels, null, authUserPermissions.allHotels.canEditEmployees
            );
        }
    }

    public onSubmit(): void {
        this.employeeForm.disable();
        let companyId = this.authService.getUser().company.isManagementCompany() ? this.employeeForm.get('company').value : this.authService.getUser().company.companyId;
        let employee = this.employeeForm.getEmployee();
        employee.company = this.authService.getUser().company.isManagementCompany() ? this.getCompany(employee.companyId) : this.authService.getUser().company;
        if (employee.company.isManagementCompany()) {
            employee.permissions = this.managementPermissionsForm.getPermissions();
        }
        if (employee.company.isFranchiseeCompany()) {
            employee.permissions = this.franchiseePermissionsForm.getPermissions();
        }
        if (this.employeePermissionsForm) {
            let employeePermissions = this.employeePermissionsForm.getPermissions();
            employee.permissions.canEditEmployees = employeePermissions.canEditEmployees;
            employee.permissions.canEditVacancies = employeePermissions.canEditVacancies;
        }
        this.httpClient.post<{
            employee: any, company: any
        }>('/v2/companies/' + companyId + '/employees', ObjectMapper.serialize({
            employee: employee
        })).toPromise().then((data) => {
            let employee = ObjectMapper.deserialize(Employee, data.employee);
            employee.company = ObjectMapper.deserialize(Company, data.company);
            if (this.authService.getUser().company.isManagementCompany()) {
                this.router.navigate(['/companies', employee.company.companyId, 'employees', employee.employeeId]);
            }
            if (this.authService.getUser().company.isFranchiseeCompany()) {
                this.router.navigate(['/employees', employee.employeeId]);
            }
        }).catch((error: any) => {
            console.error("Create employee error:", error);
            this.employeeForm.enable();
            throw error;
        });
    }

    public trackByCompany(index: number, company: Company): number {
        return company.companyId;
    }

    protected getCompany(companyId: number): Company | null {
        for (let company of this.companies) {
            if (company.companyId == companyId) {
                return company;
            }
        }
        return null;
    }

    protected onCompanyChanged(oldId: number, newId: number): void {
        if (this.employeeForm.enabled) {
            if (!oldId || this.getCompany(oldId).city.cityId == this.employeeForm.get('city').value) {
                this.employeeForm.get('city').setValue(this.getCompany(newId).city.cityId);
            }
            this.currentCompany = this.getCompany(newId);
            this.franchiseePermissionsForm = new FranchiseeEmployeePermissionsForm([], this.franchiseePermissionsForm.getPermissions());
            this.httpClient.get<{ hotels: any }>("/v2/companies/" + newId + "/hotels").subscribe((data) => {
                this.franchiseePermissionsForm = new FranchiseeEmployeePermissionsForm(
                    ObjectMapper.deserializeArray(Hotel, data.hotels),
                    this.franchiseePermissionsForm.getPermissions()
                );
            });
            if (this.authService.getUser().company.companyId != this.currentCompany.companyId || this.authService.getUser().permissions.canEditEmployees) {
                this.employeePermissionsForm = new EmployeePermissionsForm();
            } else {
                this.employeePermissionsForm = null;
            }
        }
    }

}
