import { AbstractControl, FormControl, FormGroup, ValidationErrors, Validators } from "@angular/forms";
import { RoomCategory, RoomCategoryType } from "../room-category";

export class RoomCategoryForm extends FormGroup {

    public constructor(roomCategory?: RoomCategory) {
        super({
            name: new FormControl(roomCategory ? roomCategory.name : null, [
                Validators.required,
                Validators.pattern(/^[a-zA-Zа-яёА-ЯЁ0-9 \-+_."/\\&():;]+$/),
                Validators.minLength(1),
                Validators.maxLength(50)
            ]),
            capacityGuests: new FormControl(roomCategory ? roomCategory.capacity.guests : 1, [
                Validators.required,
                Validators.min(1),
                Validators.max(100)
            ]),
            capacityAdditional: new FormControl(roomCategory ? roomCategory.capacity.additional : 0)
        });
        this.get('capacityAdditional').setValidators([
            Validators.min(0),
            Validators.max(100),
            this.capacityAdditionalValidator(roomCategory)
        ]);
    }

    public capacityAdditionalValidator(roomCategory?: RoomCategory): (control: AbstractControl) => ValidationErrors | null {
        return (control) => {
            let type: RoomCategoryType = roomCategory ? roomCategory.type : null;
            if (!type || type == RoomCategoryType.Standart && !control.value && control.value !== 0) {
                return {required: true};
            }
            return null;
        };
    }

    public getRoomCategory(): RoomCategory {
        let roomCategory = new RoomCategory();
        roomCategory.name = this.get('name').value;
        roomCategory.capacity.guests = this.get("capacityGuests").value;
        roomCategory.capacity.additional = this.get("capacityAdditional").value;
        return roomCategory;
    }

}
