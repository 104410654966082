import { NgModule } from "@angular/core";
import { LayoutComponent } from "./layout.component";
import { RouterModule } from "@angular/router";
import { SidebarComponent } from "./sidebar/sidebar.component";
import { TopbarComponent } from "./topbar.component";
import { TitleService } from "./title.service";
import { CommonModule } from "@angular/common";
import { BreadcrumbService } from "./breadcrumb.service";
import { BreadcrumbComponent } from "./breadcrumb.component";
import { TitleComponent } from "./title.component";
import { LayoutService } from "./layout.service";
import { HeadingComponent } from "./heading.component";
import { HotelSidebarComponent } from "./sidebar/hotel.component";
import { HotelSidebarService } from "./sidebar/hotel.service";
import { ManagementSidebarComponent } from "./sidebar/management.component";
import { FranchiseeSidebarComponent } from "./sidebar/franchisee.component";

@NgModule({
    imports: [
        RouterModule,
        CommonModule
    ],
    declarations: [
        LayoutComponent,
        SidebarComponent, ManagementSidebarComponent, FranchiseeSidebarComponent,
        HotelSidebarComponent,
        TopbarComponent,
        BreadcrumbComponent,
        TitleComponent,
        HeadingComponent
    ],
    providers: [
        LayoutService, TitleService, BreadcrumbService, HotelSidebarService
    ],
    exports: [
        LayoutComponent
    ]
})
export class LayoutModule {
}
