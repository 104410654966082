<div class="middle-box text-center">
    <h1>404</h1>
    <h3 class="font-bold">Страница не найдена</h3>
    <div class="error-desc text-justify">
        Вероятно, нужная вам страница удалена, как устаревшая, либо была перемещена
        в другое место. Вы можете связаться со <a href="mailto:like@likehostels.ru">службой поддержки</a>,
        либо начать с <a href="/">главной страницы</a>.
    </div>
</div>
<div class="container">
    <hr/>
    <div class="row">
        <div class="col-8">
            Лайк Хостел &copy; 2014-2018
        </div>
        <div class="col-4 text-right">
            <small>ERP v{{ appVersion }}</small>
        </div>
    </div>
</div>
