<div class="container">
    <div class="alert alert-warning m-t" *ngIf="warning">
        {{ warning }}
    </div>
    <div class="row">
        <div class="col-12 col-md-6 order-md-0 d-md-none">
            <h2 class="font-bold">Лайк Хостел ERP</h2>
        </div>
        <div class="col-12 col-md-6 order-md-2">
            <div class="ibox-content">
                <form class="m-t" role="form" [formGroup]="loginForm" (ngSubmit)="onSubmit()">
                    <div class="form-group">
                        <input type="text" class="form-control" placeholder="Имя пользователя"
                               formControlName="username">
                    </div>
                    <div class="form-group">
                        <input type="password" class="form-control" placeholder="Пароль"
                               formControlName="password" #passwordControl>
                    </div>
                    <div class="form-group form-check">
                        <input type="checkbox" class="form-check-input"
                               formControlName="rememberme" id="form-login-rememberme">
                        <label class="form-check-label" for="form-login-rememberme">Запомнить меня</label>
                    </div>
                    <button type="submit" class="btn btn-primary block full-width m-b"
                            [disabled]="!loginForm.valid && loginFormValueChanged">
                        Войти
                    </button>
                </form>
                <div class="row no-gutters">
                    <div class="col-6 text-danger">
                        <span [@showLoginErrorTrigger]="showLoginError">Неверный пароль</span>
                    </div>
                    <div class="col-6 text-right">
                        <a href="#">
                            <small>Забыли пароль?</small>
                        </a>
                    </div>
                </div>
                <p class="text-muted text-center m-t-sm">
                    <small>Присоединяйтесь к нам!</small>
                </p>
                <a class="btn btn-sm btn-white btn-block" href="https://likehostels.ru/franshiza/">
                    Заявка на франшизу
                </a>
            </div>
        </div>
        <div class="col-12 col-md-6 order-md-1">
            <h2 class="font-bold d-none d-md-block">Лайк Хостел ERP</h2>
            <p class="text-justify m-t">
                LikeХостел — это идеальное место для ночлега больших и маленьких компаний и молодых семейных пар. Здесь
                есть всё для комфортного сна, полезного и удобного питания, личной гигиены и приятного проведения
                времени.
            </p>
        </div>
    </div>
    <hr/>
    <div class="row">
        <div class="col-8">
            Лайк Хостел &copy; 2014-2018
        </div>
        <div class="col-4 text-right">
            <small>ERP v{{ appVersion }}</small>
        </div>
    </div>
</div>
