import { Component, Injectable, OnInit } from "@angular/core";
import { JsonProperty, ObjectMapper } from "json-object-mapper";
import { ActivatedRoute, ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from "@angular/router";
import { Vacancy } from "./vacancy";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { TitleService as LayoutTitleService } from "layout/title.service";
import { BreadcrumbService } from "layout/breadcrumb.service";
import { AuthService } from "app/auth/auth.service";

export class VacanciesVacancy extends Vacancy {
    @JsonProperty({name: 'responses_count'})
    public responsesCount: number;
}

export class VacanciesData {
    @JsonProperty({type: VacanciesVacancy})
    public vacancies: VacanciesVacancy[];
}

@Injectable()
export class VacanciesResolver implements Resolve<VacanciesData> {

    public constructor(
        protected httpClient: HttpClient
    ) {
    }

    public resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<VacanciesData> {
        return this.httpClient.get<VacanciesData>('/v2/vacancies')
            .pipe(map(data => ObjectMapper.deserialize(VacanciesData, data)));
    }

}

@Component({
    selector: 'vacancy-vacancies-action',
    templateUrl: './vacancies-action.component.html'
})
export class VacanciesActionComponent {
}

@Component({
    selector: 'vacancy-vacancies',
    templateUrl: './vacancies.component.html'
})
export class VacanciesComponent implements OnInit {
    public vacancies: VacanciesVacancy[] = [];

    public constructor(
        protected route: ActivatedRoute,
        protected layoutTitleService: LayoutTitleService,
        protected breadcrumbService: BreadcrumbService,
        protected authService: AuthService
    ) {
    }

    public ngOnInit(): void {
        this.layoutTitleService.title = 'Вакансии';
        this.breadcrumbService.breadcrumbs = [{name: 'Вакансии'}];
        this.route.data.subscribe(data => {
            this.vacancies = data.component.vacancies;
        });
    }

    public trackByVacancy(index: number, vacancy: Vacancy): number {
        return vacancy.vacancyId;
    }

    public showCompanyInfoLink(): boolean {
        return this.authService.getUser().permissions.canEditVacancies;
    }

}
