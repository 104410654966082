<div class="ibox-content">
    <div class="table-responsive">
        <table class="table table-hover">
            <thead>
            <tr>
                <th>ID</th>
                <th>Алиас</th>
                <th>Страна</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let country of countries; trackBy: trackByCountry">
                <th [class.del]="country.isDisabled()">
                    <a [routerLink]="['/locations/countries', country.alias]">{{ country.countryId }}</a>
                </th>
                <td [class.del]="country.isDisabled()">
                    <i *ngIf="country.isEnabled()" class="far fa-check-circle text-navy m-r" title="Страна включена"></i>
                    <i *ngIf="country.isDisabled()" class="fas fa-ban text-danger m-r" title="Страна заблокирована"></i>
                    <a [routerLink]="['/locations/countries', country.alias]">{{ country.alias }}</a>
                </td>
                <td [class.del]="country.isDisabled()">
                    <a [routerLink]="['/locations/countries', country.alias]">{{ country.name }}</a>
                </td>
            </tr>
            </tbody>
        </table>
    </div>
</div>
