import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { LayoutComponent } from "layout/layout.component";
import { AuthGuard } from "app/auth/auth.guard";
import { HotelsActionComponent, HotelsComponent, HotelsResolver } from "./hotel/hotels.component";
import { HotelComponent, HotelResolver } from "./hotel/hotel.component";
import { EditHotelComponent, EditHotelResolver } from "./hotel/edit-hotel.component";
import { NewHotelComponent, NewHotelResolver } from "./hotel/new-hotel.component";
import { AllActionsResolved } from "action/all-actions-resolved.guard";
import { RoomsActionComponent, RoomsComponent, RoomsResolver } from "./room/rooms.component";
import { NewRoomComponent, NewRoomResolver } from "./room/new-room.component";
import { RoomComponent, RoomResolver } from "./room/room.component";
import { EditRoomComponent, EditRoomResolver } from "./room/edit-room.component";
import {
    RoomCategoriesActionComponent,
    RoomCategoriesComponent,
    RoomCategoriesResolver
} from "./room-category/room-categories.component";
import { NewRoomCategoryComponent } from "./room-category/new-room-category.component";
import { RoomCategoryComponent, RoomCategoryResolver } from "./room-category/room-category.component";
import { EditRoomCategoryComponent, EditRoomCategoryResolver } from "./room-category/edit-room-category.component";
import { ManagementCompanyGuard } from "app/auth/management-company.guard";
import {
    AllHotelsGuard,
    CanEditHotelEmployeesGuard,
    CanEditHotelPricesGuard,
    CanEditHotelRoomsGuard
} from "./hotel.guard";
import { EmployeesActionComponent, EmployeesComponent, EmployeesResolver } from "./employee/employees.component";
import { EmployeeComponent, EmployeeResolver } from "./employee/employee.component";
import { NewEmployeeComponent, NewEmployeeResolver } from "./employee/new-employee.component";
import { EditEmployeeComponent, EditEmployeeResolver } from "./employee/edit-employee.component";
import { PricesActionComponent, PricesComponent, PricesResolver } from "./price/prices.component";
import { PriceActionComponent, PriceComponent, PriceResolver } from "./price/price.component";
import { NewPriceComponent, NewPriceResolver } from "./price/new-price.component";
import { EditPriceComponent, EditPriceResolver } from "./price/edit-price.component";

const routes: Routes = [
    {
        path: '',
        component: LayoutComponent,
        canActivate: [AuthGuard, AllActionsResolved],
        children: [
            {
                path: 'hotels',
                children: [
                    {
                        path: '',
                        canActivate: [AllHotelsGuard],
                        children: [
                            {
                                path: '',
                                component: HotelsComponent,
                                resolve: {
                                    component: HotelsResolver
                                }
                            },
                            {
                                path: '',
                                component: HotelsActionComponent,
                                outlet: 'action'
                            }
                        ]
                    },
                    {
                        path: 'new',
                        component: NewHotelComponent,
                        canActivate: [ManagementCompanyGuard],
                        resolve: {
                            component: NewHotelResolver
                        }
                    },
                    {
                        path: ':alias',
                        resolve: {
                            component: HotelResolver
                        },
                        children: [
                            {
                                path: '',
                                component: HotelComponent
                            },
                            {
                                path: 'rooms/categories',
                                children: [
                                    {
                                        path: '',
                                        component: RoomCategoriesComponent,
                                        resolve: {
                                            component: RoomCategoriesResolver
                                        }
                                    },
                                    {
                                        path: '',
                                        component: RoomCategoriesActionComponent,
                                        outlet: 'action'
                                    }
                                ]
                            },
                            {
                                path: 'rooms/categories/new',
                                component: NewRoomCategoryComponent,
                                canActivate: [CanEditHotelRoomsGuard]
                            },
                            {
                                path: 'rooms/categories/:roomCategoryId',
                                component: RoomCategoryComponent,
                                resolve: {
                                    component: RoomCategoryResolver
                                }
                            },
                            {
                                path: 'rooms/categories/:roomCategoryId/edit',
                                component: EditRoomCategoryComponent,
                                canActivate: [CanEditHotelRoomsGuard],
                                resolve: {
                                    component: EditRoomCategoryResolver
                                }
                            },
                            {
                                path: 'rooms',
                                children: [
                                    {
                                        path: '',
                                        component: RoomsComponent,
                                        resolve: {
                                            component: RoomsResolver
                                        }
                                    },
                                    {
                                        path: '',
                                        component: RoomsActionComponent,
                                        outlet: 'action'
                                    }
                                ]
                            },
                            {
                                path: 'rooms/new',
                                component: NewRoomComponent,
                                canActivate: [CanEditHotelRoomsGuard],
                                resolve: {
                                    component: NewRoomResolver
                                }
                            },
                            {
                                path: 'rooms/:roomNumber',
                                component: RoomComponent,
                                resolve: {
                                    component: RoomResolver
                                }
                            },
                            {
                                path: 'rooms/:roomNumber/edit',
                                component: EditRoomComponent,
                                canActivate: [CanEditHotelRoomsGuard],
                                resolve: {
                                    component: EditRoomResolver
                                }
                            },
                            {
                                path: 'employees',
                                children: [
                                    {
                                        path: '',
                                        component: EmployeesComponent,
                                        resolve: {
                                            component: EmployeesResolver
                                        }
                                    },
                                    {
                                        path: '',
                                        component: EmployeesActionComponent,
                                        outlet: 'action'
                                    }
                                ]
                            },
                            {
                                path: 'employees/new',
                                component: NewEmployeeComponent,
                                canActivate: [CanEditHotelEmployeesGuard],
                                resolve: {
                                    component: NewEmployeeResolver
                                }
                            },
                            {
                                path: 'employees/:employeeId',
                                component: EmployeeComponent,
                                resolve: {
                                    component: EmployeeResolver
                                }
                            },
                            {
                                path: 'employees/:employeeId/edit',
                                component: EditEmployeeComponent,
                                canActivate: [CanEditHotelEmployeesGuard],
                                resolve: {
                                    component: EditEmployeeResolver
                                }
                            },
                            {
                                path: 'prices',
                                children: [
                                    {
                                        path: '',
                                        component: PricesComponent,
                                        resolve: {
                                            component: PricesResolver
                                        }
                                    },
                                    {
                                        path: '',
                                        component: PricesActionComponent,
                                        outlet: 'action'
                                    }
                                ]
                            },
                            {
                                path: 'prices/new',
                                component: NewPriceComponent,
                                canActivate: [CanEditHotelPricesGuard],
                                resolve: {
                                    component: NewPriceResolver
                                }
                            },
                            {
                                path: 'prices/:priceId',
                                children: [
                                    {
                                        path: '',
                                        component: PriceComponent,
                                        resolve: {
                                            component: PriceResolver
                                        }
                                    },
                                    {
                                        path: '',
                                        component: PriceActionComponent,
                                        outlet: 'action'
                                    }
                                ]
                            },
                            {
                                path: 'prices/:priceId/edit',
                                component: EditPriceComponent,
                                canActivate: [CanEditHotelPricesGuard],
                                resolve: {
                                    component: EditPriceResolver
                                }
                            }
                        ]
                    },
                    {
                        path: ':alias/edit',
                        component: EditHotelComponent,
                        canActivate: [ManagementCompanyGuard],
                        resolve: {
                            component: EditHotelResolver
                        }
                    }
                ]
            }
        ]
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
    providers: [
        HotelsResolver, HotelResolver, EditHotelResolver, NewHotelResolver,
        RoomsResolver, RoomResolver, EditRoomResolver,
        RoomCategoriesResolver, RoomCategoryResolver, EditRoomCategoryResolver,
        NewRoomResolver, EmployeesResolver,
        CanEditHotelRoomsGuard, EmployeeResolver, EditEmployeeResolver, NewEmployeeResolver,
        CanEditHotelEmployeesGuard, PricesResolver, PriceResolver, NewPriceResolver, EditPriceResolver,
        CanEditHotelPricesGuard, AllHotelsGuard
    ]
})
export class HotelRoutingModule {
}
