<div class="ibox-content">
    <form [formGroup]="employeeForm" (ngSubmit)="onProfileSubmit()">
        <employee-form [employeeForm]="employeeForm" [cities]="cities"></employee-form>
        <div class="form-group row">
            <div class="col-10 offset-2">
                <button class="btn btn-primary" type="submit" [disabled]="!employeeForm.valid">Сохранить</button>
                <span class="text-success m-l" [@showProfileSuccessUpdateTrigger]="showProfileSuccessUpdate">
                    Изменения успешно сохранены
                </span>
            </div>
        </div>
    </form>
</div>

<div class="ibox m-t">
    <div class="ibox-title">
        <h5>Смена пароля</h5>
    </div>
    <div class="ibox-content">
        <form [formGroup]="passwordForm" (ngSubmit)="onPasswordSubmit()">
            <div class="form-group row">
                <label class="col-2 col-form-label" for="form-profile-old-password">Старый пароль:</label>
                <div class="col-10">
                    <input type="password" class="form-control"
                           id="form-profile-old-password"
                           formControlName="oldPassword">
                </div>
            </div>
            <div [class.has-error]="newPasswordError()" class="form-group row">
                <label class="col-2 col-form-label" for="form-profile-new-password">Новый пароль:</label>
                <div class="col-10">
                    <input type="password" class="form-control"
                           id="form-profile-new-password"
                           formControlName="newPassword" placeholder="Минимум 7 символов"
                           #newPasswordControl>
                </div>
            </div>
            <div [class.has-error]="confirmPasswordError()" class="form-group row">
                <label class="col-2 col-form-label" for="form-profile-confirm-password">Повторите пароль:</label>
                <div class="col-10">
                    <input type="password" class="form-control"
                           id="form-profile-confirm-password"
                           formControlName="confirmPassword">
                </div>
            </div>
            <div class="form-group row">
                <div class="col-10 offset-2">
                    <button class="btn btn-primary" type="submit" [disabled]="!passwordForm.valid">
                        Изменить пароль
                    </button>
                    <span [@showPasswordUpdateTrigger]="showPasswordUpdate">
                        <span *ngIf="showPasswordSuccessUpdate" class="text-success m-l">
                            Пароль успешно изменён
                        </span>
                        <span *ngIf="showPasswordErrorUpdate" class="text-danger m-l">
                            Неверный пароль
                        </span>
                    </span>
                </div>
            </div>
        </form>
    </div>
</div>
