import { Component, Injectable, OnInit } from "@angular/core";
import { ActivatedRoute, ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from "@angular/router";
import { Observable } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { JsonProperty, ObjectMapper } from "json-object-mapper";
import { map } from "rxjs/operators";
import { Company } from "../company";
import { TitleService as LayoutTitleService } from "layout/title.service";
import { BreadcrumbService } from "layout/breadcrumb.service";

export class CompaniesData {
    @JsonProperty({type: Company})
    public companies: Company[];
}

@Injectable()
export class CompaniesResolver implements Resolve<CompaniesData> {

    public constructor(
        protected httpClient: HttpClient
    ) {
    }

    public resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<CompaniesData> {
        return this.httpClient.get<CompaniesData>('/v2/companies')
            .pipe(map(data => ObjectMapper.deserialize(CompaniesData, data)));
    }

}

@Component({
    selector: 'company-companies-action',
    templateUrl: './companies-action.component.html'
})
export class CompaniesActionComponent {
}

@Component({
    selector: 'company-companies',
    templateUrl: './companies.component.html'
})
export class CompaniesComponent implements OnInit {
    public companies: Company[] = [];

    public constructor(
        protected route: ActivatedRoute,
        protected layoutTitleService: LayoutTitleService,
        protected breadcrumbService: BreadcrumbService
    ) {
    }

    public ngOnInit(): void {
        this.layoutTitleService.title = 'Компании';
        this.breadcrumbService.breadcrumbs = [{name: 'Компании'}];
        this.route.data.subscribe((data) => {
            this.companies = data.component.companies;
        });
    }

    public trackByCompany(index: number, company: Company): number {
        return company.companyId;
    }

}
