import { AfterViewInit, Component, ElementRef, NgZone, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { HotelType } from "hotel/hotel";
import { AuthService } from "app/auth/auth.service";
import { FranchiseeEmployeePermissions } from "company/employee";

@Component({
    selector: 'layout-sidebar-franchisee',
    templateUrl: './franchisee.component.html'
})
export class FranchiseeSidebarComponent implements OnInit, AfterViewInit, OnDestroy {
    public hotelsLinkTitle = 'Хостелы';

    @ViewChild('metisMenu')
    public metisMenu: ElementRef<HTMLElement>;

    public constructor(
        protected ngZone: NgZone,
        protected authService: AuthService
    ) {
    }

    public ngOnInit(): void {
        let userHotels = this.authService.getUser().hotels;
        for (let hotel of userHotels) {
            if (hotel.type == HotelType.Hotel) {
                this.hotelsLinkTitle = 'Отели';
                break;
            }
        }
    }

    public ngAfterViewInit(): void {
        this.ngZone.runOutsideAngular(() => {
            jQuery(this.metisMenu.nativeElement).metisMenu();
        });
    }

    public ngOnDestroy(): void {
        jQuery(this.metisMenu.nativeElement).metisMenu('dispose');
    }

    public hasHotelsItem(): boolean {
        return this.authService.getUser().hotels.length > 1;
    }

    public hasEmployeesItem(): boolean {
        let user = this.authService.getUser();
        return user.permissions.canEditEmployees || user.hotels.length > 1;
    }

    public hasVacanciesItem(): boolean {
        let permissions = this.authService.getUser().permissions as FranchiseeEmployeePermissions;
        if (permissions.canEditVacancies) {
            return true;
        }
        for (let hotelPermissions of permissions.hotels) {
            if (hotelPermissions.permissions.canEditVacancies) {
                return true;
            }
        }
        return false;
    }

}
