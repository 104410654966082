import { Component, Input } from "@angular/core";
import { HotelForm } from "./hotel.form";

@Component({
    selector: 'hotel-form',
    templateUrl: './hotel-form.component.html'
})
export class HotelFormComponent {
    @Input('hotelForm')
    public hotelForm: HotelForm;
}
