<fieldset [formGroup]="vacancyForm">
    <div class="form-group row"
         [class.has-error]="!vacancyForm.controls.title.disabled && vacancyForm.controls.title.value && !vacancyForm.controls.title.valid">
        <label class="col-2 col-form-label" for="form-vacancy-title">Заголовок:</label>
        <div class="col-10">
            <input type="text" class="form-control" id="form-vacancy-title" formControlName="title">
        </div>
    </div>
    <div class="form-group row">
        <label class="col-2 col-form-label">Описание:</label>
        <div class="col-10">
            <tui-editor [control]="vacancyForm.get('description')"></tui-editor>
        </div>
    </div>
</fieldset>
