import * as Ladda from 'ladda';
import { Injectable, NgZone } from "@angular/core";
import { LaddaButton as LaddaButtonInterface } from "ladda";

export class LaddaButton implements LaddaButtonInterface {

    public constructor(
        protected ngZone: NgZone,
        protected button: LaddaButtonInterface
    ) {
    }

    public isLoading(): boolean {
        return this.ngZone.runOutsideAngular<boolean>(() => {
            return this.button.isLoading();
        });
    }

    public remove(): void {
        return this.ngZone.runOutsideAngular<void>(() => {
            return this.button.remove();
        });
    }

    public setProgress(progress: number): void {
        return this.ngZone.runOutsideAngular<void>(() => {
            return this.button.setProgress(progress);
        });
    }

    public start(): LaddaButtonInterface {
        return this.ngZone.runOutsideAngular<LaddaButtonInterface>(() => {
            return this.button.start();
        });
    }

    public startAfter(delay: number): LaddaButtonInterface {
        return this.ngZone.runOutsideAngular<LaddaButtonInterface>(() => {
            return this.button.startAfter(delay);
        });
    }

    public stop(): LaddaButtonInterface {
        return this.ngZone.runOutsideAngular<LaddaButtonInterface>(() => {
            return this.button.stop();
        });
    }

    public toggle(): LaddaButtonInterface {
        return this.ngZone.runOutsideAngular<LaddaButtonInterface>(() => {
            return this.button.toggle();
        });
    }

}

@Injectable()
export class LaddaService {

    public constructor(
        protected ngZone: NgZone
    ) {
    }

    public create(element: HTMLButtonElement): LaddaButton {
        return this.ngZone.runOutsideAngular<LaddaButton>(() => {
            return new LaddaButton(
                this.ngZone,
                Ladda.create(element)
            );
        });
    }

}
