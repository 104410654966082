import { Component } from "@angular/core";
import { LayoutService } from "./layout.service";

@Component({
    selector: 'layout-heading',
    templateUrl: './heading.component.html'
})
export class HeadingComponent {

    public constructor(
        public layoutService: LayoutService
    ) {
    }

}
