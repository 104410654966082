<div class="ibox">
    <div class="ibox-content">
        <div class="m-b-md">
            <button *ngIf="!edit" (click)="edit = true" class="btn btn-white btn-xs float-right">
                Редактировать
            </button>
            <h2>Информация о компании</h2>
        </div>
        <ng-template [ngIf]="!edit">
            <tui-editor-viewer [content]="companyInfo.description"></tui-editor-viewer>
        </ng-template>
        <ng-template [ngIf]="edit">
            <form [formGroup]="companyInfoForm" (ngSubmit)="onSubmit()">
                <tui-editor [control]="companyInfoForm.get('description')"></tui-editor>
                <div class="form-group row mt-3">
                    <div class="col-12">
                        <button (click)="edit = false; companyInfoForm.get('description').setValue(companyInfo.description)" class="btn btn-white">Отмена</button>
                        &nbsp;
                        <button class="btn btn-primary" type="submit" [disabled]="!companyInfoForm.valid">Сохранить</button>
                    </div>
                </div>
            </form>
        </ng-template>
    </div>
</div>
