import { FormArray, FormControl, FormGroup } from "@angular/forms";
import { Hotel } from "hotel/hotel";
import {
    EmployeePermissions,
    FranchiseeEmployeeHotelPermissions,
    FranchiseeEmployeeHotelPermissionsContainer,
    FranchiseeEmployeePermissions, ManagementEmployeePermissions
} from "../employee";

export class EmployeePermissionsForm extends FormGroup {

    public constructor(permissions?: EmployeePermissions) {
        super({
            canEditEmployees: new FormControl(permissions ? permissions.canEditEmployees : false),
            canEditVacancies: new FormControl(permissions ? permissions.canEditVacancies : false)
        });
    }

    public getPermissions(): EmployeePermissions {
        let permissions = new EmployeePermissions();
        permissions.canEditEmployees = this.get('canEditEmployees').value;
        permissions.canEditVacancies = this.get('canEditVacancies').value;
        return permissions;
    }

}

export class ManagementEmployeePermissionsForm extends FormGroup {

    public constructor(permissions?: ManagementEmployeePermissions) {
        super({
            canDeleteAllObjects: new FormControl(permissions ? permissions.canDeleteAllObjects : false)
        });
    }

    public getPermissions(): ManagementEmployeePermissions {
        let permissions = new ManagementEmployeePermissions();
        permissions.canDeleteAllObjects = this.get('canDeleteAllObjects').value;
        return permissions;
    }

}

export class FranchiseeEmployeeHotelPermissionsForm extends FormGroup {

    public readonly hotel?: Hotel;

    public constructor(hotel?: Hotel, permissions?: FranchiseeEmployeeHotelPermissions) {
        super({
            canViewBookings: new FormControl(),
            canEditBookings: new FormControl(),
            canEditRooms: new FormControl(),
            canEditPrices: new FormControl(),
            canEditEmployees: new FormControl(),
            canEditVacancies: new FormControl()
        });
        this.hotel = hotel;
        let oldCanViewBookingsValue = false;
        this.get('canEditBookings').valueChanges.subscribe((value) => {
            if (value) {
                if (this.get('canViewBookings').enabled) {
                    oldCanViewBookingsValue = this.get('canViewBookings').value;
                }
                this.get('canViewBookings').setValue(true);
                this.get('canViewBookings').disable();
            } else if (this.get('canViewBookings').disabled) {
                this.get('canViewBookings').setValue(oldCanViewBookingsValue);
                this.get('canViewBookings').enable();
            }
        });
        if (permissions) {
            this.get('canViewBookings').setValue(permissions.canViewBookings);
            this.get('canEditBookings').setValue(permissions.canEditBookings);
            this.get('canEditRooms').setValue(permissions.canEditRooms);
            this.get('canEditPrices').setValue(permissions.canEditPrices);
            this.get('canEditEmployees').setValue(permissions.canEditEmployees);
            this.get('canEditVacancies').setValue(permissions.canEditVacancies);
        }
    }

    public selectAll(): void {
        this.setValue({
            canEditBookings: true,
            canViewBookings: true,
            canEditRooms: true,
            canEditPrices: true,
            canEditEmployees: true,
            canEditVacancies: true
        });
    }

    public unselectAll(): void {
        this.setValue({
            canEditBookings: false,
            canViewBookings: false,
            canEditRooms: false,
            canEditPrices: false,
            canEditEmployees: false,
            canEditVacancies: false
        });
    }

}

export class FranchiseeEmployeePermissionsForm extends FormGroup {

    public constructor(
        public readonly hotels: Hotel[],
        permissions?: FranchiseeEmployeePermissions,
        public readonly allHotelsPermissions: boolean = true
    ) {
        super({
            hotels: new FormArray([])
        });
        if (allHotelsPermissions || hotels.length > 1) {
            this.setControl('allHotels', new FranchiseeEmployeeHotelPermissionsForm(
                null, permissions ? permissions.allHotels : null
            ));
        }
        if (!allHotelsPermissions && permissions && this.get('allHotels')) {
            let allHotelsPermissionsValue = {
                canViewBookings: true,
                canEditBookings: true,
                canEditEmployees: true,
                canEditPrices: true,
                canEditRooms: true,
                canEditVacancies: true
            };
            for (let hotel of this.hotels) {
                let hotelPermissions = permissions.hotel(hotel);
                allHotelsPermissionsValue.canViewBookings = !hotelPermissions.canViewBookings ? false : allHotelsPermissionsValue.canViewBookings;
                allHotelsPermissionsValue.canEditBookings = !hotelPermissions.canEditBookings ? false : allHotelsPermissionsValue.canEditBookings;
                allHotelsPermissionsValue.canEditRooms = !hotelPermissions.canEditRooms ? false : allHotelsPermissionsValue.canEditRooms;
                allHotelsPermissionsValue.canEditPrices = !hotelPermissions.canEditPrices ? false : allHotelsPermissionsValue.canEditPrices;
                allHotelsPermissionsValue.canEditEmployees = !hotelPermissions.canEditEmployees ? false : allHotelsPermissionsValue.canEditEmployees;
                allHotelsPermissionsValue.canEditVacancies = !hotelPermissions.canEditVacancies ? false : allHotelsPermissionsValue.canEditVacancies;
            }
            this.get('allHotels').setValue(allHotelsPermissionsValue);
        }
        let hotelsFormArray: FormArray = this.get('hotels') as FormArray;
        for (let hotel of this.hotels) {
            hotelsFormArray.push(new FranchiseeEmployeeHotelPermissionsForm(
                hotel,
                permissions ? permissions.hotel(hotel) : null
            ));
        }
        if (this.get('allHotels')) {
            let hotels = this.get('hotels') as FormArray;
            let controls = ['canViewBookings', 'canEditBookings', 'canEditRooms', 'canEditPrices', 'canEditEmployees', 'canEditVacancies'];
            for (let controlName of controls) {
                this.get('allHotels').get(controlName).valueChanges.subscribe((value) => {
                    if (value !== true) value = false;
                    for (let control of hotels.controls) {
                        if (controlName == 'canViewBookings' && control.get('canEditBookings').value) {
                            control.get(controlName).setValue(true);
                        } else {
                            control.get(controlName).setValue(value);
                        }
                        if (controlName == 'canEditBookings') {
                            control.get('canViewBookings').setValue(this.get('allHotels').get('canViewBookings').value);
                        }
                    }
                });
            }
        }
    }

    public getPermissions(permissions?: FranchiseeEmployeePermissions): FranchiseeEmployeePermissions {
        if (!permissions) {
            permissions = new FranchiseeEmployeePermissions();
        }
        if (this.allHotelsPermissions) {
            let allHotelsPermissions = this.get('allHotels');
            permissions.allHotels = new FranchiseeEmployeeHotelPermissions({
                canViewBookings: allHotelsPermissions.get('canViewBookings').value,
                canEditBookings: allHotelsPermissions.get('canEditBookings').value,
                canEditRooms: allHotelsPermissions.get('canEditRooms').value,
                canEditPrices: allHotelsPermissions.get('canEditPrices').value,
                canEditEmployees: allHotelsPermissions.get('canEditEmployees').value,
                canEditVacancies: allHotelsPermissions.get('canEditVacancies').value
            });
        }
        for (let hotelPermissions of (this.get('hotels') as FormArray).controls) {
            let hotelPermissionsForm = hotelPermissions as FranchiseeEmployeeHotelPermissionsForm;
            permissions.hotels = permissions.hotels.filter(permissions => {
                return permissions.hotelId != hotelPermissionsForm.hotel.hotelId;
            });
            permissions.hotels.push(new FranchiseeEmployeeHotelPermissionsContainer({
                hotelId: hotelPermissionsForm.hotel.hotelId,
                permissions: {
                    canViewBookings: hotelPermissions.get('canViewBookings').value,
                    canEditBookings: hotelPermissions.get('canEditBookings').value,
                    canEditRooms: hotelPermissions.get('canEditRooms').value,
                    canEditPrices: hotelPermissions.get('canEditPrices').value,
                    canEditEmployees: hotelPermissions.get('canEditEmployees').value,
                    canEditVacancies: hotelPermissions.get('canEditVacancies').value
                }
            }));
        }
        return permissions;
    }

}
