<div class="ibox-content">
    <form [formGroup]="hotelForm" (ngSubmit)="onSubmit()">
        <div class="form-group row"
             [class.has-error]="hotelForm.controls.company.value && !hotelForm.controls.company.disabled && !hotelForm.controls.company.valid">
            <label class="col-2 col-form-label" for="form-hotel-company">Компания:</label>
            <div class="col-10">
                <select class="form-control" id="form-hotel-company"
                        formControlName="company">
                    <option *ngFor="let company of companies; trackBy: trackByCompany" [value]="company.companyId">
                        {{ company.name }}
                    </option>
                </select>
            </div>
        </div>
        <div class="form-group row"
             [class.has-error]="hotelForm.controls.type.value && !hotelForm.controls.type.disabled && !hotelForm.controls.type.valid">
            <label class="col-2 col-form-label" for="form-hotel-type">Тип:</label>
            <div class="col-10">
                <select class="form-control" id="form-hotel-type" formControlName="type">
                    <option [value]="HotelType.Hotel">Отель</option>
                    <option [value]="HotelType.Hostel">Хостел</option>
                </select>
            </div>
        </div>
        <hotel-form [hotelForm]="hotelForm"></hotel-form>
        <div class="form-group row"
             [class.has-error]="hotelForm.controls.city.value && !hotelForm.controls.city.disabled && !hotelForm.controls.city.valid">
            <label class="col-2 col-form-label" for="form-hotel-city">Город:</label>
            <div class="col-10">
                <select class="form-control" id="form-hotel-city" formControlName="city">
                    <option *ngFor="let city of cities; trackBy: trackByCity" [value]="city.cityId">
                        {{ city.name }}
                    </option>
                </select>
            </div>
        </div>
        <div class="form-group row">
            <div class="col-10 offset-2">
                <a class="btn btn-white"
                   [routerLink]="['/hotels']">Отмена</a>
                &nbsp;
                <button class="btn btn-primary" type="submit" [disabled]="!hotelForm.valid">Сохранить</button>
            </div>
        </div>
    </form>
</div>
