import { RouterModule, Routes } from "@angular/router";
import { NgModule } from "@angular/core";
import { AuthGuard } from "app/auth/auth.guard";
import { LayoutComponent } from "./layout/layout.component";
import { ActionComponent } from "./action.component";
import { AllActionsResolved } from "./all-actions-resolved.guard";
import { ActionGuard } from "./action.guard";
import { NoHotelsComponent } from "./nohotels/nohotels.component";
import { ProfileComponent } from "./profile/profile.component";
import { TimezoneComponent } from "./timezone/timezone.component";

const routes: Routes = [
    {
        path: 'actions',
        component: LayoutComponent,
        canActivate: [AuthGuard, ActionGuard],
        children: [
            {
                path: 'nohotels',
                component: NoHotelsComponent,
                canActivate: [ActionGuard]
            },
            {
                path: 'timezone',
                component: TimezoneComponent,
                canActivate: [ActionGuard]
            },
            {
                path: 'profile',
                component: ProfileComponent,
                canActivate: [ActionGuard]
            },
            {
                path: '**',
                component: ActionComponent,
                canActivate: [ActionGuard]
            }
        ]
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
    providers: [
        AllActionsResolved, ActionGuard
    ]
})
export class ActionRoutingModule {
}
