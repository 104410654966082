import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { RedirectComponent } from "./redirect.component";
import { AllActionsResolved } from "action/all-actions-resolved.guard";
import { AuthGuard } from "app/auth/auth.guard";

const routes: Routes = [
    {
        path: 'phperp/redirect',
        component: RedirectComponent,
        canActivate: [AuthGuard, AllActionsResolved]
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class PhperpRoutingModule {
}
