import { FormControl, FormGroup, Validators } from "@angular/forms";
import { HttpClient } from "@angular/common/http";
import { Company } from "../company";

export class CompanyForm extends FormGroup {

    public constructor(httpClient: HttpClient, company?: Company) {
        super({
            name: new FormControl(company ? company.name : null, [
                Validators.required,
                Validators.pattern(/^[a-zA-Zа-яёА-ЯЁ0-9 \-+_."/\\&():;]+$/),
                Validators.minLength(1),
                Validators.maxLength(50)
            ]),
            city: new FormControl(company ? company.cityId : null, [
                Validators.required
            ])
        });
    }

    public getCompany(): Company {
        let company = new Company();
        company.name = this.get('name').value;
        company.cityId = this.get('city').value;
        return company;
    }

}
