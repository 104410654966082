import { NgModule } from "@angular/core";
import { CompanyRoutingModule } from "./company-routing.module";
import { CompaniesActionComponent, CompaniesComponent } from "./company/companies.component";
import { EmployeesActionComponent, EmployeesComponent } from "./employee/employees.component";
import { CommonModule } from "@angular/common";
import { CompanyComponent } from "./company/company.component";
import { NewCompanyComponent } from "./company/new-company.component";
import { EditCompanyComponent } from "./company/edit-company.component";
import { ReactiveFormsModule } from "@angular/forms";
import { CompanyFormComponent } from "./company/company-form.component";
import { EmployeeComponent } from "./employee/employee.component";
import { NewEmployeeComponent } from "./employee/new-employee.component";
import { EditEmployeeComponent } from "./employee/edit-employee.component";
import { CompanyCommonModule } from "./company-common.module";
import { CommonModule as AppCommonModule } from "common/common.module";

@NgModule({
    imports: [
        CompanyCommonModule,
        CompanyRoutingModule,
        ReactiveFormsModule,
        CommonModule, AppCommonModule
    ],
    declarations: [
        CompaniesComponent, CompaniesActionComponent,
        EmployeesComponent, EmployeesActionComponent,
        CompanyComponent, NewCompanyComponent, EditCompanyComponent,
        CompanyFormComponent, EmployeeComponent,
        NewEmployeeComponent, EditEmployeeComponent
    ]
})
export class CompanyModule {
}
