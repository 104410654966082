import { Component, EventEmitter, Input, Output } from "@angular/core";
import { Employee } from "../employee";
import { ObjectMapper } from "json-object-mapper";
import { LaddaService } from "app/ladda.service";
import { SweetalertService } from "app/sweetalert.service";
import { HttpClient } from "@angular/common/http";
import { Company } from "../company";
import { AuthService } from "app/auth/auth.service";

@Component({
    selector: 'employee-buttons',
    templateUrl: './employee-buttons.component.html'
})
export class EmployeeButtonsComponent {
    public disableButtons: boolean = false;

    @Input('employee')
    public employee: Employee;

    @Output('employeeChange')
    public employeeChange = new EventEmitter<Employee>();

    @Input('editRouterLink')
    public editRouterLink: any[];

    public get company(): Company {
        return this.employee.company;
    }

    public get canChangeStatus(): boolean {
        if (!this.employee || !this.authService.isAuthenticated()) {
            return false;
        }
        return this.employee.employeeId != this.authService.getUser().employeeId;
    }

    public constructor(
        protected sweetalertService: SweetalertService,
        protected laddaService: LaddaService,
        protected httpClient: HttpClient,
        protected authService: AuthService
    ) {
    }

    public onEnable(target: HTMLButtonElement): void {
        let ladda = this.laddaService.create(target);
        this.sweetalertService.alert({
            icon: "warning",
            title: 'Разблокировать сотрудника?',
            buttons: ['Отмена', 'Разблокировать'],
            dangerMode: true
        }).then((data) => {
            if (data) {
                ladda.start();
                this.disableButtons = true;
                this.httpClient.post<{ employee: any, company: any }>('/v2/companies/' + this.company.companyId + '/employees/' + this.employee.employeeId + '/enable', {}).subscribe((data) => {
                    ladda.stop();
                    this.disableButtons = false;
                    this.employee = ObjectMapper.deserialize(Employee, data.employee);
                    this.employee.company = ObjectMapper.deserialize(Company, data.company);
                    this.employeeChange.emit(this.employee);
                    this.sweetalertService.alert({
                        icon: "success",
                        title: 'Сотрудник разблокирован!'
                    });
                }, error => {
                    ladda.stop();
                    this.disableButtons = false;
                    console.error("Enable employee error:", error);
                    throw error;
                });
            }
        });
    }

    public onDisable(target: HTMLButtonElement): void {
        let ladda = this.laddaService.create(target);
        this.sweetalertService.alert({
            icon: "warning",
            title: 'Заблокировать сотрудника?',
            buttons: ['Отмена', 'Заблокировать'],
            dangerMode: true
        }).then((data) => {
            if (data) {
                ladda.start();
                this.disableButtons = true;
                this.httpClient.post<{ employee: any, company: any }>('/v2/companies/' + this.company.companyId + '/employees/' + this.employee.employeeId + '/disable', {}).subscribe((data) => {
                    ladda.stop();
                    this.disableButtons = false;
                    this.employee = ObjectMapper.deserialize(Employee, data.employee);
                    this.employee.company = ObjectMapper.deserialize(Company, data.company);
                    this.employeeChange.emit(this.employee);
                    this.sweetalertService.alert({
                        icon: "success",
                        title: 'Сотрудник заблокирован!'
                    });
                }, error => {
                    ladda.stop();
                    this.disableButtons = false;
                    console.error("Disable employee error:", error);
                    throw error;
                });
            }
        });
    }

    public onDelete(target: HTMLButtonElement): void {
        let ladda = this.laddaService.create(target);
        this.sweetalertService.alert({
            icon: "warning",
            title: 'Удалить сотрудника?',
            buttons: ['Отмена', 'Удалить'],
            dangerMode: true
        }).then((data) => {
            if (data) {
                ladda.start();
                this.disableButtons = true;
                this.httpClient.delete<{ employee: any, company: any }>('/v2/companies/' + this.company.companyId + '/employees/' + this.employee.employeeId, {}).subscribe((data) => {
                    ladda.stop();
                    this.disableButtons = false;
                    this.employee = ObjectMapper.deserialize(Employee, data.employee);
                    this.employee.company = ObjectMapper.deserialize(Company, data.company);
                    this.employeeChange.emit(this.employee);
                    this.sweetalertService.alert({
                        icon: "success",
                        title: 'Сотрудник удалён!'
                    });
                }, error => {
                    ladda.stop();
                    this.disableButtons = false;
                    console.error("Delete employee error:", error);
                    throw error;
                });
            }
        });
    }

}
