import { AbstractControl, FormControl, FormGroup, ValidationErrors, Validators } from "@angular/forms";

export class PasswordForm extends FormGroup {

    public constructor() {
        super({
            oldPassword: new FormControl(),
            newPassword: new FormControl(),
            confirmPassword: new FormControl()
        });
        this.get('oldPassword').setValidators([
            Validators.required
        ]);
        this.get('newPassword').setValidators([
            Validators.required,
            Validators.minLength(7),
            this.newPasswordValidator()
        ]);
        this.get('confirmPassword').setValidators([
            Validators.required,
            this.confirmPasswordValidator()
        ]);
        this.get('oldPassword').valueChanges.subscribe(() => {
            this.get('newPassword').updateValueAndValidity();
        });
        this.get('newPassword').valueChanges.subscribe((value: string) => {
            this.get('confirmPassword').updateValueAndValidity();
        });
    }

    public newPasswordValidator(): (control: AbstractControl) => ValidationErrors | null {
        return (control) => {
            let value: string = control.value;
            if (this.get('oldPassword').value == value) {
                return {equalsError: true};
            }
            if (value && value.trim().length != value.length) {
                return {trimError: true};
            }
            return null;
        };
    }

    public confirmPasswordValidator(): (control: AbstractControl) => ValidationErrors | null {
        return (control) => {
            if (this.get('newPassword').value == this.get('confirmPassword').value) {
                return null;
            } else {
                return {confirmError: true};
            }
        };
    }

    public getPassword(): {
        oldPassword: string,
        newPassword: string,
        confirmPassword: string
    } {
        return {
            oldPassword: this.get('oldPassword').value,
            newPassword: this.get('newPassword').value,
            confirmPassword: this.get('confirmPassword').value
        };
    }

}
