<div class="row border-bottom white-bg dashboard-header">
    <div class="col-sm-12">
        <h2>Добро пожаловать в Лайк Хостел ERP!</h2>
    </div>
</div>

<div class="row">
    <div class="col-lg-12">
        <div class="wrapper wrapper-content">
            <div class="alert alert-warning">
                <p><strong>Уважаемые франчайзи!</strong></p>
                <p>
                    В рамках перехода на новую систему бронирования, просим Вас проверить актуальность
                    номеров, цен и Ваших сотрудников и их права доступа к системе.
                </p>
                <p>
                    На данный момен указанные в новой системе номера и цены не публикуются на сайте,
                    о запуске бронирования через новую систему Вы будете уведомлены дополнительно.
                </p>
                <p>
                    Актуальная информация о бронированиях и опубликованные на сайте описания номеров и цены
                    находятся в <a href="/phperp/redirect" target="_blank">старой ERP</a>.
                    Старая ERP будет работать до полной готовности новой системы.
                </p>
                <p>Приятной работы!</p>
                <p class="clearfix">
                    <a href="/phperp/redirect" target="_blank" class="btn btn-default btn-sm float-right">
                        Перейти к старой ERP
                    </a>
                </p>
            </div>
            <div class="row">
                <div class="col-lg-4">
                    <div class="ibox float-e-margins">
                        <div class="ibox-title">
                            <h5>Франчайзи {{ company.name }}</h5>
                        </div>
                        <div class="ibox-content">
                            <b class="m-r">Город:</b>
                            {{ company.city.name }}
                        </div>
                    </div>
                </div>
                <div *ngFor="let hotel of hotels; trackBy: trackByHotel" class="col-lg-4">
                    <dashboard-franchisee-hotel-card [hotel]="hotel"></dashboard-franchisee-hotel-card>
                </div>
            </div>
        </div>
    </div>
</div>
