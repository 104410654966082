<div class="ibox">
    <div class="ibox-content">
        <div class="m-b-md">
            <ng-template [canEditHotelRooms]="hotel">
                <a *ngIf="!room.isDeleted()" [routerLink]="['/hotels', hotel.alias, 'rooms', room.number, 'edit']"
                   class="btn btn-white btn-xs float-right">
                    Редактировать
                </a>
                <button *ngIf="room.isDisabled()" (click)="onDelete($event.target)"
                        type="button" [disabled]="disableButtons"
                        class="btn btn-danger btn-xs float-right m-r-sm ladda-button" data-style="slide-right">
                    Удалить
                </button>
                <button *ngIf="room.isEnabled()" (click)="onDisable($event.target)"
                        type="button" [disabled]="disableButtons"
                        class="btn btn-warning btn-xs float-right m-r-sm ladda-button" data-style="slide-right">
                    Отключить
                </button>
                <button *ngIf="room.isDisabled()" (click)="onEnable($event.target)"
                        type="button" [disabled]="disableButtons"
                        class="btn btn-success btn-xs float-right m-r-sm ladda-button" data-style="slide-right">
                    Включить
                </button>
            </ng-template>
            <h2>{{ room.number }}</h2>
        </div>
        <dl class="row mb-0">
            <dt class="col-2">Категория:</dt>
            <dd class="col-10">
                {{ room.category.name }}
                <small>{{ getCategoryTypeName(room.category) }}</small>
            </dd>
            <dt class="col-2">Статус:</dt>
            <dd class="col-10">
                <span *ngIf="room.isEnabled()" class="label label-primary">Активный</span>
                <span *ngIf="room.isDisabled()" class="label label-warning">Заблокирован</span>
                <span *ngIf="room.isDeleted()" class="label label-danger">Удалён</span>
            </dd>
        </dl>
        <h2 class="text-muted text-right">#{{ room.roomId }}</h2>
    </div>
</div>
