import { Component, Injectable, OnDestroy, OnInit } from "@angular/core";
import { JsonProperty, ObjectMapper } from "json-object-mapper";
import { HttpClient } from "@angular/common/http";
import { ActivatedRoute, ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from "@angular/router";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { TitleService as LayoutTitleService } from "layout/title.service";
import { BreadcrumbService } from "layout/breadcrumb.service";
import { Hotel } from "../hotel";
import { mergeRouteData } from "app/merge-route-data";
import { RoomCategory, RoomCategoryType } from "../room-category";
import { HotelSidebarService } from "layout/sidebar/hotel.service";

export class RoomCategoriesData {
    @JsonProperty({type: RoomCategory})
    public categories: RoomCategory[];

    @JsonProperty({type: Hotel})
    public hotel: Hotel;
}

@Injectable()
export class RoomCategoriesResolver implements Resolve<RoomCategoriesData> {

    public constructor(
        protected httpClient: HttpClient
    ) {
    }

    public resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<RoomCategoriesData> {
        let hotel: Hotel = mergeRouteData(route).component.hotel;
        return this.httpClient.get<RoomCategoriesData>('/v2/hotels/' + hotel.hotelId + '/rooms/categories')
            .pipe(map(data => ObjectMapper.deserialize(RoomCategoriesData, data)));
    }

}


@Component({
    selector: 'hotel-room-categories-action',
    templateUrl: './room-categories-action.component.html'
})
export class RoomCategoriesActionComponent implements OnInit {
    public hotel: Hotel;

    public constructor(
        protected route: ActivatedRoute
    ) {
    }

    public ngOnInit(): void {
        this.route.data.subscribe((data) => {
            this.hotel = data.component.hotel;
        });
    }

}

@Component({
    selector: 'hotel-room-categories',
    templateUrl: './room-categories.component.html'
})
export class RoomCategoriesComponent implements OnInit, OnDestroy {
    public RoomCategoryType = RoomCategoryType;
    public hotel: Hotel;
    public categories: RoomCategory[] = [];

    public constructor(
        protected route: ActivatedRoute,
        protected layoutTitleService: LayoutTitleService,
        protected breadcrumbService: BreadcrumbService,
        protected hotelSidebarService: HotelSidebarService
    ) {
    }

    public ngOnInit(): void {
        this.route.data.subscribe((data) => {
            this.categories = data.component.categories;
            this.hotel = data.component.hotel;
            this.layoutTitleService.title = 'Категории номеров';
            this.breadcrumbService.breadcrumbs = [
                {name: 'Отели', link: '/hotels'},
                {name: this.hotel.name, link: ['/hotels', this.hotel.alias]},
                {name: 'Категории номеров'}
            ];
            this.hotelSidebarService.setHotel(this.hotel);
        });
    }

    public ngOnDestroy(): void {
        this.hotelSidebarService.clearHotel();
    }

    public trackByCategory(index: number, category: RoomCategory): number {
        return category.roomCategoryId;
    }

}
