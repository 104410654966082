<fieldset [formGroup]="cityForm">
    <div class="form-group row"
         [class.has-error]="!cityForm.controls.country.disabled && !cityForm.controls.country.valid">
        <label class="col-2 col-form-label" for="form-country-name">Страна:</label>
        <div class="col-10">
            <select class="form-control" id="form-country-name" formControlName="country">
                <option *ngFor="let country of countries; trackBy: trackByCountry" [value]="country.countryId">
                    {{ country.name }}
                </option>
            </select>
        </div>
    </div>
    <div class="form-group row"
         [class.has-error]="!cityForm.controls.name.disabled && cityForm.controls.name.value && !cityForm.controls.name.valid">
        <label class="col-2 col-form-label" for="form-city-name">Город:</label>
        <div class="col-10">
            <input type="text" class="form-control" id="form-city-name" formControlName="name">
        </div>
    </div>
    <div class="form-group row"
         [class.has-error]="!cityForm.controls.alias.disabled && cityForm.controls.alias.value && !cityForm.controls.alias.valid">
        <label class="col-2 col-form-label" for="form-city-alias">Алиас:</label>
        <div class="col-10">
            <input type="text" class="form-control" id="form-city-alias" formControlName="alias">
        </div>
    </div>
    <div class="form-group row"
         [class.has-error]="!cityForm.controls.timezone.disabled && !cityForm.controls.timezone.valid">
        <label class="col-2 col-form-label" for="form-city-timezone">Часовой пояс:</label>
        <div class="col-10">
            <select class="form-control" id="form-city-timezone" formControlName="timezone">
                <option *ngFor="let timezone of timezones" [value]="timezone">
                    {{ timezone }}
                </option>
            </select>
        </div>
    </div>
</fieldset>
