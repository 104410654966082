<dl class="row mb-0">
    <ng-template [ngIf]="employee.name.first && employee.name.last">
        <dt class="col-2">ФИО:</dt>
        <dd class="col-10">
            {{ employee.name.first }}
            {{ employee.name.middle }}
            {{ employee.name.last }}
        </dd>
    </ng-template>
    <dt class="col-2">Статус:</dt>
    <dd class="col-10">
        <span *ngIf="employee.isEnabled()" class="label label-primary">Активный</span>
        <span *ngIf="employee.isDisabled()" class="label label-warning">Заблокирован</span>
        <span *ngIf="employee.isDeleted()" class="label label-danger">Удалён</span>
    </dd>
    <dt class="col-2">Компания:</dt>
    <dd class="col-10">{{ employee.company.name }}</dd>
    <ng-template [ngIf]="employee.position">
        <dt class="col-2">Должность:</dt>
        <dd class="col-10">{{ employee.position }}</dd>
    </ng-template>
    <dt class="col-2">Город:</dt>
    <dd class="col-10">{{ employee.city.name }}</dd>
    <dt class="col-2">Часовой пояс:</dt>
    <dd class="col-10">{{ employee.timezone }}</dd>
    <dt class="col-2">Email:</dt>
    <dd class="col-10">
        <a href="mailto:{{ employee.email }}">{{ employee.email }}</a>
    </dd>
    <ng-template [ngIf]="employee.phone">
        <dt class="col-2">Телефон:</dt>
        <dd class="col-10">{{ employee.phone }}</dd>
    </ng-template>
</dl>
