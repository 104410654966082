import { Component, Input } from "@angular/core";
import { Employee } from "../employee";

@Component({
    selector: 'employee-view',
    templateUrl: './employee-view.component.html'
})
export class EmployeeViewComponent {

    @Input('employee')
    public employee: Employee;

}
