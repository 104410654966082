import { Component, Input } from "@angular/core";
import { AuthService } from "app/auth/auth.service";
import { TimeZoneService } from "app/timezone.service";

@Component({
    selector: 'utc-time',
    templateUrl: './utc-time.component.html'
})
export class UtcTimeComponent {

    public constructor(
        protected authService: AuthService,
        protected timeZoneService: TimeZoneService
    ) {
    }

    @Input('time')
    public readonly time: number;

    @Input('timezone')
    public readonly timezone: string = this.timeZoneService.getDefaultTimeZone();

    public get showByUserTZ(): boolean {
        return this.timeZoneService.showByUserTZ;
    }

    public set showByUserTZ(value: boolean) {
        this.timeZoneService.showByUserTZ = value;
    }

    public isDifferentTZ(): boolean {
        if (!this.authService.isAuthenticated()) {
            return false;
        }
        return this.authService.getUser().timezone != this.timezone;
    }

    public getUserTZ(): string {
        return this.timeZoneService.getDefaultTimeZone();
    }

    public getTZAbbr(timezone: string): string {
        return this.timeZoneService.getTZAbbr(timezone);
    }

}
