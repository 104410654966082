import { AfterViewInit, Component, ElementRef, Input, NgZone, OnDestroy, ViewChild } from "@angular/core";
import './hotel.component.scss';
import { Router } from "@angular/router";
import { Hotel } from "hotel/hotel";

@Component({
    selector: 'layout-sidebar-hotel',
    templateUrl: './hotel.component.html'
})
export class HotelSidebarComponent implements AfterViewInit, OnDestroy {

    @Input('hotel')
    public hotel: Hotel;

    @ViewChild('metisMenu')
    public metisMenu: ElementRef<HTMLElement>;

    public constructor(
        protected router: Router,
        protected ngZone: NgZone
    ) {
    }

    public isRoomsActive(): boolean {
        let url = this.router.url.split('/');
        if (url[0] == '') url.shift();
        if (url.length < 3) {
            return false;
        }
        return url[0] == 'hotels' && url[2] == 'rooms' && url[3] != 'categories';
    }

    public ngAfterViewInit(): void {
        this.ngZone.runOutsideAngular(() => {
            jQuery(this.metisMenu.nativeElement).metisMenu();
        });
    }

    public ngOnDestroy(): void {
        jQuery(this.metisMenu.nativeElement).metisMenu('dispose');
    }

}
