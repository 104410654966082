import { ComponentFactoryResolver, Injector, NgModule } from "@angular/core";
import { Error404RoutingModule } from "./error404-routing.module";
import { Error404Component } from "./error404.component";
import { ROUTER_ERROR_HANDLERS } from "app/app-routing.module";
import { RouterErrorHandler } from "./router.error-handler";

@NgModule({
    imports: [Error404RoutingModule],
    declarations: [Error404Component],
    providers: [
        {
            provide: ROUTER_ERROR_HANDLERS,
            useClass: RouterErrorHandler,
            multi: true,
            deps: [Injector, ComponentFactoryResolver]
        }
    ]
})
export class Error404Module {
}
