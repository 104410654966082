<nav class="navbar-default navbar-static-side" role="navigation">
    <div class="sidebar-collapse">
        <ul class="nav metismenu" #metisMenu>
            <li class="nav-header">
                <div class="profile-element">
                    <a routerLink="/profile">
                        <span class="clear">
                            <span class="block m-t-xs">
                                <strong class="font-bold">{{ getName() }}</strong>
                            </span>
                            <span *ngIf="getPosition()" class="text-muted text-xs block">
                                {{ getPosition() }}
                            </span>
                        </span>
                    </a>
                </div>
            </li>
        </ul>
        <layout-sidebar-hotel *ngIf="hasHotelSidebar()" [hotel]="getHotelSidebarHotel()"></layout-sidebar-hotel>
        <layout-sidebar-management *ngIf="isManagementSidebar()"></layout-sidebar-management>
        <layout-sidebar-franchisee *ngIf="isFranchiseeSidebar()"></layout-sidebar-franchisee>
    </div>
</nav>
