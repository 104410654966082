<h3 *ngIf="permissionsForm.controls.allHotels">Управление {{ hotelsOrHostels('отелями', 'хостелами') }}</h3>
<div class="row" [formGroup]="permissionsForm">
    <div *ngIf="permissionsForm.controls.allHotels" class="col-4" formGroupName="allHotels">
        <h3>Все {{ hotelsOrHostels() }}</h3>
        <div class="form-group form-check mb-1">
            <input type="checkbox" class="form-check-input"
                   id="form-company-employee-permissions-all-canViewBookings"
                   formControlName="canViewBookings">
            <label class="form-check-label" for="form-company-employee-permissions-all-canViewBookings">
                Просмотр бронирований
            </label>
        </div>
        <div class="form-group form-check mb-1">
            <input type="checkbox" class="form-check-input"
                   id="form-company-employee-permissions-all-canEditBookings"
                   formControlName="canEditBookings">
            <label class="form-check-label" for="form-company-employee-permissions-all-canEditBookings">
                Управление бронированиями
            </label>
        </div>
        <div class="form-group form-check mb-1">
            <input type="checkbox" class="form-check-input"
                   id="form-company-employee-permissions-all-canEditRooms"
                   formControlName="canEditRooms">
            <label class="form-check-label" for="form-company-employee-permissions-all-canEditRooms">
                Управление номерами
            </label>
        </div>
        <div class="form-group form-check mb-1">
            <input type="checkbox" class="form-check-input"
                   id="form-company-employee-permissions-all-canEditPrices"
                   formControlName="canEditPrices">
            <label class="form-check-label" for="form-company-employee-permissions-all-canEditPrices">
                Управление ценами
            </label>
        </div>
        <div class="form-group form-check mb-1">
            <input type="checkbox" class="form-check-input"
                   id="form-company-employee-permissions-all-canEditEmployees"
                   formControlName="canEditEmployees">
            <label class="form-check-label" for="form-company-employee-permissions-all-canEditEmployees">
                Управление сотрудниками
            </label>
        </div>
        <div class="form-group form-check mb-1">
            <input type="checkbox" class="form-check-input"
                   id="form-company-employee-permissions-all-canEditVacancies"
                   formControlName="canEditVacancies">
            <label class="form-check-label" for="form-company-employee-permissions-all-canEditVacancies">
                Управление вакансиями
            </label>
        </div>
        <div>
            <small class="link-dashed" (click)="getHotelPermissionsForm(permissionsForm.get('allHotels')).selectAll()">
                выбрать все
            </small>
            /
            <small class="link-dashed"
                   (click)="getHotelPermissionsForm(permissionsForm.get('allHotels')).unselectAll()">
                убрать все
            </small>
        </div>
    </div>
    <ng-container formArrayName="hotels">
        <div *ngFor="let hotelPermissionsForm of getHotelsPermissionsFormArray().controls; trackBy: trackByHotelPermissionsForm; let i = index;"
             [formGroupName]="i" class="col-4">
            <h3>{{ hotelPermissionsForm.hotel.name }}</h3>
            <div class="form-group form-check mb-1">
                <input type="checkbox" class="form-check-input"
                       [id]="'form-company-employee-permissions-canViewBookings[' + i + ']'"
                       formControlName="canViewBookings">
                <label class="form-check-label" [for]="'form-company-employee-permissions-canViewBookings[' + i + ']'">
                    Просмотр бронирований
                </label>
            </div>
            <div class="form-group form-check mb-1">
                <input type="checkbox" class="form-check-input"
                       [id]="'form-company-employee-permissions-canEditBookings[' + i + ']'"
                       formControlName="canEditBookings">
                <label class="form-check-label" [for]="'form-company-employee-permissions-canEditBookings[' + i + ']'">
                    Управление бронированиями
                </label>
            </div>
            <div class="form-group form-check mb-1">
                <input type="checkbox" class="form-check-input"
                       [id]="'form-company-employee-permissions-canEditRooms[' + i + ']'"
                       formControlName="canEditRooms">
                <label class="form-check-label" [for]="'form-company-employee-permissions-canEditRooms[' + i + ']'">
                    Управление номерами
                </label>
            </div>
            <div class="form-group form-check mb-1">
                <input type="checkbox" class="form-check-input"
                       [id]="'form-company-employee-permissions-canEditPrices[' + i + ']'"
                       formControlName="canEditPrices">
                <label class="form-check-label" [for]="'form-company-employee-permissions-canEditPrices[' + i + ']'">
                    Управление ценами
                </label>
            </div>
            <div class="form-group form-check mb-1">
                <input type="checkbox" class="form-check-input"
                       [id]="'form-company-employee-permissions-canEditEmployees[' + i + ']'"
                       formControlName="canEditEmployees">
                <label class="form-check-label" [for]="'form-company-employee-permissions-canEditEmployees[' + i + ']'">
                    Управление сотрудниками
                </label>
            </div>
            <div class="form-group form-check mb-1">
                <input type="checkbox" class="form-check-input"
                       [id]="'form-company-employee-permissions-canEditVacancies[' + i + ']'"
                       formControlName="canEditVacancies">
                <label class="form-check-label" [for]="'form-company-employee-permissions-canEditVacancies[' + i + ']'">
                    Управление вакансиями
                </label>
            </div>
            <div>
                <small class="link-dashed" (click)="getHotelPermissionsForm(hotelPermissionsForm).selectAll()">
                    выбрать все
                </small>
                /
                <small class="link-dashed" (click)="getHotelPermissionsForm(hotelPermissionsForm).unselectAll()">
                    убрать все
                </small>
            </div>
        </div>
    </ng-container>
</div>
