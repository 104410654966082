import { AccessType, CacheKey, Deserializer, JsonIgnore, JsonProperty, Serializer } from "json-object-mapper";
import { RoomCategory } from "./room-category";

export enum RoomStatus {
    Disabled = 'disabled',
    Enabled = 'enabled',
    Deleted = 'deleted'
}

@CacheKey("RoomStatusSerializerDeserializer")
class RoomStatusSerializerDeserializer implements Deserializer, Serializer {

    public deserialize(value: string): RoomStatus {
        return RoomStatus[value.charAt(0).toUpperCase() + value.slice(1).toLowerCase()];
    }

    public serialize(value: RoomStatus): string {
        return '"' + RoomStatus[value.charAt(0).toUpperCase() + value.slice(1).toLowerCase()].toLowerCase() + '"';
    }

}

export class Room {

    @JsonProperty({name: 'room_id', access: AccessType.READ_ONLY})
    public roomId: number;

    @JsonProperty({
        type: RoomStatus,
        serializer: RoomStatusSerializerDeserializer,
        deserializer: RoomStatusSerializerDeserializer
    })
    public status: RoomStatus;

    @JsonIgnore()
    public isEnabled(): boolean {
        return this.status == RoomStatus.Enabled;
    }

    @JsonIgnore()
    public isDisabled(): boolean {
        return this.status == RoomStatus.Disabled;
    }

    @JsonIgnore()
    public isDeleted(): boolean {
        return this.status == RoomStatus.Deleted;
    }

    @JsonProperty()
    public number: string;

    @JsonProperty({name: 'category', type: RoomCategory, access: AccessType.READ_ONLY})
    public category?: RoomCategory;

    @JsonIgnore()
    private _categoryId: number;

    public get categoryId(): number {
        if (this.category) {
            return this.category.roomCategoryId;
        }
        return this._categoryId;
    }

    @JsonProperty({name: "category_id", access: AccessType.WRITE_ONLY})
    public set categoryId(categoryId: number) {
        if (this.categoryId != categoryId) {
            this.category = null;
        }
        this._categoryId = categoryId;
    }

}
