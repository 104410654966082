<div class="ibox-content">
    <div class="table-responsive">
        <table class="table table-hover">
            <thead>
            <tr>
                <th>ID</th>
                <th>E-mail</th>
                <th>ФИО</th>
                <th>Телефон</th>
                <th>Должность</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let employee of employees; trackBy: trackByEmployee">
                <th [class.del]="employee.isDisabled()">
                    <a [routerLink]="['/hotels', hotel.alias, 'employees', employee.employeeId]">
                        {{ employee.employeeId }}
                    </a>
                </th>
                <td [class.del]="employee.isDisabled()">
                    <i *ngIf="employee.isEnabled()" class="far fa-check-circle text-navy m-r"
                       title="Сотрудник включен"></i>
                    <i *ngIf="employee.isDisabled()" class="fas fa-ban text-danger m-r"
                       title="Сотрудник заблокирован"></i>
                    <a [routerLink]="['/hotels', hotel.alias, 'employees', employee.employeeId]">
                        {{ employee.email }}
                    </a>
                    <span *ngIf="employee.employeeId == currentEmployeeId" class="badge badge-warning m-l">Это Вы</span>
                </td>
                <td>
                    {{ employee.name.first }}
                    {{ employee.name.middle }}
                    {{ employee.name.last }}
                </td>
                <td>{{ employee.phone }}</td>
                <td>{{ employee.position }}</td>
            </tr>
            <tr *ngIf="employees.length == 0">
                <td colspan="5" class="text-center">
                    Сотрудники не найдены
                    <a *canEditHotelEmployees="hotel" class="m-l"
                       [routerLink]="['/hotels', hotel.alias, 'employees', 'new']">(добавить нового)</a>
                </td>
            </tr>
            </tbody>
        </table>
    </div>
</div>
