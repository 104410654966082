import './vendor.browser';
import { enableProdMode, getModuleFactory, NgZone } from "@angular/core";
import { platformBrowser } from "@angular/platform-browser";
import './app.module.ngfactory';
import { BootstrapService } from "./bootstrap.service";
import { errorHandler } from "./error/error";

export function startErpApp() {
    if (APP_MODE === 'production') {
        enableProdMode();
    }

    let ngZone = new NgZone({});
    ngZone.onError.subscribe((error: any) => {
        errorHandler(error); // see bootstrap.browser.ts
    });
    platformBrowser().bootstrapModuleFactory(getModuleFactory('app-browser'), {
        ngZone: ngZone
    }).then((module) => {
        console.info('Started app v' + APP_VERSION
            + ', ERP API v' + module.injector.get(BootstrapService).getWebApiVersion() + '.');
    }).catch((error) => {
        errorHandler(error); // see bootstrap.browser.ts
    });
}
