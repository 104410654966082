<h3>Управление {{ hotelsOrHostels('отелями', 'хостелами') }}</h3>
<table class="table">
    <thead>
    <tr>
        <th></th>
        <th *ngIf="viewAllHotelsPermissions">Все {{ hotelsOrHostels() }}</th>
        <th *ngFor="let hotel of getHotels(); trackBy: trackByHotel">
            {{ hotel.name }}
        </th>
    </tr>
    </thead>
    <tbody>
    <tr>
        <th>Просмотр бронирований</th>
        <td *ngIf="viewAllHotelsPermissions">
            <span *ngIf="employee.permissions.allHotels.canViewBookings" class="label label-primary">разрешён</span>
            <span *ngIf="!employee.permissions.allHotels.canViewBookings" class="label label-default">запрещён</span>
        </td>
        <td *ngFor="let hotel of getHotels(); trackBy: trackByHotel">
            <span *ngIf="employee.permissions.hotel(hotel).canViewBookings" class="label label-primary">разрешён</span>
            <span *ngIf="!employee.permissions.hotel(hotel).canViewBookings" class="label label-default">запрещён</span>
        </td>
    </tr>
    <tr>
        <th>Управление бронированиями</th>
        <td *ngIf="viewAllHotelsPermissions">
            <span *ngIf="employee.permissions.allHotels.canEditBookings" class="label label-primary">разрешено</span>
            <span *ngIf="!employee.permissions.allHotels.canEditBookings" class="label label-default">запрещено</span>
        </td>
        <td *ngFor="let hotel of getHotels(); trackBy: trackByHotel">
            <span *ngIf="employee.permissions.hotel(hotel).canEditBookings" class="label label-primary">разрешено</span>
            <span *ngIf="!employee.permissions.hotel(hotel).canEditBookings"
                  class="label label-default">запрещено</span>
        </td>
    </tr>
    <tr>
        <th>Управление номерами</th>
        <td *ngIf="viewAllHotelsPermissions">
            <span *ngIf="employee.permissions.allHotels.canEditRooms" class="label label-primary">разрешено</span>
            <span *ngIf="!employee.permissions.allHotels.canEditRooms" class="label label-default">запрещено</span>
        </td>
        <td *ngFor="let hotel of getHotels(); trackBy: trackByHotel">
            <span *ngIf="employee.permissions.hotel(hotel).canEditRooms" class="label label-primary">разрешено</span>
            <span *ngIf="!employee.permissions.hotel(hotel).canEditRooms" class="label label-default">запрещено</span>
        </td>
    </tr>
    <tr>
        <th>Управление ценами</th>
        <td *ngIf="viewAllHotelsPermissions">
            <span *ngIf="employee.permissions.allHotels.canEditPrices" class="label label-primary">разрешено</span>
            <span *ngIf="!employee.permissions.allHotels.canEditPrices" class="label label-default">запрещено</span>
        </td>
        <td *ngFor="let hotel of getHotels(); trackBy: trackByHotel">
            <span *ngIf="employee.permissions.hotel(hotel).canEditPrices" class="label label-primary">разрешено</span>
            <span *ngIf="!employee.permissions.hotel(hotel).canEditPrices" class="label label-default">запрещено</span>
        </td>
    </tr>
    <tr>
        <th>Управление сотрудниками</th>
        <td *ngIf="viewAllHotelsPermissions">
            <span *ngIf="employee.permissions.allHotels.canEditEmployees" class="label label-primary">разрешено</span>
            <span *ngIf="!employee.permissions.allHotels.canEditEmployees" class="label label-default">запрещено</span>
        </td>
        <td *ngFor="let hotel of getHotels(); trackBy: trackByHotel">
            <span *ngIf="employee.permissions.hotel(hotel).canEditEmployees"
                  class="label label-primary">разрешено</span>
            <span *ngIf="!employee.permissions.hotel(hotel).canEditEmployees"
                  class="label label-default">запрещено</span>
        </td>
    </tr>
    <tr>
        <th>Управление вакансиями</th>
        <td *ngIf="viewAllHotelsPermissions">
            <span *ngIf="employee.permissions.allHotels.canEditVacancies" class="label label-primary">разрешено</span>
            <span *ngIf="!employee.permissions.allHotels.canEditVacancies" class="label label-default">запрещено</span>
        </td>
        <td *ngFor="let hotel of getHotels(); trackBy: trackByHotel">
            <span *ngIf="employee.permissions.hotel(hotel).canEditVacancies"
                  class="label label-primary">разрешено</span>
            <span *ngIf="!employee.permissions.hotel(hotel).canEditVacancies"
                  class="label label-default">запрещено</span>
        </td>
    </tr>
    </tbody>
</table>
