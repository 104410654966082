<fieldset [formGroup]="companyForm">
    <div class="form-group row"
         [class.has-error]="companyForm.controls.name.value && !companyForm.controls.name.disabled && !companyForm.controls.name.valid">
        <label class="col-2 col-form-label" for="form-company-name">Название:</label>
        <div class="col-10">
            <input type="text" class="form-control"
                   id="form-company-name"
                   formControlName="name">
        </div>
    </div>
    <div class="form-group row"
         [class.has-error]="!companyForm.controls.city.disabled && !companyForm.controls.city.valid">
        <label class="col-2 col-form-label" for="form-company-city">Город:</label>
        <div class="col-10">
            <select class="form-control" id="form-company-city" formControlName="city">
                <option *ngFor="let city of cities; trackBy: trackByCity" [value]="city.cityId">
                    {{ city.name }}
                </option>
            </select>
        </div>
    </div>
</fieldset>
