import { Component } from "@angular/core";
import { AuthService } from "app/auth/auth.service";

@Component({
    selector: 'action-nohotels',
    templateUrl: './nohotels.component.html'
})
export class NoHotelsComponent {

    public static isNoHotels(authService: AuthService): boolean {
        let user = authService.getUser();
        if (user.company.isManagementCompany()) {
            return false;
        }
        if (user.company.isFranchiseeCompany()) {
            return user.hotels.length == 0;
        }
        return true;
    }

}
