import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { AuthService } from "./auth/auth.service";
import { User } from "./user";
import { Employee } from "company/employee";
import { JsonProperty, ObjectMapper } from "json-object-mapper";
import { map } from "rxjs/operators";
import { Hotel } from "hotel/hotel";

export class BootstrapResponse {
    @JsonProperty()
    public readonly version: string;

    @JsonProperty()
    public readonly authenticated: boolean;

    @JsonProperty({type: Employee, required: false})
    public readonly employee?: Employee;

    @JsonProperty({type: Hotel, required: false})
    public readonly hotels: Hotel[];
}

@Injectable()
export class BootstrapService {
    private webapiVersion: string;

    public constructor(
        protected httpClient: HttpClient,
        protected authService: AuthService
    ) {
    }

    public init(): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            this.httpClient.get<BootstrapResponse>('/v2')
                .pipe(map(data => ObjectMapper.deserialize(BootstrapResponse, data)))
                .subscribe((data) => {
                    this.webapiVersion = data.version;
                    if (data.authenticated) {
                        this.authService.setUser(new User(data.employee, data.hotels));
                    } else {
                        this.authService.logout();
                    }
                    resolve();
                }, (error) => {
                    reject(error);
                });
        });
    }

    public getWebApiVersion(): string {
        return this.webapiVersion;
    }

}
