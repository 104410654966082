import { Component, Injectable, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute, ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot } from "@angular/router";
import { TitleService as LayoutTitleService } from "layout/title.service";
import { BreadcrumbService } from "layout/breadcrumb.service";
import { HotelSidebarService } from "layout/sidebar/hotel.service";
import { Hotel } from "../hotel";
import { EmployeeForm } from "company/employee/employee.form";
import { HttpClient } from "@angular/common/http";
import { City } from "location/city";
import { map } from "rxjs/operators";
import { JsonIgnore, JsonProperty, ObjectMapper } from "json-object-mapper";
import { Observable } from "rxjs";
import { mergeRouteData } from "app/merge-route-data";
import { Company } from "company/company";
import { Employee } from "company/employee";
import { FranchiseeEmployeePermissionsForm } from "company/employee/employee-permissions.form";
import { AuthService } from "app/auth/auth.service";

export class NewEmployeeData {
    @JsonProperty({type: City})
    public cities: City[];

    @JsonIgnore()
    public hotel: Hotel;
}

@Injectable()
export class NewEmployeeResolver implements Resolve<NewEmployeeData> {

    public constructor(
        protected httpClient: HttpClient
    ) {
    }

    public resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<NewEmployeeData> {
        let hotel: Hotel = mergeRouteData(route).component.hotel;
        return this.httpClient.get("/v2/locations/cities")
            .pipe(map(data => ObjectMapper.deserialize(NewEmployeeData, data)))
            .pipe(map(data => {
                data.hotel = hotel;
                return data;
            }));
    }

}

@Component({
    selector: 'hotel-employee-new',
    templateUrl: 'new-employee.component.html'
})
export class NewEmployeeComponent implements OnInit, OnDestroy {
    public hotel: Hotel;
    public employeeForm: EmployeeForm;
    public franchiseePermissionsForm: FranchiseeEmployeePermissionsForm;
    public cities: City[] = [];

    public constructor(
        protected route: ActivatedRoute,
        protected layoutTitleService: LayoutTitleService,
        protected breadcrumbService: BreadcrumbService,
        protected hotelSidebarService: HotelSidebarService,
        protected httpClient: HttpClient,
        protected router: Router,
        protected authService: AuthService
    ) {
    }

    public ngOnInit(): void {
        this.route.data.subscribe((data) => {
            this.hotel = data.component.hotel;
            this.cities = data.component.cities;
            this.layoutTitleService.title = 'Новый сотрудник';
            this.breadcrumbService.breadcrumbs = [
                {name: 'Отели', link: '/hotels'},
                {name: this.hotel.name, link: ['/hotels', this.hotel.alias]},
                {name: 'Сотрудники', link: ['/hotels', this.hotel.alias, 'employees']},
                {name: 'Новый сотрудник'}
            ];
            this.hotelSidebarService.setHotel(this.hotel);
            this.employeeForm = new EmployeeForm(this.httpClient, null, !this.authService.getUser().company.isManagementCompany());
            this.employeeForm.get('city').setValue(this.hotel.city.cityId);
            this.employeeForm.get('timezone').setValue(this.hotel.city.timezone);
            this.franchiseePermissionsForm = new FranchiseeEmployeePermissionsForm(
                [this.hotel], null, false
            );
        });
    }

    public ngOnDestroy(): void {
        this.hotelSidebarService.clearHotel();
    }

    public onSubmit(): void {
        this.employeeForm.disable();
        let employee = this.employeeForm.getEmployee();
        employee.company = this.hotel.company;
        employee.permissions = this.franchiseePermissionsForm.getPermissions();
        this.httpClient.post<{
            employee: any, company: any
        }>('/v2/companies/' + employee.company.companyId + '/employees', ObjectMapper.serialize({
            employee: employee
        })).toPromise().then((data) => {
            let employee = ObjectMapper.deserialize(Employee, data.employee);
            employee.company = ObjectMapper.deserialize(Company, data.company);
            this.router.navigate(['/hotels', this.hotel.alias, 'employees', employee.employeeId]);
        }).catch((error: any) => {
            console.error("Create employee error:", error);
            this.employeeForm.enable();
            throw error;
        });
    }

}
