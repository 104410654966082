import { Component } from "@angular/core";
import { Breadcrumb, BreadcrumbService } from "./breadcrumb.service";
import { isArray, isString } from "util";
import './breadcrumb.component.scss';

@Component({
    selector: 'layout-breadcrumb',
    templateUrl: './breadcrumb.component.html'
})
export class BreadcrumbComponent {

    public constructor(
        public breadcrumbService: BreadcrumbService
    ) {
    }

    public trackByBreadcrumb(index: number, breadcrumb: Breadcrumb): string {
        if (!breadcrumb.link) {
            return breadcrumb.name;
        }
        if (isString(breadcrumb.link)) {
            return breadcrumb.link;
        }
        if (isArray(breadcrumb.link)) {
            return breadcrumb.link.join('/');
        }
        return index.toString();
    }

}
