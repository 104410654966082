import {
    AfterViewInit,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    NgZone,
    OnDestroy,
    Output,
    ViewChild
} from "@angular/core";
import { merge } from 'lodash';
import { isArray } from "util";
import { FormControl } from "@angular/forms";
import { Subscription } from "rxjs";

require('codemirror/lib/codemirror.css'); // codemirror
require('tui-editor/dist/tui-editor.css'); // editor ui
require('tui-editor/dist/tui-editor-contents.css'); // editor content
const TuiEditor = require('tui-editor');

@Component({
    selector: 'tui-editor',
    template: '<div #editor></div>'
})
export class TuiEditorComponent implements AfterViewInit, OnDestroy {

    @ViewChild("editor")
    public editorElementRef: ElementRef<HTMLElement>;

    public editor: any;

    @Input('options')
    public options: any = {};

    @Input('height')
    public height: number = 600;

    private _content: string = '';

    public get content(): string {
        if (this.editor) {
            return this.editor.getMarkdown();
        }
        return this._content;
    }

    @Input('content')
    public set content(content: string) {
        if (this.editor) {
            this.editor.setMarkdown(content);
            return;
        }
        this._content = content;
    }

    @Output('contentChange')
    public contentChange: EventEmitter<string> = new EventEmitter<string>();

    @Input('toolbar')
    public toolbar: 'full' | 'standard' | 'minimal' | 'options' | string[] = 'standard';

    private _formControl: FormControl;

    protected controlStatusSubscription: Subscription | null = null;

    @Input('control')
    public set formControl(control: FormControl) {
        this._formControl = control;
        this.content = control.value;
        if (this.controlStatusSubscription) this.controlStatusSubscription.unsubscribe();
        this.controlStatusSubscription = control.statusChanges.subscribe(status => {
            if (!this.editor) {
                return;
            }
            if (status == 'DISABLED') {
                this.editor.hide();
            }
            if (status == 'ENABLED') {
                this.editor.show();
            }
        });
    }

    public get formControl(): FormControl {
        return this._formControl;
    }

    public constructor(protected ngZone: NgZone) {
    }

    public ngAfterViewInit(): void {
        this.ngZone.runOutsideAngular(() => {
            let options = merge({
                usageStatistics: false,
                initialEditType: 'wysiwyg',
                height: this.height + 'px',
                previewStyle: 'tab', // or 'vertical'
                initialValue: this.content,
                language: 'ru_RU',
                hideModeSwitch: false,
                events: {}
            }, this.options);
            if (isArray(this.toolbar)) {
                options.toolbarItems = this.toolbar;
            } else {
                switch (this.toolbar) {
                    case 'full':
                        options.toolbarItems = this.getFullToolbarItems();
                        break;
                    case 'standard':
                        options.toolbarItems = this.getStandardToolbarItems();
                        break;
                    case 'minimal':
                        options.toolbarItems = this.getMinimalToolbarItems();
                        break;
                    case 'options':
                    default:
                        break;
                }
            }
            options.el = this.editorElementRef.nativeElement;
            options.events.change = () => {
                this.onChange();
            };
            this.editor = new TuiEditor(options);
        });
    }

    public ngOnDestroy(): void {
        if (this.controlStatusSubscription) this.controlStatusSubscription.unsubscribe();
        if (this.editor) this.editor.remove();
    }

    protected onChange(): void {
        if (!this.editor) {
            return;
        }
        let markdown = this.editor.getMarkdown();
        this.contentChange.emit(markdown);
        if (this.formControl) {
            this.formControl.setValue(markdown);
        }
    }

    protected getMinimalToolbarItems(): string[] {
        return [
            'bold', 'italic', 'strike'
        ];
    }

    protected getStandardToolbarItems(): string[] {
        return [
            'heading', 'bold', 'italic', 'strike', 'divider',
            'hr', 'quote', 'divider',
            'ul', 'ol', 'divider',
            'table', 'link'
        ];
    }

    protected getFullToolbarItems(): string[] {
        return [
            'heading', 'bold', 'italic', 'strike', 'divider',
            'hr', 'quote', 'divider',
            'ul', 'ol', 'task', 'indent', 'outdent', 'divider',
            'table', 'image', 'link', 'divider',
            'code', 'codeblock'
        ];
    }

}
