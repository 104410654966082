import { AccessType, CacheKey, Deserializer, JsonIgnore, JsonProperty, Serializer } from "json-object-mapper";
import { Company } from "company/company";
import { City } from "location/city";
import { Hotel } from "hotel/hotel";
import { DateSerializerDeserializer } from "app/date";

export enum VacancyStatus {
    Disabled = 'disabled',
    Enabled = 'enabled',
    Deleted = 'deleted',
    Archived = 'archived'
}

@CacheKey("VacancyStatusSerializerDeserializer")
class VacancyStatusSerializerDeserializer implements Deserializer, Serializer {

    public deserialize(value: string): VacancyStatus {
        return VacancyStatus[value.charAt(0).toUpperCase() + value.slice(1).toLowerCase()];
    }

    public serialize(value: VacancyStatus): string {
        return '"' + VacancyStatus[value.charAt(0).toUpperCase() + value.slice(1).toLowerCase()].toLowerCase() + '"';
    }

}

export class Vacancy {

    @JsonProperty({name: 'vacancy_id', access: AccessType.READ_ONLY})
    public vacancyId: number;

    @JsonProperty({
        type: VacancyStatus,
        serializer: VacancyStatusSerializerDeserializer,
        deserializer: VacancyStatusSerializerDeserializer
    })
    public status: VacancyStatus;

    @JsonIgnore()
    public isEnabled(): boolean {
        return this.status == VacancyStatus.Enabled;
    }

    @JsonIgnore()
    public isDisabled(): boolean {
        return this.status == VacancyStatus.Disabled;
    }

    @JsonIgnore()
    public isDeleted(): boolean {
        return this.status == VacancyStatus.Deleted;
    }

    @JsonIgnore()
    public isArchived(): boolean {
        return this.status == VacancyStatus.Archived;
    }

    @JsonProperty({
        name: 'creation_date',
        access: AccessType.READ_ONLY,
        type: Date,
        serializer: DateSerializerDeserializer,
        deserializer: DateSerializerDeserializer
    })
    public creationDate: Date;

    @JsonProperty({
        name: 'update_date',
        access: AccessType.READ_ONLY,
        type: Date,
        serializer: DateSerializerDeserializer,
        deserializer: DateSerializerDeserializer
    })
    public updateDate: Date;

    @JsonProperty({name: 'company', type: Company, access: AccessType.READ_ONLY})
    public company: Company;

    @JsonIgnore()
    private _companyId: number;

    public get companyId(): number {
        if (this.company) {
            return this.company.companyId;
        }
        return this._companyId;
    }

    @JsonProperty({name: "company_id", access: AccessType.WRITE_ONLY})
    public set companyId(companyId: number) {
        if (this.companyId != companyId) {
            this.company = null;
        }
        this._companyId = companyId;
    }

    @JsonProperty({name: 'city', type: City, access: AccessType.READ_ONLY})
    public city: City;

    @JsonIgnore()
    private _cityId: number;

    public get cityId(): number {
        if (this.city) {
            return this.city.cityId;
        }
        return this._cityId;
    }

    @JsonProperty({name: "city_id", access: AccessType.WRITE_ONLY})
    public set cityId(cityId: number) {
        if (this.cityId != cityId) {
            this.city = null;
        }
        this._cityId = cityId;
    }

    @JsonProperty({name: 'hotel', required: false, type: Hotel, access: AccessType.READ_ONLY})
    public hotel?: Hotel;

    @JsonIgnore()
    private _hotelId: number;

    public get hotelId(): number {
        if (this.hotel) {
            return this.hotel.hotelId;
        }
        return this._hotelId;
    }

    @JsonProperty({name: "hotel_id", required: false, access: AccessType.WRITE_ONLY})
    public set hotelId(hotelId: number) {
        if (this.hotelId != hotelId) {
            this.hotel = null;
        }
        this._hotelId = hotelId;
    }

    @JsonProperty()
    public title: string;

    @JsonProperty()
    public description: string;

}

export class CompanyInfo {
    @JsonProperty()
    public description: string;
}
