<div class="ibox-content">
    <form [formGroup]="vacancyForm" (ngSubmit)="onSubmit()">
        <div *ngIf="showCompanies()" class="form-group row"
             [class.has-error]="vacancyForm.controls.company.value && !vacancyForm.controls.company.disabled && !vacancyForm.controls.company.valid">
            <label class="col-2 col-form-label" for="form-vacancy-company">Компания:</label>
            <div class="col-10">
                <select class="form-control" id="form-vacancy-company" formControlName="company">
                    <option *ngFor="let company of companies; trackBy: trackByCompany" [value]="company.companyId">
                        {{ company.name }}
                    </option>
                </select>
            </div>
        </div>
        <div *ngIf="showHotels()" class="form-group row"
             [class.has-error]="vacancyForm.controls.hotel.value && !vacancyForm.controls.hotel.disabled && !vacancyForm.controls.hotel.valid">
            <label class="col-2 col-form-label" for="form-vacancy-hotel">Отель:</label>
            <div class="col-10">
                <select class="form-control" id="form-vacancy-hotel" formControlName="hotel">
                    <option *ngIf="showEmptyHotelField" value="0">Не выбран</option>
                    <option *ngFor="let hotel of hotels; trackBy: trackByHotel" [value]="hotel.hotelId">
                        {{ hotel.name }}
                    </option>
                </select>
            </div>
        </div>
        <div *ngIf="showCities()" class="form-group row"
             [class.has-error]="vacancyForm.controls.city.value && !vacancyForm.controls.city.disabled && !vacancyForm.controls.city.valid">
            <label class="col-2 col-form-label" for="form-vacancy-city">Город:</label>
            <div class="col-10">
                <select class="form-control" id="form-vacancy-city" formControlName="city">
                    <option *ngFor="let city of cities; trackBy: trackByCity" [value]="city.cityId">
                        {{ city.name }}
                    </option>
                </select>
            </div>
        </div>
        <vacancy-form [vacancyForm]="vacancyForm"></vacancy-form>
        <div class="form-group row">
            <div class="col-10 offset-2">
                <a class="btn btn-white"
                   [routerLink]="['/vacancies']">Отмена</a>
                &nbsp;
                <button class="btn btn-primary" type="submit" [disabled]="!vacancyForm.valid">Сохранить</button>
            </div>
        </div>
    </form>
</div>
