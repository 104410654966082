import { Component, OnInit } from "@angular/core";
import { AuthService } from "app/auth/auth.service";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { TimeZoneService } from "app/timezone.service";
import { HttpClient } from "@angular/common/http";
import { City } from "location/city";
import { JsonProperty, ObjectMapper } from "json-object-mapper";
import { AbstractEmployee, Employee } from "company/employee";
import { Router } from "@angular/router";
import { ActionService } from "../action.service";
import * as Cookies from 'js-cookie';
import * as moment from 'moment';

export class TimezoneForm extends FormGroup {

    public constructor() {
        super({
            timezone: new FormControl(null, [
                Validators.required
            ]),
            city: new FormControl(null, [
                Validators.required
            ])
        });
    }

}

export class ProfileModel extends AbstractEmployee {
}

export class ProfileRequest {

    public constructor(employee: Employee) {
        this.user = new ProfileModel(employee);
    }

    @JsonProperty({type: ProfileModel})
    public user: ProfileModel;
}

@Component({
    selector: 'action-timezone',
    templateUrl: './timezone.component.html'
})
export class TimezoneComponent implements OnInit {
    protected static readonly COOKIE_NAME = 'action-timezone';

    public static isIllegalTimezone(authService: AuthService): boolean {
        let user = authService.getUser();
        if (TimezoneComponent.getBrowserTimezone() == user.timezone) {
            return false;
        }
        if (Cookies.get(TimezoneComponent.COOKIE_NAME)) {
            return Cookies.get(TimezoneComponent.COOKIE_NAME) != user.timezone;
        }
        return true;
    }

    public static getBrowserTimezone(): string {
        return moment.tz.guess();
    }

    public timezoneForm: TimezoneForm = new TimezoneForm();
    public timezones: string[] = [];
    public cities: City[] = [];

    public constructor(
        protected authService: AuthService,
        protected timeZoneService: TimeZoneService,
        protected httpClient: HttpClient,
        protected router: Router,
        protected actionService: ActionService
    ) {
    }

    public ngOnInit(): void {
        Cookies.remove(TimezoneComponent.COOKIE_NAME);
        this.timeZoneService.getTimeZones().then(timezones => {
            this.timezones = timezones;
            this.timezoneForm.get('timezone').setValue(this.getProfileTimezone());
        });
        this.httpClient.get<{ cities: any }>("/v2/locations/cities").subscribe(
            data => {
                this.cities = ObjectMapper.deserializeArray(City, data.cities);
                this.timezoneForm.get('city').setValue(this.authService.getUser().cityId);
                let lastCityId: number = this.timezoneForm.get('city').value;
                this.timezoneForm.get('city').valueChanges.subscribe(value => {
                    this.onCityChanged(lastCityId, value);
                    lastCityId = value;
                });
            }, error => {
                console.error('Get cities error:', error);
                throw error;
            }
        );
    }

    public getBrowserTimezone(): string {
        return TimezoneComponent.getBrowserTimezone();
    }

    public getProfileTimezone(): string {
        return this.authService.getUser().timezone;
    }

    public onSubmit(): void {
        this.timezoneForm.disable();
        let user = this.authService.getUser();
        user.timezone = this.timezoneForm.get('timezone').value;
        user.cityId = this.timezoneForm.get('city').value;
        this.httpClient.post<{ user: any }>('/v2/user', ObjectMapper.serialize(
            new ProfileRequest(user)
        )).subscribe((data) => {
            let user = ObjectMapper.deserialize(ProfileModel, data.user);
            this.authService.setEmployee(new Employee(
                user, this.authService.getUser().company, this.authService.getUser().permissions
            ));
            if (this.getBrowserTimezone() != this.getProfileTimezone()) {
                Cookies.set(TimezoneComponent.COOKIE_NAME, this.getProfileTimezone(), {expires: new Date(2022, 12)});
            }
            this.actionService.resolveAction('timezone');
            this.router.navigate(['/actions']);
        }, (error) => {
            console.error("Update user error:", error);
            this.timezoneForm.enable();
            throw error;
        });
    }

    public trackByCity(index: number, city: City): number {
        return city.cityId;
    }

    protected onCityChanged(oldId: number, newId: number): void {
        if (this.timezoneForm.enabled && (!oldId
            || this.getCity(oldId).timezone == this.timezoneForm.get('timezone').value)) {
            this.timezoneForm.get('timezone').setValue(this.getCity(newId).timezone);
        }
    }

    protected getCity(cityId: number): City | null {
        for (let city of this.cities) {
            if (city.cityId == cityId) {
                return city;
            }
        }
        return null;
    }

}
