import { Injectable } from "@angular/core";
import { Router, Event, NavigationEnd } from "@angular/router";

@Injectable()
export class TitleService {

    public title: string | null = null;
    public small: string | null = null;

    public constructor(
        protected router: Router
    ) {
        router.events.subscribe(event => this.onRounterEvent(event));
    }

    protected onRounterEvent(event: Event): void {
        if (event instanceof NavigationEnd) {
            this.title = null;
            this.small = null;
        }
    }

}
