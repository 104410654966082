import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, CanLoad, Route, RouterStateSnapshot } from "@angular/router";
import { AuthService } from "./auth.service";

@Injectable()
export class ManagementCompanyGuard implements CanActivate, CanLoad {

    public constructor(
        protected authService: AuthService
    ) {
    }

    public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        return this.can();
    }

    public canLoad(route: Route): boolean {
        return this.can();
    }

    protected can(): boolean {
        if (!this.authService.isAuthenticated()) {
            return false;
        }
        return this.authService.getUser().company.isManagementCompany();
    }

}

@Injectable()
export class NotManagementCompanyGuard extends ManagementCompanyGuard {
    protected can(): boolean {
        return !super.can();
    }
}
