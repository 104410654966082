import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from "@angular/router";
import { PermissionsService } from "app/permissions.service";
import { AuthService } from "app/auth/auth.service";

@Injectable()
export class CanEditHotelRoomsGuard implements CanActivate {

    public constructor(
        protected permissionsService: PermissionsService
    ) {
    }

    public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        if (!route.params.alias) {
            return false;
        }
        return this.permissionsService.canEditRooms(route.params.alias);
    }

}

@Injectable()
export class CanEditHotelEmployeesGuard implements CanActivate {

    public constructor(
        protected permissionsService: PermissionsService
    ) {
    }

    public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        if (!route.params.alias) {
            return false;
        }
        return this.permissionsService.canEditEmployees(route.params.alias);
    }

}

@Injectable()
export class CanEditHotelPricesGuard implements CanActivate {

    public constructor(
        protected permissionsService: PermissionsService
    ) {
    }

    public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        if (!route.params.alias) {
            return false;
        }
        return this.permissionsService.canEditPrices(route.params.alias);
    }

}

@Injectable()
export class AllHotelsGuard implements CanActivate {

    public constructor(
        protected authService: AuthService
    ) {
    }

    public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        let user = this.authService.getUser();
        if (user.company.isManagementCompany()) {
            return true;
        }
        if (user.company.isFranchiseeCompany()) {
            return user.hotels.length > 1;
        }
        return false;
    }

}
