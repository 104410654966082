import { NgModule } from "@angular/core";
import { ProfileRoutingModule } from "./profile-routing.module";
import { ReactiveFormsModule } from "@angular/forms";
import { CommonModule } from "@angular/common";
import { ProfileComponent } from "./profile/profile.component";
import { CompanyCommonModule } from "company/company-common.module";

@NgModule({
    imports: [
        ProfileRoutingModule,
        ReactiveFormsModule,
        CommonModule, CompanyCommonModule
    ],
    declarations: [
        ProfileComponent
    ]
})
export class ProfileModule {
}
