import { Component, Input } from "@angular/core";
import { HotelType } from "hotel/hotel";
import { DashboardHotel } from "./franchisee.component";

@Component({
    selector: 'dashboard-franchisee-hotel-card',
    templateUrl: './franchisee-hotel-card.component.html'
})
export class FranchiseeHotelCardComponent {
    public HotelType = HotelType;

    @Input('hotel')
    public hotel: DashboardHotel;

}
