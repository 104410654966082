<fieldset [formGroup]="roomForm">
    <div class="form-group row"
         [class.has-error]="roomForm.controls.category.value && !roomForm.controls.category.disabled && !roomForm.controls.category.valid">
        <label class="col-2 col-form-label" for="form-hotel-room-category">Категория:</label>
        <div class="col-10">
            <select class="form-control" id="form-hotel-room-category"
                    formControlName="category">
                <option *ngFor="let category of categories; trackBy: trackByCategory" [value]="category.roomCategoryId">
                    {{ getCategoryName(category) }}
                </option>
            </select>
        </div>
    </div>
    <div class="form-group row"
         [class.has-error]="!roomForm.controls.number.disabled && roomForm.controls.number.value && !roomForm.controls.number.valid">
        <label class="col-2 col-form-label" for="form-hotel-room-number">Уникальный номер:</label>
        <div class="col-10">
            <input type="text" class="form-control"
                   id="form-hotel-room-number" formControlName="number">
        </div>
    </div>
</fieldset>
