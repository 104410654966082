import { Component, Injectable } from "@angular/core";
import { JsonProperty, ObjectMapper } from "json-object-mapper";
import { City } from "../city";
import { HttpClient } from "@angular/common/http";
import { ActivatedRoute, ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from "@angular/router";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { TitleService as LayoutTitleService } from "layout/title.service";
import { BreadcrumbService } from "layout/breadcrumb.service";

export class CitiesData {
    @JsonProperty({type: City})
    public cities: City[];
}

@Injectable()
export class CitiesResolver implements Resolve<CitiesData> {

    public constructor(
        protected httpClient: HttpClient
    ) {
    }

    public resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<CitiesData> {
        return this.httpClient.get<CitiesData>("/v2/locations/cities")
            .pipe(map(data => ObjectMapper.deserialize(CitiesData, data)));
    }

}

@Component({
    selector: 'location-cities-action',
    templateUrl: './cities-action.component.html'
})
export class CitiesActionComponent {
}

@Component({
    selector: 'location-cities',
    templateUrl: './cities.component.html'
})
export class CitiesComponent {

    public cities: City[];

    public constructor(
        protected route: ActivatedRoute,
        protected layoutTitleService: LayoutTitleService,
        protected breadcrumbService: BreadcrumbService
    ) {
    }

    public ngOnInit(): void {
        this.layoutTitleService.title = 'Города';
        this.breadcrumbService.breadcrumbs = [{name: 'Города'}];
        this.route.data.subscribe((data) => {
            this.cities = data.component.cities;
        });
    }

    public trackByCity(index: number, city: City): number {
        return city.cityId;
    }

}
