<div class="ibox-content">
    <div class="table-responsive">
        <table class="table table-hover">
            <thead>
            <tr>
                <th>ID</th>
                <th>Номер</th>
                <th>Категория</th>
                <th>Цена</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let room of rooms; trackBy: trackByRoom">
                <th [class.del]="room.isDisabled()">
                    <a [routerLink]="['/hotels', hotel.alias, 'rooms', room.number]">
                        {{ room.roomId }}
                    </a>
                </th>
                <td [class.del]="room.isDisabled()">
                    <i *ngIf="room.isEnabled()" class="far fa-check-circle text-navy m-r" title="Номер включён"></i>
                    <i *ngIf="room.isDisabled()" class="fas fa-ban text-danger m-r" title="Номер заблокирован"></i>
                    <a [routerLink]="['/hotels', hotel.alias, 'rooms', room.number]">
                        {{ room.number }}
                    </a>
                </td>
                <td>
                    {{ room.category.name }}
                    <small>{{ getCategoryTypeName(room.category) }}</small>
                </td>
                <td *ngIf="room.category.price && room.category.price.value">
                    {{ room.category.price.value / 100 }}₽
                    <ng-template [ngIf]="room.category.price.additional">
                        / {{ room.category.price.additional / 100 }}₽
                    </ng-template>
                </td>
                <td *ngIf="!room.category.price || !room.category.price.value">
                    <i>не указана</i>
                </td>
            </tr>
            <tr *ngIf="rooms.length == 0">
                <td colspan="4" class="text-center">
                    Номера не найдены
                    <a *canEditHotelRooms="hotel" class="m-l"
                       [routerLink]="['/hotels', hotel.alias, 'rooms', 'new']">(добавить новый)</a>
                </td>
            </tr>
            </tbody>
        </table>
    </div>
</div>
