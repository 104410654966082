import { Component, Injectable, OnInit } from "@angular/core";
import { ActivatedRoute, ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot } from "@angular/router";
import { HttpClient } from "@angular/common/http";
import { CompanyForm } from "./company.form";
import { JsonProperty, ObjectMapper } from "json-object-mapper";
import { Company } from "../company";
import { Observable, forkJoin } from "rxjs";
import { map } from "rxjs/operators";
import { TitleService as LayoutTitleService } from "layout/title.service";
import { BreadcrumbService } from "layout/breadcrumb.service";
import { City } from "location/city";

export class EditCompanyData {
    @JsonProperty({type: Company})
    public company: Company;

    @JsonProperty({type: City})
    public cities: City[];
}

@Injectable()
export class EditCompanyResolver implements Resolve<EditCompanyData> {

    public constructor(
        protected httpClient: HttpClient
    ) {
    }

    public resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<EditCompanyData> {
        return forkJoin(
            this.httpClient.get('/v2/companies/' + route.params.companyId),
            this.httpClient.get("/v2/locations/cities")
        ).pipe(map(data => ObjectMapper.deserialize(EditCompanyData, Object.assign({}, data[0], data[1]))));
    }

}

@Component({
    selector: 'company-company-edit',
    templateUrl: './edit-company.component.html'
})
export class EditCompanyComponent implements OnInit {

    public companyForm: CompanyForm = new CompanyForm(this.httpClient);
    public company: Company;
    public cities: City[];

    public constructor(
        protected httpClient: HttpClient,
        protected router: Router,
        protected route: ActivatedRoute,
        protected layoutTitleService: LayoutTitleService,
        protected breadcrumbService: BreadcrumbService
    ) {
    }

    public ngOnInit(): void {
        this.route.data.subscribe((data) => {
            this.company = data.component.company;
            this.cities = data.component.cities;
            this.companyForm = new CompanyForm(this.httpClient, this.company);
            this.layoutTitleService.title = this.company.name;
            this.layoutTitleService.small = 'Редактирование компании';
            this.breadcrumbService.breadcrumbs = [
                {name: 'Компании', link: '/companies'},
                {name: this.company.name, link: ['/companies', this.company.companyId]},
                {name: 'Редактирование'}
            ];
            this.companyForm.get('name').valueChanges.subscribe((name) => {
                this.layoutTitleService.title = name || 'Компания';
                this.breadcrumbService.breadcrumbs = [
                    {name: 'Компании', link: '/companies'},
                    {name: name || 'Компания', link: ['/companies', this.company.companyId]},
                    {name: 'Редактирование'}
                ];
            });
        });
    }

    public onSubmit(): void {
        this.companyForm.disable();
        this.httpClient.put<{ company: any }>('/v2/companies/' + this.company.companyId, ObjectMapper.serialize({
            company: this.companyForm.getCompany()
        })).toPromise().then((data) => {
            let company = ObjectMapper.deserialize(Company, data.company);
            this.router.navigate(['/companies', company.companyId]);
        }).catch((error: any) => {
            console.error("Save company error:", error);
            this.companyForm.enable();
            throw error;
        });
    }

}
