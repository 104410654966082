import { Component, Injectable, OnInit } from "@angular/core";
import { JsonProperty, ObjectMapper } from "json-object-mapper";
import { Vacancy } from "./vacancy";
import { ActivatedRoute, ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot } from "@angular/router";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { TitleService as LayoutTitleService } from "layout/title.service";
import { BreadcrumbService } from "layout/breadcrumb.service";
import { VacancyForm } from "./vacancy.form";

export class EditVacancyData {
    @JsonProperty({type: Vacancy})
    public vacancy: Vacancy;
}

@Injectable()
export class EditVacancyResolver implements Resolve<EditVacancyData> {

    public constructor(
        protected httpClient: HttpClient
    ) {
    }

    public resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<EditVacancyData> {
        return this.httpClient.get<EditVacancyData>('/v2/vacancies/' + route.params.vacancyId)
            .pipe(map(data => ObjectMapper.deserialize(EditVacancyData, data)));
    }

}

@Component({
    selector: 'vacancy-edit',
    templateUrl: './edit-vacancy.component.html'
})
export class EditVacancyComponent implements OnInit {
    public vacancy: Vacancy;
    public vacancyForm: VacancyForm;

    public constructor(
        protected route: ActivatedRoute,
        protected layoutTitleService: LayoutTitleService,
        protected breadcrumbService: BreadcrumbService,
        protected httpClient: HttpClient,
        protected router: Router
    ) {
    }

    public ngOnInit(): void {
        this.route.data.subscribe(data => {
            this.vacancy = data.component.vacancy;
            this.layoutTitleService.title = this.vacancy.title;
            this.breadcrumbService.breadcrumbs = [
                {name: 'Вакансии', link: '/vacancies'},
                {name: this.vacancy.title, link: ['/vacancies', this.vacancy.vacancyId]},
                {name: 'Редактирование'}
            ];
            this.vacancyForm = new VacancyForm(this.vacancy);
        });
    }

    public onSubmit(): void {
        this.vacancyForm.disable();
        let vacancy = this.vacancyForm.getVacancy();
        vacancy.company = this.vacancy.company;
        if (this.vacancy.hotel) {
            vacancy.hotel = this.vacancy.hotel;
        } else {
            vacancy.city = this.vacancy.city;
        }
        this.httpClient.put<{ vacancy: any }>('/v2/vacancies/' + this.vacancy.vacancyId, ObjectMapper.serialize({
            vacancy: vacancy
        })).toPromise().then((data) => {
            let vacancy = ObjectMapper.deserialize(Vacancy, data.vacancy);
            this.router.navigate(['/vacancies', vacancy.vacancyId]);
        }).catch((error: any) => {
            console.error("Save vacancy error:", error);
            this.vacancyForm.enable();
            throw error;
        });
    }

}
