<div id="wrapper">
    <layout-sidebar></layout-sidebar>
    <div id="page-wrapper" class="gray-bg">
        <layout-topbar></layout-topbar>
        <layout-heading></layout-heading>

        <div [ngClass]="{'wrapper wrapper-content' : isContentWrapper()}">
            <router-outlet></router-outlet>
        </div>

        <div class="footer">
            <div class="float-right">
                ERP <strong>v{{ appVersion }}</strong>
            </div>
            <div>
                <strong>Лайк Хостел</strong>
                &copy; 2014-2018
            </div>
        </div>
    </div>
</div>
