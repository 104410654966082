import { Component, Injectable, OnDestroy, OnInit } from "@angular/core";
import { mergeRouteData } from "app/merge-route-data";
import { ActivatedRoute, ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot } from "@angular/router";
import { HttpClient } from "@angular/common/http";
import { Observable, forkJoin } from "rxjs";
import { Hotel } from "../hotel";
import { map } from "rxjs/operators";
import { JsonProperty, ObjectMapper } from "json-object-mapper";
import { Room } from "../room";
import { TitleService as LayoutTitleService } from "layout/title.service";
import { BreadcrumbService } from "layout/breadcrumb.service";
import { RoomForm } from "./room.form";
import { RoomCategory } from "../room-category";
import { HotelSidebarService } from "layout/sidebar/hotel.service";

export class EditRoomData {
    @JsonProperty({type: Hotel})
    public hotel: Hotel;

    @JsonProperty({type: Room})
    public room: Room;

    @JsonProperty({type: RoomCategory})
    public categories: RoomCategory[];
}

@Injectable()
export class EditRoomResolver implements Resolve<EditRoomData> {

    public constructor(
        protected httpClient: HttpClient
    ) {
    }

    public resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<EditRoomData> {
        let hotel: Hotel = mergeRouteData(route).component.hotel;
        return forkJoin(
            this.httpClient.get('/v2/hotels/' + hotel.hotelId + '/rooms/' + route.params.roomNumber),
            this.httpClient.get('/v2/hotels/' + hotel.hotelId + '/rooms/categories')
        ).pipe(map(data => Object.assign({}, data[0], data[1])))
            .pipe(map(data => ObjectMapper.deserialize(EditRoomData, data)));
    }

}

@Component({
    selector: 'hotel-room-edit',
    templateUrl: './edit-room.component.html'
})
export class EditRoomComponent implements OnInit, OnDestroy {
    public hotel: Hotel;
    public room: Room;
    public categories: RoomCategory[];
    public roomForm: RoomForm;

    public constructor(
        protected route: ActivatedRoute,
        protected httpClient: HttpClient,
        protected router: Router,
        protected layoutTitleService: LayoutTitleService,
        protected breadcrumbService: BreadcrumbService,
        protected hotelSidebarService: HotelSidebarService
    ) {
    }

    public ngOnInit(): void {
        this.route.data.subscribe((data) => {
            this.hotel = data.component.hotel;
            this.room = data.component.room;
            this.categories = data.component.categories;
            this.roomForm = new RoomForm(this.httpClient, this.hotel, this.room);
            this.layoutTitleService.title = this.room.number;
            this.layoutTitleService.small = 'Редактирование номера';
            this.breadcrumbService.breadcrumbs = [
                {name: 'Отели', link: '/hotels'},
                {name: this.hotel.name, link: ['/hotels', this.hotel.alias]},
                {name: 'Номера', link: ['/hotels', this.hotel.alias, 'rooms']},
                {name: this.room.number, link: ['/hotels', this.hotel.alias, 'rooms', this.room.number]},
                {name: 'Редактирование'}
            ];
            this.roomForm.get('number').valueChanges.subscribe((value) => {
                this.layoutTitleService.title = value || 'Номер';
                this.breadcrumbService.breadcrumbs = [
                    {name: 'Отели', link: '/hotels'},
                    {name: this.hotel.name, link: ['/hotels', this.hotel.alias]},
                    {name: 'Номера', link: ['/hotels', this.hotel.alias, 'rooms']},
                    {name: value || 'Номер', link: ['/hotels', this.hotel.alias, 'rooms', this.room.number]},
                    {name: 'Редактирование'}
                ];
            });
            this.hotelSidebarService.setHotel(this.hotel);
        });
    }

    public ngOnDestroy(): void {
        this.hotelSidebarService.clearHotel();
    }

    public onSubmit(): void {
        this.roomForm.disable();
        this.httpClient.put<{ room: any }>('/v2/hotels/' + this.hotel.hotelId + '/rooms/' + this.room.roomId, ObjectMapper.serialize({
            room: this.roomForm.getRoom()
        })).toPromise().then((data) => {
            let room = ObjectMapper.deserialize(Room, data.room);
            this.router.navigate(['/hotels', this.hotel.alias, 'rooms', room.number]);
        }).catch((error: any) => {
            console.error("Save room error:", error);
            this.roomForm.enable();
            throw error;
        });
    }

}
