import { Component, Injectable, OnInit } from "@angular/core";
import { JsonProperty, ObjectMapper } from "json-object-mapper";
import { Vacancy } from "./vacancy";
import { ActivatedRoute, ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from "@angular/router";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { TitleService as LayoutTitleService } from "layout/title.service";
import { BreadcrumbService } from "layout/breadcrumb.service";
import { AuthService } from "app/auth/auth.service";
import { VacancyResponse } from "./vacancy-response";

export class VacancyData {
    @JsonProperty({type: Vacancy})
    public vacancy: Vacancy;

    @JsonProperty({type: VacancyResponse})
    public responses: VacancyResponse[];
}

@Injectable()
export class VacancyResolver implements Resolve<VacancyData> {

    public constructor(
        protected httpClient: HttpClient
    ) {
    }

    public resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<VacancyData> {
        return this.httpClient.get<VacancyData>('/v2/vacancies/' + route.params.vacancyId)
            .pipe(map(data => ObjectMapper.deserialize(VacancyData, data)));
    }

}

@Component({
    selector: 'vacancy-vacancy',
    templateUrl: './vacancy.component.html'
})
export class VacancyComponent implements OnInit {
    public vacancy: Vacancy;
    public responses: VacancyResponse[];

    public constructor(
        protected route: ActivatedRoute,
        protected layoutTitleService: LayoutTitleService,
        protected breadcrumbService: BreadcrumbService,
        protected authService: AuthService
    ) {
    }

    public ngOnInit(): void {
        this.route.data.subscribe(data => {
            this.vacancy = data.component.vacancy;
            this.responses = data.component.responses;
            this.layoutTitleService.title = this.vacancy.title;
            this.breadcrumbService.breadcrumbs = [{name: 'Вакансии', link: '/vacancies'}];
            if (this.vacancy.isArchived()) {
                this.breadcrumbService.breadcrumbs.push({name: 'Архив', link: '/vacancies/archive'});
            }
            this.breadcrumbService.breadcrumbs.push({name: this.vacancy.title});
        });
    }

    public showUpdateDate(): boolean {
        if (!this.vacancy) {
            return false;
        }
        return this.vacancy.updateDate.getTime() > this.vacancy.creationDate.getTime();
    }

    public showCompanyInfoLink(): boolean {
        if (!this.authService.getUser().company.isManagementCompany()) {
            return false;
        }
        return this.vacancy.company.companyId != this.authService.getUser().company.companyId;
    }

    public trackByVacancyResponse(index: number, vacancyResponse: VacancyResponse): number {
        return vacancyResponse.vacancyResponseId;
    }

}
