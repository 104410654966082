import {
    AccessType,
    CacheKey,
    Deserializer,
    JsonIgnore,
    JsonProperty,
    ObjectMapper,
    Serializer
} from "json-object-mapper";
import { DateSerializerDeserializer } from "app/date";
import { RoomCategory } from "./room-category";
import { isArray } from "util";

export enum PriceStatus {
    Disabled = 'disabled',
    Enabled = 'enabled',
    Deleted = 'deleted'
}

@CacheKey("PriceStatusSerializerDeserializer")
class PriceStatusSerializerDeserializer implements Deserializer, Serializer {

    public deserialize(value: string): PriceStatus {
        return PriceStatus[value.charAt(0).toUpperCase() + value.slice(1).toLowerCase()];
    }

    public serialize(value: PriceStatus): string {
        return '"' + PriceStatus[value.charAt(0).toUpperCase() + value.slice(1).toLowerCase()].toLowerCase() + '"';
    }

}

export abstract class PriceItem {

    @JsonProperty({name: 'item_type'})
    public itemType: string;

    @JsonProperty({name: 'item_id'})
    public itemId: number;

    @JsonProperty()
    public value: number;

    @JsonProperty()
    public item: any;

}

export class HotelRoomCategoryPriceItem extends PriceItem {
    @JsonProperty({type: RoomCategory})
    public item: RoomCategory;
}

export class HotelRoomCategoryAdditionalPriceItem extends PriceItem {
    @JsonProperty({type: RoomCategory})
    public item: RoomCategory;
}

@CacheKey("PriceItemDeserializer")
export class PriceItemDeserializer implements Deserializer {

    public deserialize(value: any): PriceItem[] {
        if (!isArray(value)) {
            value = [value];
        }
        let items: PriceItem[] = [];
        for (let item of value) {
            switch (item.item_type) {
                case "hotel_room_category":
                    items.push(ObjectMapper.deserialize(HotelRoomCategoryPriceItem, item));
                    break;
                case "hotel_room_category_additional":
                    items.push(ObjectMapper.deserialize(HotelRoomCategoryAdditionalPriceItem, item));
                    break;
            }
        }
        return items;
    }

}

export class Price {

    @JsonProperty({name: 'price_id', access: AccessType.READ_ONLY})
    public priceId: number;

    @JsonProperty({
        type: PriceStatus,
        serializer: PriceStatusSerializerDeserializer,
        deserializer: PriceStatusSerializerDeserializer
    })
    public status: PriceStatus;

    @JsonIgnore()
    public isEnabled(): boolean {
        return this.status == PriceStatus.Enabled;
    }

    @JsonIgnore()
    public isDisabled(): boolean {
        return this.status == PriceStatus.Disabled;
    }

    @JsonIgnore()
    public isDeleted(): boolean {
        return this.status == PriceStatus.Deleted;
    }

    @JsonProperty()
    public name: string;

    @JsonProperty({
        name: 'available_from',
        type: Date,
        serializer: DateSerializerDeserializer,
        deserializer: DateSerializerDeserializer
    })
    public availableFrom: Date;

    @JsonProperty({
        name: 'available_to',
        type: Date,
        serializer: DateSerializerDeserializer,
        deserializer: DateSerializerDeserializer,
        required: false
    })
    public availableTo: Date | null;

    @JsonProperty({type: PriceItem, deserializer: PriceItemDeserializer})
    public items: PriceItem[] = [];

}
